import { api } from "../api";
import axios, { CancelTokenSource } from "axios";
import {
  DEVICE_ROUTE,
  CONFIG_ROUTE,
  deleteDeviceRoute,
  editDeviceRoute,
  shareDeviceRoute,
  getDeviceRoute,
  getControllersOfDeviceRoute,
  getDeviceAccessCodeRoute,
  unshareDeviceRoute,
  deleteControllerOfDeviceRoute,
  getControllerOfDeviceRoute,
  AccepsSharedDeviceRoute,
  getDeviceControllerRoute,
  putControllerOfDeviceRoute,
  getDevicesRoute,
  getControllersOfDeviceByArcsysRoute,
  getNumbersOfDevicesRoute,
  getDevicesOfflineRoute,
  getUserDevicesRoute,
  DASHBOARD_MACS_ROUTE,
} from "../routes/arcsys";
import { AddDevice, ShareDevice } from "../types/arcsys";

// Resgatar as configurações do arcsys
export const getConfig = async () => api.get<any>(CONFIG_ROUTE);

// Rota para editar as configurações do sistema
export const editConfig = async (data: any) =>
  api.put<void>(CONFIG_ROUTE, data);

// Adicionar Dispositivo
export const addDevice = async (addDevice: AddDevice) => {
  const resp = await api.post(DEVICE_ROUTE, addDevice);
  return resp;
};

let cancelTokenSourceGetDevices: CancelTokenSource;

// Resgatar apenas os Dispositivos com pending=false
export const getDevices = async (
  pending: boolean,
  queryType?: string,
  general?: string,
) => {
  // Cancele a solicitação anterior antes de fazer uma nova solicitação
  if (cancelTokenSourceGetDevices) {
    cancelTokenSourceGetDevices.cancel();
  }

  // Cria um novo token de cancelamento
  cancelTokenSourceGetDevices = axios.CancelToken.source();
  try {
    const response = await api.get(
      getDevicesRoute(pending, queryType, general),
      {
        cancelToken: cancelTokenSourceGetDevices.token,
      },
    );
    return response;
  } catch (error) {}
};

// Resgatar dados gerais do Dispositivo
export const getDevice = (id: string) => api.get<any>(getDeviceRoute(id));

// Exclui o Dispositivo
export const deleteDevice = (id: string) =>
  api.delete<any>(deleteDeviceRoute(id));

// Descompartilhar o Dispositivo
export const unshareDevice = async (id: string, data: any) =>
  api.post<any>(unshareDeviceRoute(id), data);

// Editar o nome e as horas do Alerta de Falha de Comunicação do Dispositivo
export const editDevice = async (id: string, data: any) =>
  api.put<void>(editDeviceRoute(id), data);

// Compartilhar o Dispositivo via e-mail
export const shareDevice = async (id: string, data: ShareDevice) =>
  api.post<any>(shareDeviceRoute(id), data);

// Resgata a lista de Controladores do Dispositivo
export const getControllersOfDevice = (id: string) =>
  api.get<any>(getControllersOfDeviceRoute(id));

// Rota para resgatar os dispositivos offline dos arcsys
export const getDevicesOffline = () => api.get<any>(getDevicesOfflineRoute());

// Resgata a lista de Controladores do Dispositivo
export const getControllersOfDeviceByArcsys = (id: string) =>
  api.get<any>(getControllersOfDeviceByArcsysRoute(id));

// Resgatar o código de Acesso do Dispositivo
export const getDeviceAccessCode = (id: string) =>
  api.get<any>(getDeviceAccessCodeRoute(id));

// Excluir Controlador do Dispositivo
export const deleteControllerOfDevice = (id: string) =>
  api.delete<void>(deleteControllerOfDeviceRoute(id));

// Alterar o valor do Intervalo de Gravação da página do Controlador
export const putControllerOfDevice = (id: string, data: any) =>
  api.put<void>(putControllerOfDeviceRoute(id), data);

// Resgatar o valor do Intervalo de Gravação da página do Controlador
export const getControllerOfDevice = (id: string) =>
  api.get<void>(getControllerOfDeviceRoute(id));

// Aceitar o compartilhamento do Dispositivo
export const AccepsSharedDevice = async (id: string, data: any) =>
  api.post<any>(AccepsSharedDeviceRoute(id), data);

// Regatar os dados do Controlador, lastMsg e timestamp
export const getDeviceController = (mac: string, ctrl: string) =>
  api.get<any>(getDeviceControllerRoute(mac, ctrl));

// Rota para resgatar a quantidade de dispositivos que irão ser renderizados
export const getNumbersOfDevices = (
  pending: boolean,
  page: number,
  rowsPerPage: number,
  general: string,
  asAdmin: number,
  queryType: string,
  macid?: string,
) =>
  api.get<any>(
    getNumbersOfDevicesRoute(
      pending,
      page,
      rowsPerPage,
      general,
      asAdmin,
      queryType,
      macid,
    ),
  );

// Rota para resgatar os dispositivos do usuário na pág do usuário
export const getUserDevices = (
  pending: boolean,
  page: number,
  rowsPerPage: number,
  general: string,
  queryType: string,
  userId: number,
) =>
  api.get<any>(
    getUserDevicesRoute(pending, page, rowsPerPage, general, queryType, userId),
  );

// Rota para resgatar todos os dispositivos MACs no Dashboard
export const getDashboardMacs = async () => api.get<any>(DASHBOARD_MACS_ROUTE);
