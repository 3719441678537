import React from "react";

import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import i18n from "i18next";

import { getLanguage, languages } from "../translations/languages";
import { getModelos } from "../services/data/modelo";
import { AuthContext } from "../contexts/AuthContext";
import { TOKEN_KEY } from "../auth/constants";
import { api } from "../services/api";
import { updateCtrl } from "../config";
import { Languages } from "../translations/types";
import { editUserById } from "../services/data/user";

interface RowProps {
  language: {
    code: string;
    label: string;
  };
  isMenu?: boolean;
}

const Row = ({ language, isMenu = false }: RowProps) => {
  const { code, label } = language;

  return (
    <>
      <img
        loading="lazy"
        width="20"
        src={`/images/${code}.png`}
        alt={`Flag of ${label}`}
        style={{ marginRight: isMenu ? 0 : 8 }}
      />
      {isMenu ? null : label}
    </>
  );
};

interface LanguageSelectorProps {
  isMenu?: boolean;
}

const LanguageSelector = ({ isMenu = false }: LanguageSelectorProps) => {
  const [currentLanguage, setCurrentLanguage] = React.useState(getLanguage());
  const { authenticated, token, userId } = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageSelect = (language: Languages) => {
    i18n.changeLanguage(language.code);
    handleChangeModels(language.code);

    let langCode: string = language.code;
    if (langCode === "pt") langCode = "pt-br";

    if (authenticated) {
      editUserById(+userId, { language: langCode });
    }
    setCurrentLanguage(language);
    handleClose();
  };

  // Altera no localStorage a linguagem do Cloud confome selecionado no Select Box
  React.useEffect(() => {
    localStorage.setItem("lang", currentLanguage.code);
  }, [currentLanguage.code]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeModels = (lang: string) => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

      // Resgata os modelos JSON conforme a linguagem selecionada
      getModelos().then((res) => {
        if (lang === "pt") {
          localStorage.setItem(
            "ageonRef",
            JSON.stringify(res.data.model["pt-br"]),
          );
        } else if (lang === "en") {
          localStorage.setItem("ageonRef", JSON.stringify(res.data.model.en));
        } else {
          localStorage.setItem("ageonRef", JSON.stringify(res.data.model.es));
        }
        updateCtrl(localStorage.ageonRef);
      });
    }
  };

  return (
    <>
      <Button
        id="language-button"
        aria-controls={open ? "language-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        color="primary"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{ fontSize: 13, fontWeight: "bold" }}
      >
        <Row language={currentLanguage} isMenu={isMenu} />
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "language-button",
        }}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.code}
            onClick={() => handleLanguageSelect(language)}
          >
            <Row language={language} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelector;
