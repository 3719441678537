import React from "react";

import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import useIsMobile from "../../hooks/useIsMobile";

interface EditorProps {
  actualModel: any;
  setActualModel: (e: any) => void;
  setSaveButton: (e: boolean) => void;
  lang: string;
}

// Estrutura de edição do modelo JSON não editável
export const EditorNotEdited = ({
  actualModel,
  setActualModel,
  setSaveButton,
  lang,
}: EditorProps) => {
  const ref: any = React.useRef(false);
  const mobile = useIsMobile();
  const [editor, setEditor] = React.useState<JSONEditor>();

  React.useEffect(() => {
    if (actualModel) {
      if (!editor) {
        const newEditor: any = new JSONEditor(ref.current, {
          mode: "code",
          onChangeText: (e) => {
            setActualModel({ ...actualModel, ...JSON.parse(e) });
            setSaveButton(true);
          },
          onEditable: () => {
            return false;
          },
          mainMenuBar: false,
        });

        newEditor.set({
          [lang]: actualModel[lang],
        });
        setEditor(newEditor);
      } else {
        editor.set({
          [lang]: actualModel[lang],
        });
      }
    }
  }, [actualModel, editor, setActualModel, setSaveButton, lang]);

  return (
    <div
      id="jsoneditor"
      ref={ref}
      style={{
        height: mobile ? "500px" : "950px",
      }}
    />
  );
};
