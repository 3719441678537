import React from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import {
  Edit as EditIcon,
  HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../contexts/ToastContext";
import { Select, yupRequiredString } from "../../../components/Form";
import ErrorMessage from "../../../components/ErrorMessage";
import Modal from "../../Devices/Modal";
import { editSector } from "../../../services/data/sector";
import ModalSelectSetpoint from "./Question/SelectSetpoint";

interface ModalChangeSetpointProps {
  setSectorEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
  setpoints: any[];
  sectorId: string;
  setValue: (e: number) => void;
  sectorData: any;
  setChangingSetpoit: (e: boolean) => void;
}

const ModalChangeSetpoint = ({
  handleOpen,
  handleClose,
  setSectorEdited,
  setpoints,
  sectorId,
  setValue,
  sectorData,
  setChangingSetpoit,
}: ModalChangeSetpointProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);

  // Ações de abrir o modal de dúvidas sobre o selecionar o Setpoint
  const [openModalSelectSetpoint, setOpenModalSelectSetpoint] =
    React.useState<boolean>(false);
  const handleOpenModalSelectSetpoint = () => setOpenModalSelectSetpoint(true);
  const handleCloseModalSelectSetpoint = () => {
    setOpenModalSelectSetpoint(false);
  };

  // Filtrar index do setpoint que vem do payload get dos setores
  const setpointIndex =
    +sectorData.default_resource.split("/")[1].split("s")[1] + 1;
  // Valor inicial do formulario
  const initialSetpointCode = String(
    setpoints.filter((f) => +f?.code === setpointIndex)[0]?.code,
  );

  const formik = useFormik({
    initialValues: {
      setpoint: initialSetpointCode ?? "",
    },
    validationSchema: Yup.object({
      setpoint: yupRequiredString,
    }),
    onSubmit: (values) => {
      const data = {
        default_resource: `setpoints/s${+values.setpoint - 1}`,
      };
      editSector(sectorId, data)
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
          setValue(
            Object.values(sectorData.resource_value)[
              +values.setpoint - 1
            ] as number,
          );
          setSectorEdited(true);

          // Ação de ativar o spinner para carregar o novo setpoint no Gauge
          setChangingSetpoit(true);
          setTimeout(() => {
            setChangingSetpoit(false);
          }, 3500);

          handleClose();
        })
        .catch(() => {
          toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
        });
    },
  });

  // Lista de setpoints
  const options = setpoints.map((value: any, i: number) => (
    <MenuItem value={value.code} key={i}>
      {value.description}
    </MenuItem>
  ));

  // Valor inicial do formulário
  React.useEffect(() => {
    formik.resetForm({
      values: {
        setpoint: initialSetpointCode ?? "",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSetpointCode]);

  // Validação do botão de salvar
  const isFormValid = formik.dirty && formik.isValid;

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.ALTER")} Setpoint
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={1}>
          <Box display="flex" mb={1}>
            <InputLabel sx={{ mt: 1 }}>{t("TEXT.SELECT")} Setpoint:</InputLabel>
            <IconButton color="primary" onClick={handleOpenModalSelectSetpoint}>
              <HelpOutlineIcon color="info" />
            </IconButton>
          </Box>
          <Select
            formik={formik}
            label={`${t("TEXT.SELECT")} Setpoint`}
            name="setpoint"
            options={options}
            disabled={false}
          />
          {formik.errors.setpoint && formik.touched.setpoint ? (
            <ErrorMessage>{formik.errors.setpoint}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: "#C7252C",
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              backgroundColor: "#FFD5D7",
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
      <ModalSelectSetpoint
        handleClose={handleCloseModalSelectSetpoint}
        handleOpen={openModalSelectSetpoint}
      />
    </Modal>
  );
};

export default ModalChangeSetpoint;
