import { useTranslation } from "react-i18next";

import {
  LocalOffer as LocalOfferIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Link as MuiLink,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";

import { green } from "@mui/material/colors";
import React from "react";
import { Link } from "react-router-dom";
import { getRawCtrlConfig } from "../../../config";
import { AuthContext } from "../../../contexts/AuthContext";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import { getUserById } from "../../../services/data/user";
import { Measures } from "../../../services/types/ctrls";
import AvatarIcon from "../../AvatarIcon";

const StyledCard = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  "&>*": {
    fontWeight: "bold !important",
    textAlign: "center",
  },
}));

interface InfoProps {
  device: Measures | undefined;
  mac: string;
  deviceName: string | undefined;
  deviceInfo: any;
  tagName: string;
}

const Info = ({ deviceName, mac, device, deviceInfo, tagName }: InfoProps) => {
  const { t } = useTranslation();
  const { theme } = React.useContext(ThemeModeContext);
  const name = device && getRawCtrlConfig(device.modelo)?.nome;
  const [company, setCompany] = React.useState<string>();
  const { userArcsys } = React.useContext(AuthContext);

  // Resgatar os dados do usuário
  React.useEffect(() => {
    // Resgatar dados gerais do Dispositivo
    // Resgatar os dados do usuário
    if (userArcsys?._id !== deviceInfo?.ownerId) {
      if (deviceInfo) {
        getUserById(deviceInfo?.ownerId).then((res) => {
          if (res?.data) {
            const { data } = res as any;
            const company = (data as any)?.company;
            if (company) {
              setCompany(company);
            }
          }
        });
      }
    } else {
      const company = userArcsys?.company;
      if (company) {
        setCompany(company);
      }
    }
  }, [mac, userArcsys, deviceInfo]);

  return (
    <Card sx={{ width: 270, mb: 2, mt: 1, borderRadius: 3 }}>
      <CardContent sx={{ pb: device && device.end < 33 ? 2 : 0.5 }}>
        {device ? (
          <StyledCard>
            <Typography variant="h5" gutterBottom>
              {name}
            </Typography>
            {device.end < 33 && (
              <Typography variant="body2">
                {t("TEXT.ADDRESS")}: {device.end}
              </Typography>
            )}
          </StyledCard>
        ) : (
          <Skeleton
            variant="rounded"
            width={238}
            height={60}
            sx={{ mb: 1.2 }}
          />
        )}
      </CardContent>
      <Divider />
      {company ? (
        <CardContent
          sx={{
            pr: 0,
          }}
        >
          <List sx={{ pt: 0, pb: 0 }}>
            <ListItem alignItems="flex-start" sx={{ pt: 0, pb: 0 }}>
              <ListItemAvatar>
                <AvatarIcon name={company} bgcolor="#C7252C" />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      fontSize={17}
                      sx={{
                        display: "inline",
                        fontWeight: 450,
                      }}
                    >
                      {company}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      fontSize={15.5}
                      sx={{ display: "inline" }}
                      variant="body2"
                    >
                      {t("TEXT.COMPANY")}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </CardContent>
      ) : (
        <CardContent
          sx={{
            pr: 0,
          }}
        >
          <Skeleton variant="rounded" width={238} height={60} />
        </CardContent>
      )}
      <Divider />
      <CardContent
        sx={{
          "&:hover": {
            backgroundColor: theme === "light" ? "#F5F5F5" : "#343434",
          },
          pr: 0,
        }}
      >
        <MuiLink
          component={Link}
          to={`/devices/${mac}/${deviceInfo?.ownerId}`}
          sx={{
            textDecoration: "inherit",
            pointerEvents: "auto",
          }}
        >
          {deviceName ? (
            <List sx={{ pt: 0, pb: 0 }}>
              <ListItem alignItems="flex-start" sx={{ pt: 0, pb: 0 }}>
                <ListItemAvatar>
                  <AvatarIcon name={deviceName} bgcolor="#C7252C" />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        fontSize={17}
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        fontWeight={450}
                        width={170}
                      >
                        {deviceName}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        fontSize={15.5}
                        sx={{ display: "inline" }}
                        variant="body2"
                      >
                        {t("TEXT.DEVICE")}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          ) : (
            <Skeleton variant="rounded" width={238} height={60} />
          )}
        </MuiLink>
      </CardContent>
      {tagName && (
        <>
          <Divider />
          <CardContent
            sx={{
              pt: 2.5,
              display: "flex",
            }}
          >
            <List sx={{ pt: 0, pb: 0 }}>
              <ListItem
                alignItems="flex-start"
                sx={{ alignItems: "center", pt: 0, pb: 0 }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: "#C7252C" }}>
                    <LocalOfferIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Chip
                        label={tagName}
                        avatar={<LocalOfferOutlinedIcon />}
                        color="success"
                        sx={{
                          mr: 1,
                          mt: 1,
                          fontWeight: 700,
                          backgroundColor: green[100],
                          color: green[800],
                          textTransform: "uppercase",
                          fontSize: 12,
                          "& .MuiChip-avatar": {
                            color: "black",
                          },
                          ...(tagName.length > 15 && {
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            width: 165,
                          }),
                        }}
                      />
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
        </>
      )}
    </Card>
  );
};

export default Info;
