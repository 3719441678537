import React, { useRef } from "react";

import {
  Alert,
  Avatar,
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  ChatBubbleOutlineOutlined as ChatBubbleOutlineOutlinedIcon,
  Send as SendIcon,
  EmailOutlined as EmailOutlinedIcon,
  LocalPhoneOutlined as LocalPhoneOutlinedIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import useIsMobile from "../../../hooks/useIsMobile";
import { ToastContext } from "../../../contexts/ToastContext";
import Modal from "../../../pages/Devices/Modal";
import { sendFeedback } from "../../../services/data/user";
import PhoneMask from "../../Masks/PhoneMask";

interface ModalFeedbackProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const CustomTextField = styled(TextField)`
  fieldset {
    border-radius: 15px;
    border: 1px solid #b2b9c2;
  }
`;

const ModalFeedback = ({ handleOpen, handleClose }: ModalFeedbackProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { toastSuccess, toastError } = React.useContext(ToastContext);
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");

  const form: any = useRef(false);

  const sendEmail = (e: any) => {
    e.preventDefault();

    const data = {
      phone,
      email,
      message,
    };

    sendFeedback(data).then(
      () => {
        toastSuccess(t("TOAST.SUCCESS.FEEDBACK_SENT"));
        setPhone("");
        setEmail("");
        setMessage("");
        handleClose();
      },
      () => {
        toastError(t("TOAST.ERROR.FEEDBACK_SENT"));
      },
    );
  };

  const handleChangePhone = (event: string) => {
    setPhone(event);
  };
  const handleChangeEmail = (event: string) => {
    setEmail(event);
  };
  const handleChangeMessage = (event: string) => {
    setMessage(event);
  };

  const emailValidation =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const isFormValid =
    phone !== "" &&
    !phone.includes("_") &&
    email !== "" &&
    !email.includes(" ") &&
    // eslint-disable-next-line no-useless-escape
    email.match(/[!#$%^&*()+\=[\]{};':"`\\|,<>\/?]/g) === null &&
    email.match(emailValidation) !== null &&
    message !== "";

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 600}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 60 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.FEEDBACK_TITLE")}
      </Typography>
      <Typography mb={3}>{t("TEXT.FEEDBACK_SUBTITLE")}:</Typography>
      <Box>
        <form ref={form} onSubmit={sendEmail}>
          <Box display="grid">
            <CustomTextField
              label="Email"
              name="email"
              color="primary"
              onChange={(e) => handleChangeEmail(e.target.value)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <CustomTextField
              label={t("TEXT.PHONE")}
              name="name"
              color="primary"
              onChange={(e) => handleChangePhone(e.target.value)}
              sx={{ mt: 2, mb: 2 }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneOutlinedIcon />
                  </InputAdornment>
                ),
                inputComponent: PhoneMask as any,
              }}
            />
            <CustomTextField
              label={t("TEXT.MESSAGE")}
              name="message"
              multiline
              rows={mobile ? 2 : 6}
              color="primary"
              onChange={(e) => handleChangeMessage(e.target.value)}
              sx={{ mb: 2, width: "100%" }}
            />
          </Box>
          <Alert
            variant="outlined"
            severity="info"
            sx={{
              fontSize: 14,
              fontWeight: 500,
              ml: 1,
              mt: 1,
              mb: 3,
              maxWidth: 550,
            }}
          >
            {t("TEXT.FEEDBACK_ALERT")}
          </Alert>
          <Box display="flex" justifyContent="space-around" mt={2}>
            <Button
              onClick={handleClose}
              sx={{
                backgroundColor: "#FFD5D7",
                mt: 1,
                borderRadius: 2,
                fontSize: 12,
                p: 1,
                pl: 3,
                pr: 3,
                color: "black",
              }}
            >
              {t("BUTTON.RETURN")}
            </Button>
            <Button
              disabled={!isFormValid}
              variant="contained"
              endIcon={<SendIcon />}
              type="submit"
              sx={{
                width: 120,
                mt: 1,
                borderRadius: 2,
                fontSize: 12,
                p: 1,
                pl: 3,
                pr: 3,
              }}
            >
              {t("BUTTON.SEND")}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalFeedback;
