import React from "react";

import {
  Avatar,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  NotInterestedOutlined as NotInterestedOutlinedIcon,
  HighlightOffOutlined as HighlightOffOutlinedIcon,
  CircleOutlined as CircleOutlinedIcon,
  ControlPointOutlined as ControlPointOutlinedIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useIsMobile, { useIsMediumPage } from "../../../../hooks/useIsMobile";
import { getLanguage } from "../../../../translations/languages";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ThemeModeContext } from "../../../../contexts/ThemeModeContext";
import { api } from "../../../../services/api";
import { TOKEN_KEY } from "../../../../auth/constants";
import { getPlans } from "../../../../services/data/subscriptions";
import { GridColDef } from "@mui/x-data-grid";
import { blue, green } from "@mui/material/colors";
import AvatarIcon from "../../../../components/AvatarIcon";
import {
  convertToDatePaymentPending,
  formatValueCents,
  selectNameByTranslations,
} from "../../parse";
import Spinner from "../../../../components/Spinner";
import { PLANS_AND_SUBSCRIPTIONS } from "../../../../Routes/constants";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../../../contexts/ToastContext";
import DataGridServerSide from "../../../../components/DataGridServerSide";

const SinglePayments = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const smallWindow = window.innerWidth < 1250;
  const mediumPage = useIsMediumPage();
  const navigate = useNavigate();
  const { toastError } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const [payments, setPayments] = React.useState<any[]>([]);
  const [paymentsEdited, setPaymentsEdited] = React.useState<boolean>(false);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [searchedPayment, setSearchedPayment] = React.useState<string>("");
  const [status, setStatus] = React.useState<string>("");
  const [paymentOption, setPaymentOption] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(true);
  const [searchedClicked, setSearchedClicked] = React.useState<string>("");
  const [queryType, setQueryType] = React.useState("device");
  const [totalPages, setTotalPages] = React.useState<number>(0);

  // Selecionar o tipo de pesquisa
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryType(event.target.value);
  };

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        if (pageNumber > 0) {
          // Rota para resgatar lista de pagamentos únicos e recorrências no modo Admin
          getPlans(
            pageNumber,
            rowsPerPage,
            searchedPayment,
            `SINGLEPAYMENT-${queryType}`,
            status,
            1,
          ).then((res) => {
            const { data } = res;
            setPayments(data?.data);
            setTotalPages(data.total);
          });
        }
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, pageNumber, rowsPerPage, searchedPayment, status, token]);

  // Ação ao alterar a página da tabela
  const handleChangePage = (value: number) => {
    setPageNumber(value + 1);
  };

  // Atualizar os dados após a edição de qualquer dado
  React.useEffect(() => {
    if (paymentsEdited) {
      if (pageNumber > 0) {
        // Rota para resgatar lista de pagamentos únicos e recorrências no modo Admin
        getPlans(
          pageNumber,
          rowsPerPage,
          searchedPayment,
          queryType,
          status,
          1,
        ).then((res) => {
          const { data } = res;
          setPayments(data?.data);
          setTotalPages(data.total);
        });
      }
      setPaymentsEdited(false);
    }
  }, [
    pageNumber,
    paymentsEdited,
    rowsPerPage,
    searchedPayment,
    status,
    queryType,
  ]);

  // Armazenar o estado do usestate dos filtros dos Tabs
  React.useEffect(() => {
    if (paymentOption === 1) {
      setStatus("ACTIVE");
      setPageNumber(1);
    } else if (paymentOption === 2) {
      setStatus("INACTIVE");
      setPageNumber(1);
    } else if (paymentOption === 3) {
      setStatus("DELETED");
      setPageNumber(1);
    } else if (paymentOption === 4) {
      setStatus("DELETED");
      setPageNumber(1);
    } else {
      setStatus("");
      setPageNumber(1);
    }
  }, [paymentOption]);

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      payments.map((m, i) => {
        return {
          id: i + 1,
          _id: m?._id,
          mac: m?.mac,
          deviceName: m?.device !== "ND" ? m?.device?.name : "...",
          client: `${m?.user?.name} ${m?.user?.surname} - ${m?.user?.company}`,
          plan: selectNameByTranslations(m, language),
          payable_with:
            m?.payable_with === "courtesy"
              ? t("TEXT.COURTESY")
              : m?.payable_with === "BOLETO"
                ? t("TEXT.BILLET")
                : m?.payable_with === "CREDIT_CARD"
                  ? t("TEXT.CREDIT_CARD")
                  : m?.payable_with === "PIX"
                    ? "PIX"
                    : t("TEXT.ALL"),
          created_at: convertToDatePaymentPending(m?.created_at, language),
          expires_at: m?.expires_at?.replaceAll("-", "/"),
          status:
            m?.mac === "-1" && m?.status === "DELETED"
              ? "AVAILABLE"
              : m?.status,
          value: m?.plan
            ? formatValueCents(m?.plan?.value_cents.toString()) === "R$: 0"
              ? t("TEXT.FREE")
              : formatValueCents(m?.plan?.value_cents.toString())
            : "",
        };
      }),
    [language, payments, t],
  );

  // Filtrar status ativo
  const activeRows = React.useMemo(
    () => rows.filter((f: any) => f.status === "ACTIVE"),
    [rows],
  );

  // Filtrar status expirado
  const expiredRows = React.useMemo(
    () => rows.filter((f: any) => f.status === "INACTIVE"),
    [rows],
  );

  // Filtrar status disponivel
  const availableRows = React.useMemo(
    () => rows.filter((f: any) => f.status === "AVAILABLE"),
    [rows],
  );

  // Filtrar status cancelado
  const canceledRows = React.useMemo(
    () => rows.filter((f: any) => f.status === "DELETED"),
    [rows],
  );

  // Alterar tipo de filtro da tabela
  const handleChangeFilter = (_: any, value: number) => {
    setPaymentOption(value);
    if (value === 3 && (queryType === "device" || queryType === "mac")) {
      setQueryType("plan");
    }
  };

  // Ação de limpar barra de pesquisa dos Cupons
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    if (paymentOption === 3) {
      setSearchedPayment("-1");
      setSearchedClicked("-1");
    } else {
      setSearchedPayment("");
      setSearchedClicked("");
    }
  };

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "_id",
            hide: true,
          },
          {
            field: "mac",
            hide: true,
          },
          {
            field: "deviceName",
            headerName: t("TEXT.DEVICE"),
            align: "left",
            headerAlign: "left",
            flex: 2.6,
            renderHeader: () => (
              <p
                style={{
                  fontSize: "12px",
                  color: theme === "light" ? "#7E7E7E" : "inherit",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginLeft: "15px",
                }}
              >
                {t("TEXT.DEVICE")}
              </p>
            ),
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start">
                  {row.mac !== "-1" ? (
                    <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                      <AvatarIcon
                        name={
                          row.deviceName === "..." && row.mac !== "-1"
                            ? t("TEXT.DEVICE_NOT_AVAILABLE")
                            : row.deviceName
                        }
                        bgcolor={"#C7252C"}
                      />
                    </ListItemAvatar>
                  ) : (
                    <Avatar
                      sx={{
                        alignSelf: "center",
                        bgcolor: "#C7252C",
                        mr: 2,
                      }}
                    >
                      <AddIcon />
                    </Avatar>
                  )}
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          color={
                            row.deviceName === "..." && row.mac !== "-1"
                              ? "primary"
                              : "default"
                          }
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {row.deviceName === "..." && row.mac !== "-1"
                            ? t("TEXT.DEVICE_NOT_AVAILABLE")
                            : row.deviceName}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <Box display="flex" mt={0.2} component="span">
                        <Typography
                          component="span"
                          fontWeight={500}
                          sx={{
                            mr: 0.5,
                            mt: 0.1,
                            color: theme === "light" ? "black" : "white",
                            fontSize: 13,
                          }}
                        >
                          MAC:
                        </Typography>
                        <Typography
                          component="span"
                          sx={{ display: "inline" }}
                          variant="body2"
                        >
                          {row.mac === "-1" ? "..." : row.mac}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              </List>
            ),
          },
          {
            field: "client",
            headerName: t("TEXT.USER"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "plan",
            headerName: t("TEXT.PLAN"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "value",
            headerName: t("TEXT.AMOUNT"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "payable_with",
            headerName: t("TEXT.PAYMENT_METHODS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "created_at",
            headerName: t("TEXT.DATE"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 2,
            renderCell: ({ row }: any) => (
              <List>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    pb: 0,
                    mr: row.expires_at ? 0 : 2,
                  }}
                >
                  <Box display="flex">
                    <Typography fontWeight={500} fontSize={14} mr={0.5}>
                      {t("TEXT.CREATION")}:
                    </Typography>
                    <Typography fontSize={14}>{row.created_at}</Typography>
                  </Box>
                </ListItem>
                {row.expires_at && (
                  <ListItem alignItems="flex-start" sx={{ pt: 0.5 }}>
                    <Box display="flex">
                      <Typography fontWeight={500} fontSize={14} mr={0.5}>
                        {t("TEXT.EXPIRATION")}:
                      </Typography>
                      <Typography fontSize={14}>
                        {row.expires_at ?? "--"}
                      </Typography>
                    </Box>
                  </ListItem>
                )}
              </List>
            ),
          },
          {
            field: "status",
            sortable: false,
            disableColumnMenu: true,
            headerName: "Status",
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Chip
                label={
                  row.status === "AVAILABLE"
                    ? t("TEXT.AVAILABLE")
                    : row.status === "ACTIVE"
                      ? t("TEXT.ACTIVE")
                      : row.status === "INACTIVE"
                        ? t("TEXT.EXPIRED")
                        : t("TEXT.DELETED")
                }
                color={row.status === "ACTIVE" ? "success" : "error"}
                sx={{
                  fontWeight: 700,
                  backgroundColor:
                    row.status === "AVAILABLE"
                      ? blue[100]
                      : row.status === "ACTIVE"
                        ? green[100]
                        : "#FFD5D7",
                  color:
                    row.status === "AVAILABLE"
                      ? blue[800]
                      : row.status === "ACTIVE"
                        ? green[800]
                        : "#C7252C",
                  textTransform: "uppercase",
                  fontSize: 12,
                }}
              />
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "_id",
            hide: true,
          },
          {
            field: "mac",
            hide: true,
          },
          {
            field: "company",
            hide: true,
          },
          {
            field: "client",
            hide: true,
          },
          {
            field: "plan",
            hide: true,
          },
          {
            field: "created_at",
            hide: true,
          },
          {
            field: "status",
            hide: true,
          },
          {
            field: "deviceName",
            headerName: t("TEXT.DEVICES"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    {row.mac !== "-1" ? (
                      <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                        <AvatarIcon
                          name={
                            row.deviceName === "..." && row.mac !== "-1"
                              ? t("TEXT.DEVICE_NOT_AVAILABLE")
                              : row.deviceName
                          }
                          bgcolor={"#C7252C"}
                        />
                      </ListItemAvatar>
                    ) : (
                      <Avatar
                        sx={{
                          alignSelf: "center",
                          bgcolor: "#C7252C",
                          mr: 2,
                        }}
                      >
                        <AddIcon />
                      </Avatar>
                    )}
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          color={
                            row.deviceName === "..." && row.mac !== "-1"
                              ? "primary"
                              : "default"
                          }
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {row.deviceName === "..." && row.mac !== "-1"
                            ? t("TEXT.DEVICE_NOT_AVAILABLE")
                            : row.deviceName}
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Box display="flex" mt={0.5} component="span">
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              MAC:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.mac === "-1" ? "..." : row.mac}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.USER")}:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.client}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PLAN")}:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.plan}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.AMOUNT")}:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.value}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PAYMENT_METHODS")}:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.payable_with}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.CREATION_DATE")}:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.created_at}
                            </Typography>
                          </Box>
                          {row.expires_at && (
                            <Box
                              display="flex"
                              mt={0.5}
                              mb={1}
                              component="span"
                            >
                              <Typography
                                component="span"
                                fontWeight={500}
                                sx={{
                                  mr: 0.5,
                                  color: theme === "light" ? "black" : "white",
                                  fontSize: 13,
                                }}
                              >
                                {t("TEXT.EXPIRATION_DATE")}:
                              </Typography>
                              <Typography component="span" variant="body2">
                                {row.expires_at}
                              </Typography>
                            </Box>
                          )}
                          <Box
                            display="flex"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mt: 0.8,
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              Status:
                            </Typography>
                            <Chip
                              label={
                                row.status === "AVAILABLE"
                                  ? t("TEXT.AVAILABLE")
                                  : row.status === "ACTIVE"
                                    ? t("TEXT.ACTIVE")
                                    : row.status === "INACTIVE"
                                      ? t("TEXT.EXPIRED")
                                      : t("TEXT.DELETED")
                              }
                              color={
                                row.status === "ACTIVE" ? "success" : "error"
                              }
                              sx={{
                                fontWeight: 700,
                                backgroundColor:
                                  row.status === "AVAILABLE"
                                    ? blue[100]
                                    : row.status === "ACTIVE"
                                      ? green[100]
                                      : "#FFD5D7",
                                color:
                                  row.status === "AVAILABLE"
                                    ? blue[800]
                                    : row.status === "ACTIVE"
                                      ? green[800]
                                      : "#C7252C",
                                textTransform: "uppercase",
                                fontSize: 12,
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (payments?.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [payments, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  return (
    <Box mt={3}>
      <Tabs
        value={paymentOption}
        onChange={handleChangeFilter}
        orientation="horizontal"
        variant="scrollable"
        allowScrollButtonsMobile
        sx={{
          fontSize: 20,
          pb: 2,
          pt: 2,
          placeContent: "center",
        }}
      >
        <Tab
          icon={<CircleOutlinedIcon />}
          label={t("TEXT.ALL")}
          disableRipple
          sx={{ pt: 0 }}
        />
        <Tab
          icon={<CheckCircleOutlineOutlinedIcon />}
          label={t("TEXT.ACTIVE")}
          disableRipple
          sx={{ pt: 0 }}
        />
        <Tab
          icon={<NotInterestedOutlinedIcon />}
          label={t("TEXT.EXPIRED")}
          disableRipple
          sx={{ pt: 0 }}
        />
        <Tab
          icon={<ControlPointOutlinedIcon />}
          label={t("TEXT.AVAILABLE")}
          disableRipple
          sx={{ pt: 0 }}
        />
        <Tab
          icon={<HighlightOffOutlinedIcon />}
          label={t("TEXT.DELETED")}
          sx={{ pt: 0 }}
          disableRipple
        />
      </Tabs>
      <FormControl sx={{ mt: 1 }}>
        <FormLabel>{t("BUTTON.SEARCH")}:</FormLabel>
        <RadioGroup row value={queryType} onChange={handleChangeSearch}>
          {paymentOption !== 3 && (
            <FormControlLabel
              value="device"
              control={<Radio />}
              label={t("TEXT.DEVICE")}
            />
          )}
          {paymentOption !== 3 && (
            <FormControlLabel value="mac" control={<Radio />} label="MAC" />
          )}
          <FormControlLabel
            value="user"
            control={<Radio />}
            label={t("TEXT.USERNAME")}
          />
          <FormControlLabel
            value="plan"
            control={<Radio />}
            label={t("TEXT.PLAN")}
          />
        </RadioGroup>
      </FormControl>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          mr: mobile ? 2 : 4,
          borderRadius: 5,
          width: "100%",
          mt: 1,
          mb: 3,
          backgroundColor: theme === "dark" ? "#181818" : "#F9F9F9",
        }}
      >
        <Tooltip title={t("BUTTON.SEARCH")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={(e: any) => {
              setSearchedPayment(searchedClicked);
              e.preventDefault();
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <InputBase
          id="myInput"
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              setSearchedPayment(e.target.value);
              e.preventDefault();
            }
          }}
          onChange={(e: any) => {
            setSearchedClicked(e.target.value);
          }}
          sx={{ ml: 2, flex: 1 }}
          placeholder={`${t("TEXT.SEARCH_PAYMENTS")} ${t("TEXT.FOR_MAC")}`}
        />
        <Tooltip title={t("TEXT.CLEAN")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleClearSearch}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      {loading ? (
        <Box
          m={
            mobile
              ? "100px 0px"
              : window.screen.width < 1550
                ? "107px 0px"
                : "282.8px 0px"
          }
        >
          <Spinner />
        </Box>
      ) : (
        <Paper
          style={{
            height: mobile
              ? 400
              : mediumPage
                ? 621.5
                : window.screen.width < 1550
                  ? 270
                  : 621.5,
          }}
          elevation={0}
        >
          {rows && (
            <DataGridServerSide
              rows={
                paymentOption === 1
                  ? activeRows
                  : paymentOption === 2
                    ? expiredRows
                    : paymentOption === 3
                      ? availableRows
                      : paymentOption === 4
                        ? canceledRows
                        : rows
              }
              columns={columns}
              message={t("TOAST.INFO.NO_DATA_RECORD")}
              getRowHeight={() => "auto"}
              pageNumber={pageNumber}
              totalPages={totalPages}
              onPageChange={(newPage: number) => {
                handleChangePage(newPage);
              }}
              onPageSizeChange={(rowsPerPage: any) =>
                setRowsPerPage(rowsPerPage)
              }
              onRowClick={(params: any) => {
                if (params.row.mac && params.row.mac !== "-1") {
                  navigate(
                    `${PLANS_AND_SUBSCRIPTIONS}/${params.row.mac}/${params.row._id}`,
                  );
                } else {
                  toastError(t("TOAST.ERROR.PLAN_NOT_ASSOCIATE"));
                }
              }}
              disableSelectionOnClick
            />
          )}
        </Paper>
      )}
    </Box>
  );
};

export default SinglePayments;
