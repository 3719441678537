import * as React from "react";

import {
  LocalizationProvider,
  PickersShortcutsItem,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../translations/languages";
import { useFormik } from "formik";
import * as Yup from "yup";
import enLocale from "date-fns/locale/en-GB";
import deLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt-BR";
import { ptBR, esES, enUS } from "@mui/x-date-pickers/locales";

import {
  CalendarMonth as CalendarMonthIcon,
  Equalizer as EqualizerIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import ErrorMessage from "../../components/ErrorMessage";
import useIsMobile from "../../hooks/useIsMobile";
import {
  Select,
  TextField,
  yupNumberMinMax,
  yupRequiredString,
} from "../../components/Form";
import { getReport } from "../../services/data/report";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import { green } from "@mui/material/colors";
import AvatarIcon from "../../components/AvatarIcon";
import { FormDateProps } from "../../services/types/store";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";

// 24 * 60 * 60 * 1000
const ONE_DAY_TIMESTAMP = 86400000;
const ONE_MONTH_TIMESTAMP = new Date(
  new Date().getTime() - 30 * 86400 * 1000,
).getTime();

const currentTimestamp = +new Date();
const yesterdayTimestamp = currentTimestamp - ONE_DAY_TIMESTAMP;

// Setar timestamp em 00:00:00
const startTimestamp = new Date(yesterdayTimestamp).setHours(0, 0, 0, 0);
// Setar timestamp em 23:59:59
const endTimestamp = new Date(currentTimestamp).setHours(23, 59, 59, 999);

// Converter timestamps para o tipo 'Date'
const startDate = new Date(startTimestamp);
const endDate = new Date(endTimestamp);

let dataFetching = false;

/* let startDateChanged = false; */

type FormProps = {
  startDay: Date;
  devices: any;
  controllers: any;
  setMacId: (e: string) => void;
  setSubmit: (e: boolean) => void;
  setClean: (e: boolean) => void;
  updateReports: () => void;
  setFormValue: (e: any) => void;
  setFormDate: (e: FormDateProps) => void;
  setModel: (e: string) => void;
  setName: (e: string) => void;
  setMac: (e: string) => void;
  setUpdateLoading: (e: boolean) => void;
  handleLoadMore: () => void;
  loading: boolean;
  payloadDevices: any[];
  notShow: boolean;
  setSampling: (e: string) => void;
};

const Form = ({
  startDay,
  devices,
  controllers,
  setMac,
  setMacId,
  setSubmit,
  setClean,
  updateReports,
  setFormValue,
  setFormDate,
  setModel,
  setName,
  setUpdateLoading,
  handleLoadMore,
  loading,
  payloadDevices,
  notShow,
  setSampling,
}: FormProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const { token, authenticated } = React.useContext(AuthContext);
  const [checked, setChecked] = React.useState(true);
  const [checkedModel, setCheckedModel] = React.useState<string>("");
  const [checkedName, setCheckedName] = React.useState<string>("");
  const [startValue, setStartValue] = React.useState<Date | null>(startDate);
  const { theme } = React.useContext(ThemeModeContext);
  const [endValue, setEndValue] = React.useState<Date | null>(endDate);
  const [maxEndValue, setMaxEndValue] = React.useState<Date | null>(endDate);
  const params = useParams();
  /*   const [monthEnd, setMonthEnd] = React.useState(endDate.getMonth() + 1);
  const [monthStart, setMonthStart] = React.useState(startDate.getMonth() + 1);
  const [yearEnd, setYearEnd] = React.useState(endDate.getFullYear());
  const [yearStart, setYearStart] = React.useState(startDate.getFullYear()); */
  /*   const [highlightedDaysStart, setHighlightedDaysStart] = React.useState<
    number[]
  >([]); */
  /*   const [highlightedDaysEnd, setHighlightedDaysEnd] = React.useState<number[]>(
    []
  ); */
  /*   const [DataOnDay, setDataOnDay] = React.useState<number[]>([]); */

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  // Valor inicial do input 'Selecione o Dispositivo
  const deviceData =
    devices?.length === 1 ? devices[0].id : params.id ? params.id : "";

  const formik = useFormik({
    initialValues: {
      startDate,
      endDate,
      mac: deviceData,
      controller: params.ctrl
        ? `${params.mac}/${params.ctrl}/${params.model}`
        : "",
      samplingTime: "",
      sampling: "",
    },
    validationSchema: Yup.object().shape({
      startDate: Yup.date().required(),
      endDate: Yup.date().required(),
      mac: yupRequiredString,
      controller: yupRequiredString,
      samplingTime: yupRequiredString,
      sampling: yupNumberMinMax(1, 60),
    }),
    onSubmit: (values, { setSubmitting }) => {
      // Armazenar a data inicial e final do formulário em um useState
      setFormDate({
        dateStart: startValue as Date,
        dateEnd: endValue as Date,
      });

      // Checar o tipo da taxa de amostragem selecionada
      const valueSampling =
        values.samplingTime === t("TEXT.MINUTES")
          ? +values.sampling * 60000
          : values.samplingTime === t("TEXT.HOURS")
            ? +values.sampling * 60 * 60000
            : +values.sampling * 24 * 60 * 60000;

      setSampling(String(valueSampling));

      // Resgatar o valor inicial do '_id' (XX-XX-XX-XX-XX-XX/CTRLXX)
      const formatedCtrl = `${values.controller.split("/")[0]}/${
        values.controller.split("/")[1]
      }`;

      const controllerSelected = controllers.filter(
        (f: any) => f._id === formatedCtrl,
      )[0];

      if (controllerSelected) {
        if (authenticated) {
          if (token !== undefined) {
            localStorage.setItem(TOKEN_KEY, token);
          }
          api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
          setName(checkedName);
          setModel(checkedModel);
          setMac(formatedCtrl);
          setUpdateLoading(true);
          // Resgatar os dados gerais dos relatórios
          const dataEnd = endValue?.getTime() as number;
          const dataStart = startValue?.getTime() as number;
          if (dataFetching === false) {
            dataFetching = true;
            getReport(
              controllerSelected._id,
              controllerSelected.arcsysId,
              dataEnd,
              dataStart,
              valueSampling,
            )
              .then((res) => {
                dataFetching = false;
                const result = [];
                for (let i = 0; i < res.data.items.length; i++) {
                  if (i === 0 && res?.data.items.length > 0) {
                    const newItem = {
                      ...res.data.items[i],
                      timestamp: dataStart - 1000, // 1 segundo após a data/hora atual
                      data: {
                        ...res.data.items[i].data,
                        estado: null,
                        alarme: 0,
                        modelo: checkedModel,
                        medidas: {},
                        setpoints: {},
                      },
                    };
                    result.push(newItem);
                  }
                  if (i < res.data.items.length - 1) {
                    if (
                      checkedModel !== res.data.items[i].data.modelo &&
                      checkedModel === res.data.items[i + 1].data.modelo
                    ) {
                      const currentTimestamp = res.data.items[i].timestamp;
                      const newItem = {
                        ...res.data.items[i + 1],
                        // 1 Segundo após a data/hora atual
                        timestamp: currentTimestamp - 1000,
                        data: {
                          ...res.data.items[i + 1].data,
                          estado: null,
                          alarme: 0,
                          modelo: checkedModel,
                          medidas: {},
                          setpoints: {},
                        },
                      };
                      result.push(newItem);
                      continue;
                    }
                  }

                  // Adiciona somente os dados do modelo atual - previne historico antigo de outros controladores
                  if (checkedModel === res.data.items[i].data.modelo) {
                    result.push(res.data.items[i]);

                    // Verificando se não está no último elemento
                    if (i < res.data.items.length - 1) {
                      const currentTimestamp = res.data.items[i].timestamp;
                      const nextTimestamp = res.data.items[i + 1].timestamp;
                      const differenceInMilliseconds = Math.abs(
                        nextTimestamp - currentTimestamp,
                      );

                      if (differenceInMilliseconds > 4500000) {
                        // 1:15h
                        const newItem = {
                          ...res.data.items[i],
                          // 1 Segundo após a data/hora atual
                          timestamp: currentTimestamp - 1000,
                          data: {
                            ...res.data.items[i].data,
                            estado: null,
                            alarme: 0,
                            medidas: {},
                            setpoints: {},
                          },
                        };
                        result.push(newItem);
                      }
                    }
                  }
                  // ultimo timestamp
                  if (
                    i === res?.data.items.length - 1 &&
                    res?.data.items.length > 0
                  ) {
                    const newItem = {
                      ...res.data.items[i],
                      timestamp: dataEnd + 1000, // 1 segundo antes
                      data: {
                        ...res.data.items[i].data,
                        estado: null,
                        alarme: 0,
                        modelo: checkedModel,
                        medidas: {},
                        setpoints: {},
                      },
                    };
                    result.push(newItem);
                  }
                }
                setFormValue(result);
                setUpdateLoading(false);
              })
              .catch(() => {
                dataFetching = false;
                setUpdateLoading(false);
              });
          }
        }
      }
      updateReports();
      setClean(false);
      setSubmitting(false);
      setSubmit(true);
    },
  });

  // Atualizar os dados de acordo com a linguagem atual (pt, en, es)
  // React.useEffect(() => {
  //   updateCtrl(localStorage.ageonRef);

  //   const controllerSelected = controllers?.filter(
  //     (f: any) => f._id === formik.values.controller
  //   )[0];

  //   if (controllerSelected && !formik.isSubmitting) {
  //     // Resgatar os dados gerais dos relatórios
  //     setUpdateLoading(true);
  //     let dataEnd = endValue?.getTime() as number;
  //     let dataStart = startValue?.getTime() as number;
  //     if (dataFetching === false){
  //       dataFetching = true;
  //       getReport(
  //         controllerSelected._id,
  //         controllerSelected.arcsysId,
  //         dataEnd,
  //         dataStart
  //       ).then((res) => {
  //         dataFetching = false;
  //         const result = [];
  //         for (let i = 0; i < res.data.items?.length; i++) {
  //           if (i < res.data.items?.length - 1) {
  //             if (
  //               checkedModel !== res.data.items[i].data.modelo &&
  //               checkedModel === res.data.items[i + 1].data.modelo
  //             ) {
  //               const currentTimestamp = res.data.items[i].timestamp;
  //               const newItem = {
  //                 ...res.data.items[i + 1],
  //                 // 1 Segundo após a data/hora atual
  //                 timestamp: currentTimestamp - 1000,
  //                 data: {
  //                   ...res.data.items[i + 1].data,
  //                   estado: 0,
  //                   alarme: 0,
  //                   modelo: controllerSelected.model,
  //                   medidas: {},
  //                   setpoints: {},
  //                 },
  //               };
  //               result.push(newItem);
  //               continue;
  //             }
  //           }

  //           // Adiciona somente os dados do modelo atual - previne historico antigo de outros controladores
  //           if (checkedModel === res.data.items[i].data.modelo) {
  //             result.push(res.data.items[i]);

  //             // Verificando se não está no último elemento
  //             if (i < res.data.items.length - 1) {
  //               const currentTimestamp = res.data.items[i].timestamp;
  //               const nextTimestamp = res.data.items[i + 1].timestamp;
  //               const differenceInMilliseconds = Math.abs(
  //                 nextTimestamp - currentTimestamp
  //               );

  //               if (differenceInMilliseconds > 3600000) {
  //                 const newItem = {
  //                   ...res.data.items[i],
  //                   // 1 Segundo após a data/hora atual
  //                   timestamp: currentTimestamp - 1000,
  //                   data: {
  //                     ...res.data.items[i].data,
  //                     estado: 0,
  //                     alarme: 0,
  //                     medidas: {},
  //                     setpoints: {},
  //                   },
  //                 };
  //                 result.push(newItem);
  //               }
  //             }
  //           }
  //         }
  //         setFormValue(result);
  //         setUpdateLoading(false);
  //       }).catch(() => {
  //         dataFetching = false;
  //         setUpdateLoading(false);
  //       });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [controllers, setFormValue, language]);

  // Resgatar o valor inicial do '_id' (XX-XX-XX-XX-XX-XX/CTRLXX)
  const formatedCtrl = `${formik.values.controller.split("/")[0]}/${
    formik.values.controller.split("/")[1]
  }`;

  // Resgatar o nome inicial do Modelo
  React.useEffect(() => {
    const sectedObjectCtrl = controllers?.filter(
      (f: any) => f._id === formatedCtrl,
    )[0];

    const selectedModel =
      sectedObjectCtrl?.modelsUsed.length === 0
        ? sectedObjectCtrl.model
        : sectedObjectCtrl?.modelsUsed.filter(
            (f: any) => f.modelo === sectedObjectCtrl.model,
          )[0];

    setCheckedModel(selectedModel?.modelo);
    setCheckedName(selectedModel?.nome);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllers]);

  // Valor inicial do formulário
  React.useEffect(() => {
    formik.resetForm({
      values: {
        startDate,
        endDate,
        mac: deviceData,
        controller: params.ctrl
          ? `${params.mac}/${params.ctrl}/${params.model}`
          : "",
        samplingTime: t("TEXT.MINUTES"),
        sampling: "5",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, deviceData, t]);

  // Alterar a data inicial no Input
  const handleStartDateChange = (start: Date | null) => {
    setStartValue(start);
    if (start !== null) {
      const endValueMax = new Date(start);
      endValueMax.setMonth(start.getMonth() + 1);
      const currentDate = new Date();
      if (endValueMax > currentDate) {
        endValueMax.setTime(currentDate.getTime()); // Set endValue to the current date
      }
      if (endValue !== null && endValue > endValueMax) {
        setEndValue(endValueMax);
      }
      if (endValue !== null && endValue < start) {
        setEndValue(start);
      }

      setMaxEndValue(endValueMax);
    }
    /*   startDateChanged = true; */
    formik.setFieldValue("startDate", start);
  };

  // Alterar a data final no Input
  const handleEndDateChange = (end: Date | null) => {
    setEndValue(end);
    formik.setFieldValue("endDate", end);
  };

  // Lista de Dispositivos
  const optionsMac = devices ? (
    devices?.map(
      (value: any) =>
        value && (
          <MenuItem
            value={value.id}
            key={value.id}
            sx={{ pt: 0, pb: 0, borderBottom: "1px solid #F1F1F1" }}
          >
            {!mobile ? (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor={"#C7252C"} />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ p: 0 }}
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                          <Chip
                            label={value.online ? "Online" : "Offline"}
                            color={value.online ? "success" : "error"}
                            sx={{
                              mr: 1,
                              fontWeight: 700,
                              backgroundColor: value.online
                                ? green[100]
                                : "#FFD5D7",
                              color: value.online ? green[800] : "#C7252C",
                              textTransform: "uppercase",
                              fontSize: 12,
                            }}
                          />
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ) : (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor={"#C7252C"} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                        <Chip
                          component="span"
                          label={value.online ? "Online" : "Offline"}
                          color={value.online ? "success" : "error"}
                          sx={{
                            mt: 0.5,
                            mr: 1,
                            fontWeight: 700,
                            backgroundColor: value.online
                              ? green[100]
                              : "#FFD5D7",
                            color: value.online ? green[800] : "#C7252C",
                            textTransform: "uppercase",
                            fontSize: 12,
                          }}
                        />
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            )}
          </MenuItem>
        ),
    )
  ) : (
    <Spinner />
  );

  // Resgatar o id do MAC selecionado para dar um .GET na lista de Controladores
  React.useEffect(() => {
    setMacId(formik.values.mac);
  }, [formik.values.mac, setMacId]);

  // Lista de Controladores
  const optionsController = controllers?.reduce((acc: any, p: any) => {
    acc.push(
      <ListSubheader key={p._id}>
        {`${t("TEXT.ADDRESS")} ${p._id.slice(22, 24)}`}
      </ListSubheader>,
    );
    if (p.modelsUsed.length === 0) {
      acc.push(
        <MenuItem
          value={`${p._id}/${p.model}`}
          key={`${p._id}/${p.model}`}
          sx={{ pt: 1, pb: 1, borderBottom: "1px solid #F1F1F1" }}
          onClick={() => {
            setCheckedModel(p.model);
            setCheckedName(p.name);
          }}
        >
          <Box display="flex">
            <Chip
              label={p.status === "ACTIVE" ? "Online" : "Offline"}
              color={p.status === "ACTIVE" ? "success" : "error"}
              sx={{
                mr: 1,
                fontWeight: 700,
                backgroundColor: p.status === "ACTIVE" ? green[100] : "#FFD5D7",
                color: p.status === "ACTIVE" ? green[800] : "#C7252C",
                textTransform: "uppercase",
                fontSize: 12,
              }}
            />
            <Typography sx={{ ml: 0.5, mt: 0.5, whiteSpace: "break-spaces" }}>
              {p.name}
            </Typography>
          </Box>
        </MenuItem>,
      );
    } else {
      acc.push(
        ...p.modelsUsed.map(
          (pl: any) =>
            pl.nome && (
              <MenuItem
                value={`${p._id}/${pl.modelo}`}
                key={`${p._id}/${pl.modelo}`}
                sx={{ pt: 1, pb: 1, borderBottom: "1px solid #F1F1F1" }}
                onClick={() => {
                  setCheckedModel(pl.modelo);
                  setCheckedName(pl.nome);
                }}
              >
                <Box display="flex">
                  <Chip
                    label={
                      p.status === "ACTIVE" && p.name === pl.nome
                        ? "Online"
                        : "Offline"
                    }
                    color={
                      p.status === "ACTIVE" && p.name === pl.nome
                        ? "success"
                        : "error"
                    }
                    sx={{
                      mr: 1,
                      fontWeight: 700,
                      backgroundColor:
                        p.status === "ACTIVE" && p.name === pl.nome
                          ? green[100]
                          : "#FFD5D7",
                      color:
                        p.status === "ACTIVE" && p.name === pl.nome
                          ? green[800]
                          : "#C7252C",
                      textTransform: "uppercase",
                      fontSize: 12,
                    }}
                  />
                  <Typography
                    sx={{ ml: 0.5, mt: 0.5, whiteSpace: "break-spaces" }}
                  >
                    {pl.nome}
                  </Typography>
                </Box>
              </MenuItem>
            ),
        ),
      );
    }

    return acc;
  }, []);

  const samplingTypes = [t("TEXT.MINUTES"), t("TEXT.HOURS"), t("TEXT.DAYS")];

  // Lista de tipos de taxas de amostragem
  const optionsSampling = samplingTypes.map((value: any) => (
    <MenuItem
      value={value}
      key={value}
      sx={{ pt: 1, pb: 1, borderBottom: "1px solid #F1F1F1" }}
    >
      {value}
    </MenuItem>
  ));

  /*   // Atualizar o useState do mês escolhido no calendário
  const setValueMonthStart = (value: any) => {
    setMonthStart(new Date(value).getMonth() + 1);
  };

  // Atualizar o useState do mês escolhido no calendário
  const setValueMonthEnd = (value: any) => {
    setMonthEnd(new Date(value).getMonth() + 1);
  };

  // Atualizar o useState do mês escolhido no calendário
  const setValueYearStart = (value: any) => {
    setYearStart(new Date(value).getFullYear());
  };

  // Atualizar o useState do mês escolhido no calendário
  const setValueYearEnd = (value: any) => {
    setYearEnd(new Date(value).getFullYear());
  }; */

  // Id do dono do Dispositivo selecionado
  /*   const userId =
    formik.values.mac !== "" && devices
      ? devices.filter((f: any) => f.id === formik.values.mac)[0]?.ownerId
      : undefined; */

  // Modelo do Controlador selecionado
  /*   const model =
    formik.values.controller !== "" && controllers
      ? controllers.filter((f: any) => f._id === formik.values.controller)[0]
          ?.model
      : undefined; */

  // Data de início
  /*   const beginTsStart = new Date(formik.values.startDate); */

  // Função que resgata os dados do dia do Relatório
  /*   const getDaysOnMonth = (startCalendar: boolean) => {
    if (
      (startCalendar && DataOnDay.length === 0) ||
      startDateChanged === true
    ) {
      startDateChanged = false;
      // 2 meses antes da data final
      beginTsStart.setMonth(beginTsStart.getMonth() - 2);
      getDataOnDay(
        formik.values.controller,
        beginTsStart.getTime(),
        model,
        userId
      ).then((res) => {
        const { data } = res;
        // Dias do mes para o calendario de data inicial
        let newHighlightedDays = data.map((m: any) => {
          if (
            monthStart === +m.split("/")[1] &&
            yearStart === +m.split("/")[2]
          ) {
            return +m.split("/")[0];
          }
          return 0;
        });
        setHighlightedDaysStart(newHighlightedDays);
        // Dias do mes para o calendario de data final
        newHighlightedDays = data.map((m: any) => {
          if (monthEnd === +m.split("/")[1] && yearEnd === +m.split("/")[2]) {
            return +m.split("/")[0];
          }
          return 0;
        });
        setHighlightedDaysEnd(newHighlightedDays);
        setDataOnDay(data);
      });
    }
  }; */

  // Use effect to update 'highlightedDays' based on the 'month'
  /*   React.useEffect(() => {
    // Dias do mes para o calendario de data inicial
    let newHighlightedDays = DataOnDay.map((m: any) => {
      if (monthStart === +m.split("/")[1] && yearStart === +m.split("/")[2]) {
        return +m.split("/")[0];
      }
      return 0;
    });
    setHighlightedDaysStart(newHighlightedDays);
    // Dias do mes para o calendario de data final
    newHighlightedDays = DataOnDay.map((m: any) => {
      if (monthEnd === +m.split("/")[1] && yearEnd === +m.split("/")[2]) {
        return +m.split("/")[0];
      }
      return 0;
    });
    setHighlightedDaysEnd(newHighlightedDays);
  }, [DataOnDay, monthStart, monthEnd, yearStart, yearEnd]);
 */
  // Linguagem do componente Input das datas
  const checkLanguage =
    language === "pt" ? ptLocale : language === "en" ? enLocale : deLocale;

  // Linguagem do componente Input das datas
  const checkLanguageText =
    language === "pt"
      ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
      : language === "en"
        ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
        : esES.components.MuiLocalizationProvider.defaultProps.localeText;

  // Checar se o range da data inicial e final não ultrapassou 1 mês
  const checkValidDate =
    +formik.values.startDate >=
    +formik.values.endDate - ONE_MONTH_TIMESTAMP * 2;

  // Checar se o input do Controlador foi selecionado
  const checkIfFormikCtrlIsNull =
    controllers
      ?.map((f: any) => {
        return f._id;
      })
      .indexOf(formatedCtrl) === -1
      ? false
      : true;

  // Validação do input do Controlador
  const checkCtrlInput = !optionsController || controllers?.length === 0;

  // Validação do input das datas
  const checkDate = formatedCtrl !== "";

  // Validação geral do botão 'Aplicar'
  const isFormValid = params.id
    ? !formik.isSubmitting &&
      formik.isValid &&
      checkValidDate &&
      !checkCtrlInput &&
      checkIfFormikCtrlIsNull &&
      !formik.values.sampling.toString().includes(".")
    : !formik.isSubmitting &&
      formik.dirty &&
      formik.isValid &&
      checkValidDate &&
      !checkCtrlInput &&
      checkIfFormikCtrlIsNull &&
      !formik.values.sampling.toString().includes(".");

  // Atalhos de dias do Input da data inicial
  const shortcutsItems: PickersShortcutsItem<any>[] = [
    {
      label: `48 ${t("TEXT.HOUR")}s`,
      getValue: () => {
        const value = new Date(new Date().getTime() - 2 * 86400 * 1000);
        return value;
      },
    },
    {
      label: `7 ${t("TEXT.DAYS")}`,
      getValue: () => {
        const value = new Date(new Date().getTime() - 7 * 86400 * 1000);
        return value;
      },
    },
    {
      label: `14 ${t("TEXT.DAYS")}`,
      getValue: () => {
        const value = new Date(new Date().getTime() - 14 * 86400 * 1000);
        return value;
      },
    },
    {
      label: `30 ${t("TEXT.DAYS")}`,
      getValue: () => {
        const value = new Date(new Date().getTime() - 30 * 86400 * 1000);
        return value;
      },
    },
  ];

  // Adicionar novos dados no select dos Dispositivos
  const addMoreItens = (
    <MenuItem>
      <Button
        onClick={(e: any) => {
          e.stopPropagation();
          handleLoadMore();
        }}
        disabled={loading}
        fullWidth
        variant="outlined"
      >
        {loading ? <Spinner size={24} /> : t("TEXT.CHARGE_MORE_DEVICES")}
      </Button>
    </MenuItem>
  );

  // Adicionar o botão 'addMoreItems' na lista de dispositivos
  const allMenuItems =
    notShow === true || payloadDevices.length === 0
      ? optionsMac
      : [...optionsMac, addMoreItens];

  return (
    <Paper sx={{ borderRadius: 3 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ width: "100%", height: "100%", cursor: "pointer" }}
        onClick={handleChange}
        p={1}
        pl={2}
        pr={2}
      >
        <Box display="flex">
          <Box alignSelf="center" mr={1} ml={1}>
            <IconButton aria-label="open drawer mobile" edge="start">
              <CalendarMonthIcon />
            </IconButton>
          </Box>
          <Typography variant="h6" alignSelf="center">
            {t("TEXT.REPORT_DATA")}
          </Typography>
        </Box>
        <Box alignSelf="center" mt={0.5}>
          <ExpandMoreIcon />
        </Box>
      </Box>
      <Box pl={mobile ? 1 : 4} pr={mobile ? 1 : 4} pt={0}>
        <Collapse in={checked}>
          <Box pb={4} pt={1}>
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Select
                  formik={formik}
                  label={t("TEXT.SELECT_DEVICE")}
                  name="mac"
                  options={allMenuItems}
                  isDisabled={devices?.length === 0}
                  sx={
                    formik.values.mac === undefined
                      ? {
                          "& .css-1rioaud-MuiFormLabel-root-MuiInputLabel-root":
                            {
                              padding: "6px",
                            },
                        }
                      : formik.values.mac !== ""
                        ? {
                            "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                              {
                                p: 0,
                              },
                            "& .css-qiwgdb": {
                              p: 0,
                            },
                          }
                        : {}
                  }
                />
              </Box>
              <Box mt={2} mb={2}>
                <Select
                  formik={formik}
                  isDisabled={checkCtrlInput}
                  label={t("TEXT.SELECT_CONTROLLER")}
                  name="controller"
                  options={optionsController}
                />
              </Box>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={checkLanguage}
                localeText={checkLanguageText}
              >
                {mobile ? (
                  <DatePicker
                    disableFuture
                    label={t("TEXT.START_DATE")}
                    onChange={handleStartDateChange}
                    /* onOpen={() => getDaysOnMonth(true)} */
                    /* onMonthChange={(newValue: any) =>
                      setValueMonthStart(newValue)
                    } */
                    /*  onYearChange={(newValue: any) =>
                      setValueYearStart(newValue)
                    } */
                    value={startValue}
                    minDate={startDay}
                    maxDate={new Date()}
                    disabled={!checkDate}
                    format={
                      language === "en"
                        ? "MM/dd/yyyy hh:mm a"
                        : "dd/MM/yyyy HH:mm:ss"
                    }
                    /*  slots={{
                      day: CustomDayDateTimeStart,
                    }} */
                    slotProps={{
                      /*    day: {
                        highlightedDaysStart,
                      } as any, */
                      textField: {
                        error: false,
                      },
                    }}
                    sx={{ width: "100%" }}
                  />
                ) : (
                  <DatePicker
                    disableFuture
                    label={t("TEXT.START_DATE")}
                    onChange={handleStartDateChange}
                    /*   onOpen={() => getDaysOnMonth(true)} */
                    /* onMonthChange={(newValue: any) =>
                      setValueMonthStart(newValue)
                    } */
                    /*  onYearChange={(newValue: any) =>
                      setValueYearStart(newValue)
                    } */
                    value={startValue}
                    minDate={startDay}
                    maxDate={new Date()}
                    disabled={!checkDate}
                    format={
                      language === "en"
                        ? "MM/dd/yyyy hh:mm a"
                        : "dd/MM/yyyy HH:mm:ss"
                    }
                    /*    slots={{
                      day: CustomDayDateTimeStart,
                    }} */
                    slotProps={{
                      shortcuts: {
                        items: shortcutsItems,
                      },
                      textField: {
                        error: false,
                      },
                      /*  day: {
                        highlightedDaysStart,
                      } as any, */
                    }}
                    sx={{ width: "100%" }}
                  />
                )}
                <Box mt={2}>
                  {mobile ? (
                    <DatePicker
                      disableFuture
                      label={t("TEXT.END_DATE")}
                      onChange={handleEndDateChange}
                      /*  onOpen={() => getDaysOnMonth(false)} */
                      /*  onMonthChange={(newValue: any) =>
                        setValueMonthEnd(newValue)
                      } */
                      /*  onYearChange={(newValue: any) =>
                        setValueYearEnd(newValue)
                      } */
                      value={endValue}
                      minDate={startValue as Date}
                      maxDate={maxEndValue as Date}
                      disabled={!checkDate}
                      /* slots={{
                        day: CustomDayDateTimeEnd,
                      }} */
                      slotProps={{
                        textField: {
                          error: false,
                        },
                        /*    day: {
                          highlightedDaysEnd,
                        } as any, */
                      }}
                      format={
                        language === "en"
                          ? "MM/dd/yyyy hh:mm a"
                          : "dd/MM/yyyy HH:mm:ss"
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  ) : (
                    <DatePicker
                      disableFuture
                      label={t("TEXT.END_DATE")}
                      onChange={handleEndDateChange}
                      /*  onOpen={() => getDaysOnMonth(false)} */
                      /*  onMonthChange={(newValue: any) =>
                        setValueMonthEnd(newValue)
                      } */
                      /*  onYearChange={(newValue: any) =>
                        setValueYearEnd(newValue)
                      } */
                      value={endValue}
                      minDate={startValue as Date}
                      maxDate={maxEndValue as Date}
                      disabled={!checkDate}
                      /*  slots={{
                        day: CustomDayDateTimeEnd,
                      }} */
                      slotProps={{
                        textField: {
                          error: false,
                        },
                        /*    day: {
                          highlightedDaysEnd,
                        } as any, */
                      }}
                      format={
                        language === "en"
                          ? "MM/dd/yyyy hh:mm a"
                          : "dd/MM/yyyy HH:mm:ss"
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  )}
                </Box>
                {checkValidDate ? null : (
                  <Box mt={0.5} textAlign="left">
                    <ErrorMessage>{t("TOAST.ERROR.RANGE_MONTH")}</ErrorMessage>
                  </Box>
                )}
              </LocalizationProvider>
              <Box mt={2} display="flex">
                <TextField
                  formik={formik}
                  InputProps={{
                    inputProps: {
                      max: 60,
                      min: 1,
                    },
                  }}
                  type="number"
                  disabled={!checkDate}
                  onClick={(e: any) => e.stopPropagation()}
                  label={t("TEXT.SAMPLING_RATE")}
                  name="sampling"
                  fullWidth
                />
                <Box width={120}>
                  <Select
                    formik={formik}
                    disabled={true}
                    label=""
                    name="samplingTime"
                    options={optionsSampling}
                  />
                </Box>
              </Box>
              {formik.errors.sampling && formik.touched.sampling ? (
                <ErrorMessage>{formik.errors.sampling}</ErrorMessage>
              ) : null}
              <Box textAlign="center">
                <Button
                  size="small"
                  variant="outlined"
                  type="submit"
                  disabled={!isFormValid}
                  onChange={formik.handleChange}
                  onClick={handleChange}
                  color="success"
                  startIcon={<EqualizerIcon />}
                  sx={{
                    width: 150,
                    mt: 3,
                    mb: 0.5,
                    borderWidth: 2,
                    "&:hover": { borderWidth: 2 },
                  }}
                >
                  {t("BUTTON.APPLY")}
                </Button>
              </Box>
            </form>
          </Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

export default Form;
