export const CONFIG_ROUTE = "/config";

export const DEVICE_ROUTE = "/arcsys";

const CONTROLLER_ROUTE = "/controller";

// Rota para resgatar dados gerais do Dispositivo
export const getDeviceRoute = (id: string) => `${DEVICE_ROUTE}/${id}`;

// Rota para excluir o Dispositivo
export const deleteDeviceRoute = (id: string) => `${DEVICE_ROUTE}/${id}`;

// Rota para descompartilhar o Dispositivo
export const unshareDeviceRoute = (id: string) =>
  `${DEVICE_ROUTE}/${id}/unshare`;

// Rota para editar o nome e as horas do Alerta de Falha de Comunicação do Dispositivo
export const editDeviceRoute = (id: string) => `${DEVICE_ROUTE}/${id}`;

// Rota para compartilhar o Dispositivo via e-mail
export const shareDeviceRoute = (id: string) => `${DEVICE_ROUTE}/${id}/share`;

// Rota para resgatar os dispositivos offline dos arcsys
export const getDevicesOfflineRoute = () => `${DEVICE_ROUTE}/showoffline`;

// Rota para resgatar a lista de Controladores do Dispositivo
export const getControllersOfDeviceRoute = (id: string) =>
  `${CONTROLLER_ROUTE}?order=-creationTs&page=1&offset=40&arcsysId=${id}`;

// Rota para resgatar a lista de Controladores do Dispositivo
export const getControllersOfDeviceByArcsysRoute = (id: string) =>
  `${CONTROLLER_ROUTE}/byarcsys/${id}`;

// Rota para resgatar o código de Acesso do Dispositivo
export const getDeviceAccessCodeRoute = (id: string) =>
  `${DEVICE_ROUTE}/${id}/brokerPass`;

// Rota para excluir Controlador do Dispositivo
export const deleteControllerOfDeviceRoute = (id: string) =>
  `${CONTROLLER_ROUTE}/${id}`;

// Rota para alterar o valor do Intervalo de Gravação da página do Controlador
export const putControllerOfDeviceRoute = (id: string) =>
  `${CONTROLLER_ROUTE}/${id}`;

// Rota para resgatar o valor do Intervalo de Gravação da página do Controlador
export const getControllerOfDeviceRoute = (id: string) =>
  `${CONTROLLER_ROUTE}/${id}`;

// Rota para aceitar o compartilhamento do Dispositivo
export const AccepsSharedDeviceRoute = (id: string) =>
  `${DEVICE_ROUTE}/${id}/accept`;

// Rota para resgatar os dados do Controlador, lastMsg e timestamp
export const getDeviceControllerRoute = (mac: string, ctrl: string) =>
  `${CONTROLLER_ROUTE}/${mac}/${ctrl}`;

// Rota para resgatar os dados do Controlador, lastMsg e timestamp
export const getDevicesRoute = (
  pending: boolean,
  queryType?: string,
  general?: string,
) =>
  general !== undefined
    ? `${DEVICE_ROUTE}/?exclude_pending=${pending}&offset=10000&general=${general}&queryType=${queryType}`
    : `${DEVICE_ROUTE}/?exclude_pending=${pending}&offset=10000&queryType=${queryType}`;

// Rota para resgatar a quantidade de dispositivos que irão ser renderizados
export const getNumbersOfDevicesRoute = (
  pending: boolean,
  page: number,
  rowsPerPage: number,
  general: string,
  asAdmin: number,
  queryType: string,
  macid?: string,
) =>
  `${DEVICE_ROUTE}?exclude_pending=${pending}&order=-creationTs&page=${page}&offset=${rowsPerPage}&asAdmin=${asAdmin}&general=${general}&queryType=${queryType}&macid=${macid}`;

// Rota para resgatar os dispositivos do usuário na pág do usuário
export const getUserDevicesRoute = (
  pending: boolean,
  page: number,
  rowsPerPage: number,
  general: string,
  queryType: string,
  userId: number,
) =>
  `${DEVICE_ROUTE}/getuserdevices?exclude_pending=${pending}&page=${page}&offset=${rowsPerPage}&general=${general}&queryType=${queryType}&userId=${userId}`;

// Rota para resgatar todos os dispositivos MACs no Dashboard
export const DASHBOARD_MACS_ROUTE = `${DEVICE_ROUTE}/dashboardmacs`;
