const CTRL_EVENT_ROUTE = "/ctrlEvent";

const REPORT_ROUTE = "/report";

// Rota para resgatar os dados gerais dos relatórios
export const getReportRoute = (
  id: string,
  arcsysId: string,
  endTs: number,
  beginTs: number,
  sampling: number,
) =>
  `${CTRL_EVENT_ROUTE}${REPORT_ROUTE}/${id}?arcsysId=${arcsysId}&endTs=${endTs}&beginTs=${beginTs}&sampling=${sampling}`;

// Rota para exportar arquivo em .xlsx
export const exportToXlsRoute = (
  arcsysId: string,
  ctrlId: string,
  endTs: number,
  beginTs: number,
  language: string,
  selected_model: string,
  sampling: string,
) =>
  `${CTRL_EVENT_ROUTE}${REPORT_ROUTE}/xlsx?arcsysId=${arcsysId}&ctrlId=${ctrlId}&preDefinedDate=48_hours&endTs=${endTs}&beginTs=${beginTs}&sampling=${sampling}&page=1&offset=25&language=${language}&selected_model=${selected_model}`;

// Rota para resgatar as datas e médias do Relatório
export const generalInfoRoute = (
  arcsysId: string,
  ctrlId: string,
  endTs: number,
  beginTs: number,
  language: string,
  selected_model: string,
) =>
  `${CTRL_EVENT_ROUTE}${REPORT_ROUTE}/daily/?preDefinedDate=&endTs=${endTs}&beginTs=${beginTs}&ctrlId=${ctrlId}&arcsysId=${arcsysId}&sampling=300000&page=1&offset=25&language=${language}&selected_model=${selected_model}`;

// Rota para resgatar os dados do dia do Relatório
export const getDataOnDayRoute = (
  ctrlId: string,
  beginTs: number,
  model: string,
  userId: number,
) =>
  `${CTRL_EVENT_ROUTE}/dataonday?ctrlId=${ctrlId}&startDate=${beginTs}&model=${model}&userId=${userId}`;
