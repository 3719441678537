import React from "react";
import { useTranslation } from "react-i18next";

import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Paper,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { AuthContext } from "../../contexts/AuthContext";
import { ToastContext } from "../../contexts/ToastContext";
import {
  PasswordVisibility,
  yupRequired,
  yupStringMax,
} from "../../components/Form";
import { TOKEN_KEY } from "../../auth/constants";
import { Login } from "../../services/types/auth";
import { api } from "../../services/api";
import ErrorMessage from "../../components/ErrorMessage";
import Spinner from "../../components/Spinner";
import Modal from "../Devices/Modal";
import { deleteUser, deleteUserProfile } from "../../services/data/user";
import useIsMobile from "../../hooks/useIsMobile";

interface ModalRemoveProps {
  id: string;
  name: string;
  setUserEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
  isProfileUser?: boolean;
}

const ModalRemove = ({
  handleOpen,
  id,
  name,
  setUserEdited,
  handleClose,
  isProfileUser,
}: ModalRemoveProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { signIn, signOut, user, token, authenticated } =
    React.useContext(AuthContext);
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState(false);
  const maxLength = 20;

  // Checkbox da exclusão da conta
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: yupRequired(yupStringMax(maxLength)),
    }),
    onSubmit: (values) => {
      if (authenticated) {
        setLoading(true);
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const validLogin: Login = {
          login: user.login,
          password: values.password,
        };

        // Após digitar a senha da conta, exclui o usuário
        signIn(validLogin).then(async (success) => {
          if (success) {
            if (isProfileUser) {
              // Exclui o usuário na página do 'Perfil do usuário'
              deleteUserProfile()
                .then(() => {
                  toastSuccess(t("TOAST.SUCCESS.DELETE_USER"));
                  setLoading(false);
                  setUserEdited(true);
                  signOut();
                  handleClose();
                })
                .catch(() => {
                  toastError(t("TOAST.ERROR.DELETE_USER"));
                  setLoading(false);
                });
            } else {
              // Exclui o usuário na página dos usuários
              deleteUser(+id)
                .then(() => {
                  toastSuccess(t("TOAST.SUCCESS.DELETE_USER"));
                  setLoading(false);
                  setUserEdited(true);
                  handleClose();
                })
                .catch(() => {
                  toastError(t("TOAST.ERROR.DELETE_USER"));
                  setLoading(false);
                });
            }
          } else {
            toastError(t("TOAST.ERROR.INVALID_PASSWORD"));
            setLoading(false);
          }
        });
      }
    },
  });

  const isFormValid = isProfileUser
    ? formik.dirty && formik.isValid && checked === true && !loading
    : formik.dirty && formik.isValid && !loading;

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 325 : 380}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <DeleteIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {`${t("BUTTON.DELETE")} ${
          isProfileUser ? t("TEXT.ACCOUNT") : t("TEXT.USER")
        }`}
      </Typography>
      {isProfileUser ? (
        <Typography variant="body2" mb={1}>
          {t("TEXT.ACCOUNT_DELETION_SUBTITLE")}?
        </Typography>
      ) : (
        <Typography variant="body2">
          {t("TEXT.DELETE_USER_MESSAGE")}
          <strong>{` ${name}`}</strong>?
        </Typography>
      )}
      {isProfileUser && (
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleChange} />}
          label={
            <Typography sx={{ mt: 1 }} variant="body2">
              {t("TEXT.ACCOUNT_DELETION_CHECKBOX")}
            </Typography>
          }
          sx={{ mb: 1, mr: 0 }}
        />
      )}
      <Typography sx={{ mt: 1 }} variant="body2">
        {t("TEXT.ENTER_PASSWORD")}:
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <PasswordVisibility
            formik={formik}
            disabled={false}
            onClick={(e: any) => e.stopPropagation()}
            label={t("TEXT.PASSWORD")}
            name="password"
            fullWidth
            maxLength={maxLength}
          />
          {formik.errors.password && formik.touched.password ? (
            <ErrorMessage>{formik.errors.password}</ErrorMessage>
          ) : null}
        </Box>
        <Button
          variant="contained"
          color="error"
          type="submit"
          disabled={!isFormValid}
          fullWidth
          sx={{
            mt: 3,
            borderRadius: 2,
            backgroundColor: "#C7252C",
            fontSize: 12,
            pt: 1,
            pb: 1,
          }}
        >
          {loading === true ? (
            <Spinner disableMargin size={20} />
          ) : (
            t("BUTTON.CONFIRM_DELETION")
          )}
        </Button>
        <Button
          onClick={handleClose}
          fullWidth
          sx={{
            backgroundColor: "#FFD5D7",
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            pt: 1,
            pb: 1,
            color: "black",
          }}
        >
          {t("BUTTON.CANCEL")}
        </Button>
      </form>
    </Modal>
  );
};

export default ModalRemove;
