import {
  Box,
  Button,
  Chip,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  LocalOfferOutlined as LocalOfferOutlinedIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  AddToPhotos as AddToPhotosIcon,
  HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material";
import useIsMobile from "../../../hooks/useIsMobile";
import DataGridTable from "../../../components/DataGrid/DataGridTable";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import React from "react";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthContext";
import { TOKEN_KEY } from "../../../auth/constants";
import { api } from "../../../services/api";
import { getDashboardTags } from "../../../services/data/TAGs";
import { green } from "@mui/material/colors";
import AvatarIcon from "../../../components/AvatarIcon";
import ModalEditTag from "./Modal/ModalEdit";
import ModalDeleteTag from "./Modal/ModalDelete";
import ModalAddTag from "../../../components/Controller/SettingsTable/Modal/Tag/ModalAdd";
import Spinner from "../../../components/Spinner";
import { DashboardFilterContext } from "../../../contexts/DashboardFilterContext";
import ModalHelpTags from "./Modal/ModalHelp";
import { TagsContext } from "../../../contexts/TagsContext";

const Tag = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1475;
  const { token, authenticated, userId } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const { setTag } = React.useContext(DashboardFilterContext);
  const { tagsList, setTagsList, setGlobalTagEdited } =
    React.useContext(TagsContext);
  const [searchedTags, setSearchedTags] = React.useState<string>("");
  const [tagEdited, setTagEdited] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);

  // Ações de abrir o modal de dúvidas em relação as TAGs
  const [openModalHelp, setOpenModalHelp] = React.useState<boolean>(false);
  const handleOpenModalHelp = () => setOpenModalHelp(true);
  const handleCloseModalHelp = () => {
    setOpenModalHelp(false);
  };

  // Ações de abrir o modal de adicionar TAG
  const [openModalAdd, setOpenModalAdd] = React.useState<boolean>(false);
  const handleOpenModalAdd = () => setOpenModalAdd(true);
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  // Ações do modal de deletar
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleOpenModalDelete = (data: any) => {
    setModalDeleteValues({ data });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ações do modal para editar
  const [modalEditValues, setModalEditValues] = React.useState<any>();
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const handleOpenModalEdit = (data: any) => {
    setModalEditValues({ data });
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  // Resgatar as TAGs dos controladores
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      if (tagsList?.length === 0) {
        getDashboardTags(+userId).then((res) => {
          const { data } = res;
          setTagsList(data);
          setLoading(false);
        });
      }
    }
  }, [authenticated, setTagsList, tagsList?.length, token, userId]);

  // Atualizar os dados da TAG na tela após a edição de algum dado
  React.useEffect(() => {
    if (tagEdited) {
      // Resgatar os dados do usuário
      getDashboardTags(+userId).then((res) => {
        const { data } = res;
        setTagsList(data);
        // Atualizar context do filtro no Dashboard
        setGlobalTagEdited(true);
        setTag([]);
        setLoading(false);
      });
      setTagEdited(false);
    }
  }, [setGlobalTagEdited, setTag, setTagsList, tagEdited, userId]);

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      tagsList.map((row: any, i: number) => {
        return {
          id: i + 1,
          tagId: row._id,
          name: row.tag_name,
          associates: row.ctrl_ids?.length,
          actions: "",
          isMobile: "",
        };
      }),
    [tagsList],
  );

  //  Filtrar pela a barra de pesquisa
  const searchRows = React.useMemo(
    () => rows.filter((f: any) => f.name.includes(searchedTags)),
    [rows, searchedTags],
  );

  // Barra de pesquisa das TAGs
  const handleSearch = (e: any) => {
    setSearchedTags(e);
  };

  // Limpar barra de pesquisa das TAGs
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    setSearchedTags("");
  };

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "name",
            headerName: t("TEXT.NAME"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start" sx={{ p: 0, pl: 4 }}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Chip
                          component="span"
                          avatar={<LocalOfferOutlinedIcon />}
                          label={row.name}
                          color="success"
                          sx={{
                            mr: 1,
                            fontWeight: 700,
                            backgroundColor: green[100],
                            color: green[800],
                            textTransform: "uppercase",
                            fontSize: mobile ? 10 : 12,
                            "& .MuiChip-avatar": {
                              color: "black",
                            },
                          }}
                        />
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ),
          },
          {
            field: "associates",
            align: "center",
            headerAlign: "center",
            headerName: t("TEXT.ASSOCIATES"),
            flex: 1,
            renderCell: ({ row }: any) => (
              <Typography textAlign="center">{row.associates}</Typography>
            ),
          },
          {
            field: "actions",
            headerName: t("TEXT.ACTIONS"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <React.Fragment>
                <Box mr={1}>
                  <Tooltip title={`${t("BUTTON.EDIT")} TAG`}>
                    <IconButton
                      sx={{ p: 0 }}
                      color="error"
                      onClick={() => handleOpenModalEdit(row)}
                    >
                      <EditIcon color="warning" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Tooltip title={`${t("BUTTON.DELETE")} TAG`}>
                  <IconButton
                    color="error"
                    sx={{ p: 0 }}
                    onClick={() => handleOpenModalDelete(row)}
                  >
                    <DeleteOutlineIcon color="error" />
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "name",
            hide: true,
          },
          {
            field: "associates",
            hide: true,
          },
          {
            field: "actions",
            hide: true,
          },
          {
            field: "isMobile",
            headerName: t("TEXT.NAME"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemAvatar sx={{ alignSelf: "center" }}>
                      <AvatarIcon name={row.name} bgcolor={"#C7252C"} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Chip
                          component="span"
                          avatar={<LocalOfferOutlinedIcon />}
                          label={row.name}
                          color="success"
                          sx={{
                            mr: 1,
                            fontWeight: 700,
                            backgroundColor: green[100],
                            color: green[800],
                            textTransform: "uppercase",
                            fontSize: mobile ? 10 : 12,
                            "& .MuiChip-avatar": {
                              color: "black",
                            },
                          }}
                        />
                      }
                      secondary={
                        <React.Fragment>
                          <Box
                            component="span"
                            display="flex"
                            justifyContent="left"
                            mt={0.5}
                          >
                            <Typography
                              fontWeight={600}
                              component="span"
                              sx={{
                                mt: 0.4,
                                mr: 1,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.ASSOCIATES")}:
                            </Typography>
                            <Typography component="span">
                              {row.associates}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.5} component="span">
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.2,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.ACTIONS")}:
                            </Typography>
                            <Box mr={1} component="span">
                              <Tooltip title={`${t("BUTTON.EDIT")} TAG`}>
                                <IconButton
                                  sx={{ p: 0 }}
                                  color="error"
                                  onClick={() => handleOpenModalEdit(row)}
                                >
                                  <EditIcon color="warning" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                            <Tooltip title={`${t("BUTTON.DELETE")} TAG`}>
                              <IconButton
                                color="error"
                                sx={{ p: 0 }}
                                onClick={() => handleOpenModalDelete(row)}
                              >
                                <DeleteOutlineIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  // Loading do Spinner inical da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (rows?.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [rows?.length, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 20000);
  }, []);

  return (
    <>
      <Paper
        sx={{ p: mobile ? 0 : 3.5, borderRadius: 3, mt: 3.5 }}
        elevation={3}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
          p={mobile ? 2 : 0}
          pb={mobile ? 2 : 0}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1.2} mt={1}>
              <LocalOfferOutlinedIcon fontSize="large" />
            </Box>
            <Typography fontSize={mobile ? 25 : 28}>TAGs</Typography>
            <IconButton
              color="primary"
              onClick={handleOpenModalHelp}
              sx={{ ml: 0.2 }}
            >
              <HelpOutlineIcon color="info" />
            </IconButton>
          </Box>
          <Box display="flex">
            <Button
              size="small"
              color="warning"
              startIcon={<AddToPhotosIcon />}
              onClick={handleOpenModalAdd}
              sx={{
                fontSize: 13,
                fontWeight: 600,
              }}
            >
              {t("TEXT.CREATE_TAG")}
            </Button>
          </Box>
        </Box>
        {loading ? (
          <Spinner />
        ) : (
          rows?.length !== 0 && (
            <>
              <Box pl={mobile ? 1.5 : 0} pr={mobile ? 1.5 : 0}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    mr: mobile ? 2 : 4,
                    borderRadius: 5,
                    width: "100%",
                    mt: 1,
                    mb: 3,
                    backgroundColor: theme === "dark" ? "#181818" : "#F7F7F7",
                  }}
                >
                  <SearchIcon sx={{ ml: 2 }} />
                  <InputBase
                    id="myInput"
                    onChange={(e) => handleSearch(e.target.value)}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    }}
                    sx={{ ml: 2, flex: 1 }}
                    placeholder={`${t("BUTTON.SEARCH")} TAG`}
                  />
                  <Tooltip title={t("TEXT.CLEAN")}>
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      onClick={handleClearSearch}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </Paper>
              </Box>
              <Paper
                style={{
                  height: 350,
                }}
              >
                <DataGridTable
                  rows={searchedTags === "" ? rows : searchRows}
                  isRowSelectable={(params: GridRowParams) =>
                    +params.row.userId === +userId
                  }
                  columns={columns}
                  message={t("TEXT.NO_TAG_AVAILABLE")}
                  getRowHeight={() => "auto"}
                  pageSize={5}
                  rowsPerPageOptions={[10]}
                  disableSelectionOnClick
                />
              </Paper>
            </>
          )
        )}
      </Paper>
      {modalDeleteValues && (
        <ModalDeleteTag
          setTagEdited={setTagEdited}
          data={modalDeleteValues}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
      {modalEditValues && (
        <ModalEditTag
          setTagEdited={setTagEdited}
          data={modalEditValues}
          handleOpen={openModalEdit}
          handleClose={handleCloseModalEdit}
        />
      )}
      <ModalAddTag
        handleOpen={openModalAdd}
        handleClose={handleCloseModalAdd}
        setTagEdited={setTagEdited}
      />
      <ModalHelpTags
        handleOpen={openModalHelp}
        handleClose={handleCloseModalHelp}
      />
    </>
  );
};

export default Tag;
