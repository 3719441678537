import React from "react";

import {
  AccessTime as AccessTimeIcon,
  BrowserNotSupported as BrowserNotSupportedIcon,
  FilterList as FilterListIcon,
  History as HistoryIcon,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import useIsMobile from "../../../hooks/useIsMobile";
import Modal from "../../../pages/Devices/Modal";
import { convertToDateTime } from "../../../pages/PlansAndSubscriptions/parse";
import { getLanguage } from "../../../translations/languages";
import { Estado, RowData } from "../../Controller/parser/types";
import Spinner from "../../Spinner";
import { filteredConditions, firstLetterUpperCase, splitTime } from "../parse";

interface ModalHistoryProps {
  dataRecords: any[];
  handleOpen: boolean;
  handleClose: () => void;
  sensorSelected: (e: number) => RowData;
  row: any;
  loadingModalHistory: boolean;
  estado: Estado[];
}

const ModalHistory = ({
  dataRecords,
  handleOpen,
  handleClose,
  row,
  sensorSelected,
  loadingModalHistory,
  estado,
}: ModalHistoryProps) => {
  const { t } = useTranslation();
  const { theme } = React.useContext(ThemeModeContext);
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const conditions = row.conditions;

  // Icones dos Estados
  const conditionFilteredData = filteredConditions(estado, conditions, theme);

  // Resgata o valor final com o dividor de cada Sensor no .map
  const finalValueSensorDivider = (
    posSensor: number,
    valorReferencia: number,
  ) =>
    (sensorSelected(+posSensor) as any)?.max[1] > 0
      ? valorReferencia / (sensorSelected(+posSensor) as any)?.max[1]
      : valorReferencia;

  /*   const dataMock = [
    {
      _id: "652ec7663615f4b114e19fee",
      status: "SUCESSO",
      timestamp: "1970-01-20T15:32:44.391Z",
      conditional_id: "652e9492497cc3c3e25b1766",
      command: "54-43-B2-F9-5E-98/CFG/PUT/CTRL03/alarme/temp_min",
      ctrlId: "54-43-B2-F9-5E-98/CTRL03",
      set_value: "12",
      result_array: [
        {
          posSensor: -1,
          operador: "igual",
          valorReferencia: 0,
        },
        {
          posSensor: -2,
          operador: "menor",
          valorReferencia: 170181000,
        },
      ],
    },
    {
      _id: "652ec7663615f4b114e19fee",
      status: "SUCESSO",
      timestamp: "1970-01-20T15:32:44.391Z",
      conditional_id: "652e9492497cc3c3e25b1766",
      command: "54-43-B2-F9-5E-98/CFG/PUT/CTRL03/alarme/temp_min",
      ctrlId: "54-43-B2-F9-5E-98/CTRL03",
      set_value: "12",
      result_array: [
        {
          posSensor: -1,
          operador: "igual",
          valorReferencia: 0,
        },
        {
          posSensor: -2,
          operador: "menor",
          valorReferencia: 170181000,
        },
      ],
    },
    {
      _id: "652ec7663615f4b114e19fee",
      status: "SUCESSO",
      timestamp: "1970-01-20T15:32:44.391Z",
      conditional_id: "652e9492497cc3c3e25b1766",
      command: "54-43-B2-F9-5E-98/CFG/PUT/CTRL03/alarme/temp_min",
      ctrlId: "54-43-B2-F9-5E-98/CTRL03",
      set_value: "12",
      result_array: [
        {
          posSensor: -1,
          operador: "igual",
          valorReferencia: 0,
        },
        {
          posSensor: -2,
          operador: "menor",
          valorReferencia: 170181000,
        },
      ],
    },
  ]; */

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 600}
    >
      <Box display="flex" mb={mobile ? 1 : 2}>
        <HistoryIcon sx={{ fontSize: 40, mr: 1.2 }} />
        <Typography fontSize={22} fontWeight={700} mt={0.5}>
          {t("TEXT.HISTORIC")}:
        </Typography>
      </Box>
      {loadingModalHistory === true ? (
        <Box>
          <Spinner />
        </Box>
      ) : dataRecords && dataRecords.length !== 0 ? (
        <Box
          sx={{
            maxHeight: mobile ? 450 : window.screen.width < 1550 ? 300 : 410,
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  {t("TEXT.CONDITION_SATISFIED")}
                </TableCell>
                {!mobile && <TableCell align="center">Status</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataRecords.map((row: any, i: number) => (
                <TableRow key={i}>
                  <TableCell align="center" sx={{ pt: 1.5, pb: 1.5 }}>
                    {row?.result_array?.map((v: any, i: number) => (
                      <List
                        key={i}
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                          p: 0,
                        }}
                      >
                        <ListItem sx={{ p: 0, pt: i === 0 ? 0 : 1.5 }}>
                          <ListItemAvatar>
                            <Avatar>
                              <FilterListIcon />
                            </Avatar>
                          </ListItemAvatar>
                          {v?.posSensor === -2 ? (
                            <ListItemText
                              primary={
                                <Typography fontSize={15}>
                                  {`${firstLetterUpperCase(t("TEXT.TIME"))} ${
                                    v?.operador === "maior"
                                      ? t("TEXT.TIME_AFTER")
                                      : v?.operador === "menor"
                                        ? t("TEXT.TIME_BEFORE")
                                        : t("TEXT.BETWEEN")
                                  } ${
                                    language === "es"
                                      ? t("TEXT.TIME_DAS")
                                      : language === "pt"
                                        ? v?.operador === "menor"
                                          ? t("TEXT.TIME_DAS")
                                          : v?.operador === "maior"
                                            ? "as"
                                            : ""
                                        : t("TEXT.THAN")
                                  } ${splitTime(
                                    v?.valorReferencia * 1000,
                                    language,
                                  )}
                                  ${
                                    v?.operador === "entre" ? t("TEXT.AND") : ""
                                  }
                                  ${
                                    v?.operador === "entre"
                                      ? splitTime(
                                          v?.valorReferencia_2 * 1000,
                                          language,
                                        )
                                      : ""
                                  }`}
                                </Typography>
                              }
                            />
                          ) : v?.operador === "igual" ||
                            v?.operador === "diferente" ||
                            v?.operador === "qualquer" ? (
                            <ListItemText
                              primary={
                                <Box component="span" display="flex">
                                  {v?.operador !== "qualquer" && (
                                    <Typography
                                      fontSize={15}
                                      alignSelf="center"
                                    >
                                      {`${firstLetterUpperCase(
                                        t("TEXT.STATE"),
                                      )} ${
                                        v?.operador === "igual"
                                          ? t("TEXT.EQUAL").toLowerCase()
                                          : t("TEXT.DIFFERENT").toLowerCase()
                                      } ${
                                        language === "es"
                                          ? v?.operador === "igual"
                                            ? t("TEXT.TO")
                                            : "de"
                                          : language === "pt"
                                            ? v?.operador === "igual"
                                              ? t("TEXT.TO")
                                              : "de"
                                            : t("TEXT.TO")
                                      }`}
                                    </Typography>
                                  )}
                                  {v?.operador === "qualquer" && (
                                    <Typography
                                      fontSize={15}
                                      alignSelf="center"
                                    >
                                      {`${firstLetterUpperCase(
                                        t("TEXT.STATE"),
                                      )} ${t("TEXT.ANYONE").toLowerCase()} `}
                                    </Typography>
                                  )}

                                  {conditionFilteredData[i]?.length !== 0 ? (
                                    <Box ml={0.5} mt={0.5}>
                                      <Grid container component="span">
                                        {conditionFilteredData[i]?.map(
                                          (
                                            { icon, description }: any,
                                            index: number,
                                          ) => (
                                            <Grid
                                              item
                                              key={index}
                                              component="span"
                                              ml={0.5}
                                            >
                                              <Tooltip
                                                title={description.active}
                                                enterTouchDelay={0}
                                              >
                                                <Box component="span">
                                                  {icon}
                                                </Box>
                                              </Tooltip>
                                            </Grid>
                                          ),
                                        )}
                                      </Grid>
                                    </Box>
                                  ) : (
                                    <Box ml={0.5} mt={0.5}>
                                      <Tooltip
                                        title={t("TEXT.NO_STATE_SELECTED")}
                                        enterTouchDelay={0}
                                      >
                                        <BrowserNotSupportedIcon
                                          sx={{
                                            color:
                                              theme === "light"
                                                ? "#B2B2B2"
                                                : "gray",
                                            width: "28px",
                                            height: "28px",
                                          }}
                                        />
                                      </Tooltip>
                                    </Box>
                                  )}
                                </Box>
                              }
                            />
                          ) : (
                            <ListItemText
                              primary={
                                <Typography fontSize={15}>
                                  {`${
                                    sensorSelected(+v?.posSensor)?.description
                                  } ${
                                    v?.operador === "maior"
                                      ? t("TEXT.BIGGER").toLowerCase()
                                      : t("TEXT.SMALLER").toLowerCase()
                                  } ${t(
                                    "TEXT.THAN",
                                  ).toLowerCase()} ${finalValueSensorDivider(
                                    v?.posSensor,
                                    v?.valorReferencia,
                                  )} ${sensorSelected(+v?.posSensor)?.min}`}
                                </Typography>
                              }
                            />
                          )}
                        </ListItem>
                      </List>
                    ))}
                    <List>
                      <ListItem sx={{ p: 0, pt: 0.5 }}>
                        <ListItemAvatar>
                          <Avatar>
                            <AccessTimeIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography fontSize={14}>
                              {convertToDateTime(
                                new Date(row?.timestamp).getTime(),
                                language,
                              ).replace(",", " - ")}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </List>
                    {mobile && (
                      <Box display="flex" justifyContent="left" mt={0.5}>
                        <Typography
                          fontWeight={500}
                          fontSize={14}
                          mr={1}
                          mt={0.8}
                        >
                          Status:
                        </Typography>
                        <Chip
                          label={
                            row.status === "SUCESSO"
                              ? t("TEXT.SUCCESS")
                              : row.status === "FALHA"
                                ? t("TEXT.ERROR")
                                : t("TEXT.ERROR") + " COM"
                          }
                          color={row.status === "SUCESSO" ? "success" : "error"}
                          sx={{
                            mr: 1,
                            fontWeight: 700,
                            backgroundColor:
                              row.status === "SUCESSO" ? green[100] : "#FFD5D7",
                            color:
                              row.status === "SUCESSO" ? green[800] : "#C7252C",
                            textTransform: "uppercase",
                            fontSize: 12,
                          }}
                        />
                      </Box>
                    )}
                  </TableCell>
                  {!mobile && (
                    <TableCell align="center">
                      <Chip
                        label={
                          row.status === "SUCESSO"
                            ? t("TEXT.SUCCESS")
                            : row.status === "FALHA"
                              ? t("TEXT.ERROR")
                              : t("TEXT.ERROR") + " COM"
                        }
                        color={row.status === "SUCESSO" ? "success" : "error"}
                        sx={{
                          mr: 1,
                          fontWeight: 700,
                          backgroundColor:
                            row.status === "SUCESSO" ? green[100] : "#FFD5D7",
                          color:
                            row.status === "SUCESSO" ? green[800] : "#C7252C",
                          textTransform: "uppercase",
                          fontSize: 12,
                        }}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Grid container justifyContent="center" mt={mobile ? 5 : 8} mb={6}>
          <Grid item>
            <Alert
              severity="info"
              sx={{
                alignItems: "center",
                placeContent: "center",
                mb: mobile ? 0 : 3,
              }}
            >
              <AlertTitle sx={{ mt: 0.8, fontSize: 15 }}>
                {t("TEXT.NO_HISTORIC")}
              </AlertTitle>
            </Alert>
          </Grid>
        </Grid>
      )}
    </Modal>
  );
};

export default ModalHistory;
