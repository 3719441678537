import React from "react";
import { useTranslation } from "react-i18next";

import { Edit as EditIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormHelperText,
  Paper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { TOKEN_KEY } from "../../../../auth/constants";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ToastContext } from "../../../../contexts/ToastContext";
import { api } from "../../../../services/api";
import { putControllerOfDevice } from "../../../../services/data/arcsys";
import ErrorMessage from "../../../ErrorMessage";
import { TextField, yupNumberMinMax } from "../../../Form";
import Modal from "./Modal";

interface ModalAddProps {
  updateInterval: number | undefined;
  handleOpen: boolean;
  handleClose: () => void;
  setUpdateInterval: (e: number) => void;
}

const ModalEditRecInt = ({
  updateInterval,
  handleOpen,
  handleClose,
  setUpdateInterval,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);
  const idCtrl = `${params.mac}/${params.ctrl}`;

  // Submit do intervalo de gravação
  const formik = useFormik({
    initialValues: {
      updateInterval: updateInterval ?? 5,
    },
    validationSchema: Yup.object({
      updateInterval: yupNumberMinMax(0, 60),
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const convertIntervalToTimestamp = +values.updateInterval * 60000;

        // Requisição MQTT
        putControllerOfDevice(idCtrl, {
          updateInterval: convertIntervalToTimestamp,
        })
          .then(() => {
            setUpdateInterval(convertIntervalToTimestamp / 60000);
            toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
            handleClose();
          })
          .catch(() => {
            toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
          });
      }
    },
  });

  const isFormValid =
    formik.isValid && !formik.values.updateInterval.toString().includes(".");

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={350}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {`${t("TEXT.EDIT")} ${t("TEXT.RECORDING_INTERVAL")}`}?
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            type="number"
            label={t("TEXT.RECORDING_INTERVAL")}
            name="updateInterval"
            maxLength={19}
            fullWidth
          />
          <FormHelperText>0 a 60 min</FormHelperText>
          {formik.errors.updateInterval && formik.touched.updateInterval ? (
            <ErrorMessage>{formik.errors.updateInterval}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: "#C7252C",
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              backgroundColor: "#FFD5D7",
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalEditRecInt;
