import React from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  NativeSelect,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { FileDownloadOutlined as FileDownloadOutlinedIcon } from "@mui/icons-material";
import { ToastContext } from "../../../contexts/ToastContext";
import Modal from "../../Devices/Modal";
import useIsMobile from "../../../hooks/useIsMobile";
import Switch from "../../../components/Switch";
import { generateReportAdminArea } from "../../../services/data/subscriptions";

interface ModalSignatureReportProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalSignatureReport = ({
  handleOpen,
  handleClose,
}: ModalSignatureReportProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { toastError, toastSuccess } = React.useContext(ToastContext);

  // Ação de incluir sub gratis ou não no relatório
  const [includeFree, setIncludeFree] = React.useState(true);
  const handleChangeIncludeFree = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIncludeFree(event.target.checked);
  };

  // Ação de alterar o status do relatório
  const [status, setStatus] = React.useState<string>("");
  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value as string);
  };

  // Ação de gerar relatório
  const handleSubmit = () => {
    // Rota para gerar o relatorio da lista de subs via csv no modo Admin nos planos
    const valueStatus = status === "ALL" ? "" : status;
    generateReportAdminArea(includeFree, valueStatus)
      .then((res) => {
        // Baixar relatório .csv
        window.open(`${res.config.baseURL}${res.config.url}`);
        toastSuccess(t("TOAST.SUCCESS.GENERATE_REPORT_ADMIN"));
        handleClose();
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.GENERATE_REPORT_ADMIN"));
      });
  };

  const statusData = [
    { name: t("TEXT.ACTIVE"), value: "ACTIVE" },
    { name: t("TEXT.INACTIVE"), value: "INACTIVE" },
    { name: t("TEXT.DELETED"), value: "DELETED" },
    { name: t("TEXT.ALL"), value: "ALL" },
  ];

  // Lista dos tipos de status
  const options = statusData.map((data: any, i: number) => (
    <option key={i} value={data.value}>
      <Typography>{data.name}</Typography>
    </option>
  ));

  // Limpa o formulário e fecha o modal
  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <Modal
      open={handleOpen}
      handleClose={handleCloseModal}
      width={mobile ? 340 : 380}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <FileDownloadOutlinedIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography variant="body2" fontSize={21} mt={1}>
        {t("TEXT.GENERATE_SUBS_REPORT")}
      </Typography>
      <Typography fontSize={14.5} fontWeight={700} mt={2} mb={1}>
        {t("TEXT.INCLUDE_IN_REPORT")}:
      </Typography>
      <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
        <FormControlLabel
          sx={{ mb: 1, ml: 0.5, mt: 0.5 }}
          control={
            <Switch
              sx={{ mr: 1.5 }}
              checked={includeFree}
              onChange={handleChangeIncludeFree}
            />
          }
          labelPlacement="end"
          label={t("TEXT.INCLUDE_FREE_SUBS")}
        />
      </Tooltip>
      <Box display="grid" mt={1}>
        <Typography fontSize={14.5} fontWeight={700} mb={1.5}>
          Status
        </Typography>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Status
          </InputLabel>
          <NativeSelect
            defaultValue="ALL"
            inputProps={{
              name: "Status",
              id: "uncontrolled-native",
            }}
            onChange={handleChangeStatus}
          >
            {options}
          </NativeSelect>
        </FormControl>
      </Box>
      <Box display="grid" mt={2}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            mt: 2,
            borderRadius: 2,
            backgroundColor: "#C7252C",
            fontSize: 12,
            pt: 1,
            pb: 1,
          }}
          fullWidth
        >
          {t("TEXT.GENERATE_REPORT")}
        </Button>
        <Button
          onClick={handleCloseModal}
          fullWidth
          sx={{
            backgroundColor: "#FFD5D7",
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            pt: 1,
            pb: 1,
            color: "black",
          }}
        >
          {t("BUTTON.CANCEL")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalSignatureReport;
