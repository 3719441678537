import React from "react";

import {
  AddToPhotos as AddToPhotosIcon,
  Clear as ClearIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { TOKEN_KEY } from "../../../../auth/constants";
import AvatarIcon from "../../../../components/AvatarIcon";
import DataGridTable from "../../../../components/DataGrid/DataGridTable";
import Spinner from "../../../../components/Spinner";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ThemeModeContext } from "../../../../contexts/ThemeModeContext";
import useIsMobile, { useIsMediumPage } from "../../../../hooks/useIsMobile";
import { api } from "../../../../services/api";
import { getCouponsAdmin } from "../../../../services/data/subscriptions";
import { formatValueCents } from "../../parse";
import ModalAddCupom from "./Modal/Add";
import ModalEdit from "./Modal/Edit";
import ModalRemove from "./Modal/Remove";

const Coupon = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1200;
  const mediumPage = useIsMediumPage();
  const { token, authenticated } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const [coupons, setCoupons] = React.useState<any[]>([]);
  const [couponsEdited, setCouponsEdited] = React.useState<boolean>(false);
  const [searchedCoupon, setSearchedCoupon] = React.useState<string>("");
  const [searchedClicked, setSearchedClicked] = React.useState<string>("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        // Rota para resgatar lista de cupons no modo Admin
        getCouponsAdmin().then((res) => {
          const { data } = res;
          setCoupons(data.data);
        });
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, token]);

  // Se o Cupom for editado resgata novamente a lista de Cupons e atualiza o useState
  React.useEffect(() => {
    if (couponsEdited) {
      // Rota para resgatar lista de cupons no modo Admin
      getCouponsAdmin().then((res) => {
        const { data } = res;
        setCoupons(data.data);
      });
      setCouponsEdited(false);
    }
  }, [couponsEdited]);

  // Ação de limpar barra de pesquisa dos Cupons
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    setSearchedCoupon("");
    setSearchedClicked("");
  };

  // Ações do modal de adicionar
  const [openModalAdd, setOpenModalAdd] = React.useState(false);
  const handleOpenModalAdd = () => {
    setOpenModalAdd(true);
  };
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  // Ações do modal de editar
  const [modalEditValues, setModalEditValues] = React.useState<any>();
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const handleOpenModalEdit = (e: any, data: any) => {
    e.stopPropagation();
    setModalEditValues({ data });
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  // Ações do modal de remover
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleOpenModalDelete = (e: any, id: string, coupon: string) => {
    e.stopPropagation();
    setModalDeleteValues({ id, coupon });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      coupons.map((row: any, i: number) => {
        return {
          id: i + 1,
          code: row?.code,
          value:
            formatValueCents(row?.value.toString()) === "R$: 0"
              ? t("TEXT.FREE")
              : formatValueCents(row?.value.toString()),
          starting_quantity:
            row?.starting_quantity === -1
              ? t("TEXT.UNLIMITED")
              : row?.starting_quantity,
          quantity: row?.quantity === -1 ? t("TEXT.UNLIMITED") : row?.quantity,
          starts_at: row?.starts_at,
          expires_at:
            row?.expires_at === "31-12-2100"
              ? t("TEXT.UNDETERMINED")
              : row?.expires_at,
          _id: row?._id,
          _v: row?._v,
          isSelected: row?.isSelected,
          status:
            row.status === "ACTIVE" ? t("TEXT.ACTIVE") : t("TEXT.INACTIVE"),
          actions: "",
          mobileMode: "",
          plan: row?.plan,
          mac: row?.mac,
        };
      }),
    [coupons, t],
  );

  // Filtrar planos ativos
  const filteredRows = React.useMemo(
    () =>
      rows.filter(
        (f: any) =>
          f.code.toUpperCase().includes(searchedCoupon.toUpperCase()) ||
          f.code.toLowerCase().includes(searchedCoupon.toLowerCase()),
      ),
    [rows, searchedCoupon],
  );

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "_id",
            hide: true,
          },
          {
            field: "code",
            headerName: t("TEXT.COUPON_CODE"),
            headerAlign: "left",
            flex: 2,
            renderHeader: () => (
              <p
                style={{
                  fontSize: "12px",
                  color: theme === "light" ? "#7E7E7E" : "inherit",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginLeft: "15px",
                }}
              >
                {t("TEXT.COUPON_CODE")}
              </p>
            ),
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start" sx={{ placeItems: "center" }}>
                  <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                    <AvatarIcon name={row.code} bgcolor={"#C7252C"} />
                  </ListItemAvatar>
                  <Box>
                    <Typography
                      component="span"
                      sx={{
                        display: "inline",
                        fontWeight: 450,
                        whiteSpace: "break-spaces",
                        textTransform: "uppercase",
                      }}
                    >
                      {row.code !== "" ? row.code : "--"}
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            ),
          },
          {
            field: "value",
            headerName: t("TEXT.DISCOUNT_AMOUNT"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "starting_quantity",
            headerName: t("TEXT.NUMBER_COUPONS"),
            align: "center",
            headerAlign: "center",
            flex: 1.5,
            renderCell: ({ row }: any) => (
              <List>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    pb: 0,
                    mr: row.starting_quantity === t("TEXT.UNLIMITED") ? 0 : 5.5,
                  }}
                >
                  <Box display="flex">
                    <Typography fontWeight={500} fontSize={14} mr={0.5}>
                      {t("TEXT.INITIAL")}:
                    </Typography>
                    <Typography fontSize={14}>
                      {row.starting_quantity}
                    </Typography>
                  </Box>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    pt: 0.5,
                    mr: row.quantity === t("TEXT.UNLIMITED") ? 0 : 5.5,
                  }}
                >
                  <Box display="flex">
                    <Typography fontWeight={500} fontSize={14} mr={0.5}>
                      {t("TEXT.REMANING")}:
                    </Typography>
                    <Typography fontSize={14}>{row.quantity}</Typography>
                  </Box>
                </ListItem>
              </List>
            ),
          },
          {
            field: "starts_at",
            headerName: t("TEXT.DATE"),
            align: "center",
            headerAlign: "center",
            flex: 1.5,
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start" sx={{ pb: 0 }}>
                  <Box display="flex">
                    <Typography fontWeight={500} fontSize={14} mr={0.5}>
                      {t("TEXT.CREATION")}:
                    </Typography>
                    <Typography fontSize={14}>{row.starts_at}</Typography>
                  </Box>
                </ListItem>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    pt: 0.5,
                    mr: row.expires_at === t("TEXT.UNDETERMINED") ? 0 : 2,
                  }}
                >
                  <Box display="flex">
                    <Typography fontWeight={500} fontSize={14} mr={0.5}>
                      {t("TEXT.EXPIRATION")}:
                    </Typography>
                    <Typography fontSize={14}>
                      {row.expires_at ?? "--"}
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            ),
          },
          {
            field: "actions",
            headerName: t("TEXT.ACTIONS"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <>
                <Tooltip title={t("TEXT.EDIT")}>
                  <IconButton
                    sx={{ p: 0.5 }}
                    color="warning"
                    onClick={(e) => handleOpenModalEdit(e, row)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("TEXT.REMOVE")}>
                  <IconButton
                    sx={{ p: 0.5 }}
                    color="error"
                    onClick={(e) => handleOpenModalDelete(e, row._id, row.code)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
          {
            field: "status",
            headerName: "Status",
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mt={2} mb={2}>
                <Chip
                  label={row.status}
                  color={row.status === t("TEXT.ACTIVE") ? "success" : "error"}
                  sx={{
                    mr: 1,
                    fontWeight: 700,
                    backgroundColor:
                      row.status === t("TEXT.ACTIVE") ? green[100] : "#FFD5D7",
                    color:
                      row.status === t("TEXT.ACTIVE") ? green[800] : "#C7252C",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                />
              </Box>
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "_id",
            hide: true,
          },
          {
            field: "code",
            hide: true,
          },
          {
            field: "value",
            hide: true,
          },
          {
            field: "starting_quantity",
            hide: true,
          },
          {
            field: "quantity",
            hide: true,
          },
          {
            field: "starts_at",
            hide: true,
          },
          {
            field: "expires_at",
            hide: true,
          },
          {
            field: "status",
            hide: true,
          },
          {
            field: "mobileMode",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.COUPONS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                      <AvatarIcon name={row.code} bgcolor={"#C7252C"} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Box display="flex">
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.COUPON_CODE")}:
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ textTransform: "uppercase" }}
                            >
                              {row.code}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.DISCOUNT_AMOUNT")}:
                            </Typography>
                            <Typography variant="body2">{row.value}</Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.NUMBER_OF_COUPONS")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.starting_quantity}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.REMANING_QUANTITY_COUPONS")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.quantity}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.START_DATE")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.starts_at}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.EXPIRATION_DATE")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.expires_at}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={1}>
                            <Typography
                              sx={{
                                mr: 0.5,
                                mt: 0.3,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.ACTIONS")}:
                            </Typography>
                            <Tooltip title={t("BUTTON.EDIT")}>
                              <IconButton
                                sx={{ p: 0 }}
                                color="warning"
                                onClick={(e) => handleOpenModalEdit(e, row)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("BUTTON.REMOVE")}>
                              <IconButton
                                sx={{ p: 0 }}
                                color="error"
                                onClick={(e) =>
                                  handleOpenModalDelete(e, row._id, row.code)
                                }
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box display="flex" mt={1}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 1,
                                fontSize: 13,
                              }}
                            >
                              Status:
                            </Typography>
                            <Chip
                              label={row.status}
                              color={
                                row.status === t("TEXT.ACTIVE")
                                  ? "success"
                                  : "error"
                              }
                              sx={{
                                mr: 1,
                                fontWeight: 700,
                                backgroundColor:
                                  row.status === t("TEXT.ACTIVE")
                                    ? green[100]
                                    : "#FFD5D7",
                                color:
                                  row.status === t("TEXT.ACTIVE")
                                    ? green[800]
                                    : "#C7252C",
                                textTransform: "uppercase",
                                fontSize: 12,
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (coupons?.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [coupons, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  return (
    <Box>
      <Box textAlign="right" mb={2}>
        <Button
          size="small"
          color="warning"
          startIcon={<AddToPhotosIcon />}
          onClick={handleOpenModalAdd}
          sx={{
            fontSize: 13,
            fontWeight: 600,
          }}
        >
          {t("BUTTON.ADD")}
        </Button>
      </Box>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          mr: mobile ? 2 : 4,
          borderRadius: 5,
          width: "100%",
          mt: 1,
          mb: 3,
          backgroundColor: theme === "dark" ? "#181818" : "#F9F9F9",
        }}
      >
        <Tooltip title={t("BUTTON.SEARCH")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={(e: any) => {
              setSearchedCoupon(searchedClicked);
              e.preventDefault();
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <InputBase
          id="myInput"
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              setSearchedCoupon(e.target.value);
              e.preventDefault();
            }
          }}
          onChange={(e: any) => {
            setSearchedClicked(e.target.value);
          }}
          sx={{ ml: 2, flex: 1 }}
          placeholder={t("TEXT.SEARCH_COUPONS")}
        />
        <Tooltip title={t("TEXT.CLEAN")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleClearSearch}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      {loading ? (
        <Box
          m={
            mobile
              ? "120px 0px"
              : window.screen.width < 1550
                ? "151px 0px"
                : "327.5px 0px"
          }
        >
          <Spinner />
        </Box>
      ) : (
        <Paper
          style={{
            height: mobile
              ? 365
              : mediumPage
                ? 711
                : window.screen.width < 1550
                  ? 359
                  : 711,
          }}
          elevation={0}
        >
          {rows && (
            <DataGridTable
              rows={searchedCoupon === "" ? rows : filteredRows}
              columns={columns}
              message={t("TEXT.NO_SIGNATURE")}
              getRowHeight={() => "auto"}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
            />
          )}
        </Paper>
      )}
      <ModalAddCupom
        setCouponsEdited={setCouponsEdited}
        handleOpen={openModalAdd}
        handleClose={handleCloseModalAdd}
      />
      {modalEditValues && (
        <ModalEdit
          setCouponsEdited={setCouponsEdited}
          data={modalEditValues.data}
          handleOpen={openModalEdit}
          handleClose={handleCloseModalEdit}
        />
      )}
      {modalDeleteValues && (
        <ModalRemove
          setCouponsEdited={setCouponsEdited}
          id={modalDeleteValues.id}
          coupon={modalDeleteValues.coupon}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
    </Box>
  );
};

export default Coupon;
