import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { TOKEN_KEY } from "../../../../auth/constants";
import { getRawCtrlConfig } from "../../../../config";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ThemeModeContext } from "../../../../contexts/ThemeModeContext";
import useIsMobile from "../../../../hooks/useIsMobile";
import ModalTelegramHelp from "../../../../pages/Profile/Modal/ModalTelegramHelp";
import { api } from "../../../../services/api";
import AvatarIcon from "../../../AvatarIcon";
import { Acoes, RowData } from "../../../Controller/parser/types";
import { applyDivider } from "../../../Controller/parser/utils";
import { Select, TextField } from "../../../Form";
import Spinner from "../../../Spinner";
import { divider } from "../../parse";
import ModalHelpDevice from "../ModalQuestions/ModalHelpDevice";
import { uniqKeysById } from "../../../../pages/Sectors/parse";
import { getDeviceAndCtrlData } from "../../../../services/data/scenarios";

interface StepTwoProps {
  formik: FormikProps<any>;
  acoes: Acoes[];
  parametros: RowData[];
  filteredParam: RowData;
  filteredAction: any;
  filteredSetpoint: any;
  optionsParamNotInt: any;
  conditionAction: string;
  setConditionAction: (e: string) => void;
  setpoints: any;
  setController: (e: string) => void;
  controller: string;
  setDeviceNameAction?: (e: string) => void;
  setCtrlModel: (e: string) => void;
  macId: string;
  setMacId: (e: string) => void;
  controllers: any;
  setControllers: (e: any) => void;
  ctrlModel: string;
  mac: string;
  deviceNameAction: string;
}

const StepTwo = ({
  formik,
  acoes,
  parametros,
  filteredAction,
  filteredParam,
  filteredSetpoint,
  optionsParamNotInt,
  conditionAction,
  setConditionAction,
  setpoints,
  setController,
  controller,
  setDeviceNameAction,
  setCtrlModel,
  macId,
  mac,
  setMacId,
  controllers,
  setControllers,
  ctrlModel,
  deviceNameAction,
}: StepTwoProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { authenticated, token } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const [pages, setPages] = React.useState<number>(1);
  const [payloadDevices, setPayloadDevices] = React.useState<any>([]);
  const [notShow, setNotShow] = React.useState(false);
  const [devices, setDevices] = React.useState<any>([]);
  const [loadingDevice, setLoadingDevice] = React.useState(true);
  const initialCtrlId =
    mac !== "" && ctrlModel !== "" ? `${mac}/${ctrlModel}` : "";

  // Ações do modal de dúvidas sobre o bot do Telegram
  const [openModalTelegramHelp, setOpenModalTelegramHelp] =
    React.useState(false);
  const handleOpenModalTelegramHelp = () => {
    setOpenModalTelegramHelp(true);
  };
  const handleCloseModalTelegramHelp = () => {
    setOpenModalTelegramHelp(false);
  };

  // Ações de abrir o modal de dúvidas sobre a seleção do Dispositivo e Controlador
  const [openModalHelpDevice, setOpenModalHelpDevice] =
    React.useState<boolean>(false);
  const handleOpenModalHelpDevice = () => setOpenModalHelpDevice(true);
  const handleCloseModalHelpDevice = () => setOpenModalHelpDevice(false);

  // Lista de Setpoints
  const optionsSetpoints = setpoints.map(
    (value: any, i: number) =>
      value.readOnly === false &&
      value.visible === true && (
        <MenuItem value={i} key={i}>
          {value.description}
        </MenuItem>
      ),
  );

  // Lista de Ações
  const optionsActions = acoes?.map((value: any, i: number) => (
    <MenuItem value={value.id} key={i}>
      {value.label}
    </MenuItem>
  ));

  // Range da ação selecionada
  const actionRange = `${filteredAction?.min} a ${filteredAction?.max}`;

  const rawCtrlConfig = getRawCtrlConfig(ctrlModel);

  // Range do setpoint selecionado
  const min = String(filteredSetpoint?.min);
  const minSetpoint = applyDivider(
    min.includes("detalhamento")
      ? rawCtrlConfig.detalhamento[min.split("detalhamento")[1]]?.[1]
      : filteredSetpoint?.min,
    filteredSetpoint?.divider ?? 0,
  );

  // Valor máximo do setpoint
  const max = String(filteredSetpoint?.max);
  const maxSetpoint = applyDivider(
    max.includes("detalhamento")
      ? rawCtrlConfig.detalhamento[max.split("detalhamento")[1]]?.[2]
      : filteredSetpoint?.max,
    filteredSetpoint?.divider ?? 0,
  );

  // Checa se o Setpoint é inteiro
  const isMinSetpointIsInt =
    divider(filteredSetpoint) === 0 || !Number.isInteger(minSetpoint)
      ? minSetpoint
      : `${minSetpoint}.0`;

  // Checa se o Setpoint é inteiro
  const isMaxSetpointIsInt =
    divider(filteredSetpoint) === 0 || !Number.isInteger(maxSetpoint)
      ? maxSetpoint
      : `${maxSetpoint}.0`;

  const setpointRange = `${isMinSetpointIsInt} a ${isMaxSetpointIsInt}`;

  // Range do parametro selecionado
  const minParam = applyDivider(
    filteredParam?.min ?? 0,
    divider(filteredParam),
  );
  const maxParam = applyDivider(
    filteredParam?.max ?? 0,
    divider(filteredParam),
  );

  // Checa se o parametro é inteiro
  const isMinParamIsInt =
    divider(filteredParam) === 0 || !Number.isInteger(minParam)
      ? minParam
      : `${minParam}.0`;

  // Checa se o parametro é inteiro
  const isMaxParamIsInt =
    divider(filteredParam) === 0 || !Number.isInteger(maxParam)
      ? maxParam
      : `${maxParam}.0`;

  const paramtrosRange = `${isMinParamIsInt} a ${isMaxParamIsInt}`;

  // Lista de Parametros
  const optionsParams = parametros.map(
    (value: any, i: number) =>
      value.readOnly === false &&
      value.visible === true && (
        <MenuItem value={i} key={i}>
          {value.description}
        </MenuItem>
      ),
  );

  // Lista de valores do parametro selecionado (quando não for do tipo 'INT')
  const optionsMap =
    optionsParamNotInt &&
    Object.entries(optionsParamNotInt).map(([key, label]) => (
      <MenuItem key={key} value={key}>
        {label as string}
      </MenuItem>
    ));

  // Alterar a forma de Ação
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConditionAction((event.target as HTMLInputElement).value);
  };

  const handleChangeMac = (event: SelectChangeEvent) => {
    setMacId(event.target.value as string);
    if (setController) {
      setController("");
    }
  };

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        // Rota para resgatar a quantidade de dispositivos e controladores que irão ser renderizados
        getDeviceAndCtrlData(pages, 10, initialCtrlId).then((res) => {
          if (res?.data) {
            const payloadData = res.data.items?.sort((s: any) =>
              s.online === true ? -1 : 1,
            );
            if (res.data.total_pages === res.data.total) {
              setNotShow(true);
            }
            setPayloadDevices(res.data.items);
            setDevices((prevOptions: any) => [...prevOptions, ...payloadData]);

            setLoadingDevice(false);
          }
        });
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, controller, initialCtrlId, pages, token]);

  // Adicionar novos dados no select dos Dispositivos
  const handleLoadMore = () => {
    setPages((prevPage) => prevPage + 1);
  };

  // Resgatar dados iniciais dos controladores do Dispositivo
  React.useEffect(() => {
    const valueCtrl = uniqKeysById(devices).filter(
      (f: any) => f.name === deviceNameAction,
    )[0]?.controllers;

    setControllers(valueCtrl);
  }, [deviceNameAction, devices, setControllers]);

  // Lista de Dispositivos
  const optionsMac = devices ? (
    uniqKeysById(devices)?.map(
      (value: any) =>
        value && (
          <MenuItem
            value={value._id}
            key={value._id}
            sx={{ pt: 0, pb: 0, borderBottom: "1px solid #F1F1F1" }}
            onClick={() => {
              if (setDeviceNameAction) {
                setDeviceNameAction(value.name);
              }
              setControllers(value.controllers);
              setCtrlModel("");
            }}
          >
            {!mobile ? (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor="#C7252C" />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ p: 0 }}
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                          <Chip
                            label={value.online ? "Online" : "Offline"}
                            color={value.online ? "success" : "error"}
                            sx={{
                              mr: 1,
                              fontWeight: 700,
                              backgroundColor: value.online
                                ? green[100]
                                : "#FFD5D7",
                              color: value.online ? green[800] : "#C7252C",
                              textTransform: "uppercase",
                              fontSize: 12,
                            }}
                          />
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ) : (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor="#C7252C" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                        <Chip
                          component="span"
                          label={value.online ? "Online" : "Offline"}
                          color={value.online ? "success" : "error"}
                          sx={{
                            mt: 0.5,
                            mr: 1,
                            fontWeight: 700,
                            backgroundColor: value.online
                              ? green[100]
                              : "#FFD5D7",
                            color: value.online ? green[800] : "#C7252C",
                            textTransform: "uppercase",
                            fontSize: 12,
                          }}
                        />
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            )}
          </MenuItem>
        ),
    )
  ) : (
    <Spinner />
  );

  // Adicionar novos dados no select dos Dispositivos
  const addMoreItens = (
    <MenuItem>
      <Button
        onClick={(e: any) => {
          e.stopPropagation();
          if (handleLoadMore) {
            handleLoadMore();
          }
        }}
        disabled={loadingDevice}
        fullWidth
        variant="outlined"
      >
        {loadingDevice ? <Spinner size={24} /> : t("TEXT.CHARGE_MORE_DEVICES")}
      </Button>
    </MenuItem>
  );

  // Adicionar o botão 'addMoreItems' na lista de dispositivos
  const allMenuItems =
    notShow === true || payloadDevices?.length === 0
      ? optionsMac
      : [...optionsMac, addMoreItens];

  // Lista de Controladores
  const optionsController = controllers?.map((p: any) => (
    <MenuItem
      value={p._id}
      key={p._id}
      sx={{ pt: 1, pb: 1, borderBottom: "1px solid #F1F1F1" }}
      onClick={() => setCtrlModel(p.model)}
    >
      <Box display="flex">
        <Chip
          label={p.status === "ACTIVE" ? "Online" : "Offline"}
          color={p.status === "ACTIVE" ? "success" : "error"}
          sx={{
            mr: 1,
            fontWeight: 700,
            backgroundColor: p.status === "ACTIVE" ? green[100] : "#FFD5D7",
            color: p.status === "ACTIVE" ? green[800] : "#C7252C",
            textTransform: "uppercase",
            fontSize: 12,
          }}
        />
        <Typography sx={{ ml: 0.5, mt: 0.5, whiteSpace: "break-spaces" }}>
          {`${p.name} - ${p._id.split("/")[1].split("L")[1]}`}
        </Typography>
      </Box>
    </MenuItem>
  ));

  const handleChangeController = (event: SelectChangeEvent) => {
    setController(event.target.value as string);
  };

  // Validação do input do Controlador
  const checkCtrlInput = !optionsController || controllers?.length === 0;

  return (
    <Box
      sx={{
        maxHeight: mobile || window.screen.width < 1550 ? 400 : 600,
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >
      <FormLabel sx={{ textAlign: "left", fontSize: 18, mt: 3, ml: 2 }}>
        {t("TEXT.TYPE_OF_ACTION")}
      </FormLabel>
      <Box ml={2}>
        <FormControl>
          <RadioGroup
            value={conditionAction}
            onChange={handleChange}
            sx={{
              placeContent: "left",
              mt: 0,
              mb: 1,
            }}
          >
            <Box display="flex">
              <FormControlLabel
                value="3"
                control={<Radio />}
                label={t("TEXT.TELEGRAM_MESSAGE")}
                sx={{ mr: 0 }}
              />
              <IconButton color="primary" onClick={handleOpenModalTelegramHelp}>
                <HelpOutlineIcon color="info" />
              </IconButton>
            </Box>
            <FormControlLabel
              value="0"
              control={<Radio />}
              label={t("TEXT.TAKE_ACTION_COMMAND")}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label={`${t("TEXT.ALTER")} setpoint`}
            />
          </RadioGroup>
        </FormControl>
      </Box>
      {conditionAction !== "3" && (
        <Box pl={2} pr={2}>
          <Box display="flex" mt={1} alignItems="center">
            <FormLabel>{t("TEXT.DEVICE")}</FormLabel>
            <Box>
              <IconButton
                color="primary"
                onClick={handleOpenModalHelpDevice}
                sx={{ pl: 1 }}
              >
                <HelpOutlineIcon color="info" />
              </IconButton>
            </Box>
          </Box>
          <Box display="grid" mt={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t("TEXT.SELECT_DEVICE")}
              </InputLabel>
              <MuiSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={macId}
                label={t("TEXT.SELECT_DEVICE")}
                onChange={handleChangeMac}
                disabled={devices?.length === 0}
                sx={
                  macId === undefined
                    ? {
                        "& .css-1rioaud-MuiFormLabel-root-MuiInputLabel-root": {
                          padding: "6px",
                        },
                      }
                    : macId !== ""
                      ? {
                          "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              p: 0,
                            },
                          "& .css-qiwgdb": {
                            p: 0,
                          },
                        }
                      : {}
                }
              >
                {allMenuItems}
              </MuiSelect>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">
                {t("TEXT.SELECT_CONTROLLER")}
              </InputLabel>
              <MuiSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={controller}
                label={t("TEXT.SELECT_CONTROLLER")}
                onChange={handleChangeController}
                disabled={checkCtrlInput}
              >
                {optionsController}
              </MuiSelect>
            </FormControl>
          </Box>
        </Box>
      )}
      {conditionAction === "0" ? (
        <Box display={mobile ? "grid" : "flex"} mt={2} ml={2} mr={2}>
          <Box
            display="grid"
            width={filteredAction?.tipo !== "number" || mobile ? "100%" : "70%"}
            mr={filteredAction?.tipo !== "number" || mobile ? 0 : 2}
          >
            <Select
              formik={formik}
              label={t("TEXT.COMMANDS").split("s")[0]}
              name="action"
              options={optionsActions}
              disabled={acoes === undefined || acoes?.length === 0}
            />
          </Box>
          {filteredAction?.tipo === "number" && (
            <Box
              display="grid"
              width={mobile ? "100%" : "30%"}
              mt={mobile ? 1 : 0}
            >
              <TextField
                formik={formik}
                disabled={false}
                label={t("TEXT.VALUE")}
                name="valueAction"
                type="number"
                fullWidth
              />
              <FormHelperText>{actionRange}</FormHelperText>
            </Box>
          )}
        </Box>
      ) : conditionAction === "1" ? (
        <Box display={mobile ? "grid" : "flex"} mt={4}>
          <Box
            display="grid"
            width={mobile || formik.values.param === "" ? "100%" : "70%"}
            mr={2}
            mb={mobile ? 1 : 0}
          >
            <Select
              formik={formik}
              label={t("TEXT.PARAMS")}
              name="param"
              options={optionsParams as React.JSX.Element[]}
              disabled={parametros === undefined || parametros.length === 0}
            />
          </Box>
          {formik.values.param !== "" && (
            <Box width={mobile ? "100%" : "50%"}>
              {!optionsParamNotInt ? (
                <Box width="100%">
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>{t("TEXT.VALUE")}</InputLabel>
                    <OutlinedInput
                      name="valueParam"
                      value={formik.values.valueParam}
                      onChange={formik.handleChange}
                      endAdornment={
                        <InputAdornment position="end">
                          {filteredParam?.unitOfMeasurement}
                        </InputAdornment>
                      }
                      label={t("TEXT.VALUE")}
                    />
                    <FormHelperText>
                      {`${paramtrosRange} ${filteredParam?.unitOfMeasurement}`}
                    </FormHelperText>
                  </FormControl>
                </Box>
              ) : (
                <Box width="100%">
                  <Select
                    formik={formik}
                    label={t("TEXT.VALUE")}
                    name="valueParam"
                    options={optionsMap}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      ) : conditionAction === "2" ? (
        <Box display={mobile ? "grid" : "flex"} mt={2} ml={2} mr={2}>
          <Box
            display="grid"
            width={formik.values.setpoint === "" || mobile ? "100%" : "70%"}
            mr={formik.values.setpoint === "" || mobile ? 0 : 2}
          >
            <Select
              formik={formik}
              label="Setpoints"
              name="setpoint"
              options={optionsSetpoints}
              disabled={setpoints === undefined || setpoints.length === 0}
            />
          </Box>
          {formik.values.setpoint !== "" && setpoints.length > 0 && (
            <Box
              display="grid"
              width={mobile ? "100%" : "30%"}
              mt={mobile ? 1 : 0}
            >
              <TextField
                formik={formik}
                disabled={false}
                label={t("TEXT.VALUE")}
                name="valueSetpoint"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {filteredSetpoint?.unitOfMeasurement}
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText>
                {`${setpointRange} ${filteredSetpoint?.unitOfMeasurement}`}
              </FormHelperText>
            </Box>
          )}
        </Box>
      ) : (
        <Box mt={2} ml={2} mr={2}>
          <TextField
            formik={formik}
            disabled={false}
            label={t("TEXT.MESSAGE")}
            name="valueNotification"
            maxLength={500}
            fullWidth
          />
        </Box>
      )}
      {openModalTelegramHelp && (
        <ModalTelegramHelp
          handleOpen={openModalTelegramHelp}
          handleClose={handleCloseModalTelegramHelp}
        />
      )}
      {openModalHelpDevice && (
        <ModalHelpDevice
          handleOpen={openModalHelpDevice}
          handleClose={handleCloseModalHelpDevice}
        />
      )}
    </Box>
  );
};

export default StepTwo;
