Array.prototype.contains = function (b) {
  return !!~this.indexOf(b);
};

Array.prototype.isEmpty = function () {
  return this.length === 0;
};

Array.prototype.pushAll = function (arr) {
  this.push(...arr);
};

Array.prototype.unshiftAll = function (arr) {
  this.unshift(...arr);
};

Array.prototype.shuffle = function () {
  let i = this.length,
    j,
    temp;
  if (i == 0) return this;
  while (--i) {
    j = Math.floor(Math.random() * (i + 1));
    temp = this[i];
    this[i] = this[j];
    this[j] = temp;
  }
  return this;
};

export {};
