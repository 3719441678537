import React from "react";
import { useTranslation } from "react-i18next";

import { AddToPhotos as AddToPhotosIcon } from "@mui/icons-material";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";
import { TOKEN_KEY } from "../../auth/constants";
import ErrorMessage from "../../components/ErrorMessage";
import { TextField, yupRequiredString } from "../../components/Form";
import MacMask from "../../components/Masks/MacMask";
import { AuthContext } from "../../contexts/AuthContext";
import { ToastContext } from "../../contexts/ToastContext";
import { api } from "../../services/api";
import { addMacAdmin } from "../../services/data/mac";
import Modal from "../Devices/Modal";

interface ModalAddProps {
  setMacEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalAdd = ({ handleOpen, handleClose, setMacEdited }: ModalAddProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);

  // Limpar o formulário e fechar o Modal
  const closeModal = () => {
    formik.resetForm();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      mac: "",
    },
    validationSchema: Yup.object({
      mac: yupRequiredString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const data = {
          _id: values.mac.toUpperCase(),
        };

        // Adicionar Endereço MAC
        addMacAdmin(data)
          .then(() => {
            setMacEdited(true);
            toastSuccess(t("TOAST.SUCCESS.ADD_MAC_ADDRESS"));
            closeModal();
          })
          .catch((err) => {
            const error = err.response.status;
            if (error === 403) {
              toastError(t("TOAST.ERROR.ADD_MAC_ADDRESS_EXISTENT"));
            } else {
              toastError(t("TOAST.ERROR.ADD_MAC_ADDRESS"));
            }
          });
      }
    },
  });

  const isFormValid =
    formik.dirty && formik.isValid && !formik.values.mac.includes("_");

  return (
    <Modal open={handleOpen} handleClose={closeModal} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <AddToPhotosIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.ADD_MAC_ADDRESS")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            label="MAC"
            name="mac"
            fullWidth
            InputProps={{
              inputComponent: MacMask,
            }}
          />
          {formik.errors.mac && formik.touched.mac ? (
            <ErrorMessage>{formik.errors.mac}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: "#C7252C",
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          <Button
            onClick={closeModal}
            fullWidth
            sx={{
              backgroundColor: "#FFD5D7",
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalAdd;
