import {
  AddCircleOutline as AddCircleOutlineIcon,
  CancelOutlined as CancelOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  LocalOffer as LocalOfferIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
  ViewList as ViewListIcon,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TOKEN_KEY } from "../../auth/constants";
import ModalAddTag from "../../components/Controller/SettingsTable/Modal/Tag/ModalAdd";
import ModalDeleteTag from "../../components/Controller/SettingsTable/Modal/Tag/ModalDelete";
import ModalSelectTag from "../../components/Controller/SettingsTable/Modal/Tag/ModalSelect";
import Spinner from "../../components/Spinner";
import { AuthContext } from "../../contexts/AuthContext";
import { DashboardFilterContext } from "../../contexts/DashboardFilterContext";
import { TagsContext } from "../../contexts/TagsContext";
import useIsMobile from "../../hooks/useIsMobile";
import { api } from "../../services/api";
import { getDashboardTags } from "../../services/data/TAGs";
import { convertToDateTime } from "../PlansAndSubscriptions/parse";
import ModalRemoveController from "./DevicePage/Modal/ModalRemoveController";

interface ControllerProps {
  controllers: any;
  loadingCtrls: boolean;
  setLoadingCtrls: (e: boolean) => void;
  setDeviceEdited: (e: boolean) => void;
  devicePermission: string;
  modalControllersValues: any;
  setModalControllersValues: (e: any) => void;
}

const ControllerTable = ({
  controllers,
  loadingCtrls,
  devicePermission,
  setLoadingCtrls,
  setDeviceEdited,
  modalControllersValues,
  setModalControllersValues,
}: ControllerProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const navigate = useNavigate();
  const smallWindow = window.innerWidth < 1370;
  const { token, authenticated, userId } = React.useContext(AuthContext);
  const { setDashboardDeviceEdited } = React.useContext(DashboardFilterContext);
  const { tagsList, setTagsList, globalTagEdited, setGlobalTagEdited } =
    React.useContext(TagsContext);
  const [tagEdited, setTagEdited] = React.useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();

  // Resgatar as TAGs dos controladores
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      if (tagsList.length === 0 || globalTagEdited === true) {
        getDashboardTags(+userId).then((res) => {
          const { data } = res;
          setGlobalTagEdited(false);
          setTagsList(data);
        });
      }
    }
  }, [
    authenticated,
    globalTagEdited,
    setGlobalTagEdited,
    setTagsList,
    tagsList.length,
    token,
    userId,
  ]);

  // Atualizar os dados da TAG após selecionar alguma TAG
  React.useEffect(() => {
    if (tagEdited) {
      // Resgatar os dados do usuário
      getDashboardTags(+userId).then((res) => {
        const { data } = res;
        setGlobalTagEdited(false);
        setTagsList(data);
      });
      setTagEdited(false);
    }
  }, [setGlobalTagEdited, setTagsList, tagEdited, userId]);

  // Checar se a TAG existe no controlador
  const checkIsTagExist = (id: string) =>
    tagsList.length > 0 &&
    tagsList
      .map((m: any) => {
        return m.ctrl_ids.includes(id) ? true : false;
      })
      .includes(true);

  // Ações do modal de deletar o controlador
  const handleOpenModalDelete = (e: any, row: any) => {
    e.stopPropagation();
    setModalDeleteValues({ row });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ações de abrir o modal de adicionar TAG
  const [openModalAdd, setOpenModalAdd] = React.useState<boolean>(false);
  const [modalAddValuesTag, setModalAddValuesTag] = React.useState<any>();
  const handleOpenModalAdd = (e: any, ctrlId: string) => {
    e.stopPropagation();
    setModalAddValuesTag({ ctrlId });
    setOpenModalAdd(true);
  };
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  // Ações de abrir o modal de selecionar TAG
  const [openModalSelect, setOpenModalSelect] = React.useState<boolean>(false);
  const [modalSelectValuesTag, setModalSelectValuesTag] = React.useState<any>();
  const handleOpenModalSelect = (e: any, ctrlId: string) => {
    e.stopPropagation();
    setModalSelectValuesTag({ ctrlId });
    setOpenModalSelect(true);
  };
  const handleCloseModalSelect = () => {
    setOpenModalSelect(false);
  };

  // Ações de abrir o modal de deletar TAG
  const [openModalDeleteTag, setOpenModalDeleteTag] =
    React.useState<boolean>(false);
  const [modalDeleteValuesTag, setModalDeleteValuesTag] = React.useState<any>();
  const handleOpenModalDeleteTag = (e: any, tag: any, ctrlId: string) => {
    e.stopPropagation();
    setModalDeleteValuesTag({ tag, ctrlId });
    setOpenModalDeleteTag(true);
  };
  const handleCloseModalDeleteTag = () => {
    setOpenModalDeleteTag(false);
  };

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (controllers?.length !== 0 && loadingCtrls) {
        setLoadingCtrls(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [controllers?.length, loadingCtrls, setLoadingCtrls]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoadingCtrls(false);
    }, 5000);
  }, [setLoadingCtrls]);

  return (
    <>
      <Paper sx={{ p: mobile ? 0 : 3.5, borderRadius: 3, mt: 3 }}>
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            pt={mobile ? 2 : 0}
            pl={mobile ? 2 : 0}
            pb={1.5}
          >
            <Box sx={{ mr: 1 }}>
              <ViewListIcon fontSize="large" />
            </Box>
            <Typography fontSize={mobile ? 25 : 28} gutterBottom>
              {t("TEXT.CONTROLLERS")}
            </Typography>
          </Box>
          {modalControllersValues && (
            <Box pt={mobile ? 1.5 : 0}>
              <Tooltip title={t("TEXT.CLOSE_CONTROLLER_LIST")}>
                <IconButton
                  color="primary"
                  onClick={(e: any) => {
                    setModalControllersValues(undefined);
                    e.preventDefault();
                  }}
                >
                  <CancelOutlinedIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        {loadingCtrls ? (
          <Box>
            <Spinner />
          </Box>
        ) : controllers && controllers?.length !== 0 ? (
          <Box
            sx={{
              maxHeight: mobile ? 220 : 320,
              overflow:
                !mobile && controllers?.length < 5 ? "hidden" : "scroll",
              overflowX: "hidden",
            }}
          >
            <Paper>
              {!mobile && !smallWindow ? (
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t("TEXT.NAME")}</TableCell>
                      <TableCell align="center">
                        {t("TEXT.LAST_RECORDED_EVENT")}
                      </TableCell>
                      {devicePermission === "OWNER" && (
                        <TableCell align="center">
                          {t("TEXT.ACTIONS")}
                        </TableCell>
                      )}
                      <TableCell align="center">TAG</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {controllers.map((row: any, i: number) => (
                      <TableRow
                        hover
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/controller/${row._id}`)}
                      >
                        <TableCell align="center">{`${
                          row.name
                        } (${row._id.slice(22, 25)})`}</TableCell>
                        <TableCell align="center">
                          {convertToDateTime(
                            row.lastMsgTs,
                            localStorage.lang,
                          ).replace(",", " - ")}
                        </TableCell>
                        {devicePermission === "OWNER" && (
                          <TableCell align="center">
                            <Box
                              pl={3}
                              pr={3}
                              pt={0.5}
                              pb={0.5}
                              onClick={(e) => handleOpenModalDelete(e, row)}
                            >
                              <Box display="flex" justifyContent="center">
                                <Tooltip title={t("TEXT.DELETE_CONTROLLER")}>
                                  <IconButton color="error" sx={{ p: 0 }}>
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        )}
                        {row._id.slice(22, 25) !== "36" ? (
                          <TableCell align="center">
                            {tagsList.length > 0 &&
                              tagsList.map((m: any, i: number) =>
                                m.ctrl_ids.map(
                                  (mc: string) =>
                                    mc === row._id && (
                                      <Box
                                        key={i}
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <Chip
                                          label={m.tag_name}
                                          avatar={<LocalOfferOutlinedIcon />}
                                          color="success"
                                          sx={{
                                            mr: 0.5,
                                            fontWeight: 700,
                                            backgroundColor: green[100],
                                            color: green[800],
                                            textTransform: "uppercase",
                                            fontSize: 12,
                                            "& .MuiChip-avatar": {
                                              color: "black",
                                            },
                                          }}
                                        />
                                        <Tooltip
                                          key={i}
                                          title={`${t("TEXT.REMOVE")} TAG`}
                                        >
                                          <IconButton
                                            color="primary"
                                            sx={{ p: 0 }}
                                            onClick={(e) =>
                                              handleOpenModalDeleteTag(e, m, mc)
                                            }
                                          >
                                            <DeleteOutlineIcon color="warning" />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    ),
                                ),
                              )}
                            {checkIsTagExist(row._id) === false && (
                              <>
                                <Tooltip title={t("TEXT.SELECT_TAG")}>
                                  <IconButton
                                    color="primary"
                                    sx={{ p: 0.5 }}
                                    onClick={(e) =>
                                      handleOpenModalSelect(e, row._id)
                                    }
                                  >
                                    <LocalOfferIcon color="warning" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={t("TEXT.CREATE_TAG")}>
                                  <IconButton
                                    color="primary"
                                    sx={{ p: 0.5 }}
                                    onClick={(e) =>
                                      handleOpenModalAdd(e, row._id)
                                    }
                                  >
                                    <AddCircleOutlineIcon color="warning" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </TableCell>
                        ) : (
                          <TableCell></TableCell>
                        )}
                        <TableCell align="center">
                          <Chip
                            label={
                              row.status === "ACTIVE" ? "Online" : "Offline"
                            }
                            color={
                              row.status === "ACTIVE" ? "success" : "error"
                            }
                            sx={{
                              mr: 1,
                              fontWeight: 700,
                              backgroundColor:
                                row.status === "ACTIVE"
                                  ? green[100]
                                  : "#FFD5D7",
                              color:
                                row.status === "ACTIVE"
                                  ? green[800]
                                  : "#C7252C",
                              textTransform: "uppercase",
                              fontSize: 12,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Table aria-label="simple table">
                  <TableBody>
                    {controllers.map((row: any, i: number) => (
                      <TableRow
                        hover
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/controller/${row._id}`)}
                      >
                        <TableCell align="center" sx={{ pl: 3.5 }}>
                          <Box display="flex" justifyContent="left" mb={1}>
                            <Typography fontWeight={500} mr={1}>
                              {t("TEXT.NAME")}:
                            </Typography>
                            <Typography>{`${row.name} (${row._id.slice(
                              22,
                              25,
                            )})`}</Typography>
                          </Box>
                          <Box mb={1}>
                            {window.innerWidth > 370 ? (
                              <Typography
                                mr={1}
                                textAlign="left"
                                fontSize={14.8}
                              >
                                <strong>
                                  {t("TEXT.LAST_RECORDED_EVENT")}:{" "}
                                </strong>
                                {convertToDateTime(
                                  row.lastMsgTs,
                                  localStorage.lang,
                                ).replace(",", " - ")}
                              </Typography>
                            ) : (
                              <>
                                <Typography
                                  mr={1}
                                  textAlign="left"
                                  fontSize={14.8}
                                >
                                  <strong>
                                    {t("TEXT.LAST_RECORDED_EVENT")}:{" "}
                                  </strong>
                                </Typography>
                                <Typography
                                  mr={1}
                                  textAlign="left"
                                  fontSize={14.8}
                                >
                                  {convertToDateTime(
                                    row.lastMsgTs,
                                    localStorage.lang,
                                  ).replace(",", " - ")}
                                </Typography>
                              </>
                            )}
                          </Box>
                          {devicePermission === "OWNER" && (
                            <Box display="flex" justifyContent="left">
                              <Typography fontWeight={500} mr={1}>
                                {t("TEXT.ACTIONS")}:
                              </Typography>
                              <Tooltip title={t("TEXT.DELETE_CONTROLLER")}>
                                <IconButton
                                  color="error"
                                  sx={{ p: 0 }}
                                  onClick={(e) => handleOpenModalDelete(e, row)}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                          {row._id.slice(22, 25) !== "36" && (
                            <Box
                              display="flex"
                              justifyContent="left"
                              mb={1}
                              mt={1}
                            >
                              <Typography fontWeight={500} mr={1} mt={0.5}>
                                TAG:
                              </Typography>
                              {tagsList.length > 0 &&
                                tagsList.map((m: any, i: number) =>
                                  m.ctrl_ids.map(
                                    (mc: string) =>
                                      mc === row._id && (
                                        <Box key={i} display="flex">
                                          <Chip
                                            label={m.tag_name}
                                            avatar={<LocalOfferOutlinedIcon />}
                                            color="success"
                                            sx={{
                                              mr: 0.5,
                                              fontWeight: 700,
                                              backgroundColor: green[100],
                                              color: green[800],
                                              textTransform: "uppercase",
                                              fontSize: 12,
                                              "& .MuiChip-avatar": {
                                                color: "black",
                                              },
                                            }}
                                          />
                                          <Tooltip
                                            key={i}
                                            title={`${t("TEXT.REMOVE")} TAG`}
                                          >
                                            <IconButton
                                              color="primary"
                                              sx={{ p: 0 }}
                                              onClick={(e) =>
                                                handleOpenModalDeleteTag(
                                                  e,
                                                  m,
                                                  mc,
                                                )
                                              }
                                            >
                                              <DeleteOutlineIcon color="warning" />
                                            </IconButton>
                                          </Tooltip>
                                        </Box>
                                      ),
                                  ),
                                )}
                              {checkIsTagExist(row._id) === false && (
                                <>
                                  <Tooltip title={t("TEXT.SELECT_TAG")}>
                                    <IconButton
                                      color="primary"
                                      sx={{ p: 0.5 }}
                                      onClick={(e) =>
                                        handleOpenModalSelect(e, row._id)
                                      }
                                    >
                                      <LocalOfferIcon color="warning" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={t("TEXT.CREATE_TAG")}>
                                    <IconButton
                                      color="primary"
                                      sx={{ p: 0.5 }}
                                      onClick={(e) =>
                                        handleOpenModalAdd(e, row._id)
                                      }
                                    >
                                      <AddCircleOutlineIcon color="warning" />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </Box>
                          )}
                          <Box display="flex" justifyContent="left" mt={1}>
                            <Typography fontWeight={500} mr={1} mt={0.4}>
                              Status:
                            </Typography>
                            <Chip
                              label={
                                row.status === "ACTIVE" ? "Online" : "Offline"
                              }
                              color={
                                row.status === "ACTIVE" ? "success" : "error"
                              }
                              sx={{
                                mr: 1,
                                fontWeight: 700,
                                backgroundColor:
                                  row.status === "ACTIVE"
                                    ? green[100]
                                    : "#FFD5D7",
                                color:
                                  row.status === "ACTIVE"
                                    ? green[800]
                                    : "#C7252C",
                                textTransform: "uppercase",
                                fontSize: 12,
                              }}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Paper>
          </Box>
        ) : (
          <Grid container justifyContent="center">
            <Grid item>
              <Alert
                severity="info"
                sx={{
                  alignItems: "center",
                  placeContent: "center",
                  mb: mobile ? 3 : 0,
                }}
              >
                <AlertTitle sx={{ mt: 0.8 }}>
                  {t("TEXT.NO_CONTROLLER_FOUND")}
                </AlertTitle>
              </Alert>
            </Grid>
          </Grid>
        )}
      </Paper>
      {modalDeleteValues && (
        <ModalRemoveController
          setDashboardDeviceEdited={setDashboardDeviceEdited}
          setDeviceEdited={setDeviceEdited}
          row={modalDeleteValues}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
      {modalAddValuesTag && (
        <ModalAddTag
          ctrlId={modalAddValuesTag.ctrlId}
          handleOpen={openModalAdd}
          handleClose={handleCloseModalAdd}
          setTagEdited={setTagEdited}
        />
      )}
      {modalSelectValuesTag && (
        <ModalSelectTag
          ctrlId={modalSelectValuesTag.ctrlId}
          handleOpen={openModalSelect}
          handleClose={handleCloseModalSelect}
          setTagEdited={setTagEdited}
        />
      )}
      {modalDeleteValuesTag && (
        <ModalDeleteTag
          tagName={modalDeleteValuesTag.tag.tag_name}
          tag_id={modalDeleteValuesTag.tag._id}
          ctrlId={modalDeleteValuesTag.ctrlId}
          handleOpen={openModalDeleteTag}
          handleClose={handleCloseModalDeleteTag}
          setTagEdited={setTagEdited}
        />
      )}
    </>
  );
};
export default ControllerTable;
