import React, { MutableRefObject, Ref } from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";

// Formatação de endereço MAC em componentes Input
const MacMask = React.forwardRef(
  (topLevelProps: MaskedInputProps, ref: Ref<HTMLInputElement>) => {
    return (
      <MaskedInput
        render={(textMaskRef, props) => (
          <input
            {...props}
            ref={(node) => {
              if (node) {
                textMaskRef(node);
                if (ref) {
                  (ref as MutableRefObject<HTMLInputElement>).current = node;
                }
              }
            }}
          />
        )}
        {...topLevelProps}
        mask={[
          /[A-Za-z0-9_.]/,
          /[A-Za-z0-9_.]/,
          "-",
          /[A-Za-z0-9_.]/,
          /[A-Za-z0-9_.]/,
          "-",
          /[A-Za-z0-9_.]/,
          /[A-Za-z0-9_.]/,
          "-",
          /[A-Za-z0-9_.]/,
          /[A-Za-z0-9_.]/,
          "-",
          /[A-Za-z0-9_.]/,
          /[A-Za-z0-9_.]/,
          "-",
          /[A-Za-z0-9_.]/,
          /[A-Za-z0-9_.]/,
        ]}
        style={{
          textTransform: "uppercase",
        }}
      />
    );
  },
);

export default MacMask;
