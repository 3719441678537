import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddToPhotos as AddToPhotosIcon,
  AttachMoney as AttachMoneyIcon,
  Clear as ClearIcon,
  HighlightOff as HighlightOffIcon,
  DeleteOutline as DeleteOutlineIcon,
  ViewList as ViewListIcon,
  Edit as EditIcon,
  Share as ShareIcon,
  Devices as DevicesIcon,
  CloudQueue as CloudQueueIcon,
  CloudOff as CloudOffIcon,
  Search as SearchIcon,
  Replay as ReplayIcon,
  RunningWithErrors as RunningWithErrorsIcon,
  ShopOutlined as ShopOutlinedIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import React from "react";
import {
  getConfig,
  getControllersOfDevice,
  getDashboardMacs,
  getNumbersOfDevices,
} from "../../services/data/arcsys";
import { AuthContext } from "../../contexts/AuthContext";
import { TOKEN_KEY } from "../../auth/constants";
import { api } from "../../services/api";
import { GridColDef } from "@mui/x-data-grid";
import Spinner from "../../components/Spinner";
import ModalRemove from "./Modal/ModalRemove";
import ModalAdd from "./Modal/ModalAdd";
import ModalEdit from "./Modal/ModalEdit";
import { useNavigate } from "react-router-dom";
import ModalShare from "./Modal/ModalShare";
import ModalUnshare from "./Modal/ModalUnshare";
import AvatarIcon from "../../components/AvatarIcon";
import { green, grey } from "@mui/material/colors";
import useIsMobile, { useIsMediumPage } from "../../hooks/useIsMobile";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import ModalAcceptShared from "./Modal/ModalAcceptShared";
import { ToastContext } from "../../contexts/ToastContext";
import { PLANS_AND_SUBSCRIPTIONS } from "../../Routes/constants";
import { getLanguage } from "../../translations/languages";
import {
  convertToDateValue,
  enExpiresDate,
} from "../PlansAndSubscriptions/parse";
import ControllerTable from "./ControllerTable";
import ModalWebFiConnect from "./Modal/ModalWebFiConnect";
import DataGridServerSide from "../../components/DataGridServerSide";
import { queryTypeOptions } from "./parse";
import { ArcsysContext } from "../../contexts/ArcsysProviderContext";
import { DashboardFilterContext } from "../../contexts/DashboardFilterContext";

const Devices = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1200;
  const mediumPage = useIsMediumPage();
  const language = getLanguage().code;
  const navigate = useNavigate();
  const { userArcsys, token, authenticated, userId } =
    React.useContext(AuthContext);
  const { setMacsToSubscribe } = React.useContext(ArcsysContext);
  const { toastError } = React.useContext(ToastContext);
  const { theme } = React.useContext(ThemeModeContext);
  const { setIsOfflineDevicesStored } = React.useContext(
    DashboardFilterContext,
  );
  const [deviceList, setDeviceList] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  const [loadingCtrls, setLoadingCtrls] = React.useState(true);
  const [loadingModalCtrl, setLoadingModalCtrl] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openModalAdd, setOpenModalAdd] = React.useState(false);
  const [openModalWebFiConnect, setOpenModalWebFiConnect] =
    React.useState(false);
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const [openModalShare, setOpenModalShare] = React.useState(false);
  const [openModalUnshare, setOpenModalUnshare] = React.useState(false);
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [modalEditValues, setModalEditValues] = React.useState<any>();
  const [modalShareValues, setModalShareValues] = React.useState<any>();
  const [modalUnshareValues, setModalUnshareValues] = React.useState<any>();
  const [controllers, setControllers] = React.useState<any>();
  const [modalControllersValues, setModalControllersValues] =
    React.useState<any>();
  const [openModalAcceptShare, setOpenModalAcceptShare] = React.useState(false);
  const [modalAcceptShareValues, setModalAcceptShareValues] =
    React.useState<any>();
  const [deviceEdited, setDeviceEdited] = React.useState<boolean>(false);
  const [deviceOption, setDeviceOption] = React.useState(0);
  const [searchedDevice, setSearchedDevice] = React.useState<string>("");
  const [isSearched, setIsSearched] = React.useState<boolean>(false);
  const [usersPerArcsys, setUsersPerArcsys] = React.useState<number>();
  const [checked, setChecked] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [asAdmin, setAsAdmin] = React.useState<number>(0);
  const [searchedDeviceClicked, setSearchedDeviceClicked] =
    React.useState<string>("");
  const [searchedCleaned, setSearchedCleaned] = React.useState<boolean>(false);
  const [queryType, setQueryType] = React.useState("name");

  // Selecionar o tipo de pesquisa
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryType(event.target.value);
  };

  // Checar se o usuário é Admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  React.useEffect(() => {
    const fetchStatus = () => {
      // Requisição get contendo o valor configurado do tempo offline do arcsys
      getConfig().then((res) => {
        setUsersPerArcsys(res.data.usersPerArcsys);
      });
    };
    fetchStatus();
    // Dar um get somente uma vez nessa requisição
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
  }, [setUsersPerArcsys]);

  // Barra de pesquisa do Dispositivo
  const handleSearch = (e: any) => {
    setSearchedDevice(e);
    setIsSearched(true);
  };

  // Limpar barra de pesquisa do Dispositivo
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    setSearchedDevice("");
    setSearchedDeviceClicked("");
    setLoading(true);
    setSearchedCleaned(true);
  };

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        if (
          searchedDevice !== "" ||
          searchedCleaned === true ||
          checked ||
          queryType ||
          isSearched
        ) {
          // Rota para resgatar a quantidade de dispositivos que irão ser renderizados
          getNumbersOfDevices(
            false,
            pageNumber,
            rowsPerPage,
            searchedDevice,
            asAdmin,
            queryTypeOptions(deviceOption, searchedDevice, queryType),
          ).then((res) => {
            if (res?.data) {
              setDeviceList(
                res.data.items?.sort((s: any) => (s.online === true ? -1 : 1)),
              );
              setTotalPages(res.data.total);
              if (res.data.items?.length === 0) {
                setLoading(false);
              }
            }
          });
        }
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checked,
    pageNumber,
    searchedDevice,
    authenticated,
    setDeviceList,
    token,
    searchedCleaned,
    rowsPerPage,
    deviceOption,
    isSearched,
  ]);

  // Ação ao alterar a página da tabela de dispositivos
  const handleChangePage = (value: number) => {
    setPageNumber(value + 1);
  };

  // Atualizar os dados do Dispositivo após a edição de qualquer dado
  React.useEffect(() => {
    if (deviceEdited) {
      if (pageNumber > 0) {
        // Rota para resgatar a quantidade de dispositivos que irão ser renderizados
        getNumbersOfDevices(
          false,
          pageNumber,
          rowsPerPage,
          searchedDevice,
          asAdmin,
          queryTypeOptions(deviceOption, searchedDevice, queryType),
        ).then((res) => {
          if (res?.data) {
            setDeviceList(
              res.data.items?.sort((s: any) => (s.online === true ? -1 : 1)),
            );
            setTotalPages(res.data.total);
          }
        });
      }

      // Atualizar dados dos dispositivos MACs no Dashboard
      getDashboardMacs().then((res) => {
        const { data } = res;
        setMacsToSubscribe(data);
      });
      setIsOfflineDevicesStored(true);
      setDeviceEdited(false);

      // if (+userId) {
      //   getUserById(+userId).then((res) => {
      //     if (res?.data) {
      //       const { data } = res as any;
      //       // Resgatar os arcsys do usuário logado
      //       setUserArcsys(data);
      //       localStorage.setItem("userId", JSON.stringify(data._id));
      //     }
      //   });
      // }
    }
  }, [
    asAdmin,
    deviceEdited,
    pageNumber,
    rowsPerPage,
    searchedDevice,
    queryType,
    setDeviceList,
    // setUserArcsys,
    checked,
    deviceOption,
    setMacsToSubscribe,
    setIsOfflineDevicesStored,
  ]);

  // Ações do modal de deletar
  const handleOpenModalDelete = (e: any, id: string, device: string) => {
    e.stopPropagation();
    setModalDeleteValues({ id, device });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ações do modal de adicionar
  const handleOpenModalAdd = () => {
    setOpenModalAdd(true);
  };
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  // Ações do modal do WebFi Connect
  const handleOpenModalWebFiConnect = () => {
    setOpenModalWebFiConnect(true);
  };
  const handleCloseModalWebFiConnect = () => {
    setOpenModalWebFiConnect(false);
  };

  // Ações do modal de editar
  const getDeviceById = (id: string) =>
    deviceList !== undefined ? deviceList.filter((f: any) => f.id === id) : [];
  const handleOpenModalEdit = (e: any, name: string, id: string) => {
    e.stopPropagation();
    setModalEditValues({ name, id, allDevices: getDeviceById(id)[0] });
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  // Ações do modal de compartilhar
  const handleOpenModalShare = (e: any, id: string) => {
    e.stopPropagation();
    if (usersPerArcsys !== undefined) {
      if (getDeviceById(id)[0].users.length >= usersPerArcsys) {
        toastError(t("TOAST.ERROR.SHARE_DEVICE_PERMISSION_MAX"));
        return;
      }
    }
    setModalShareValues({ id });
    setOpenModalShare(true);
  };
  const handleCloseModalShare = () => {
    setOpenModalShare(false);
  };

  // Ações do modal de descompartilhar
  const handleOpenModalUnshare = (
    e: any,
    id: string,
    device: string,
    ownerId: number,
  ) => {
    e.stopPropagation();
    setModalUnshareValues({ id, device, ownerId });
    setOpenModalUnshare(true);
  };
  const handleCloseModalUnshare = () => {
    setOpenModalUnshare(false);
  };

  // Ações do modal de visualizar os controladores
  const handleClickControllers = (
    e: any,
    mac: string,
    devicePermission: string,
  ) => {
    e.stopPropagation();
    setModalControllersValues({ mac, devicePermission });

    // Checar se a tabela de Controladores foi renderizada
    if (modalControllersValues?.mac === mac) {
      setModalControllersValues(undefined);
    } else {
      setLoadingModalCtrl(true);
      setTimeout(() => {
        setLoadingModalCtrl(false);
      }, 500);
    }
  };

  // Resgatar a lista de Controladores de acordo com o Dispositivo selecionado
  React.useEffect(() => {
    if (modalControllersValues) {
      // Resgatar dados gerais do Dispositivo
      const deviceFiltered = deviceList.filter(
        (f: any) => f.mac === modalControllersValues.mac,
      )[0];
      if (deviceFiltered) {
        // Resgata a lista de Controladores do Dispositivo
        getControllersOfDevice(deviceFiltered.id).then((res) => {
          setControllers(res.data.items);
          if (res.data.items?.length === 0) {
            setLoadingCtrls(false);
          }
        });
      }
    }
  }, [deviceList, modalControllersValues]);

  // Ações do modal de aceitar o compartilhamento
  const handleOpenModalAcceptShare = (e: any, id: string) => {
    e.stopPropagation();
    if (usersPerArcsys !== undefined) {
      if (getDeviceById(id)[0].users.length >= usersPerArcsys) {
        toastError(t("TOAST.ERROR.SHARE_DEVICE_PERMISSION_MAX"));
        return;
      }
    }
    setModalAcceptShareValues({ id });
    setOpenModalAcceptShare(true);
  };
  const handleCloseModalAcceptShare = () => {
    setOpenModalAcceptShare(false);
  };

  // Alterar o plano
  const handleChangePlan = (e: any, mac: string, id: string) => {
    e.stopPropagation();
    navigate(`${PLANS_AND_SUBSCRIPTIONS}/${mac}/${id}`);
  };

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      deviceList !== undefined
        ? deviceList.map((m: any, i: number) => {
            const filterPermissions = m.users.filter(
              (f: any) => f.id === +userId,
            )[0];

            // Permissões do Dispositivo
            const result =
              filterPermissions?.role === "OWNER"
                ? t("TEXT.MANAGE")
                : filterPermissions?.role === "EDIT"
                  ? t("TEXT.EDIT")
                  : filterPermissions?.role === "VIEW"
                    ? t("TEXT.VIEW")
                    : t("TEXT.MANAGE"); // role ADMIN

            // Checar se o usuário tem permissão para compartilhar o Dispositivo
            const filterPermissionsShared =
              m.subscriptions
                .filter(
                  (f: any) => f.status === "ACTIVE" && f.user_id === m.ownerId,
                )
                .reverse()[0]?.plan.permissions.DEVICE.SHAREABLE ?? false;

            // Data de expiração do plano
            const expiresDate = m?.subscriptions
              .filter(
                (f: any) => f.status === "ACTIVE" && f.user_id === m?.ownerId,
              )
              ?.reverse()[0]
              ?.expires_at?.replace("-", "/")
              ?.replace("-", "/")
              .toString();

            // Alterar o formato da data de expiração de acordo com a linguagem
            const convertedExpiresDate =
              language !== "en" ? expiresDate : enExpiresDate(expiresDate);

            // Data de expiração do Plano - 30 dias
            const expDataMinThirtyDays = new Date(
              +convertToDateValue(convertedExpiresDate, language) -
                30 * 86400 * 1000,
            ).getTime();

            // Checar se falta 30 dias para o plano se expirar
            const checkRenewDate =
              expDataMinThirtyDays < new Date().getTime() ? true : false;

            return {
              id: i + 1,
              deviceId: m.id,
              name: m.name,
              owner: m.owner?.email,
              ownerId: m.ownerId,
              mac: m.mac,
              permission: result,
              actions: "",
              status: m.status,
              online: m.online,
              controllers: "",
              pending: filterPermissions?.pending,
              permissionShared: filterPermissionsShared,
              checkRenewDate,
              ownerRole: m.owner?.role,
            };
          })
        : [],
    [deviceList, language, t, userId],
  );

  // Checkbox de selecionar todos os dispositivos
  const handleChangeAllDevices = () => {
    const check = !checked;
    setChecked(check);
    setAsAdmin(check === true ? 1 : 0);
    setSearchedDevice("");
    setSearchedDeviceClicked("");
    setSearchedCleaned(true);
  };

  // Filtrar status online
  const activeRows = React.useMemo(
    () => rows.filter((f: any) => f.online),
    [rows],
  );

  // Filtrar status offline
  const inactiveRows = React.useMemo(
    () => rows.filter((f: any) => !f.online),
    [rows],
  );

  // Filtrar dispositivos compartilhados
  const sharedRows = React.useMemo(
    () =>
      rows.filter((f: any) => f.permission?.toString() !== t("TEXT.MANAGE")),
    [rows, t],
  );

  // Verificar se o dispositivo tem permissão para compartilhar
  const handleNoPermissionShare = (e: any) => {
    e.stopPropagation();
    toastError(t("TOAST.ERROR.SHARE_DEVICE_PERMISSION"));
  };

  // Mensagem dos dispositivos compartilhados
  const handleSharedDevice = () => {
    toastError(t("TOAST.ERROR.SHARE_DEVICE_PLAN"));
  };

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "deviceId",
            hide: true,
          },
          {
            field: "owner",
            hide: true,
          },
          {
            field: "ownerId",
            hide: true,
          },
          {
            field: "pending",
            hide: true,
          },
          {
            field: "mac",
            hide: true,
          },
          {
            field: "name",
            headerName: t("TEXT.DEVICE"),
            align: "left",
            headerAlign: "left",
            flex: 3,
            renderHeader: () => (
              <p
                style={{
                  fontSize: "12px",
                  color: theme === "light" ? "#7E7E7E" : "inherit",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginLeft: "15px",
                }}
              >
                {t("TEXT.DEVICE")}
              </p>
            ),
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                    <AvatarIcon name={row.name} bgcolor="#C7252C" />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {row.name}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{ display: "inline" }}
                          variant="body2"
                        >
                          {row.owner}
                        </Typography>
                        <Box display="flex" mt={0.2} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography
                            component="span"
                            sx={{ display: "inline" }}
                            variant="body2"
                          >
                            {row.mac}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ),
          },
          {
            field: "controllers",
            headerName: t("TEXT.CONTROLLERS"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box
                sx={{
                  cursor: row.pending ? "default" : "pointer",
                }}
                p={3}
                onClick={(e) =>
                  !row.pending &&
                  handleClickControllers(e, row.mac, row.permission)
                }
              >
                <Box display="flex" justifyContent="center">
                  <Tooltip title={t("TEXT.VIEW")}>
                    <IconButton
                      sx={{ p: 0 }}
                      color={
                        row.mac === modalControllersValues?.mac
                          ? "success"
                          : "primary"
                      }
                    >
                      <ViewListIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            ),
          },
          {
            field: "actions",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.ACTIONS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) =>
              row.permission === t("TEXT.MANAGE") ? (
                <>
                  <Tooltip title={t("TEXT.EDIT_DEVICE")}>
                    <IconButton
                      sx={{ p: 0 }}
                      color="warning"
                      onClick={(e) =>
                        handleOpenModalEdit(e, row.name, row.deviceId)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("TEXT.SHARE_DEVICE")}>
                    <IconButton
                      sx={{
                        p: 0,
                        color: row.permissionShared ? "#0388D1" : "#B8B8B8",
                      }}
                      onClick={(e) =>
                        row.permissionShared
                          ? handleOpenModalShare(e, row.deviceId)
                          : handleNoPermissionShare(e)
                      }
                    >
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("TEXT.CHANGE_PLAN")}>
                    <IconButton
                      color="success"
                      onClick={(e) =>
                        handleChangePlan(e, row.mac, row.deviceId)
                      }
                      sx={{ p: 0 }}
                    >
                      <AttachMoneyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("TEXT.DELETE_DEVICE_TITLE")}>
                    <IconButton
                      sx={{ p: 0 }}
                      color="error"
                      onClick={(e) =>
                        handleOpenModalDelete(e, row.deviceId, row.name)
                      }
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  {row.pending && (
                    <Tooltip title={t("TEXT.ACCEPT_SHARING")}>
                      <IconButton
                        sx={{ p: 0 }}
                        color="warning"
                        onClick={(e) =>
                          handleOpenModalAcceptShare(e, row.deviceId)
                        }
                      >
                        <ShareIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title={t("TEXT.UNSHARE_DEVICE_TITLE")}>
                    <IconButton
                      sx={{ p: 0 }}
                      color="error"
                      onClick={(e) =>
                        handleOpenModalUnshare(
                          e,
                          row.deviceId,
                          row.name,
                          row.ownerId,
                        )
                      }
                    >
                      <HighlightOffIcon sx={{ fontSize: 26 }} />
                    </IconButton>
                  </Tooltip>
                </>
              ),
          },
          {
            field: "permission",
            headerName: t("TEXT.PERMISSION"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "status",
            sortable: false,
            disableColumnMenu: true,
            headerName: "Status",
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <>
                <Chip
                  label={
                    row.pending
                      ? t("TEXT.PENDING")
                      : row.online
                        ? "Online"
                        : "Offline"
                  }
                  color={row.online ? "success" : "error"}
                  sx={{
                    fontWeight: 700,
                    backgroundColor: row.pending
                      ? grey[200]
                      : row.online
                        ? green[100]
                        : "#FFD5D7",
                    color: row.pending
                      ? grey[700]
                      : row.online
                        ? green[800]
                        : "#C7252C",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                />
                {row.checkRenewDate === true && (
                  <Box>
                    {+row.ownerId === +userId ? (
                      <Tooltip title={t("TEXT.PLAN_EXPIRED")}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `${PLANS_AND_SUBSCRIPTIONS}/${row.mac}/${row.planId}`,
                            );
                          }}
                          sx={{
                            p: 0,
                            ml: 1,
                            cursor: "pointer",
                          }}
                        >
                          <RunningWithErrorsIcon
                            sx={{ fontSize: 28 }}
                            fontSize="large"
                            color="warning"
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title={t("TEXT.PLAN_EXPIRED")}>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSharedDevice();
                          }}
                          sx={{
                            p: 0,
                            ml: 1,
                            cursor: "pointer",
                          }}
                        >
                          <RunningWithErrorsIcon
                            sx={{ fontSize: 28, color: "#B8B8B8" }}
                            fontSize="large"
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                )}
              </>
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "deviceId",
            hide: true,
          },
          {
            field: "pending",
            hide: true,
          },
          {
            field: "owner",
            hide: true,
          },
          {
            field: "ownerId",
            hide: true,
          },
          {
            field: "mac",
            hide: true,
          },
          {
            field: "actions",
            hide: true,
          },
          {
            field: "status",
            hide: true,
          },
          {
            field: "name",
            headerName: t("TEXT.DEVICES"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemAvatar sx={{ alignSelf: "center" }}>
                      <AvatarIcon name={row.name} bgcolor="#C7252C" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {row.name}
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ width: "10px" }}
                            mt={0.5}
                          >
                            {row.owner}
                          </Typography>
                          <Box display="flex" mt={0.5} component="span">
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              MAC:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.mac}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.5} component="span">
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.2,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.CONTROLLERS")}:
                            </Typography>
                            <IconButton
                              sx={{
                                p: 0,
                                cursor: row.pending ? "default" : "pointer",
                              }}
                              color={
                                row.mac === modalControllersValues?.mac
                                  ? "success"
                                  : "primary"
                              }
                              onClick={(e) =>
                                !row.pending &&
                                handleClickControllers(
                                  e,
                                  row.mac,
                                  row.permission,
                                )
                              }
                            >
                              <ViewListIcon />
                            </IconButton>
                          </Box>
                          <Box
                            display="flex"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PERMISSION")}:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.permission}
                            </Typography>
                          </Box>
                          <Box display="flex" component="span">
                            {row.permission === t("TEXT.MANAGE") ? (
                              <Box mt={1} mb={1} component="span">
                                <Tooltip title={t("TEXT.EDIT_DEVICE")}>
                                  <IconButton
                                    sx={{ p: 0 }}
                                    color="warning"
                                    onClick={(e) =>
                                      handleOpenModalEdit(
                                        e,
                                        row.name,
                                        row.deviceId,
                                      )
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={t("TEXT.SHARE_DEVICE")}>
                                  <IconButton
                                    sx={{
                                      p: 0,
                                      color: row.permissionShared
                                        ? "#0388D1"
                                        : "#B8B8B8",
                                    }}
                                    onClick={(e) =>
                                      row.permissionShared
                                        ? handleOpenModalShare(e, row.deviceId)
                                        : handleNoPermissionShare(e)
                                    }
                                  >
                                    <ShareIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={t("TEXT.CHANGE_PLAN")}>
                                  <IconButton
                                    color="success"
                                    sx={{ p: 0 }}
                                    onClick={(e) =>
                                      handleChangePlan(e, row.mac, row.deviceId)
                                    }
                                  >
                                    <AttachMoneyIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={t("TEXT.DELETE_DEVICE_TITLE")}>
                                  <IconButton
                                    sx={{ p: 0 }}
                                    color="error"
                                    onClick={(e) =>
                                      handleOpenModalDelete(
                                        e,
                                        row.deviceId,
                                        row.name,
                                      )
                                    }
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            ) : (
                              <Box mt={1} mb={1} component="span">
                                {row.pending && (
                                  <Tooltip title={t("TEXT.ACCEPT_SHARING")}>
                                    <IconButton
                                      sx={{ p: 0 }}
                                      color="warning"
                                      onClick={(e) =>
                                        handleOpenModalAcceptShare(
                                          e,
                                          row.deviceId,
                                        )
                                      }
                                    >
                                      <ShareIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title={t("TEXT.UNSHARE_DEVICE_TITLE")}>
                                  <IconButton
                                    sx={{ p: 0 }}
                                    color="error"
                                    onClick={(e) =>
                                      handleOpenModalUnshare(
                                        e,
                                        row.deviceId,
                                        row.name,
                                        row.ownerId,
                                      )
                                    }
                                  >
                                    <HighlightOffIcon sx={{ fontSize: 26 }} />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                            <Chip
                              component="span"
                              label={
                                row.pending
                                  ? t("TEXT.PENDING")
                                  : row.online
                                    ? "Online"
                                    : "Offline"
                              }
                              color={row.online ? "success" : "error"}
                              sx={{
                                fontWeight: 700,
                                backgroundColor: row.pending
                                  ? grey[200]
                                  : row.online
                                    ? green[100]
                                    : "#FFD5D7",
                                color: row.pending
                                  ? grey[700]
                                  : row.online
                                    ? green[800]
                                    : "#C7252C",
                                textTransform: "uppercase",
                                fontSize: 12,
                                ml: 1,
                                mt: 0.5,
                              }}
                            />
                            {row.checkRenewDate === true && (
                              <Box mt={0.8}>
                                {+row.ownerId === +userId ? (
                                  <Tooltip title={t("TEXT.PLAN_EXPIRED")}>
                                    <IconButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(
                                          `${PLANS_AND_SUBSCRIPTIONS}/${row.mac}/${row.planId}`,
                                        );
                                      }}
                                      sx={{
                                        p: 0,
                                        ml: 1,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <RunningWithErrorsIcon
                                        sx={{ fontSize: 28 }}
                                        fontSize="large"
                                        color="warning"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title={t("TEXT.PLAN_EXPIRED")}>
                                    <IconButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleSharedDevice();
                                      }}
                                      sx={{
                                        p: 0,
                                        ml: 1,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <RunningWithErrorsIcon
                                        sx={{ fontSize: 28, color: "#B8B8B8" }}
                                        fontSize="large"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                            )}
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (rows.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [rows.length, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 60000);
  }, []);

  // Alterar tipo de filtro da tabela
  const handleChangeFilter = (_: any, value: number) => {
    setDeviceOption(value);
  };

  // Ação de atualizar tabela
  const reloadTable = () => {
    // Rota para resgatar a quantidade de dispositivos que irão ser renderizados
    getNumbersOfDevices(
      false,
      pageNumber,
      rowsPerPage,
      searchedDevice,
      asAdmin,
      queryTypeOptions(deviceOption, searchedDevice, queryType),
    ).then((res) => {
      if (res?.data) {
        setDeviceList(
          res.data.items?.sort((s: any) => (s.online === true ? -1 : 1)),
        );
        setTotalPages(res.data.total);
      }
    });
  };

  return (
    <Box display="grid">
      <Box overflow="auto" whiteSpace="nowrap">
        <Box display="flex" justifyContent="center">
          <Card elevation={3} sx={{ width: 1500, p: mobile ? 0 : 1, mb: 2 }}>
            <CardContent
              sx={{ p: mobile ? 0 : 1 }}
              style={{ paddingBottom: "0px" }}
            >
              <Box display="flex" justifyContent="space-between" ml={2}>
                <Typography
                  fontSize={mobile ? 22 : 25}
                  gutterBottom
                  sx={{ mt: mobile ? 1 : 0, mb: 0 }}
                >
                  {t("TEXT.DEVICES")}
                </Typography>
                <Box display="flex">
                  {!smallWindow && userIsAdmin && (
                    <FormControl component="fieldset" variant="standard">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={handleChangeAllDevices}
                              name="gilad"
                            />
                          }
                          label={t("TEXT.ALL_DEVICES")}
                        />
                      </FormGroup>
                    </FormControl>
                  )}
                  {window.innerWidth < 900 ? (
                    <IconButton
                      color="warning"
                      onClick={handleOpenModalWebFiConnect}
                      sx={{ mt: 0.8 }}
                    >
                      <ShopOutlinedIcon />
                    </IconButton>
                  ) : (
                    <Button
                      size="small"
                      color="warning"
                      startIcon={<ShopOutlinedIcon />}
                      onClick={handleOpenModalWebFiConnect}
                      sx={{
                        fontSize: 13,
                        fontWeight: 600,
                        mt: mobile ? 1 : 0,
                        mr: mobile ? 1 : 2,
                      }}
                    >
                      WebFi Connect
                    </Button>
                  )}
                  {window.innerWidth < 900 ? (
                    <IconButton
                      color="warning"
                      onClick={handleOpenModalAdd}
                      sx={{ mt: 0.8 }}
                    >
                      <AddToPhotosIcon />
                    </IconButton>
                  ) : (
                    <Button
                      size="small"
                      color="warning"
                      startIcon={<AddToPhotosIcon />}
                      onClick={handleOpenModalAdd}
                      sx={{
                        fontSize: 13,
                        fontWeight: 600,
                        mt: mobile ? 1 : 0,
                        mr: mobile ? 1 : 2,
                      }}
                    >
                      {t("BUTTON.ADD")}
                    </Button>
                  )}
                  <Tooltip title={t("TEXT.UPDATE") as string}>
                    <IconButton
                      color="warning"
                      onClick={reloadTable}
                      sx={{ mt: mobile ? 0.5 : 0 }}
                    >
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              {smallWindow && userIsAdmin && (
                <Box sx={{ textAlign: "center" }} mt={1}>
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={handleChangeAllDevices}
                            name="gilad"
                          />
                        }
                        label={t("TEXT.ALL_DEVICES")}
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              )}
              <Card elevation={0}>
                <CardContent>
                  {loading ? (
                    <Box
                      m={
                        mobile
                          ? "230px 0px"
                          : window.screen.width < 1550
                            ? "290px 0px"
                            : "470px 0px"
                      }
                    >
                      <Spinner />
                    </Box>
                  ) : (
                    <>
                      <Tabs
                        value={deviceOption}
                        onChange={handleChangeFilter}
                        orientation="horizontal"
                        variant="scrollable"
                        allowScrollButtonsMobile
                        sx={{
                          fontSize: 20,
                          pb: 2,
                          pt: 2,
                          placeContent: "center",
                        }}
                      >
                        <Tab
                          icon={<DevicesIcon />}
                          label={t("TEXT.ALL")}
                          disableRipple
                          sx={{ pt: 0 }}
                        />
                        <Tab
                          icon={<CloudQueueIcon />}
                          label="Online"
                          disableRipple
                          sx={{ pt: 0 }}
                        />
                        <Tab
                          icon={<CloudOffIcon />}
                          label="Offline"
                          sx={{ pt: 0 }}
                          disableRipple
                        />
                        <Tab
                          icon={<ShareIcon />}
                          label={`${t("TEXT.SHARED")}s`}
                          sx={{ pt: 0 }}
                          disableRipple
                        />
                      </Tabs>
                      <FormControl>
                        <FormLabel>{t("BUTTON.SEARCH")}:</FormLabel>
                        <RadioGroup
                          row
                          value={queryType}
                          onChange={handleChangeSearch}
                        >
                          <FormControlLabel
                            value="name"
                            control={<Radio />}
                            label={t("TEXT.NAME")}
                          />
                          <FormControlLabel
                            value="mac"
                            control={<Radio />}
                            label="MAC"
                          />
                          <FormControlLabel
                            value="email"
                            control={<Radio />}
                            label={t("TEXT.EMAIL")}
                          />
                        </RadioGroup>
                      </FormControl>
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          mr: mobile ? 2 : 4,
                          borderRadius: 5,
                          width: "100%",
                          mt: 1,
                          mb: 3,
                          backgroundColor:
                            theme === "dark" ? "#181818" : "#F9F9F9",
                          cursor: "pointer",
                        }}
                      >
                        <Tooltip title={t("BUTTON.SEARCH")}>
                          <IconButton
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                            onClick={(e: any) => {
                              handleSearch(searchedDeviceClicked);
                              e.preventDefault();
                              if (isSearched) {
                                setIsSearched(false);
                              }
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                        <InputBase
                          id="myInput"
                          onKeyDown={(e: any) => {
                            if (e.key === "Enter") {
                              handleSearch(e.target.value);
                              e.preventDefault();
                              if (isSearched) {
                                setIsSearched(false);
                              }
                            }
                          }}
                          onChange={(e: any) => {
                            setSearchedDeviceClicked(e.target.value);
                          }}
                          sx={{ ml: 2, flex: 1 }}
                          placeholder={t("TEXT.SEARCH_DEVICE")}
                        />
                        <Tooltip title={t("TEXT.CLEAN")}>
                          <IconButton
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                            onClick={handleClearSearch}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Tooltip>
                      </Paper>
                      <Paper
                        style={{
                          height: modalControllersValues
                            ? 370
                            : mobile
                              ? 370
                              : mediumPage
                                ? 835
                                : window.screen.width < 1550
                                  ? 480
                                  : 835,
                          cursor: "pointer",
                        }}
                        elevation={0}
                      >
                        <DataGridServerSide
                          rows={
                            deviceOption === 1
                              ? activeRows
                              : deviceOption === 2
                                ? inactiveRows
                                : deviceOption === 3
                                  ? sharedRows
                                  : rows
                          }
                          columns={columns}
                          pageNumber={pageNumber}
                          totalPages={totalPages}
                          message={t("TEXT.NO_DEVICES")}
                          getRowHeight={() => "auto"}
                          onRowClick={(params: any) =>
                            params.row.pending
                              ? toastError(
                                  t("TOAST.ERROR.ACCESS_SHARED_DEVICE"),
                                )
                              : navigate(
                                  `/devices/${params.row.mac}/${params.row.ownerId}`,
                                )
                          }
                          onPageChange={(newPage: number) => {
                            handleChangePage(newPage);
                          }}
                          onPageSizeChange={(rowsPerPage: any) =>
                            setRowsPerPage(rowsPerPage)
                          }
                          disableSelectionOnClick
                        />
                      </Paper>
                      {loadingModalCtrl === false ? (
                        modalControllersValues && (
                          <ControllerTable
                            setDeviceEdited={setDeviceEdited}
                            controllers={controllers}
                            loadingCtrls={loadingCtrls}
                            setLoadingCtrls={setLoadingCtrls}
                            devicePermission={
                              modalControllersValues.devicePermission
                            }
                            modalControllersValues={modalControllersValues}
                            setModalControllersValues={
                              setModalControllersValues
                            }
                          />
                        )
                      ) : (
                        <Spinner />
                      )}
                    </>
                  )}
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Box>
      </Box>
      {modalDeleteValues && (
        <ModalRemove
          setDeviceEdited={setDeviceEdited}
          id={modalDeleteValues.id}
          device={modalDeleteValues.device}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
      <ModalAdd
        setDeviceEdited={setDeviceEdited}
        handleOpen={openModalAdd}
        handleClose={handleCloseModalAdd}
      />
      <ModalWebFiConnect
        handleOpen={openModalWebFiConnect}
        handleClose={handleCloseModalWebFiConnect}
      />
      {modalEditValues && (
        <ModalEdit
          setDeviceEdited={setDeviceEdited}
          id={modalEditValues.id}
          allDevice={modalEditValues}
          handleOpen={openModalEdit}
          handleClose={handleCloseModalEdit}
        />
      )}
      {modalShareValues && (
        <ModalShare
          setDeviceEdited={setDeviceEdited}
          id={modalShareValues.id}
          handleOpen={openModalShare}
          handleClose={handleCloseModalShare}
        />
      )}
      {modalUnshareValues && (
        <ModalUnshare
          setDeviceEdited={setDeviceEdited}
          id={modalUnshareValues.id}
          userId={+userId}
          device={modalUnshareValues.device}
          handleOpen={openModalUnshare}
          handleClose={handleCloseModalUnshare}
        />
      )}
      {modalAcceptShareValues && (
        <ModalAcceptShared
          setDeviceEdited={setDeviceEdited}
          id={modalAcceptShareValues.id}
          handleOpen={openModalAcceptShare}
          handleClose={handleCloseModalAcceptShare}
        />
      )}
    </Box>
  );
};

export default Devices;
