import React from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";

import { green } from "@mui/material/colors";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import AvatarIcon from "../../components/AvatarIcon";
import Spinner from "../../components/Spinner";
import useIsMobile from "../../hooks/useIsMobile";
import Modal from "../Devices/Modal";
import { getDeviceAndCtrlData } from "../../services/data/scenarios";
import { uniqKeysById } from "../Sectors/parse";
import { Estado, RowData } from "../../components/Controller/parser/types";
import ModalEdit from "../../components/Scenarios/Modal/ModalEdit";

interface ModalEditScenarioProps {
  handleOpen: boolean;
  handleClose: () => void;
  setScenarioEdited: (e: boolean) => void;
  estadoOfEdit: Estado[];
  parametrosOfEdit: RowData[];
  sensoresOfEdit: any;
  modalEditValues: any;
}

const ModalEditScenario = ({
  handleOpen,
  handleClose,
  setScenarioEdited,
  estadoOfEdit,
  parametrosOfEdit,
  sensoresOfEdit,
  modalEditValues,
}: ModalEditScenarioProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  // Ações de abrir o modal de Editar Cenário
  const [openModalEditScenario, setOpenModalEditScenario] =
    React.useState<boolean>(false);
  const handleOpenModalEditScenario = () => setOpenModalEditScenario(true);
  const handleCloseModalEditScenario = () => {
    setOpenModalEditScenario(false);
  };
  const { token, authenticated } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const [conditionWork, setConditionWork] = React.useState<string>("AND");
  const [devices, setDevices] = React.useState<any>([]);
  const [pages, setPages] = React.useState<number>(1);
  const [mac, setMac] = React.useState<string>("");
  const [notShow, setNotShow] = React.useState(false);
  const [payloadDevices, setPayloadDevices] = React.useState<any>([]);
  const [macId, setMacId] = React.useState<string>("");
  const [controllers, setControllers] = React.useState<any[]>([]);
  const [model, setModel] = React.useState<string>("");
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [planPermissions, setPlanPermissions] = React.useState<any>();
  const [loadingDevice, setLoadingDevice] = React.useState(true);
  const [controller, setController] = React.useState<string>("");
  const [deviceName, setDeviceName] = React.useState<string>("");
  const initialValues = modalEditValues?.row;

  // Valores iniciais do formulário
  React.useEffect(() => {
    if (initialValues) {
      setMac(initialValues.ctrlId.split("/")[0]);
      setDeviceName(initialValues.device);
      setMacId(initialValues.controller.arcsysId);
      setController(initialValues.ctrlId);
      setModel(initialValues.model);
    }
  }, [initialValues]);

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        // Rota para resgatar a quantidade de dispositivos e controladores que irão ser renderizados
        getDeviceAndCtrlData(pages, 10, initialValues.ctrlId).then((res) => {
          if (res?.data) {
            const payloadData = res.data.items?.sort((s: any) =>
              s.online === true ? -1 : 1,
            );
            if (res.data.total_pages === res.data.total) {
              setNotShow(true);
            }
            setPayloadDevices(res.data.items);
            setDevices((prevOptions: any) => [...prevOptions, ...payloadData]);

            setLoadingDevice(false);
          }
        });
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, initialValues.ctrlId, pages, token]);

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        const filteredDevice =
          controller &&
          payloadDevices.filter(
            (f: any) => f.mac === controller.split("/")[0],
          )[0];

        if (filteredDevice) {
          setPlanPermissions(filteredDevice.plans?.permissions?.DEVICE);
        }

        setLoadingDevice(false);
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, controller, payloadDevices, token]);

  // Adicionar novos dados no select dos Dispositivos
  const handleLoadMore = () => {
    setPages((prevPage) => prevPage + 1);
  };

  // Resgatar dados iniciais dos controladores do Dispositivo
  React.useEffect(() => {
    const valueCtrl = uniqKeysById(devices).filter(
      (f: any) => f.name === deviceName,
    )[0]?.controllers;

    setControllers(valueCtrl);
  }, [controllers, deviceName, devices]);

  // Lista de Dispositivos
  const optionsMac = devices ? (
    uniqKeysById(devices)?.map(
      (value: any) =>
        value && (
          <MenuItem
            value={value._id}
            key={value._id}
            sx={{ pt: 0, pb: 0, borderBottom: "1px solid #F1F1F1" }}
            onClick={() => {
              setDeviceName(value.name);
              setControllers(value.controllers);
            }}
          >
            {!mobile ? (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor={"#C7252C"} />
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ p: 0 }}
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                          <Chip
                            label={value.online ? "Online" : "Offline"}
                            color={value.online ? "success" : "error"}
                            sx={{
                              mr: 1,
                              fontWeight: 700,
                              backgroundColor: value.online
                                ? green[100]
                                : "#FFD5D7",
                              color: value.online ? green[800] : "#C7252C",
                              textTransform: "uppercase",
                              fontSize: 12,
                            }}
                          />
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ) : (
              <List sx={{ pt: 0, pb: 0 }}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <AvatarIcon name={value.name} bgcolor={"#C7252C"} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Box mb={0.5}>
                          <Typography
                            component="span"
                            sx={{
                              display: "inline",
                              fontWeight: 450,
                              whiteSpace: "break-spaces",
                              mr: 1,
                            }}
                          >
                            {value.name}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.5} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography variant="body2" component="span">
                            {value.mac}
                          </Typography>
                        </Box>
                        <Chip
                          component="span"
                          label={value.online ? "Online" : "Offline"}
                          color={value.online ? "success" : "error"}
                          sx={{
                            mt: 0.5,
                            mr: 1,
                            fontWeight: 700,
                            backgroundColor: value.online
                              ? green[100]
                              : "#FFD5D7",
                            color: value.online ? green[800] : "#C7252C",
                            textTransform: "uppercase",
                            fontSize: 12,
                          }}
                        />
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            )}
          </MenuItem>
        ),
    )
  ) : (
    <Spinner />
  );

  // Adicionar novos dados no select dos Dispositivos
  const addMoreItens = (
    <MenuItem>
      <Button
        onClick={(e: any) => {
          e.stopPropagation();
          if (handleLoadMore) {
            handleLoadMore();
          }
        }}
        disabled={loadingDevice}
        fullWidth
        variant="outlined"
      >
        {loadingDevice ? <Spinner size={24} /> : t("TEXT.CHARGE_MORE_DEVICES")}
      </Button>
    </MenuItem>
  );

  // Adicionar o botão 'addMoreItems' na lista de dispositivos
  const allMenuItems =
    notShow === true || payloadDevices?.length === 0
      ? optionsMac
      : [...optionsMac, addMoreItens];

  // Lista de Controladores
  const optionsController = controllers?.map((p: any) => (
    <MenuItem
      value={p._id}
      key={p._id}
      sx={{ pt: 1, pb: 1, borderBottom: "1px solid #F1F1F1" }}
      onClick={() => {
        if (setModel) {
          setModel(p.model);
        }
      }}
    >
      <Box display="flex">
        <Chip
          label={p.status === "ACTIVE" ? "Online" : "Offline"}
          color={p.status === "ACTIVE" ? "success" : "error"}
          sx={{
            mr: 1,
            fontWeight: 700,
            backgroundColor: p.status === "ACTIVE" ? green[100] : "#FFD5D7",
            color: p.status === "ACTIVE" ? green[800] : "#C7252C",
            textTransform: "uppercase",
            fontSize: 12,
          }}
        />
        <Typography sx={{ ml: 0.5, mt: 0.5, whiteSpace: "break-spaces" }}>
          {`${p.name} - ${p._id.split("/")[1].split("L")[1]}`}
        </Typography>
      </Box>
    </MenuItem>
  ));

  const handleChangeMac = (event: SelectChangeEvent) => {
    setMacId(event.target.value as string);
    setController("");
  };

  const handleChangeController = (event: SelectChangeEvent) => {
    setController(event.target.value as string);
  };

  const ctrlId = controller
    ? `${controller?.split("/")[0]}/${controller?.split("/")[1]}`
    : undefined;

  // Validação do input do Controlador
  const checkCtrlInput = !optionsController || controllers?.length === 0;

  const isFormValid = controller !== "";

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 420}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <EditIcon sx={{ fontSize: 62 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={2}>
        {t("TEXT.EDIT_SCENARIO")}
      </Typography>
      <Box display="grid" height={openModalEditScenario ? 90 : "100%"}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {t("TEXT.SELECT_DEVICE")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={macId}
            label={t("TEXT.SELECT_DEVICE")}
            onChange={handleChangeMac}
            disabled={devices?.length === 0}
            sx={
              mac === undefined
                ? {
                    "& .css-1rioaud-MuiFormLabel-root-MuiInputLabel-root": {
                      padding: "6px",
                    },
                  }
                : mac !== ""
                  ? {
                      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          p: 0,
                        },
                      "& .css-qiwgdb": {
                        p: 0,
                      },
                    }
                  : {}
            }
          >
            {allMenuItems}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="demo-simple-select-label">
            {t("TEXT.SELECT_CONTROLLER")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={controller}
            label={t("TEXT.SELECT_CONTROLLER")}
            onChange={handleChangeController}
            disabled={checkCtrlInput}
          >
            {optionsController}
          </Select>
        </FormControl>
      </Box>
      <Box display="grid" mt={2}>
        <Button
          variant="contained"
          onClick={() => {
            handleOpenModalEditScenario();
          }}
          disabled={!isFormValid}
          sx={{
            mt: 2,
            borderRadius: 2,
            backgroundColor: "#C7252C",
            fontSize: 12,
            pt: 1,
            pb: 1,
          }}
          fullWidth
        >
          {t("TEXT.NEXT")}
        </Button>
        <Button
          onClick={handleClose}
          fullWidth
          sx={{
            backgroundColor: "#FFD5D7",
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            pt: 1,
            pb: 1,
            color: "black",
          }}
        >
          {t("BUTTON.CANCEL")}
        </Button>
      </Box>
      {openModalEditScenario && modalEditValues && (
        <ModalEdit
          setScenarioEdited={setScenarioEdited}
          row={modalEditValues.row}
          handleOpen={openModalEditScenario}
          handleClose={handleCloseModalEditScenario}
          model={model}
          sensores={sensoresOfEdit}
          parametros={parametrosOfEdit}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          conditionWork={conditionWork}
          setConditionWork={setConditionWork}
          estado={estadoOfEdit}
          planPermissions={planPermissions}
          deviceName={deviceName}
          handleCloseModalEdit={handleClose}
          ctrlIdDevice={ctrlId}
        />
      )}
    </Modal>
  );
};

export default ModalEditScenario;
