import React from "react";

import {
  Equalizer as EqualizerIcon,
  History as HistoryIcon,
  Replay as ReplayIcon,
} from "@mui/icons-material";

import {
  Box,
  CardContent,
  Grid,
  Typography,
  Card as MuiCard,
  Alert,
  AlertTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import Card from "../../components/Card";
import Info from "../../components/Controller/Info";
import useIsMobile, { useIsMediumPage } from "../../hooks/useIsMobile";
import { useNavigate, useParams } from "react-router-dom";
import { parseCardData } from "../../components/Card/parser";
import {
  COM_ERROR,
  OFFLINE_ERROR,
} from "../../components/Controller/constants";
import {
  getDashboardMacs,
  getDevice,
  getDeviceController,
} from "../../services/data/arcsys";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import Spinner from "../../components/Spinner";
import { ArcsysContext } from "../../contexts/ArcsysProviderContext";
import { AuthContext } from "../../contexts/AuthContext";
import { ControllerEditProvider } from "../../contexts/ControllerEditContext";
import ActionsTable from "../../components/Controller/SettingsTable/Action/index";
import { getCtrlConfig } from "../../components/Controller/parser";
import { Graph as GraphType, GraphicData } from "../../services/types/store";
import { getStore } from "../../services/data/store";
import { TOKEN_KEY } from "../../auth/constants";
import { api } from "../../services/api";
import {
  filterNotNullLabels,
  filterTimes,
  filterTimesFromBack,
  getFields,
  graphData,
  medidasLabels,
  resultMedidas,
  resultSetpoints,
  setpointLabels,
} from "../../components/Graph/parser";
import { useTranslation } from "react-i18next";
import Graph from "../../components/Graph";
import { getModelos } from "../../services/data/modelo";
import { getRawCtrlConfig, updateCtrl } from "../../config";
import Params from "./Params";
import { requestCtrlData } from "../../services/data/ctrls";
import { ParametrosContext } from "../../contexts/ParametrosContext";
import { CurrentMqttSubsContext } from "../../contexts/CurrentMqttSubsContext";
import { REPORTS } from "../../Routes/constants";
import { MqttContext } from "../../contexts/MqttContext";
import Scenarios from "../../components/Scenarios";
import { Ctrl, Setpoints, SetpointsKeys } from "../../services/types/ctrls";
import { getCtrlTagById } from "../../services/data/TAGs";
import { cardNull } from "./parse";

let myDevicesList: any[] = [];
let graphStructure: any[] = [];
let columns: any = [];

type IconsDescription = {
  [key: string]: number | null;
};

const Controller = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const mediumPage = useIsMediumPage();
  const navigate = useNavigate();
  const params = useParams();
  const ctrl = params.ctrl ?? "";
  const { mqtt } = React.useContext(MqttContext);
  const [dateNow, setDateNow] = React.useState<number>();
  const { theme } = React.useContext(ThemeModeContext);
  const { authenticated, token, client, userArcsys, userId } =
    React.useContext(AuthContext);
  const [devicePermission, setDevicePermission] = React.useState<string>();
  const { currentMqtt, setCurrentMqtt } = React.useContext(
    CurrentMqttSubsContext,
  );
  const {
    macsToSubscribe,
    setMacsToSubscribe,
    setIsMacsToSubscribeRequested,
    isMacsToSubscribeRequested,
  } = React.useContext(ArcsysContext);
  const { submitParametros, setSubmitParametros } =
    React.useContext(ParametrosContext);
  const [model, setModel] = React.useState<string>();
  const [backDataGraph, setBackDataGraph] = React.useState<any>([]);
  const [graphicVariables, setGraphicVariables] = React.useState<GraphicData>();
  const [graphLabels, setGraphLabels] = React.useState<string[]>([]);
  const [dataGraph, setDataGraph] = React.useState<any>([]);
  const [renderGraph, setRenderGraph] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState(true);
  const [loadingGraph, setLoadingGraph] = React.useState(true);
  const [comError, setComError] = React.useState(true);
  const [offlineError, setOfflineError] = React.useState(true);
  const [reportParams, setReportParams] = React.useState<string>();
  const [isUndefinedCtrl, setIsUndefinedCtrl] = React.useState(false);
  const [ctrls, setCtrls] = React.useState<Ctrl>();
  const [ctrlSetpoints, setCtrlSetpoints] = React.useState<Setpoints>();
  const [permissionToEdit, setPermissionToEdit] =
    React.useState<boolean>(false);
  const [permissionToEditTag, setPermissionToEditTag] =
    React.useState<boolean>(false);
  const { parametrosData, setParametrosData } =
    React.useContext(ParametrosContext);
  const [planPermissions, setPlanPermissions] = React.useState<any>();
  const [loadingTables, setLoadingTables] = React.useState(true);
  const [loadingCard, setLoadingCard] = React.useState(true);
  const [deviceInfo, setDeviceInfo] = React.useState<any>();
  const [deviceUpdateInterval, setDeviceUpdateInterval] =
    React.useState<number>();
  const [tagName, setTagName] = React.useState<string>("");
  const [tag_id, setTag_id] = React.useState<string>("");
  const [arcsysCtrl, setArcsysCtrl] = React.useState<any>();
  // Atualizar TAGs
  const [tagEdited, setTagEdited] = React.useState<boolean>(false);
  const [ctrlInitialNameScenario, setCtrlInitialNameScenario] =
    React.useState<string>("");

  // Parâmetros do Controlador
  const parametros: any = React.useMemo(() => parametrosData, [parametrosData]);

  // Checar se o usuário é admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  // Armazenar os parametros em um useState: ctrls -> resposta do mqtt
  React.useEffect(() => {
    if (parametrosData && ctrl) {
      setCtrls(parametros[ctrl]);
    }
    setParametrosData(undefined);
  }, [ctrl, parametrosData, parametros, setParametrosData]);

  // Regatar o nome da TAG atual do controlador
  const ctrlTag = params.mac ?? "";
  const idTag = params.ctrl ?? "";
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      getCtrlTagById(ctrlTag, idTag).then((res) => {
        const { data } = res;
        setTag_id(data.tag_id);
        setTagName(data.tagName);
      });
    }
  }, [authenticated, ctrlTag, idTag, token]);

  // Atualizar os dados da TAG após selecionar alguma TAG
  React.useEffect(() => {
    if (tagEdited) {
      // Resgatar os dados do usuário
      getCtrlTagById(ctrlTag, idTag).then((res) => {
        const { data } = res;
        setTag_id(data.tag_id);
        setTagName(data.tagName);
      });
      setTagEdited(false);
    }
  }, [ctrlTag, idTag, tagEdited]);

  React.useEffect(() => {
    const updateMacSubscription = async (mac: string, currentData: any[]) => {
      const currentMacFound = currentData.some(
        (obj: { mac: string }) => obj.mac === mac,
      );

      if (!currentMacFound && mac) {
        try {
          const res = await getDevice(mac);
          const newMacToSubscribe = {
            mac,
            name: res.data?.name,
            arcsysId: res.data?.id,
          };

          // Use functional update to avoid needing macsToSubscribe as a dependency
          setMacsToSubscribe((prevData) => {
            // Only update if this MAC is not already present
            if (!prevData.some((item) => item.mac === mac)) {
              return [...prevData, newMacToSubscribe];
            }
            return prevData;
          });
        } catch (error) {
          console.error("Failed to update subscription:", error);
        }
      }
    };

    const fetchStatus = () => {
      const mac = params.mac ?? "";

      if (!isMacsToSubscribeRequested) {
        getDashboardMacs().then((res) => {
          const { data } = res;
          if (userIsAdmin) {
            updateMacSubscription(mac, data);
          } else {
            setMacsToSubscribe(data);
          }
          setIsMacsToSubscribeRequested(true);
        });
      } else if (userIsAdmin) {
        // At this point, macsToSubscribe is already in state, no need to depend on it
        // Just use the latest value inside the callback
        updateMacSubscription(mac, macsToSubscribe);
      }
    };

    fetchStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMacsToSubscribeRequested, params.mac, userIsAdmin]);

  // Iniciar a página no topo
  React.useEffect(() => {
    const fetchScrollTop = () => {
      window.scrollTo({ top: 0, left: 0 });
    };
    fetchScrollTop();
    const scrollTopInterval = setInterval(fetchScrollTop, 100000000);
    return () => clearInterval(scrollTopInterval);
  }, []);

  React.useEffect(() => {
    const fetchStatus = () => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        // Se a key 'ageonRef' do localStorage for indefinida:
        // Resgata os modelos JSON conforme a linguagem selecionada
        if (localStorage.ageonRef === undefined) {
          getModelos().then((res) => {
            if (localStorage.lang === "pt") {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model["pt-br"]),
              );
            } else if (localStorage.lang === "en") {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model.en),
              );
            } else {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model.es),
              );
            }
            updateCtrl(localStorage.ageonRef);
          });
        } else {
          updateCtrl(localStorage.ageonRef);
        }
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  // Dar subscribe nos arcsys do usuário
  React.useEffect(() => {
    const fetchStatus = () => {
      if (client?.connected === true) {
        if (userIsAdmin) {
          const mac = params.mac;
          if (mac !== undefined) {
            if (currentMqtt) {
              // Caso exista o campo
              if (!currentMqtt.includes(mac + `/EVT/#`)) {
                // Caso não esteja na lista
                const subscription = client?.subscribe(mac + `/EVT/#`); // cria sub
                setCurrentMqtt(currentMqtt + "," + mac + `/EVT/#`); // armazena na lista
                return subscription;
              }
            } else {
              // Caso não tenha lista
              const subscription = client?.subscribe(mac + `/EVT/#`);
              setCurrentMqtt(mac + `/EVT/#`);
              return subscription;
            }
          }
        } else {
          macsToSubscribe?.map(async (v: any) => {
            await timeout(100);
            if (currentMqtt) {
              // Caso exista o campo
              if (!currentMqtt.includes(v.mac + `/EVT/#`)) {
                // Caso não esteja na lista
                const subscription = client?.subscribe(v.mac + `/EVT/#`); // cria sub
                setCurrentMqtt(currentMqtt + "," + v.mac + `/EVT/#`); // armazena na lista
                return subscription;
              }
            } else {
              // Caso não tenha lista
              const subscription = client?.subscribe(v.mac + `/EVT/#`);
              setCurrentMqtt(v.mac + `/EVT/#`);
              return subscription;
            }
          });
        }
      }
    };
    fetchStatus();
    const scrollTopInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(scrollTopInterval);
  }, [
    client,
    currentMqtt,
    params.mac,
    setCurrentMqtt,
    userIsAdmin,
    macsToSubscribe,
  ]);

  // Armazena a data atual no state 'setDateNow' a cada 3 segundos
  React.useEffect(() => {
    const fetchStatus = () => {
      setDateNow(Date.now());
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 3000);
    return () => clearInterval(statusInterval);
  }, []);

  // Checa cada arcsys do usuário logado
  React.useEffect(() => {
    const fetchStatus = () => {
      myDevicesList = [];
      const mac = params.mac ?? "";
      macsToSubscribe?.forEach((obj) => {
        // Checa se cada endereço MAC do arcsys é igual da rota URL acessada
        if (obj.mac === mac) {
          myDevicesList.push({
            mac: mac,
            name: obj.name,
            id: obj.arcsysId,
            ctrls:
              mqtt && mqtt[mac] !== undefined
                ? Object.entries(mqtt[mac]).map(([ctrl, payload]) => ({
                    ctrl,
                    payload,
                  }))
                : [],
          });
        }
      });

      myDevicesList?.forEach(
        (v) =>
          v.mac === mac &&
          v.ctrls &&
          v.ctrls?.forEach((device: any) => {
            if (device.ctrl === params.ctrl) {
              // Armazena no state se o controlador está 'Offline'
              setOfflineError(device?.payload?.alarme === OFFLINE_ERROR);
              // Armazena no state se o controlador está com erro de comunicação 'COM'
              setComError(device?.payload?.alarme === COM_ERROR);
            }
          }),
      );

      const mapSetpoints = (setpoints: any): Setpoints => {
        const mappedSetpoints: Setpoints = {
          s0: 0,
          s1: 0,
          s2: 0,
          s3: 0,
          s4: 0,
        };
        for (let i = 1; i <= 5; i++) {
          const key = `s${i}`;
          const newKey = `s${i - 1}`;
          if (setpoints && setpoints[key] !== undefined) {
            mappedSetpoints[newKey as SetpointsKeys] = setpoints[key];
          }
        }
        return mappedSetpoints;
      };

      const setpoints: Setpoints = mapSetpoints(
        myDevicesList?.map(
          (v) =>
            v.mac === params.mac &&
            v.ctrls &&
            v.ctrls.filter((device: any) => device.ctrl === params.ctrl)[0],
        )[0]?.payload?.setpoints,
      );

      setCtrlSetpoints(setpoints);
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [macsToSubscribe, mqtt, params]);

  React.useEffect(() => {
    if (token !== undefined) {
      localStorage.setItem(TOKEN_KEY, token);
    }
    api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

    const fetchStatus = () => {
      if (graphicVariables) {
        // Requisição get para montar o gráfico
        getStore(
          graphicVariables.mac,
          graphicVariables.ctrl,
          graphicVariables.id,
        ).then((res) => {
          const result = [];
          if (res?.data.items.length === 0) {
            setLoadingGraph(false);
          }
          if (res?.data) {
            for (let i = 0; i < res.data.items.length; i++) {
              // indez 0 é o menor timestamp
              if (i === 0 && res?.data.items.length > 0) {
                const currentTimestamp = Date.now() - 24 * 60 * 60 * 1000;
                const newItem = {
                  ...res.data.items[i],
                  timestamp: currentTimestamp - 1000, // 1 segundo após a data/hora atual
                  data: {
                    ...res.data.items[i].data,
                    estado: null,
                    alarme: 0,
                    modelo: graphicVariables.model,
                    medidas: {},
                    setpoints: {},
                  },
                };
                result.push(newItem);
              }
              // Verifica se o modelo do item[i] é igual ao modelo sendo apresentado na pagina.
              // Se nao foi igual, mas o item seguinte [i+1] for, adiciona um valor null antes para quebra do grafico - espaco vazio
              if (i < res.data.items.length - 1) {
                if (
                  graphicVariables.model !== res.data.items[i].data.modelo &&
                  graphicVariables.model === res.data.items[i + 1].data.modelo
                ) {
                  const currentTimestamp = res.data.items[i].timestamp;
                  const newItem = {
                    ...res.data.items[i + 1],
                    timestamp: currentTimestamp - 1000, // 1 segundo após a data/hora atual
                    data: {
                      ...res.data.items[i + 1].data,
                      estado: null,
                      alarme: 0,
                      modelo: graphicVariables.model,
                      medidas: {},
                      setpoints: {},
                    },
                  };
                  result.push(newItem);
                  continue;
                }
              }
              // Adiciona somente os dados do modelo atual - previne historico antigo de outros controladores
              if (graphicVariables.model === res.data.items[i].data.modelo) {
                result.push(res.data.items[i]);

                // Adiciona um valor null no grafico entre valores espacados por mais de 1hora - para gerar espaco vazio
                if (i < res.data.items.length - 1) {
                  const currentTimestamp = res.data.items[i].timestamp;
                  const nextTimestamp = res.data.items[i + 1].timestamp;
                  const differenceInMilliseconds = Math.abs(
                    nextTimestamp - currentTimestamp,
                  );

                  if (differenceInMilliseconds > 3600000) {
                    const newItem = {
                      ...res.data.items[i],
                      timestamp: currentTimestamp - 1000, // 1 segundo antes da data/hora atual
                      data: {
                        ...res.data.items[i].data,
                        estado: null,
                        alarme: 0,
                        medidas: {},
                        setpoints: {},
                      },
                    };
                    result.push(newItem);
                  }
                }
              }
              // maior timestamp
              if (
                i === res?.data.items.length - 1 &&
                res?.data.items.length > 0
              ) {
                const currentTimestamp = Date.now();
                const newItem = {
                  ...res.data.items[i],
                  timestamp: currentTimestamp + 1000, // 1 segundo antes
                  data: {
                    ...res.data.items[i].data,
                    estado: null,
                    alarme: 0,
                    modelo: graphicVariables.model,
                    medidas: {},
                    setpoints: {},
                  },
                };
                result.push(newItem);
              }
            }
            // Armazena os dados recuperados do gráfico da requisição GET no state
            setBackDataGraph(result);
          }
        });
      }
    };
    fetchStatus();
    const statusInterval = setInterval(
      fetchStatus,
      deviceUpdateInterval === undefined || deviceUpdateInterval === 0
        ? 300000
        : deviceUpdateInterval,
    );
    return () => clearInterval(statusInterval);
  }, [deviceUpdateInterval, graphicVariables, token]);

  // Ação de atualizar o gráfico
  const reloadGraph = () => {
    setLoadingGraph(true);
    if (graphicVariables) {
      // Requisição get para montar o gráfico
      getStore(
        graphicVariables.mac,
        graphicVariables.ctrl,
        graphicVariables.id,
      ).then((res) => {
        const result = [];
        if (res?.data) {
          if (res?.data.items.length === 0) {
            setLoadingGraph(false);
          }
          for (let i = 0; i < res.data.items.length; i++) {
            // Verifica se o modelo do item[i] é igual ao modelo sendo apresentado na pagina.
            // Se nao foi igual, mas o item seguinte [i+1] for, adiciona um valor null antes para quebra do grafico - espaco vazio
            if (i < res.data.items.length - 1) {
              if (
                graphicVariables.model !== res.data.items[i].data.modelo &&
                graphicVariables.model === res.data.items[i + 1].data.modelo
              ) {
                const currentTimestamp = res.data.items[i].timestamp;
                const newItem = {
                  ...res.data.items[i + 1],
                  timestamp: currentTimestamp - 1000, // 1 segundo após a data/hora atual
                  data: {
                    ...res.data.items[i + 1].data,
                    estado: null,
                    alarme: 0,
                    modelo: graphicVariables.model,
                    medidas: {},
                    setpoints: {},
                  },
                };
                result.push(newItem);
                continue;
              }
            }
            // Adiciona somente os dados do modelo atual - previne historico antigo de outros controladores
            if (graphicVariables.model === res.data.items[i].data.modelo) {
              result.push(res.data.items[i]);

              // Adiciona um valor null no grafico entre valores espacados por mais de 1hora - para gerar espaco vazio
              if (i < res.data.items.length - 1) {
                const currentTimestamp = res.data.items[i].timestamp;
                const nextTimestamp = res.data.items[i + 1].timestamp;
                const differenceInMilliseconds = Math.abs(
                  nextTimestamp - currentTimestamp,
                );

                if (differenceInMilliseconds > 3600000) {
                  const newItem = {
                    ...res.data.items[i],
                    timestamp: currentTimestamp - 1000, // 1 segundo antes da data/hora atual
                    data: {
                      ...res.data.items[i].data,
                      estado: null,
                      alarme: 0,
                      medidas: {},
                      setpoints: {},
                    },
                  };
                  result.push(newItem);
                }
              }
            }
            setLoadingGraph(false);
          }
          // Armazena os dados recuperados do gráfico da requisição GET no state
          setBackDataGraph(result);
        }
      });
    }
  };

  // Checar se o gráfico poderá ser renderizado via propriedade do modelo JSON
  React.useEffect(() => {
    const isRenderGraph = getRawCtrlConfig(
      graphicVariables ? graphicVariables.model : "",
    );
    if (isRenderGraph) {
      if (isRenderGraph?.showGraph === false) setRenderGraph(false);
    }
  }, [graphicVariables]);

  React.useEffect(() => {
    graphStructure = [];
    const ctrlConfig = getCtrlConfig(
      graphicVariables !== undefined ? graphicVariables.model : "",
    );
    const { estado } = ctrlConfig;
    const rawIcons = estado.filter(({ icon }) => !!icon);
    backDataGraph.map((v: any) => {
      const { data } = v;
      const icons_labels: string[] = [];
      const icons_description: IconsDescription = {};
      rawIcons.forEach((icon) => {
        icons_description[icon.description.active] =
          data.estado !== null ? (data.estado & (1 << icon.bit) ? 1 : 0) : null;
        icons_labels.push(icon.description.active);
      });
      // Armazena as medidas e setpoints do gráfico
      graphStructure.push({
        ...resultMedidas(data.medidas, data.modelo),
        ...resultSetpoints(data.setpoints, data.modelo),
        ...icons_description,
      });
      // Armazena os rótulos do gráfico
      if (setpointLabels(data.modelo).length === 0) {
        setGraphLabels([...medidasLabels(data.modelo), ...icons_labels]);
      } else {
        setGraphLabels([
          ...medidasLabels(data.modelo),
          ...setpointLabels(data.modelo),
          ...icons_labels,
        ]);
      }
      return v;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backDataGraph]);

  // Payload final do gráfico
  const graphDataValues: GraphType = React.useMemo(
    () => ({
      graphLabels: graphLabels,
      data: graphStructure,
      times: filterTimesFromBack(backDataGraph),
    }),
    [backDataGraph, graphLabels],
  );

  React.useEffect(() => {
    columns = [];
    // Filtrar dados e armazenar os valores das medidas e setpoints do gráfico
    filterNotNullLabels(graphDataValues)?.forEach((v) => {
      return columns.push([
        v,
        ...getFields(graphDataValues?.data, v).map((v: number) => {
          return v;
        }),
      ]);
    });
    setDataGraph(columns);
  }, [graphDataValues]);

  // Array de arrays contendo o rótulo e os valores numéricos do gráfico
  const dataOfGraph = React.useMemo(
    () => graphData(dataGraph, graphDataValues),
    [dataGraph, graphDataValues],
  );

  // Lista de timestamps do gráfico
  const graphTimes = React.useMemo(
    () => filterTimes(graphDataValues),
    [graphDataValues],
  );

  // Loading do Spinner do gráfico
  React.useEffect(() => {
    const fetchStatus = () => {
      if (dataGraph.length !== 0 && loadingGraph) {
        setLoadingGraph(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [dataGraph.length, loadingGraph]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoadingGraph(false);
    }, 5000);
  }, []);

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (dataGraph.length !== 0 && graphTimes.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [dataGraph.length, graphTimes.length, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  // Loading do Card do controlador
  React.useEffect(() => {
    const fetchStatus = () => {
      if (myDevicesList[0]?.ctrls.length > 0 && loadingCard) {
        setLoadingCard(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [loadingCard]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoadingCard(false);
    }, 5000);
  }, []);

  // Cria o card do Controlador no topo da página
  const card = loadingCard ? (
    <Box position="sticky" top="100px" pb={1}>
      <Box>
        <Card
          id={undefined}
          ctrl={`${params.mac}/${params.ctrl}`}
          data={undefined}
          comError={comError}
          theme={theme}
        />
        <Info
          device={
            cardNull(params.mac, params.ctrl, model, arcsysCtrl?.name).measures
          }
          deviceName={deviceInfo?.name}
          mac={deviceInfo?.mac}
          deviceInfo={deviceInfo}
          tagName={tagName}
        />
      </Box>
    </Box>
  ) : offlineError ? (
    <Box position="sticky" top="100px" pb={1}>
      <Box>
        <Card
          id={deviceInfo?._id}
          ctrl={`${params.mac}/${params.ctrl}`}
          data={cardNull(params.mac, params.ctrl, model, arcsysCtrl?.name)}
          comError={comError}
          theme={theme}
        />
        <Info
          device={
            cardNull(params.mac, params.ctrl, model, arcsysCtrl?.name).measures
          }
          deviceName={deviceInfo?.name}
          mac={deviceInfo?.mac}
          deviceInfo={deviceInfo}
          tagName={tagName}
        />
      </Box>
    </Box>
  ) : (
    myDevicesList[0]?.ctrls.length > 0 &&
    myDevicesList?.map(
      (v) =>
        v.mac === params.mac &&
        v.ctrls &&
        v.ctrls
          ?.sort((a: any, b: any) => a.payload.end - b.payload.end)
          .map((device: any, i: number) => {
            const card = parseCardData(theme, device?.payload);

            return (
              dateNow &&
              device.ctrl === params.ctrl && (
                <Box key={i} position="sticky" top="100px" pb={1}>
                  <Box>
                    <Card
                      id={v.id}
                      ctrl={`${v.mac}/${device.ctrl}`}
                      data={card}
                      comError={comError}
                      theme={theme}
                    />
                    <Info
                      device={card.measures}
                      deviceName={v.name}
                      mac={v.mac}
                      deviceInfo={deviceInfo}
                      tagName={tagName}
                    />
                  </Box>
                </Box>
              )
            );
          }),
    )
  );

  // Resgatar as Ações do Controlador
  const ctrlConfig = getCtrlConfig(model ?? "");
  const { acoes: acoesConfig } = ctrlConfig;

  // Resgatar os dados dos parametros após alterações
  React.useEffect(() => {
    const ctrl = params.ctrl;
    if (submitParametros) {
      requestCtrlData(`${params.mac}/${ctrl}`, userId, client);
      setSubmitParametros(false);
    }
  }, [client, params, setSubmitParametros, submitParametros, userId]);

  // Resgatar os dados dos parametros ao entrar na pagina
  React.useEffect(() => {
    const fetchStatus = () => {
      const ctrl = params.ctrl;
      requestCtrlData(`${params.mac}/${ctrl}`, userId, client);
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 100000000);
    return () => clearInterval(statusInterval);
  }, [client, params, userId]);

  // Verificar a permissão de visualizar e editar do Dispositivo
  React.useEffect(() => {
    const ctrl = params.ctrl ?? "";
    const mac = params.mac ?? "";
    api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
    // Resgatar dados gerais do Dispositivo
    if (devicePermission === undefined) {
      getDevice(mac).then((res) => {
        setDeviceInfo(res.data);
        // Chegar a permissão do usuário
        const filterPermissions = res.data.users.filter(
          (f: any) => f.id === +userId,
        )[0];
        setDevicePermission(filterPermissions?.role);

        // Checar se os parametros tem permissão para serem editados
        const permissionToEdit =
          res.data.subscriptions
            .filter(
              (f: any) =>
                f.status === "ACTIVE" && f.user_id === res.data.ownerId,
            )
            .reverse()[0]?.plan.permissions.DEVICE.CONTROL_PARAMETERS ?? false;
        setPermissionToEdit(permissionToEdit);

        // Checar se a TAG tem permissão para ser editada
        const permissionToEditTag =
          res.data.subscriptions
            .filter(
              (f: any) =>
                f.status === "ACTIVE" && f.user_id === res.data.ownerId,
            )
            .reverse()[0]?.plan.permissions.DEVICE.CONTROL_TAGS ?? false;
        setPermissionToEditTag(permissionToEditTag);

        // Permissões dos planos
        const allPlansPermissions = res.data.subscriptions
          .filter(
            (f: any) => f.status === "ACTIVE" && f.user_id === res.data.ownerId,
          )
          .reverse()[0]?.plan.permissions.DEVICE;
        setPlanPermissions(allPlansPermissions);

        // Dados do Controlador, lastMsg e timestamp
        getDeviceController(mac, ctrl)
          .then((res_ctrl) => {
            setDeviceUpdateInterval(
              res_ctrl.data.ctrl?.updateInterval ?? 300000,
            );
            setGraphicVariables({
              mac: mac, // 'XX-XX-XX-XX-XX-XX
              ctrl: ctrl, // CTRLXX
              id: res.data.id as string, // 'XX-XX-XX-XX-XX-XX/CTRLXX
              model: res_ctrl.data.ctrl.model,
            });
            // Armazena no state o nome do modelo da página
            setModel(res_ctrl.data.ctrl.model);
            setArcsysCtrl(res_ctrl.data.ctrl);
            setReportParams(
              `${REPORTS}/${res.data.id}/${params.mac}/${params.ctrl}/${res_ctrl.data.ctrl.model}`,
            );
            // Resgatar o nome o o ctrl do controlador inicial dos cenários
            setCtrlInitialNameScenario(
              `${res_ctrl.data.ctrl.name} (${
                res_ctrl.data.ctrl._id?.split("/")[1].split("L")[1]
              })`,
            );
          })
          .catch(() => {
            setIsUndefinedCtrl(true);
          });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, userId]);

  // Loading para verificar se existe erro do comunicação
  React.useEffect(() => {
    const fetchStatus = () => {
      if (
        ctrls &&
        model &&
        // myDevicesList[0]?.ctrls.length > 0 &&
        loadingTables
      ) {
        setLoadingTables(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [ctrls, loadingTables, model]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoadingTables(false);
    }, 15000);
  }, []);

  // Memorize useMemo params table
  const paramsMemo = React.useMemo(() => {
    return (
      deviceUpdateInterval !== undefined &&
      model &&
      ctrlSetpoints && (
        <Params
          model={model}
          permissionToEdit={permissionToEdit}
          permissionToEditTag={permissionToEditTag}
          devicePermission={devicePermission !== "VIEW"}
          ctrls={ctrls}
          deviceUpdateInterval={deviceUpdateInterval}
          setTagEdited={setTagEdited}
          tagName={tagName}
          tag_id={tag_id}
          setpoint={ctrlSetpoints}
        />
      )
    );
  }, [
    ctrlSetpoints,
    ctrls,
    devicePermission,
    deviceUpdateInterval,
    model,
    permissionToEdit,
    permissionToEditTag,
    tagName,
    tag_id,
  ]);

  return (
    <Grid container sx={{ placeContent: "center", mb: 2 }}>
      <Box pr={3.5} pl={mobile ? 3.5 : 0}>
        {card}
      </Box>
      <Box display="grid">
        <CardContent sx={{ p: mobile ? 0.5 : 1 }}>
          {renderGraph !== false && (
            <MuiCard
              sx={{
                width:
                  mobile || mediumPage || window.screen.width < 1550
                    ? "100%"
                    : 1100,
                mb: 3,
              }}
            >
              <CardContent>
                <Box display="flex" flexDirection="column" mb={3}>
                  <Box display="flex" justifyContent="space-between">
                    <Box />
                    <Box display="flex">
                      <HistoryIcon sx={{ mt: mobile ? 3 : 1.5, mr: 1 }} />
                      <Typography
                        variant={mobile ? "body1" : "h5"}
                        fontWeight={mobile ? 500 : 400}
                        gutterBottom
                        textAlign={mobile ? "left" : "center"}
                        sx={{ mb: 0, placeSelf: "center", mr: mobile ? 0 : 1 }}
                      >
                        {t("TEXT.DATA_GRAPH_24H")}
                      </Typography>
                      <Tooltip title={t("TEXT.REPORTS") as string}>
                        <IconButton
                          color="primary"
                          onClick={() => reportParams && navigate(reportParams)}
                        >
                          <EqualizerIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Tooltip title={t("TEXT.UPDATE") as string}>
                      <IconButton color="primary" onClick={reloadGraph}>
                        <ReplayIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {loadingGraph === true ? (
                    <Box m="186px 0px">
                      <Spinner />
                    </Box>
                  ) : dataGraph.length === 0 || graphTimes.length === 0 ? (
                    <Grid container justifyContent="center">
                      <Grid item mt={2}>
                        <Alert
                          severity="info"
                          sx={{
                            alignItems: "center",
                            placeContent: "center",
                            mb: 1,
                            mt: 1,
                          }}
                        >
                          <AlertTitle sx={{ mt: 0.8 }}>
                            {t("TOAST.INFO.NO_DATA_RECORD")}
                          </AlertTitle>
                        </Alert>
                      </Grid>
                    </Grid>
                  ) : (
                    <Box height={500}>
                      <Graph
                        graphData={dataOfGraph}
                        graphTimes={graphTimes}
                        mobile={mobile}
                      />
                    </Box>
                  )}
                </Box>
              </CardContent>
            </MuiCard>
          )}
          {acoesConfig && (
            <Box>
              {model && isUndefinedCtrl === false ? (
                <MuiCard
                  sx={{
                    width:
                      mobile || mediumPage || window.screen.width < 1550
                        ? "100%"
                        : 1100,
                    mb: 3,
                  }}
                >
                  <CardContent>
                    <ControllerEditProvider>
                      <ActionsTable
                        model={model}
                        permissionToEdit={permissionToEdit}
                        devicePermission={devicePermission !== "VIEW"}
                        offlineError={offlineError}
                      />
                    </ControllerEditProvider>
                  </CardContent>
                </MuiCard>
              ) : (
                <MuiCard sx={{ mb: 3 }}>
                  <CardContent>
                    <Alert
                      severity="warning"
                      sx={{ mt: 1, textAlign: "center" }}
                    >
                      {t("TOAST.WARNING.ERROR_COM")}
                    </Alert>
                  </CardContent>
                </MuiCard>
              )}
            </Box>
          )}
          {model ? (
            <MuiCard
              sx={{
                width:
                  mobile || mediumPage || window.screen.width < 1550
                    ? "100%"
                    : 1100,
                mb: 3,
              }}
            >
              <CardContent>
                <Scenarios
                  model={model}
                  ctrls={ctrls}
                  planPermissions={planPermissions}
                  devicePermission={devicePermission}
                  deviceName={deviceInfo?.name}
                  ctrlInitialNameScenario={ctrlInitialNameScenario}
                />
              </CardContent>
            </MuiCard>
          ) : (
            <MuiCard sx={{ mb: 3 }}>
              <CardContent>
                <Alert severity="warning" sx={{ mt: 1, textAlign: "center" }}>
                  {t("TOAST.WARNING.ERROR_COM")}
                </Alert>
              </CardContent>
            </MuiCard>
          )}
          {loadingTables ? (
            <MuiCard sx={{ mb: 3 }}>
              <CardContent>
                <Alert severity="warning" sx={{ mt: 1, textAlign: "center" }}>
                  {t("TOAST.WARNING.LOADING_ERROR_COM")}
                </Alert>
              </CardContent>
            </MuiCard>
          ) : model &&
            !comError &&
            !offlineError &&
            isUndefinedCtrl === false ? (
            <MuiCard
              sx={{
                width:
                  mobile || mediumPage || window.screen.width < 1550
                    ? "100%"
                    : 1100,
                mb: 3,
              }}
            >
              <CardContent>{paramsMemo}</CardContent>
            </MuiCard>
          ) : (
            <MuiCard sx={{ mb: 3 }}>
              <CardContent>
                <Alert severity="warning" sx={{ mt: 1, textAlign: "center" }}>
                  {t("TOAST.WARNING.ERROR_COM")}
                </Alert>
              </CardContent>
            </MuiCard>
          )}
        </CardContent>
      </Box>
    </Grid>
  );
};

export default Controller;

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}
