// Formatar o payload recebido do MQTT
export const formatMessage = (rawMessage: any) => {
  try {
    const sanitizedMessage = rawMessage
      .toString()
      .replace(/[^\w\s:,_\-{}"']/gi, ""); // Simplified regex

    const message = JSON.parse(sanitizedMessage);
    const firstKey = Object.keys(message)[0];

    return message[firstKey];
  } catch (error) {
    console.error("Failed to format message:", error);
    return undefined; // Explicitly return undefined
  }
};
