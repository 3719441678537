import { useMediaQuery } from "@mui/material";

import theme from "../theme";

// Checar se está no modo mobile
const useIsMobile = () => useMediaQuery(theme("light").breakpoints.down("sm"));
// useMediaQuery(theme("light" || "dark").breakpoints.down("sm"));

// Checar se está no modo tablet
export const useIsMediumPage = () =>
  useMediaQuery(theme("light").breakpoints.down("md"));
// useMediaQuery(theme("light" || "dark").breakpoints.down("md"));

export default useIsMobile;
