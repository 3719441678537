import React from "react";
import { useTranslation } from "react-i18next";

import { Delete as DeleteIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { TagsContext } from "../../../../../contexts/TagsContext";
import { ToastContext } from "../../../../../contexts/ToastContext";
import { deleteTagToCtrl } from "../../../../../services/data/TAGs";
import Modal from "../Modal";

interface ModalDeleteTagProps {
  setTagEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
  tagName: string;
  tag_id: string;
  ctrlId?: string;
}

const ModalDeleteTag = ({
  setTagEdited,
  handleOpen,
  handleClose,
  tagName,
  tag_id,
  ctrlId,
}: ModalDeleteTagProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { setGlobalTagEdited } = React.useContext(TagsContext);
  const params = useParams();

  // Excluir TAG
  const handleDelete = () => {
    const data = {
      tag_id,
      ctrlId: ctrlId ?? `${params.mac}/${params.ctrl}`,
    };

    // Remover TAG no controlador
    deleteTagToCtrl(data)
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.REMOVE_TAG"));
        setTagEdited(true);
        setGlobalTagEdited(true);
        handleClose();
      })
      .catch((e) => {
        if (e?.response?.data?.message) {
          toastError(t(e?.response?.data?.message));
        } else {
          toastError(t("TOAST.ERROR.REMOVE_TAG"));
        }
      });
  };

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={325}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <DeleteIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.REMOVE")} TAG?
      </Typography>
      <Typography variant="body2">
        {t("TEXT.REMOVE_TAG")}
        <strong>{` ${tagName}`}</strong>?
      </Typography>
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={handleDelete}
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: "#C7252C",
          fontSize: 12,
          pt: 1,
          pb: 1,
        }}
      >
        {t("TEXT.REMOVE")}
      </Button>
      <Button
        onClick={handleClose}
        fullWidth
        sx={{
          backgroundColor: "#FFD5D7",
          mt: 1,
          borderRadius: 2,
          fontSize: 12,
          pt: 1,
          pb: 1,
          color: "black",
        }}
      >
        {t("BUTTON.CANCEL")}
      </Button>
    </Modal>
  );
};

export default ModalDeleteTag;
