import { Alert, Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Modal from "../../../Devices/Modal";
import useIsMobile from "../../../../hooks/useIsMobile";

interface ModalStandardCtrlProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalStandardCtrl = ({
  handleOpen,
  handleClose,
}: ModalStandardCtrlProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 350 : 400}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <HelpOutlineIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.STANDARD_CTRL")}
      </Typography>
      <Alert severity="info" sx={{ mt: 2, mb: 2, alignItems: "center" }}>
        <Typography>{t("TEXT.STANDARD_CTRL_SUBTITLE")}</Typography>
      </Alert>
      <Box mt={2} textAlign="center">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#FFD5D7",
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalStandardCtrl;
