import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  HelpOutline as HelpOutlineIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Filter1 as Filter1Icon,
  Filter2 as Filter2Icon,
  Filter3 as Filter3Icon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../hooks/useIsMobile";
import Modal from "../../../Devices/Modal";

interface ModalHelpMainSensorProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalHelpMainSensor = ({
  handleOpen,
  handleClose,
}: ModalHelpMainSensorProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 350 : window.screen.width < 1550 ? 600 : 900}
    >
      <Box
        sx={{
          maxHeight: window.screen.width < 1550 ? 500 : 700,
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 3.5,
            pb: 3.5,
            borderRadius: 4,
            mb: 2,
            backgroundColor: "#FFD5D7",
          }}
          elevation={0}
        >
          <Avatar
            sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
          >
            <HelpOutlineIcon sx={{ fontSize: 65 }} />
          </Avatar>
        </Paper>
        <Typography fontSize={21} fontWeight={700} mb={1}>
          {t("TEXT.MAIN_SENSOR")}
        </Typography>
        <Paper
          sx={{
            backgroundColor: "#E5F6FD",
            p: 4,
            mt: 1,
            borderRadius: 3,
          }}
          elevation={0}
        >
          <Stepper orientation="vertical">
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={600}>
                  {t("TEXT.MAIN_SENSOR_WHAT")}?
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.MAIN_SENSOR_WHAT_SUBTITLE")}
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={600}>
                  {t("TEXT.MAIN_SENSOR_WHY")}?
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces", p: 0, pl: 2.5 }}>
                <Stepper orientation="vertical">
                  <Step active>
                    <StepLabel
                      StepIconComponent={Filter1Icon as any}
                      sx={{ color: "#c62828" }}
                    >
                      <Typography fontSize={16} fontWeight={600}>
                        {t("TEXT.UPDATED_DATA")}:
                      </Typography>
                    </StepLabel>
                    <StepContent sx={{ whiteSpace: "break-spaces" }}>
                      {t("TEXT.UPDATED_DATA_MESSAGE")}
                    </StepContent>
                  </Step>
                  <Step active>
                    <StepLabel
                      StepIconComponent={Filter2Icon as any}
                      sx={{ color: "#c62828", pt: 0 }}
                    >
                      <Typography fontSize={16} fontWeight={600}>
                        {t("TEXT.TRUSTWORTHY_REPRESENTATION")}:
                      </Typography>
                    </StepLabel>
                    <StepContent sx={{ whiteSpace: "break-spaces" }}>
                      {t("TEXT.TRUSTWORTHY_REPRESENTATION_MESSAGE")}
                    </StepContent>
                  </Step>
                  <Step active>
                    <StepLabel
                      StepIconComponent={Filter3Icon as any}
                      sx={{ color: "#c62828", pt: 0 }}
                    >
                      <Typography fontSize={16} fontWeight={600}>
                        {t("TEXT.SELECT_MAIN_SENSOR_HOW")}?
                      </Typography>
                    </StepLabel>
                    <StepContent sx={{ whiteSpace: "break-spaces" }}>
                      <List sx={{ pb: 0, pt: 0 }}>
                        <ListItem sx={{ pl: 0, pt: 0 }}>
                          <ListItemIcon sx={{ mr: -3 }}>
                            <KeyboardArrowRightIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={t("TEXT.SELECT_MAIN_SENSOR_HOW_MESSAGE_1")}
                          />
                        </ListItem>
                        <ListItem sx={{ pl: 0, pt: 0 }}>
                          <ListItemIcon sx={{ mr: -3 }}>
                            <KeyboardArrowRightIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={t("TEXT.SELECT_MAIN_SENSOR_HOW_MESSAGE_2")}
                          />
                        </ListItem>
                        <ListItem sx={{ pl: 0, pt: 0 }}>
                          <ListItemIcon sx={{ mr: -3 }}>
                            <KeyboardArrowRightIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={t("TEXT.SELECT_MAIN_SENSOR_HOW_MESSAGE_3")}
                          />
                        </ListItem>
                        <ListItem sx={{ pl: 0, pt: 0 }}>
                          <ListItemIcon sx={{ mr: -3 }}>
                            <KeyboardArrowRightIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={t("TEXT.SELECT_MAIN_SENSOR_HOW_MESSAGE_4")}
                          />
                        </ListItem>
                      </List>
                    </StepContent>
                  </Step>
                </Stepper>
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={600}>
                  {t("TEXT.IMPORTANT")}:
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.IMPORTANT_MESSAGE")}
              </StepContent>
            </Step>
          </Stepper>
        </Paper>
      </Box>
      <Box mt={2} textAlign="center">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#FFD5D7",
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalHelpMainSensor;
