import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  LocalizationProvider,
  MobileDatePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR, esES, enUS } from "@mui/x-date-pickers/locales";
import enLocale from "date-fns/locale/en-GB";
import deLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt-BR";
import {
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../contexts/ToastContext";
import {
  PasswordVisibility,
  TextField,
  yupRequiredString,
  yupString,
} from "../../components/Form";
import ErrorMessage from "../../components/ErrorMessage";
import {
  loginBackground,
  loginComponentBackground,
} from "../../components/Login/BackgroundImages";
import { LOGIN } from "../../Routes/constants";
import useIsMobile, { useIsMediumPage } from "../../hooks/useIsMobile";
import {
  completeRegistration,
  getConfirmRegistrationEmail,
} from "../../services/data/auth";
import { getLanguage } from "../../translations/languages";
import { countryListEn, countryListEs, countryListBr } from "./parse";
import PhoneMask from "../../components/Masks/PhoneMask";
import { getCep } from "../../services/data/searchCep";
import CepMask from "../../components/Masks/CepMask";
import Terms from "../ActivateAccount/Terms";
import { AutoComplete } from "../../components/Form/AutoComplete";
import { AuthContext } from "../../contexts/AuthContext";
import {
  yupDate,
  yupOneOf,
  yupRequiredStringMin,
} from "../../components/Form/yup";

const CompleteRegister = () => {
  const navigate = useNavigate();
  const mobile = useIsMobile();
  const mediumPage = useIsMediumPage();
  const { t } = useTranslation();
  const language = getLanguage().code;
  const checkLanguage =
    language === "en" ? "en" : language === "pt" ? "pt-br" : "es";
  const { toastSuccess, toastError } = React.useContext(ToastContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const { signOut } = React.useContext(AuthContext);
  const token = queryParameters.get("token");
  const [activeStep, setActiveStep] = React.useState(0);
  const [city, setCity] = React.useState<string>("");
  const [street, setStreet] = React.useState<string>("");
  const [neighborhood, setNeighborhood] = React.useState<string>("");
  const [state, setState] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [checked, setChecked] = React.useState(false);
  const [valueDate, setValueDate] = React.useState<Date | null>(null);
  const [openCalendar, setOpenCalendar] = React.useState(false);

  React.useEffect(() => {
    const fetchStatus = () => {
      signOut();
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Checkbox dos termos de uso
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  React.useEffect(() => {
    const fetchStatus = () => {
      if (token) {
        // Resgatar o email do usuario via token
        getConfirmRegistrationEmail(token).then((res) => {
          setEmail(res.data.email);
        });
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
  }, [token]);

  // Resgatar o endereço via CEP do formulário
  const getAdress = () => {
    getCep(formikCreateAccount.values.cep)
      .then((res) => {
        const { data } = res;
        if (data.erro === "true") {
          toastError(t("TOAST.ERROR.ZIP_CODE"));
        } else {
          toastSuccess(t("TOAST.SUCCESS.ZIP_CODE"));
          setCity(data.localidade);
          setStreet(data.logradouro);
          setNeighborhood(data.bairro);
          setState(data.uf);
        }
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.ZIP_CODE"));
      });
  };

  // Armazenar a cidade digitada no formulário
  const handleChangeCity = (e: string) => {
    setCity(e);
  };

  // Armazenar a rua digitada no formulário
  const handleChangeStreet = (e: string) => {
    setStreet(e);
  };

  // Armazenar o bairro digitado no formulário
  const handleChangeNeighborhood = (e: string) => {
    setNeighborhood(e);
  };

  // Armazenar o estado digitado no formulário
  const handleChangeState = (e: string) => {
    setState(e);
  };

  // Validação das senhas
  const minLength = 6;
  const passwordSchema = yupRequiredStringMin(minLength);

  const formikCreateAccount = useFormik({
    initialValues: {
      name: "",
      surname: "",
      company: "",
      username: "",
      createPassword: "",
      confirmPassword: "",
      dateOfBirth: "",
      phone: "",
      local: "",
      cep: "",
      adressNumber: "",
      complement: "",
    },
    validationSchema: Yup.object({
      name: yupRequiredString,
      surname: yupRequiredString,
      company: yupString,
      username: yupRequiredString,
      createPassword: passwordSchema,
      confirmPassword: yupOneOf(
        [Yup.ref("createPassword")],
        "ERROR.REQUIRED_CONFIRM_NEW_PASSWORD",
      ).min(minLength, t("ERROR.PASSWORD")),
      dateOfBirth: yupDate(),
      phone: yupString,
      local: yupString,
      cep: yupString,
      adressNumber: yupString,
      complement: yupString,
    }),
    onSubmit: (values) => {
      // Finalizar o registro da conta
      completeRegistration({
        arcsys: { mac: "" },
        token: token,
        user:
          values.local === "Brazil" || values.local === "Brasil"
            ? {
                name: values.name,
                surname: values.surname,
                username: values.username,
                email: email,
                password: values.createPassword,
                confirmPassword: values.confirmPassword,
                company: values.company,
                dateOfBirth: values.dateOfBirth,
                phone: values.phone,
                country: values.local,
                location: values.local,
                language: checkLanguage,
                info: {
                  personType: "PERSON",
                  address: {
                    city: city,
                    complement: values.complement,
                    neighborhood: neighborhood,
                    number: values.adressNumber,
                    cep: values.cep,
                    state: state,
                    street: street,
                    fetching: true,
                  },
                  cpf: "",
                  cnpj: "",
                  legalName: "",
                  stateInsc: "",
                  phone: values.phone,
                },
              }
            : {
                name: values.name,
                surname: values.surname,
                username: values.username,
                email: email,
                password: values.createPassword,
                confirmPassword: values.confirmPassword,
                company: values.company,
                dateOfBirth: values.dateOfBirth,
                phone: values.phone,
                country: values.local,
                location: values.local,
                language: checkLanguage,
              },
      })
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.ACCOUNT_CREATED"));
          navigate(LOGIN);
        })
        .catch(() => {
          toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
        });
    },
  });

  // Alterar a data de nascimento no Input
  const handleDateChange = (d: Date | null) => {
    setValueDate(d);
    formikCreateAccount.setFieldValue("dateOfBirth", d);
  };

  // Linguagem do componente Input das datas
  const checkLanguageInput =
    language === "pt" ? ptLocale : language === "en" ? enLocale : deLocale;

  // Linguagem do componente Input das datas
  const checkLanguageText =
    language === "pt"
      ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
      : language === "en"
        ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
        : esES.components.MuiLocalizationProvider.defaultProps.localeText;

  const steps = [
    {
      label: t("TEXT.CREATE_ARCSYS_ACCOUNT"),
    },
    {
      label: t("TEXT.ADDRESS_INFO"),
    },
    {
      label: t("TEXT.LICENSE_TERMS"),
    },
  ];

  // Alterar para o Step anterior
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Alterar para o próximo Step
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Lista de países de acordo com a linguagem selecionada
  const checkOptionsLanguage =
    language === "en"
      ? countryListEn
      : language === "pt"
        ? countryListBr
        : countryListEs;

  // SelectBox dos países
  const options = checkOptionsLanguage.map((value: string) => value);

  // Validação do formulário
  const isFormCreateAccountValid =
    formikCreateAccount.dirty &&
    formikCreateAccount.isValid &&
    !formikCreateAccount.values.phone.includes("_");

  // Checar se o local selecionado no formulário é o Brasil para renderizar novas informações
  const checkIfLocalIsBrazil =
    formikCreateAccount.values.local === "Brazil" ||
    formikCreateAccount.values.local === "Brasil";

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        height:
          window.screen.width < 1550 && openCalendar === true
            ? "145vh"
            : "100vh",
        backgroundImage: loginBackground,
        backgroundSize: "cover",
      }}
    >
      <Grid item>
        <Card
          sx={{
            minWidth: 350,
            p: 2,
            backgroundImage: loginComponentBackground,
          }}
          elevation={5}
        >
          <CardContent sx={{ position: "relative" }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step
                  key={step.label}
                  sx={{
                    overflow: "inherit",
                    overflowX:
                      mobile || window.screen.width < 1550 || mediumPage
                        ? "hidden"
                        : "inherit",
                  }}
                >
                  <StepLabel>
                    <Typography
                      variant={mobile ? "subtitle2" : "h6"}
                      fontSize={mobile ? 15 : 18}
                    >
                      {step.label}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    {index === 0 ? (
                      <Box
                        sx={{
                          height: mobile
                            ? 400
                            : mediumPage
                              ? 540
                              : window.screen.width < 1550
                                ? 300
                                : 540,
                        }}
                      >
                        <Grid container direction="column" spacing={3}>
                          {mobile ? (
                            <>
                              <Grid item>
                                <TextField
                                  formik={formikCreateAccount}
                                  disabled={false}
                                  label={t("TEXT.NAME")}
                                  name="name"
                                  fullWidth
                                />
                                {formikCreateAccount.errors.name &&
                                formikCreateAccount.touched.name ? (
                                  <ErrorMessage>
                                    {formikCreateAccount.errors.name}
                                  </ErrorMessage>
                                ) : null}
                              </Grid>
                              <Grid item>
                                <TextField
                                  formik={formikCreateAccount}
                                  disabled={false}
                                  label={t("TEXT.SURNAME")}
                                  name="surname"
                                  fullWidth
                                />
                                {formikCreateAccount.errors.surname &&
                                formikCreateAccount.touched.surname ? (
                                  <ErrorMessage>
                                    {formikCreateAccount.errors.surname}
                                  </ErrorMessage>
                                ) : null}
                              </Grid>
                            </>
                          ) : (
                            <Grid item sx={{ display: "flex" }}>
                              <Box mr={2} width="100%">
                                <TextField
                                  formik={formikCreateAccount}
                                  disabled={false}
                                  label={t("TEXT.NAME")}
                                  name="name"
                                  fullWidth
                                />
                                {formikCreateAccount.errors.name &&
                                formikCreateAccount.touched.name ? (
                                  <ErrorMessage>
                                    {formikCreateAccount.errors.name}
                                  </ErrorMessage>
                                ) : null}
                              </Box>
                              <Box width="100%">
                                <TextField
                                  formik={formikCreateAccount}
                                  disabled={false}
                                  label={t("TEXT.SURNAME")}
                                  name="surname"
                                  fullWidth
                                />
                                {formikCreateAccount.errors.surname &&
                                formikCreateAccount.touched.surname ? (
                                  <ErrorMessage>
                                    {formikCreateAccount.errors.surname}
                                  </ErrorMessage>
                                ) : null}
                              </Box>
                            </Grid>
                          )}
                          <Grid item>
                            <TextField
                              formik={formikCreateAccount}
                              disabled={false}
                              label={t("TEXT.COMPANY")}
                              name="company"
                              fullWidth
                            />
                            {formikCreateAccount.errors.company &&
                            formikCreateAccount.touched.company ? (
                              <ErrorMessage>
                                {formikCreateAccount.errors.company}
                              </ErrorMessage>
                            ) : null}
                          </Grid>
                          <Grid item>
                            <TextField
                              formik={formikCreateAccount}
                              disabled={false}
                              label={t("TEXT.USERNAME")}
                              name="username"
                              fullWidth
                            />
                            {formikCreateAccount.errors.username &&
                            formikCreateAccount.touched.username ? (
                              <ErrorMessage>
                                {formikCreateAccount.errors.username}
                              </ErrorMessage>
                            ) : null}
                          </Grid>
                          <Grid item>
                            <Box display="flex">
                              <Typography fontWeight={600} variant="subtitle1">
                                Email:
                              </Typography>
                              <Typography
                                ml={1}
                                variant="subtitle1"
                                fontSize={mobile ? 14 : 15}
                              >
                                {email}
                              </Typography>
                            </Box>
                          </Grid>
                          {mobile ? (
                            <>
                              <Grid item>
                                <PasswordVisibility
                                  name="createPassword"
                                  formik={formikCreateAccount}
                                  label={t("TEXT.CREATE_PASSWORD")}
                                  fullWidth
                                />
                                {formikCreateAccount.errors.createPassword &&
                                formikCreateAccount.touched.createPassword ? (
                                  <ErrorMessage>
                                    {formikCreateAccount.errors.createPassword}
                                  </ErrorMessage>
                                ) : null}
                              </Grid>
                              <Grid item>
                                <PasswordVisibility
                                  name="confirmPassword"
                                  formik={formikCreateAccount}
                                  label={t("TEXT.CONFIRM_PASSWORD")}
                                  fullWidth
                                />
                                {formikCreateAccount.errors.confirmPassword &&
                                formikCreateAccount.touched.confirmPassword ? (
                                  <ErrorMessage>
                                    {formikCreateAccount.errors.confirmPassword}
                                  </ErrorMessage>
                                ) : null}
                              </Grid>
                            </>
                          ) : (
                            <Grid item sx={{ display: "flex" }}>
                              <Box mr={2} width="100%">
                                <PasswordVisibility
                                  name="createPassword"
                                  formik={formikCreateAccount}
                                  label={t("TEXT.CREATE_PASSWORD")}
                                  fullWidth
                                  sx={{ mr: 2 }}
                                />
                                {formikCreateAccount.errors.createPassword &&
                                formikCreateAccount.touched.createPassword ? (
                                  <ErrorMessage>
                                    {formikCreateAccount.errors.createPassword}
                                  </ErrorMessage>
                                ) : null}
                              </Box>
                              <Box width="100%">
                                <PasswordVisibility
                                  name="confirmPassword"
                                  formik={formikCreateAccount}
                                  label={t("TEXT.CONFIRM_PASSWORD")}
                                  fullWidth
                                />
                                {formikCreateAccount.errors.confirmPassword &&
                                formikCreateAccount.touched.confirmPassword ? (
                                  <ErrorMessage>
                                    {formikCreateAccount.errors.confirmPassword}
                                  </ErrorMessage>
                                ) : null}
                              </Box>
                            </Grid>
                          )}
                          {mobile ? (
                            <>
                              <Grid item>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  adapterLocale={checkLanguageInput}
                                  localeText={checkLanguageText}
                                >
                                  <MobileDatePicker
                                    disableFuture
                                    label={t("TEXT.DATE_OF_BIRTH")}
                                    onChange={handleDateChange}
                                    value={valueDate}
                                    minDate={new Date(-2208988800000)} // Valor mínimo: data atual/mes atual/1900
                                    slotProps={{
                                      textField: {
                                        error: false,
                                      },
                                    }}
                                    format={
                                      language === "en"
                                        ? "MM/dd/yyyy"
                                        : "dd/MM/yyyy"
                                    }
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item>
                                <TextField
                                  formik={formikCreateAccount}
                                  disabled={false}
                                  label={t("TEXT.PHONE")}
                                  name="phone"
                                  fullWidth
                                  InputProps={{
                                    inputComponent: PhoneMask,
                                  }}
                                />
                                {formikCreateAccount.errors.phone &&
                                formikCreateAccount.touched.phone ? (
                                  <ErrorMessage>
                                    {formikCreateAccount.errors.phone}
                                  </ErrorMessage>
                                ) : null}
                              </Grid>
                            </>
                          ) : (
                            <Grid item sx={{ display: "flex" }}>
                              <Box mr={2} width="100%">
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  adapterLocale={checkLanguageInput}
                                  localeText={checkLanguageText}
                                >
                                  <DatePicker
                                    disableFuture
                                    label={t("TEXT.DATE_OF_BIRTH")}
                                    onOpen={() => setOpenCalendar(true)}
                                    onClose={() => setOpenCalendar(false)}
                                    onChange={handleDateChange}
                                    value={valueDate}
                                    minDate={new Date(-2208988800000)} // Valor mínimo: data atual/mes atual/1900
                                    slotProps={{
                                      textField: {
                                        error: false,
                                      },
                                    }}
                                    format={
                                      language === "en"
                                        ? "MM/dd/yyyy"
                                        : "dd/MM/yyyy"
                                    }
                                    sx={{
                                      width: "100%",
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                              <Box width="100%">
                                <TextField
                                  formik={formikCreateAccount}
                                  disabled={false}
                                  label={t("TEXT.PHONE")}
                                  name="phone"
                                  fullWidth
                                  InputProps={{
                                    inputComponent: PhoneMask,
                                  }}
                                />
                                {formikCreateAccount.errors.phone &&
                                formikCreateAccount.touched.phone ? (
                                  <ErrorMessage>
                                    {formikCreateAccount.errors.phone}
                                  </ErrorMessage>
                                ) : null}
                              </Box>
                            </Grid>
                          )}
                          <Box mt={3} display="flex" justifyContent="center">
                            <Button
                              variant="outlined"
                              startIcon={<ArrowLeftIcon />}
                              onClick={() => navigate(LOGIN)}
                              sx={{ mr: 1, ml: 3 }}
                            >
                              Login
                            </Button>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              disabled={!isFormCreateAccountValid}
                              endIcon={<ArrowRightIcon />}
                            >
                              {t("TEXT.NEXT")}
                            </Button>
                          </Box>
                        </Grid>
                      </Box>
                    ) : index === 1 ? (
                      <Box
                        sx={{
                          height: !checkIfLocalIsBrazil
                            ? 150
                            : mobile
                              ? 400
                              : mediumPage
                                ? 540
                                : window.screen.width < 1550
                                  ? 300
                                  : 600,
                          overflow: checkIfLocalIsBrazil ? "scroll" : "hidden",
                          overflowX: "hidden",
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          spacing={3}
                          component="form"
                          onSubmit={formikCreateAccount.handleSubmit}
                        >
                          <Grid item sx={{ mt: 2 }}>
                            <AutoComplete
                              formik={formikCreateAccount}
                              label={t("TEXT.COUNTRY")}
                              name="local"
                              options={options}
                              disabled={false}
                            />
                          </Grid>
                          {checkIfLocalIsBrazil && (
                            <>
                              <Grid item>
                                <Grid item sx={{ display: "flex" }}>
                                  <Box mr={2} width="100%">
                                    <TextField
                                      formik={formikCreateAccount}
                                      label={t("TEXT.ZIP_CODE")}
                                      name="cep"
                                      fullWidth
                                      InputProps={{
                                        inputComponent: CepMask,
                                      }}
                                    />
                                    {formikCreateAccount.errors.cep &&
                                    formikCreateAccount.touched.cep ? (
                                      <ErrorMessage>
                                        {formikCreateAccount.errors.cep}
                                      </ErrorMessage>
                                    ) : null}
                                  </Box>
                                  <Box alignSelf="center">
                                    <Button
                                      variant="contained"
                                      color="success"
                                      onClick={getAdress}
                                    >
                                      {t("BUTTON.SEARCH")}
                                    </Button>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <FormControl fullWidth>
                                  <InputLabel>{t("TEXT.STREET")}</InputLabel>
                                  <OutlinedInput
                                    value={street}
                                    onChange={(e) =>
                                      handleChangeStreet(e.target.value)
                                    }
                                    startAdornment={
                                      <InputAdornment position="start"></InputAdornment>
                                    }
                                    label={t("TEXT.STREET")}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <FormControl fullWidth>
                                  <InputLabel>{t("TEXT.CITY")}</InputLabel>
                                  <OutlinedInput
                                    value={city}
                                    onChange={(e) =>
                                      handleChangeCity(e.target.value)
                                    }
                                    startAdornment={
                                      <InputAdornment position="start"></InputAdornment>
                                    }
                                    label={t("TEXT.CITY")}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <FormControl fullWidth>
                                  <InputLabel>
                                    {t("TEXT.STATE_USER_REGISTRATION")}
                                  </InputLabel>
                                  <OutlinedInput
                                    value={state}
                                    onChange={(e) =>
                                      handleChangeState(e.target.value)
                                    }
                                    startAdornment={
                                      <InputAdornment position="start"></InputAdornment>
                                    }
                                    label={t("TEXT.STATE_USER_REGISTRATION")}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <FormControl fullWidth>
                                  <InputLabel>
                                    {t("TEXT.NEIGHBORHOOD")}
                                  </InputLabel>
                                  <OutlinedInput
                                    value={neighborhood}
                                    onChange={(e) =>
                                      handleChangeNeighborhood(e.target.value)
                                    }
                                    startAdornment={
                                      <InputAdornment position="start"></InputAdornment>
                                    }
                                    label={t("TEXT.NEIGHBORHOOD")}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <TextField
                                  formik={formikCreateAccount}
                                  label="Número"
                                  name="adressNumber"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item>
                                <TextField
                                  formik={formikCreateAccount}
                                  label="Complemento"
                                  name="complement"
                                  fullWidth
                                />
                              </Grid>
                            </>
                          )}
                          <Box mt={3} display="flex" justifyContent="center">
                            <Button
                              variant="outlined"
                              startIcon={<ArrowLeftIcon />}
                              onClick={handleBack}
                              sx={{ mr: 1, ml: 3 }}
                            >
                              {t("TEXT.PREVIOUS")}
                            </Button>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              disabled={
                                formikCreateAccount.values.local === "" ||
                                formikCreateAccount.values.local === null
                              }
                              endIcon={<ArrowRightIcon />}
                            >
                              {t("TEXT.NEXT")}
                            </Button>
                          </Box>
                        </Grid>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          height: mobile
                            ? 400
                            : mediumPage
                              ? 540
                              : window.screen.width < 1550
                                ? 300
                                : 660,
                          overflow: "inherit",
                          overflowX:
                            mobile || window.screen.width < 1550 || mediumPage
                              ? "hidden"
                              : "inherit",
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          component="form"
                          onSubmit={formikCreateAccount.handleSubmit}
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            minWidth: 250,
                            maxWidth: 700,
                            mt: 1,
                          }}
                        >
                          <Grid item>
                            <Card elevation={5} sx={{ mb: mobile ? 2 : 0 }}>
                              <CardContent
                                sx={{
                                  position: "relative",
                                  overflow: "auto",
                                  pr: 0,
                                }}
                              >
                                <Typography
                                  variant="h5"
                                  fontWeight={700}
                                  sx={{ mb: 2 }}
                                >
                                  {t("TEXT.LICENSE_TERMS")}
                                </Typography>
                                <Terms />
                              </CardContent>
                            </Card>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  onChange={handleChange}
                                />
                              }
                              label={t("TEXT.TERMS_CHECKBOX_MESSAGE")}
                            />
                            <Box mt={3} display="flex" justifyContent="center">
                              <Button
                                variant="outlined"
                                startIcon={<ArrowLeftIcon />}
                                onClick={handleBack}
                                sx={{ mr: 1, fontSize: mobile ? 10.5 : 14 }}
                              >
                                {t("TEXT.PREVIOUS")}
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                endIcon={<ArrowRightIcon />}
                                disabled={!checked}
                                sx={{ fontSize: mobile ? 10.5 : 14 }}
                                size={mobile ? "small" : "medium"}
                              >
                                {t("TEXT.ACCEPT_TERMS")}
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CompleteRegister;
