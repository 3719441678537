export const USER_ROUTE = "/users";

// Rota para resgatar/editar os dados do usuário
export const getUserRoute = (addr: number) => `${USER_ROUTE}/${addr}`;

export const getMeRoute = () => `${USER_ROUTE}/me`;

// Rota para editar a senha do usuário logado
export const changePasswordRoute = (addr: number) =>
  `${USER_ROUTE}/change-pass/${addr}`;

// Rota para editar o email do usuário logado
export const changeEmailRoute = () => `${USER_ROUTE}/change/email`;

// Rota para confirmar código de 6 digito para email
export const confirmChangeEmailRoute = () =>
  `${USER_ROUTE}/change/email/confirm`;

export const cancelChangeEmailRoute = () => `${USER_ROUTE}/change/email/cancel`;

export const resendCodeEmailRoute = () => `${USER_ROUTE}/change/email/resend`;

// Rota para resgatar o Token Telegram
export const GET_TOKEN_TELEGRAM_ROUTE = `${USER_ROUTE}/link/telegramtoken`;

// Rota para resgatar a quantidade de usuários que irão ser renderizados
export const getNumbersOfUsersRoute = (
  page: number,
  general: string,
  rowsPerPage: number,
  asAdmin: number,
  queryType: string,
) =>
  `${USER_ROUTE}?order=-creationTs&page=${page}&offset=${rowsPerPage}&asAdmin=${asAdmin}&general=${general}&queryType=${queryType}`;

// Rota para excluir usuário
export const deleteUserRoute = (addr: number) => `${USER_ROUTE}/${addr}`;

export const MQTT_USER_PASSWORD = `${USER_ROUTE}/me/password`;

// Rota para o envio do feedback
export const SEND_FEEDBACK_ROUTE = `${USER_ROUTE}/feedback`;
