import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Avatar,
  Box,
  Button,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { ToastContext } from "../../../contexts/ToastContext";
import Modal from "../../Devices/Modal";
import {
  cancelChangeEmail,
  confirmChangeEmail,
  resendCodeEmail,
} from "../../../services/data/user";
import { useParams } from "react-router-dom";
import { ConfirmationModal } from "../../../components/ConfirmationModal";

interface ModalEditEmailConfirmProps {
  setDeviceEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalEditEmailConfirm = ({
  setDeviceEdited,
  handleOpen,
  handleClose,
}: ModalEditEmailConfirmProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const inputsRef = useRef<Array<HTMLInputElement>>([]);
  const [valid, setValid] = React.useState<boolean>(false);
  const [code, setCode] = React.useState<(string | undefined)[]>([]);
  const [cancelCodeModal, setCancelCodeModal] = React.useState<boolean>(false);

  const params = useParams();
  const id = params?.addr && +params?.addr;
  // Checar se o usuário é Admin

  const handleCloseModal = () => {
    handleClose();

    setCode([]);
  };

  const handleSubmit = () => {
    if (id) {
      let codes = "";

      for (const c of code) {
        codes += c;
      }

      confirmChangeEmail(codes)
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.EDITED_USER"));
          setDeviceEdited(true);
          handleClose();
        })
        .catch((e) => {
          if (e?.response?.data?.message) {
            toastError(t(e?.response?.data?.message));
          } else {
            toastError(t("TOAST.USER.CODE_STILL_VALID"));
          }
        });
    }
  };

  const handleOnChange = () => {
    if (code.filter((c) => c).length === 6 && !valid) {
      setValid(true);
    }

    if (code.filter((c) => c).length < 6 && valid) {
      setValid(false);
    }
  };

  const handleOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    i: number,
  ) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;
    const previousElementSibling =
      target.parentElement?.previousElementSibling?.firstChild;
    const nextElementSibling =
      target.parentElement?.nextElementSibling?.firstChild;

    if (key === "Enter" && valid) {
      handleSubmit();
      return;
    }

    if (key.match("[0-9]{1}")) {
      setCode((prev) => {
        prev[i] = key;
        return prev;
      });

      setTimeout(() => {
        if (nextElementSibling) {
          (nextElementSibling as HTMLInputElement).focus();
        }
      }, 10);

      return;
    }

    if (key === "Backspace") {
      target.value = "";

      setValid(false);

      setCode((prev) => {
        prev[i] = undefined;
        return prev;
      });

      setTimeout(
        () =>
          previousElementSibling &&
          (previousElementSibling as HTMLInputElement).select(),
        10,
      );

      return;
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedValue = e.clipboardData.getData("Text");

    let currentInput = 0;

    for (let i = 0; i < pastedValue.length; i++) {
      const pastedCharacter = pastedValue.charAt(i);
      const currentInputElement = inputsRef.current[currentInput];
      if (pastedCharacter.match("[0-9]{1}")) {
        setCode((prev) => {
          prev[i] = pastedCharacter;
          return prev;
        });

        currentInputElement.value = pastedCharacter;
        const nextElementSibling =
          currentInputElement.parentElement?.nextElementSibling?.firstChild;

        if (nextElementSibling) {
          (nextElementSibling as HTMLInputElement).focus();
          currentInput++;
        }
      }

      if (code.filter((c) => c).length === 6) {
        setValid(true);
      }
    }

    e.preventDefault();
  };

  const invalidateCode = () => {
    setCancelCodeModal(true);
  };

  const resendCode = () => {
    resendCodeEmail()
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.RESEND_CODE"));
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.RESEND_CODE"));
      });
  };

  return (
    <Modal open={handleOpen} handleClose={handleCloseModal} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.INSERT_CODE")}
      </Typography>
      {
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {Array.from(Array(6).keys()).map((i) => (
            <OutlinedInput
              key={i}
              onChange={handleOnChange}
              onKeyDown={(e) => handleOnKeyDown(e, i)}
              onFocus={handleOnFocus}
              onPaste={handleOnPaste}
              inputProps={{
                maxLength: 1,
                style: { textAlign: "center" },
              }}
              type="tel"
              inputRef={(el: HTMLInputElement) => {
                inputsRef.current[i] = el;
              }}
              autoComplete={i === 0 ? "one-time-code" : "off"}
              sx={{
                height: 40,
                width: 40,
                mr: 0.5,
                ml: 0.5,
              }}
            />
          ))}
        </Box>
      }
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button variant="text" size="small" onClick={resendCode}>
          {t("TEXT.RESEND_CODE")}
        </Button>
        <Button variant="text" size="small" onClick={invalidateCode}>
          {t("TEXT.INVALIDATE_CODE")}
        </Button>
      </Box>
      {cancelCodeModal && (
        <ConfirmationModal
          open={cancelCodeModal}
          handleClose={() => setCancelCodeModal(false)}
          title={t("TEXT.INVALIDATE_CODE_CONFIRMATION_TITLE")}
          confirm={() => {
            cancelChangeEmail()
              .then(() => {
                toastSuccess(t("TOAST.SUCCESS.INVALIDATE_CODE"));
                handleCloseModal();
              })
              .catch(() => {
                toastError(t("TOAST.ERROR.INVALIDATE_CODE"));
              });
          }}
        ></ConfirmationModal>
      )}
      <Box display="grid" mt={2}>
        <Button
          variant="contained"
          type="submit"
          disabled={!valid}
          onClick={handleSubmit}
          sx={{
            mt: 2,
            borderRadius: 2,
            backgroundColor: "#C7252C",
            fontSize: 12,
            pt: 1,
            pb: 1,
          }}
          fullWidth
        >
          {t("BUTTON.CONFIRM")}
        </Button>
        <Button
          onClick={handleClose}
          fullWidth
          sx={{
            backgroundColor: "#FFD5D7",
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            pt: 1,
            pb: 1,
            color: "black",
          }}
        >
          {t("BUTTON.CANCEL")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalEditEmailConfirm;
