import React from "react";
import { filterTimes, graphData } from "../../../../components/Graph/parser";
import useIsMobile from "../../../../hooks/useIsMobile";

import Graph from "./Graph";
import { Alert, Box, Grid } from "@mui/material";
import Spinner from "../../../../components/Spinner";
import { useTranslation } from "react-i18next";

interface ReportGraphProps {
  formValue: any;
  dataGraph: any;
  graphDataValues: any;
}

const ReportGraph = ({ dataGraph, graphDataValues }: ReportGraphProps) => {
  const mobile = useIsMobile();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);

  // Array de arrays contendo o rótulo e os valores numéricos do gráfico
  const dataOfGraph = React.useMemo(
    () => graphData(dataGraph, graphDataValues),
    [dataGraph, graphDataValues],
  );

  // Filtrar somente os timestamps para o gráfico
  const graphTimes = React.useMemo(
    () => filterTimes(graphDataValues),
    [graphDataValues],
  );

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if ((dataGraph.length !== 0 || graphTimes.length !== 0) && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [dataGraph.length, graphTimes.length, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  return loading ? (
    <Box m="126px 0px">
      <Spinner />
    </Box>
  ) : dataGraph.length === 0 || graphTimes.length === 0 ? (
    <Grid container justifyContent="center">
      <Grid item mt={2}>
        <Alert
          severity="info"
          sx={{
            alignItems: "center",
            placeContent: "center",
            mb: 1,
            mt: 1,
            width: 310,
          }}
        >
          {t("TOAST.INFO.NO_DATA_RECORD")}
        </Alert>
      </Grid>
    </Grid>
  ) : (
    <Box height={500} m="0px -15px">
      <Graph graphData={dataOfGraph} graphTimes={graphTimes} mobile={mobile} />
    </Box>
  );
};
export default ReportGraph;
