import { api } from "../api";
import {
  exportToXlsRoute,
  generalInfoRoute,
  getDataOnDayRoute,
  getReportRoute,
} from "../routes/report";

// Resgatar os dados gerais dos relatórios
export const getReport = async (
  id: string,
  arcsysId: string,
  endTs: number,
  beginTs: number,
  sampling: number,
) => api.get<any>(getReportRoute(id, arcsysId, endTs, beginTs, sampling));

// Ação de exportar arquivo em .xlsx
export const exportToXls = async (
  arcsysId: string,
  ctrlId: string,
  endTs: number,
  beginTs: number,
  language: string,
  selected_model: string,
  sampling: string,
) =>
  api.get<any>(
    exportToXlsRoute(
      arcsysId,
      ctrlId,
      endTs,
      beginTs,
      language,
      selected_model,
      sampling,
    ),
    {
      responseType: "blob",
    },
  );

// Resgatar as datas e médias do Relatório
export const generalInfo = async (
  arcsysId: string,
  ctrlId: string,
  endTs: number,
  beginTs: number,
  language: string,
  selected_model: string,
) =>
  api.get<any>(
    generalInfoRoute(
      arcsysId,
      ctrlId,
      endTs,
      beginTs,
      language,
      selected_model,
    ),
  );

// Resgatar os dados do dia do Relatório
export const getDataOnDay = async (
  ctrlId: string,
  beginTs: number,
  model: string,
  userId: number,
) => api.get<any>(getDataOnDayRoute(ctrlId, beginTs, model, userId));
