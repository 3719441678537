import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";

import { PasswordVisibility, yupOneOf } from "../../components/Form";
import { ToastContext } from "../../contexts/ToastContext";
import {
  loginBackground,
  loginComponentBackground,
} from "../../components/Login/BackgroundImages";
import ErrorMessage from "../../components/ErrorMessage";
import { LOGIN } from "../../Routes/constants";
import { reset } from "../../services/data/auth";
import { yupRequiredStringMin } from "../../components/Form/yup";
import { AuthContext } from "../../contexts/AuthContext";

const RecoveryAccessFromEmail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toastSuccess, toastError } = React.useContext(ToastContext);
  const { signOut } = React.useContext(AuthContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  const minLength = 6;
  const passwordSchema = yupRequiredStringMin(minLength);

  React.useEffect(() => {
    const fetchStatus = () => {
      signOut();
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: passwordSchema,
      confirmPassword: yupOneOf(
        [Yup.ref("newPassword")],
        "ERROR.REQUIRED_CONFIRM_NEW_PASSWORD",
      ).min(minLength, t("ERROR.PASSWORD")),
    }),
    onSubmit: (values) => {
      // Criar uma nova senha para a conta do Cloud
      reset({ password: values.confirmPassword, token: token })
        .then(() => {
          toastSuccess(t("TOAST.SUCCESS.PASSWORD_CHANGED"));
          navigate(LOGIN);
        })
        .catch(() => {
          toastError(t("TOAST.ERROR.PASSWORD_CHANGED"));
        });
    },
  });

  // Validação do formulário
  const isFormValid = formik.dirty && formik.isValid;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100vh",
        backgroundImage: loginBackground,
        backgroundSize: "cover",
      }}
    >
      <Grid item>
        <Card
          sx={{
            minWidth: 350,
            maxWidth: 400,
            p: 2,
            backgroundImage: loginComponentBackground,
            ml: 2,
            mr: 2,
          }}
          elevation={5}
        >
          <CardContent sx={{ position: "relative" }}>
            <Grid
              container
              direction="column"
              spacing={3}
              component="form"
              onSubmit={formik.handleSubmit}
            >
              <Grid item>
                <Typography textAlign="center" variant="h4" color="primary">
                  {t("TEXT.RECOVER_ACCESS")}
                </Typography>
                <Typography
                  textAlign="center"
                  fontSize={17}
                  sx={{ mt: 1, mb: 1 }}
                  variant="body1"
                >
                  {t("TEXT.RECOVER_ACCESS_MESSAGE")}
                </Typography>
              </Grid>
              <Grid item>
                <PasswordVisibility
                  name="newPassword"
                  formik={formik}
                  label={t("TEXT.NEW_PASSWORD")}
                  fullWidth
                  maxLength={20}
                />
                {formik.errors.newPassword && formik.touched.newPassword ? (
                  <ErrorMessage>{formik.errors.newPassword}</ErrorMessage>
                ) : null}
              </Grid>
              <Grid item>
                <PasswordVisibility
                  name="confirmPassword"
                  formik={formik}
                  label={t("TEXT.RETYPE_NEW_PASSWORD")}
                  fullWidth
                  maxLength={20}
                />
                {formik.errors.confirmPassword &&
                formik.touched.confirmPassword ? (
                  <ErrorMessage>{formik.errors.confirmPassword}</ErrorMessage>
                ) : null}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!isFormValid}
                  fullWidth
                >
                  {t("BUTTON.RESET_PASSWORD")}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RecoveryAccessFromEmail;
