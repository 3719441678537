import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface ArcsysProviderProps {
  children: ReactNode;
}

interface ArcsysData {
  devicesOffline: any[] | [];
  setDevicesOffline: Dispatch<SetStateAction<any[] | []>>;
  macsToSubscribe: any[] | [];
  setMacsToSubscribe: Dispatch<SetStateAction<any[] | []>>;
  isMacsToSubscribeRequested: boolean;
  setIsMacsToSubscribeRequested: Dispatch<SetStateAction<boolean>>;
}

export const ArcsysContext = createContext({} as ArcsysData);

export const ArcsysProvider = ({ children }: ArcsysProviderProps) => {
  const [devicesOffline, setDevicesOffline] = useState<any[]>([]);
  const [macsToSubscribe, setMacsToSubscribe] = useState<string[]>([]);
  const [isMacsToSubscribeRequested, setIsMacsToSubscribeRequested] =
    useState(false);

  const ArcysProviderValue = useMemo(
    () => ({
      devicesOffline,
      setDevicesOffline,
      macsToSubscribe,
      setMacsToSubscribe,
      isMacsToSubscribeRequested,
      setIsMacsToSubscribeRequested,
    }),
    [devicesOffline, macsToSubscribe, isMacsToSubscribeRequested],
  );

  return (
    <ArcsysContext.Provider value={ArcysProviderValue}>
      {children}
    </ArcsysContext.Provider>
  );
};
