import React from "react";
import { useTranslation } from "react-i18next";

import {
  Send as SendIcon,
  SendOutlined as SendOutlinedIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { grey } from "@material-ui/core/colors";
import { ThemeModeContext } from "../../../../contexts/ThemeModeContext";
import { ToastContext } from "../../../../contexts/ToastContext";
import useIsMobile from "../../../../hooks/useIsMobile";
import { CtrlModels } from "../../../../services/types/ctrls";
import Spinner from "../../../Spinner";
import TopicTitle from "../../../TopicTitle";
import { getCtrlConfig } from "../../parser";
import ModalAction from "./Modal/ModalAction";

interface SettingsTableProps {
  model: CtrlModels;
  permissionToEdit: boolean;
  devicePermission: boolean;
  ctrlId?: string;
  idSector?: string;
  startIntervalAction?: () => void;
  handleCloseModalAction?: () => void;
  handleCloseModalActionCtrl?: (e: any) => void;
  ctrl?: string;
  submittingAction?: boolean;
  actionsIsDisabled?: boolean;
  offlineError?: boolean;
}

// Ações da página do controlador
const ActionsTable = ({
  model,
  permissionToEdit,
  devicePermission,
  ctrlId,
  idSector,
  startIntervalAction,
  handleCloseModalAction,
  handleCloseModalActionCtrl,
  ctrl,
  submittingAction,
  actionsIsDisabled,
  offlineError,
}: SettingsTableProps) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = React.useState(false);
  const [modalValues, setModalValues] = React.useState<any>();
  const { theme } = React.useContext(ThemeModeContext);
  const { toastError } = React.useContext(ToastContext);

  const [actionValue, setActionValue] = React.useState<{
    value: string;
    i: number;
  }>({ value: "", i: 0 });
  const tablePadding = useIsMobile() ? 0.3 : 1;
  const ctrlConfig = getCtrlConfig(model);
  const { acoes: acoesConfig } = ctrlConfig;

  const handleOpenModal = (
    tipo: string,
    id: number,
    value: number,
    label: string,
  ) => {
    if (
      devicePermission &&
      actionsIsDisabled !== true &&
      offlineError !== true
    ) {
      setOpenModal(true);
      setModalValues({ tipo, id, value, label });
    }
  };
  const handleCloseModal = () => setOpenModal(false);

  // Verificar se o dispositivo tem permissão para editar
  const handleNoPermissionShare = () => {
    toastError(t("TOAST.ERROR.SHARE_DEVICE_PERMISSION"));
  };

  // Alterar o valor das ações do tipo Input
  const handleChangeAction = (event: SelectChangeEvent, i: number) => {
    if (devicePermission) {
      setActionValue({ value: event.target.value, i });
    }
  };
  return (
    <TableContainer
      sx={{
        "& td": { pl: tablePadding, pr: tablePadding },
      }}
    >
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>
              <Grid container justifyContent="space-between" mt={1}>
                <Grid item alignSelf="center">
                  <TopicTitle icon={<SendOutlinedIcon />}>
                    {idSector
                      ? t("TEXT.COMMANDS_COLLECTIVE")
                      : t("TEXT.COMMANDS")}
                  </TopicTitle>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        {submittingAction && submittingAction === true ? (
          <Spinner size={80} />
        ) : (
          <TableBody>
            {acoesConfig?.map((v, i) => {
              return v.tipo === "toggle" ? (
                <TableRow
                  key={v.id}
                  hover
                  sx={{
                    cursor:
                      devicePermission &&
                      actionsIsDisabled !== true &&
                      offlineError !== true
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    permissionToEdit
                      ? handleOpenModal(
                          v.tipo,
                          +v.id,
                          +actionValue.value,
                          v.label,
                        )
                      : handleNoPermissionShare()
                  }
                >
                  <TableCell
                    padding="checkbox"
                    sx={{
                      textAlign: "center",
                      verticalAlign: "text-top",
                    }}
                  >
                    <Avatar
                      color="primary"
                      variant="rounded"
                      sx={{
                        bgcolor:
                          actionsIsDisabled === true || offlineError === true
                            ? grey[500]
                            : "#C7252C",
                        mt: 1,
                        mb: 1,
                      }}
                    >
                      <SendIcon />
                    </Avatar>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme === "light" ? "#6D6D6D" : "#FFFF",
                        fontSize: 16,
                      }}
                    >
                      {v.label}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {`${v.min !== undefined ? v.min : ""} ${
                        v.min !== undefined && v.max !== undefined ? "a" : ""
                      } ${v.max !== undefined ? v.max : ""}`}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={v.id} hover>
                  <TableCell
                    padding="checkbox"
                    style={{ paddingLeft: "15px" }}
                    sx={{
                      verticalAlign: "text-left",
                    }}
                    onClick={() =>
                      actionValue.value !== "" &&
                      v.min !== undefined &&
                      v.max !== undefined &&
                      actionValue.i === i &&
                      !actionValue.value.toString().includes(".") &&
                      v.min <= +actionValue.value &&
                      v.max >= +actionValue.value
                        ? permissionToEdit
                          ? handleOpenModal(
                              v.tipo,
                              +v.id,
                              +actionValue.value,
                              v.label,
                            )
                          : handleNoPermissionShare()
                        : undefined
                    }
                  >
                    <Avatar
                      color="primary"
                      variant="rounded"
                      sx={{
                        bgcolor:
                          actionsIsDisabled === true ||
                          offlineError === true ||
                          !(
                            actionValue.value !== "" &&
                            v.min !== undefined &&
                            v.max !== undefined &&
                            actionValue.i === i &&
                            !actionValue.value.toString().includes(".") &&
                            v.min <= +actionValue.value &&
                            v.max >= +actionValue.value
                          )
                            ? grey[500]
                            : "#C7252C",
                        mt: 1,
                        mb: 1,
                        cursor:
                          actionsIsDisabled !== true &&
                          offlineError !== true &&
                          actionValue.value !== "" &&
                          v.min !== undefined &&
                          v.max !== undefined &&
                          actionValue.i === i &&
                          !actionValue.value.toString().includes(".") &&
                          v.min <= +actionValue.value &&
                          v.max >= +actionValue.value
                            ? "pointer"
                            : "default",
                      }}
                    >
                      <SendIcon />
                    </Avatar>
                  </TableCell>
                  <TableCell align="left" sx={{ display: "flex" }}>
                    <TextField
                      label={t("TEXT.VALUE")}
                      onChange={(e: any) => handleChangeAction(e, i)}
                      type="number"
                      size="small"
                      value={actionValue.i === i ? actionValue.value : ""}
                      sx={{ width: 90, alignSelf: "center" }}
                      InputProps={{ inputProps: { min: v.min, max: v.max } }}
                    />
                    <Box ml={2}>
                      <Typography
                        variant="body1"
                        sx={{
                          mt: 0.5,
                          color: theme === "light" ? "#6D6D6D" : "#FFFF",
                          fontSize: 16,
                        }}
                      >
                        {v.label}
                      </Typography>
                      {v.min === 0 && v.max === 1 ? (
                        <Typography variant="body2" color="text.secondary">
                          {`${v.min !== undefined ? v.min : ""} ${
                            v.min !== undefined && v.max !== undefined
                              ? t("TEXT.OR")
                              : ""
                          } ${v.max !== undefined ? v.max : ""}`}
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          {`${v.min !== undefined ? v.min : ""} ${
                            v.min !== undefined && v.max !== undefined
                              ? "a"
                              : ""
                          } ${v.max !== undefined ? v.max : ""}`}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      <ModalAction
        handleOpen={openModal}
        modalValues={modalValues}
        handleClose={handleCloseModal}
        ctrlId={ctrlId}
        idSector={idSector}
        startIntervalAction={startIntervalAction}
        handleCloseModalAction={handleCloseModalAction}
        handleCloseModalActionCtrl={handleCloseModalActionCtrl}
        ctrl={ctrl}
      />
    </TableContainer>
  );
};

export default ActionsTable;
