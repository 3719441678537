import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../contexts/ToastContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { TextField, yupRequiredString } from "../../../components/Form";
import { TOKEN_KEY } from "../../../auth/constants";
import { api } from "../../../services/api";
import Modal from "../../Devices/Modal";
import ErrorMessage from "../../../components/ErrorMessage";
import { changeEmail } from "../../../services/data/user";
import { useParams } from "react-router-dom";

interface ModalEditEmailProps {
  user: any;
  setDeviceEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
  setModalEmailConfirmCode: (e: boolean) => void;
}

const ModalEditEmail = ({
  user,
  setDeviceEdited,
  handleOpen,
  handleClose,
  setModalEmailConfirmCode,
}: ModalEditEmailProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);

  const params = useParams();
  const id = params?.addr && +params?.addr;
  // Checar se o usuário é Admin

  const formik = useFormik({
    initialValues: {
      email: (user.user.email as string) ?? "",
    },
    validationSchema: Yup.object({
      email: yupRequiredString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token) {
          localStorage.setItem(TOKEN_KEY, token);
        }

        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        if (id) {
          // Editar o email do usuário logado
          changeEmail(values.email)
            .then(() => {
              toastSuccess(t("TOAST.SUCCESS.EDITED_USER"));
              setDeviceEdited(true);

              setTimeout(() => {
                setModalEmailConfirmCode(true);
              }, 100);

              handleClose();
            })
            .catch((e) => {
              if (e?.response?.data?.message) {
                toastError(t(e?.response?.data?.message));
              } else {
                toastError(t("TOAST.ERROR.EDITED_USER"));
              }
            });
        }
      }
    },
  });

  const isFormValid =
    formik.dirty && formik.isValid && !formik.values.email.includes(" ");

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.EDIT")} {t("TEXT.EMAIL")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            onClick={(e: any) => e.stopPropagation()}
            label={t("TEXT.EMAIL")}
            name="email"
            maxLength={320}
            fullWidth
          />
          {formik.errors.email && formik.touched.email ? (
            <ErrorMessage>{formik.errors.email}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: "#C7252C",
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.APPLY")}
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              backgroundColor: "#FFD5D7",
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalEditEmail;
