import React from "react";

import {
  LocalizationProvider,
  MobileTimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  ControlPointOutlined as ControlPointOutlinedIcon,
  RemoveCircleOutlineOutlined as RemoveCircleOutlineOutlinedIcon,
  AccessAlarm as AccessAlarmIcon,
  HelpOutline as HelpOutlineIcon,
  WbSunny as WbSunnyIcon,
  Sensors as SensorsIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Tooltip,
  Typography,
  Select as MuiSelect,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Select, TextField } from "../../../Form";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../hooks/useIsMobile";
import { FormikProps } from "formik";
import enLocale from "date-fns/locale/en-GB";
import deLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt-BR";
import { ptBR, esES, enUS } from "@mui/x-date-pickers/locales";
import { getLanguage } from "../../../../translations/languages";
import ModalAllCondition from "../ModalQuestions/ModalAllCondition";
import ModalAnyCondition from "../ModalQuestions/ModalAnyCondition";
import { Estado } from "../../../Controller/parser/types";
import { parseIcon } from "../../../../pages/Report/Pages/RecordTable/Icons";
import {
  firstLetterUpperCase,
  getActiveBits,
  selectBorderStyle,
  timePickerBorderStyle,
  textFieldBorderStyle,
  StyledToggleButtonGroup,
  StyledToggle,
} from "../../parse";
import { green } from "@mui/material/colors";
import { ThemeModeContext } from "../../../../contexts/ThemeModeContext";
import ModalDayOfWeek from "../ModalQuestions/ModalDayOfWeek";
import { checkIfIsUndefinedString } from "../../../Card/parser";

interface StepOneProps {
  formik: FormikProps<any>;
  conditiosLength: number;
  maxConditiosLength: number;
  handleAddConditions: () => void;
  handleRemoveConditions: () => void;
  conditionWork: string;
  setConditionWork: (e: string) => void;
  sensores: any;
  estado: Estado[];
  isFormStepOneIsValid: boolean;
  handleDateChange: (index: number, e: Date | null) => void;
  handleDateFinalChange: (index: number, e: Date | null) => void;
  selected: string[];
  setSelected: (index: number, e: string) => void;
  checked: number[];
  setChecked: (index: number, e: number) => void;
  row?: any;
  deviceName?: string;
  ctrlInitialName: string;
}

const StepOne = ({
  formik,
  conditiosLength,
  maxConditiosLength,
  handleAddConditions,
  handleRemoveConditions,
  conditionWork,
  setConditionWork,
  sensores,
  estado,
  isFormStepOneIsValid,
  handleDateChange,
  handleDateFinalChange,
  selected,
  setSelected,
  checked,
  setChecked,
  row,
  deviceName,
  ctrlInitialName,
}: StepOneProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const { theme } = React.useContext(ThemeModeContext);

  // Ícones
  const rawIcons = estado.filter(({ icon }) => !!icon);
  const icons = rawIcons
    .map((icon) => ({
      bit: icon.bit,
      description: icon.description,
      icon: parseIcon(icon.icon, theme ?? "light"),
    }))
    .reverse();

  // Ação do checkbox dos Ícones da primeira condição
  const handleChecked = (
    _e: React.MouseEvent<HTMLElement>,
    newChecked: number[],
    index: number,
  ) => {
    let state_number = 0;
    newChecked.forEach((e) => {
      state_number += Math.pow(2, e);
    });
    setChecked(index, state_number);
  };

  const checkedIconList = Array.from({ length: checked.length }, (_, index) =>
    getActiveBits(checked[index]),
  );

  const operatorListIcons = [
    t("TEXT.EQUAL"),
    t("TEXT.DIFFERENT"),
    t("TEXT.ANYONE"),
  ];

  // Lista de condições com ícones
  const optionsOperatorIcons = operatorListIcons.map(
    (value: any, i: number) => (
      <MenuItem
        value={
          value === t("TEXT.EQUAL")
            ? "igual"
            : value === t("TEXT.ANYONE")
              ? "qualquer"
              : "diferente"
        }
        key={i}
      >
        {value}
      </MenuItem>
    ),
  );

  // Checa se a lista de Sensores está vazia
  const checkIfSensorIsNull = sensores
    .map((value: any) => {
      return checkIfIsUndefinedString(value.description)
        ? undefined
        : value.description;
    })
    .filter((f: any) => f !== undefined);

  // Checa se a lista de Estados está vazia
  const checkIfStateIsNull = estado
    .map((value: any) => {
      return checkIfIsUndefinedString(value.icon) ? undefined : value.icon;
    })
    .filter((f: any) => f !== undefined);

  // Lista de condições do Cenário
  const actions =
    checkIfSensorIsNull.length === 0 && checkIfStateIsNull.length === 0
      ? [firstLetterUpperCase(t("TEXT.TIME"))]
      : checkIfSensorIsNull.length === 0
        ? [
            firstLetterUpperCase(t("TEXT.STATE")),
            firstLetterUpperCase(t("TEXT.TIME")),
          ]
        : checkIfStateIsNull.length === 0
          ? ["Sensor", firstLetterUpperCase(t("TEXT.TIME"))]
          : [
              "Sensor",
              firstLetterUpperCase(t("TEXT.STATE")),
              firstLetterUpperCase(t("TEXT.TIME")),
            ];

  const createOptionsActions = (index: any) => {
    return actions.map((value: string, i: number) => (
      <MenuItem value={value} key={i} onClick={() => setSelected(index, value)}>
        <ListItemIcon sx={{ pl: 1, pr: 1 }}>
          {value === "Sensor" ? (
            <Box display="flex" justifyContent="center">
              <SensorsIcon fontSize="small" sx={{ mt: 0.2 }} />
              <Typography
                ml={1.5}
                sx={{ color: theme === "light" ? "black" : "white" }}
              >
                {value}
              </Typography>
            </Box>
          ) : value === firstLetterUpperCase(t("TEXT.TIME")) ? (
            <Box display="flex" justifyContent="center">
              <AccessAlarmIcon />
              <Typography
                ml={1.5}
                sx={{ color: theme === "light" ? "black" : "white" }}
              >
                {value}
              </Typography>
            </Box>
          ) : (
            <Box display="flex" justifyContent="center">
              <WbSunnyIcon />
              <Typography
                ml={1.5}
                sx={{ color: theme === "light" ? "black" : "white" }}
              >
                {value}
              </Typography>
            </Box>
          )}
        </ListItemIcon>
      </MenuItem>
    ));
  };

  // Linguagem do componente Input das datas
  const checkLanguage =
    language === "pt" ? ptLocale : language === "en" ? enLocale : deLocale;

  // Linguagem do componente Input do horário
  const checkLanguageText =
    language === "pt"
      ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
      : language === "en"
        ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
        : esES.components.MuiLocalizationProvider.defaultProps.localeText;

  // Lista de Labels
  const optionsSensor = sensores.map((value: any, i: number) => (
    <MenuItem value={i} key={i}>
      {value.description}
    </MenuItem>
  ));

  // Unidade do sensor selecionado
  const filteredUnit = (index: number) =>
    sensores.filter(
      (_f: any, i: number) =>
        i === formik.values.sensorConditions[index].sensor,
    )[0]?.min;

  const operatorList = [t("TEXT.BIGGER"), t("TEXT.SMALLER")];
  const operatorTimeList = [
    t("TEXT.TIME_BEFORE"),
    t("TEXT.TIME_AFTER"),
    t("TEXT.BETWEEN"),
  ];

  // Lista de condições do Sejsor
  const optionsOperator = operatorList.map((value: any, i: number) => (
    <MenuItem value={value === t("TEXT.BIGGER") ? "maior" : "menor"} key={i}>
      {value}
    </MenuItem>
  ));

  // Lista de condições do horário
  const optionsOperatorTime = operatorTimeList.map((value: any, i: number) => (
    <MenuItem
      value={
        value === t("TEXT.TIME_AFTER")
          ? "maior"
          : value === t("TEXT.TIME_BEFORE")
            ? "menor"
            : "entre"
      }
      key={i}
    >
      {value}
    </MenuItem>
  ));

  // Alterar a forma que as condições funcionam
  const handleChangeConditionWork = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConditionWork((event.target as HTMLInputElement).value);
  };

  // Ações de abrir o modal de dúvidas sobre 'Todas as condições sejam satisfeitas'
  const [openModalAllCondition, setOpenModalAllCondition] =
    React.useState<boolean>(false);
  const handleOpenModalAllCondition = () => setOpenModalAllCondition(true);
  const handleCloseModalAllCondition = () => setOpenModalAllCondition(false);

  // Ações de abrir o modal de dúvidas sobre 'Qaulquer condição seja satisfeita'
  const [openModalAnyCondition, setOpenModalAnyCondition] =
    React.useState<boolean>(false);
  const handleOpenModalAnyCondition = () => setOpenModalAnyCondition(true);
  const handleCloseModalAnyCondition = () => setOpenModalAnyCondition(false);

  // Ações de abrir o modal de dúvidas sobre 'Dias da semana'
  const [openModalDayOfWeek, setOpenModalDayOfWeek] =
    React.useState<boolean>(false);
  const handleOpenModalDayOfWeek = () => setOpenModalDayOfWeek(true);
  const handleCloseModalDayOfWeek = () => setOpenModalDayOfWeek(false);

  // Função que altera os dias da semana selecionados
  const handleDaySelection = (index: number) => {
    // For multiple shots, toggle the selected day
    const updatedDays = [...formik.values.daysOfWeek];
    updatedDays[index] = updatedDays[index] === 0 ? 1 : 0;
    formik.setFieldValue("daysOfWeek", updatedDays);
  };

  // Função que seleciona TODOS os dias da semana
  const handleAllDaySelection = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedDays =
      event.target.checked === true
        ? [1, 1, 1, 1, 1, 1, 1]
        : [0, 0, 0, 0, 0, 0, 0];
    formik.setFieldValue("daysOfWeek", updatedDays);
  };

  // Days of the week array
  const daysOfWeekLabels = [
    "TEXT.SUNDAY",
    "TEXT.MONDAY",
    "TEXT.TUESDAY",
    "TEXT.WEDNESDAY",
    "TEXT.THURSDAY",
    "TEXT.FRIDAY",
    "TEXT.SATURDAY",
  ];

  // Componente de selecionar os dias da semana
  const daysOfWeekSelection = (
    <Box>
      {daysOfWeekLabels.map((day, index) => (
        <Tooltip title={t(day)} enterTouchDelay={0} key={index}>
          <StyledToggleButtonGroup
            size="small"
            value={formik.values.daysOfWeek[index] === 1 ? [index] : null}
            onChange={() => handleDaySelection(index)}
          >
            <StyledToggle key={t(day)} value={index} aria-label={t(day)}>
              {t(day).toUpperCase().split(" ")[0][0]}
            </StyledToggle>
          </StyledToggleButtonGroup>
        </Tooltip>
      ))}
    </Box>
  );

  return (
    <Box
      sx={{
        maxHeight: mobile || window.screen.width < 1550 ? 400 : 600,
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >
      <Box display="grid" mt={1}>
        {deviceName && (
          <FormControl sx={{ mt: 1 }}>
            <FormLabel>{t("TEXT.CONTROLLER")}:</FormLabel>
            <List>
              <ListItem sx={{ pl: 1, pb: 0, pt: 0 }}>
                <ListItemIcon sx={{ mr: -2 }}>
                  <CheckIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box display={mobile ? "grid" : "flex"}>
                      <Typography fontSize={17} fontWeight={500}>
                        {ctrlInitialName}
                      </Typography>
                      {!mobile && (
                        <Typography fontSize={15} ml={1} mr={1}>
                          -
                        </Typography>
                      )}
                      <Typography fontSize={15} mt={0.1}>
                        {deviceName}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
            </List>
          </FormControl>
        )}
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display={mobile ? "grid" : "flex"}>
          <Box display="flex" mt={1} alignItems="center">
            <FormLabel>{t("TEXT.SELECT_DAYS")}</FormLabel>
            <Box>
              <IconButton
                color="primary"
                onClick={handleOpenModalDayOfWeek}
                sx={{ pl: 1 }}
              >
                <HelpOutlineIcon color="info" />
              </IconButton>
            </Box>
          </Box>
          <FormControlLabel
            onChange={(e: any) => handleAllDaySelection(e)}
            sx={{ ml: mobile ? 0 : 1, mt: 1, mb: mobile ? 0.5 : 0 }}
            control={
              <Checkbox checked={!formik.values.daysOfWeek.includes(0)} />
            }
            label={t("TEXT.SELECT_ALL")}
          />
        </Box>
        {daysOfWeekSelection}
      </Box>
      {mobile && conditiosLength > 1 && (
        <FormControl sx={{ mt: 1 }}>
          <FormLabel>{t("TEXT.HOW_CONDITIONS_WORK")}:</FormLabel>
          <RadioGroup
            value={conditionWork}
            onChange={handleChangeConditionWork}
          >
            <Box display="flex" mt={1}>
              <FormControlLabel
                value="AND"
                control={<Radio />}
                label={t("TEXT.EXECUTE_ALL_CONDITION")}
              />
              <Box>
                <IconButton
                  color="primary"
                  onClick={handleOpenModalAllCondition}
                  sx={{ pl: mobile ? 0 : 1 }}
                >
                  <HelpOutlineIcon color="info" />
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" mt={1}>
              <FormControlLabel
                value="OR"
                control={<Radio />}
                label={t("TEXT.EXECUTE_ANY_CONDITION")}
              />
              <Box>
                <IconButton
                  color="primary"
                  onClick={handleOpenModalAnyCondition}
                  sx={{ pl: mobile ? 0 : 1 }}
                >
                  <HelpOutlineIcon color="info" />
                </IconButton>
              </Box>
            </Box>
          </RadioGroup>
        </FormControl>
      )}
      <Box
        display="flex"
        justifyContent={
          mobile ? "right" : conditiosLength > 1 ? "space-between" : "right"
        }
      >
        {!mobile && conditiosLength > 1 && (
          <Box display="grid">
            <FormLabel sx={{ mt: 1 }}>
              {t("TEXT.HOW_CONDITIONS_WORK")}:
            </FormLabel>
            <FormControl>
              <RadioGroup
                value={conditionWork}
                onChange={handleChangeConditionWork}
              >
                <Box display="flex" mt={1}>
                  <FormControlLabel
                    value="AND"
                    control={<Radio />}
                    label={t("TEXT.EXECUTE_ALL_CONDITION")}
                  />
                  <Box>
                    <IconButton
                      color="primary"
                      onClick={handleOpenModalAllCondition}
                      sx={{ pl: mobile ? 0 : 1 }}
                    >
                      <HelpOutlineIcon color="info" />
                    </IconButton>
                  </Box>
                </Box>
                <Box display="flex" mt={1}>
                  <FormControlLabel
                    value="OR"
                    control={<Radio />}
                    label={t("TEXT.EXECUTE_ANY_CONDITION")}
                  />
                  <Box>
                    <IconButton
                      color="primary"
                      onClick={handleOpenModalAnyCondition}
                      sx={{ pl: mobile ? 0 : 1 }}
                    >
                      <HelpOutlineIcon color="info" />
                    </IconButton>
                  </Box>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>
        )}
        <Box display="flex" justifyContent="right">
          <Box>
            {conditiosLength < maxConditiosLength &&
              isFormStepOneIsValid === true && (
                <Tooltip title={t("BUTTON.CREATE_NEW_CONDITION")}>
                  <IconButton
                    type="button"
                    aria-label="search"
                    onClick={handleAddConditions}
                    disabled={false}
                  >
                    <ControlPointOutlinedIcon
                      color={
                        !(
                          conditiosLength < maxConditiosLength &&
                          isFormStepOneIsValid === true
                        )
                          ? "inherit"
                          : "success"
                      }
                      sx={{ fontSize: 30 }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            {!(
              conditiosLength < maxConditiosLength &&
              isFormStepOneIsValid === true
            ) && (
              <IconButton
                type="button"
                aria-label="search"
                onClick={handleAddConditions}
                disabled={true}
              >
                <ControlPointOutlinedIcon
                  color={
                    !(
                      conditiosLength < maxConditiosLength &&
                      isFormStepOneIsValid === true
                    )
                      ? "inherit"
                      : "success"
                  }
                  sx={{ fontSize: 30 }}
                />
              </IconButton>
            )}
          </Box>
          <Box>
            {!(conditiosLength === 1) && (
              <Tooltip title={t("BUTTON.REMOVE_NEW_CONDITION")}>
                <IconButton
                  type="button"
                  aria-label="search"
                  onClick={handleRemoveConditions}
                  disabled={false}
                >
                  <RemoveCircleOutlineOutlinedIcon
                    color={conditiosLength === 1 ? "inherit" : "error"}
                    sx={{ fontSize: 30 }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {conditiosLength === 1 && (
              <IconButton
                type="button"
                aria-label="search"
                onClick={handleRemoveConditions}
                disabled={true}
              >
                <RemoveCircleOutlineOutlinedIcon
                  color={conditiosLength === 1 ? "inherit" : "error"}
                  sx={{ fontSize: 30 }}
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          maxHeight: mobile ? 280 : window.screen.width < 1550 ? 300 : "100%",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        {Array.from({ length: maxConditiosLength }).map((_, index) => {
          if (index >= conditiosLength) return null;

          return (
            <Box
              key={index}
              border="1px solid lightGray"
              p={1}
              pt={1.5}
              pl={mobile ? 1 : 2}
              mt={conditiosLength === 1 ? 1 : 2}
              mr={mobile ? 1 : 0}
              borderRadius="10px"
            >
              {selected[index] === firstLetterUpperCase(t("TEXT.TIME")) ? (
                <Box>
                  {formik.values.sensorConditions[index]?.conditionSelected}
                  <Box width={mobile ? "100%" : "50%"} display="flex" mb={1}>
                    <Typography
                      alignSelf="center"
                      mr={1}
                      fontWeight={700}
                      fontSize={17}
                      width={100}
                      mb={0.8}
                    >
                      {`${t("TEXT.CONDITION")} ${index + 1}:`}
                    </Typography>
                    <FormControl variant="standard">
                      <MuiSelect
                        value={selected[index]}
                        onChange={() => selected[index]}
                        sx={selectBorderStyle}
                      >
                        {createOptionsActions(index)}
                      </MuiSelect>
                    </FormControl>
                  </Box>
                  <Box display={mobile ? "grid" : "flex"}>
                    <Box
                      display="flex"
                      width={
                        mobile ? "100%" : language === "en" ? "18%" : "21%"
                      }
                      mt={1.5}
                    >
                      <Typography
                        alignSelf="center"
                        mr={1}
                        fontWeight={500}
                        fontSize={17}
                        mt={mobile ? 1.5 : 0}
                      >
                        {t("TEXT.IF")}
                      </Typography>
                      <Typography
                        alignSelf="center"
                        fontSize={17}
                        mt={mobile ? 1.5 : 0}
                      >
                        {t("TEXT.THE")}
                      </Typography>
                      <Box display="grid">
                        <Typography
                          alignSelf="center"
                          pl={0.6}
                          fontSize={17}
                          mt={mobile ? 1.5 : 0}
                        >
                          {t("TEXT.TIME")}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display={mobile ? "grid" : "flex"}
                      width="100%"
                      mt={1.5}
                    >
                      <Box display="flex" width={mobile ? "100%" : "40%"}>
                        <Typography
                          alignSelf="center"
                          mr={1}
                          fontWeight={500}
                          fontSize={17}
                          mt={mobile ? 2.5 : 0}
                        >
                          {t("TEXT.IS")}
                        </Typography>
                        <Box display="grid" width="100%" mt={mobile ? 0 : 1}>
                          <Select
                            formik={formik}
                            label={t("TEXT.OPERATOR")}
                            name={`sensorConditions[${index}].operator`}
                            options={optionsOperatorTime}
                            disabled={false}
                            variant="standard"
                            sx={{
                              ml: mobile ? 0 : 0.5,
                              textAlign: "center",
                              ...selectBorderStyle,
                            }}
                          />
                        </Box>
                      </Box>
                      <Box display="flex" width="100%">
                        <Typography
                          alignSelf="center"
                          ml={mobile ? 0 : 1.5}
                          mr={1.4}
                          fontWeight={500}
                          fontSize={17}
                          mt={mobile ? 1.5 : -0.5}
                        >
                          {language === "es"
                            ? t("TEXT.TIME_DAS")
                            : language === "pt"
                              ? formik.values.sensorConditions[index]
                                  .operator === "menor"
                                ? t("TEXT.TIME_DAS")
                                : formik.values.sensorConditions[index]
                                      .operator === "maior"
                                  ? "as"
                                  : ""
                              : t("TEXT.THAN")}
                        </Typography>
                        <Box display="grid" width="100%" mb={3}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={checkLanguage}
                            localeText={checkLanguageText}
                          >
                            <DemoContainer components={["TimePicker"]}>
                              <Box
                                width={
                                  mobile
                                    ? "100%"
                                    : language === "en"
                                      ? "35%"
                                      : "30%"
                                }
                                display="flex"
                              >
                                {row === undefined ? (
                                  <MobileTimePicker
                                    label={
                                      formik.values.sensorConditions[index]
                                        .operator === "entre"
                                        ? t("TEXT.TIME_START")
                                        : firstLetterUpperCase(t("TEXT.TIME"))
                                    }
                                    ampm={language === "en" ? true : false}
                                    onChange={(newValue) =>
                                      handleDateChange(index, newValue as Date)
                                    }
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                    slotProps={{
                                      textField: { variant: "standard" },
                                    }}
                                    sx={{
                                      textAlignLast: "center",
                                      width: "100%",
                                      ...timePickerBorderStyle,
                                    }}
                                  />
                                ) : row?.conditions[index]?.posSensor !== -2 ? (
                                  <MobileTimePicker
                                    label={
                                      formik.values.sensorConditions[index]
                                        .operator === "entre"
                                        ? t("TEXT.TIME_START")
                                        : firstLetterUpperCase(t("TEXT.TIME"))
                                    }
                                    ampm={language === "en" ? true : false}
                                    onChange={(newValue) =>
                                      handleDateChange(index, newValue as Date)
                                    }
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                    slotProps={{
                                      textField: { variant: "standard" },
                                    }}
                                    sx={{
                                      textAlignLast: "center",
                                      width: "100%",
                                      ...timePickerBorderStyle,
                                    }}
                                  />
                                ) : (
                                  <MobileTimePicker
                                    label={
                                      formik.values.sensorConditions[index]
                                        .operator === "entre"
                                        ? t("TEXT.TIME_START")
                                        : firstLetterUpperCase(t("TEXT.TIME"))
                                    }
                                    ampm={language === "en" ? true : false}
                                    onChange={(newValue) =>
                                      handleDateChange(index, newValue as Date)
                                    }
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                    value={
                                      new Date(
                                        row?.conditions[index]
                                          ?.valorReferencia * 1000,
                                      )
                                    }
                                    slotProps={{
                                      textField: { variant: "standard" },
                                    }}
                                    sx={{
                                      textAlignLast: "center",
                                      width: "100%",
                                      ...timePickerBorderStyle,
                                    }}
                                  />
                                )}
                              </Box>
                              {formik.values.sensorConditions[index]
                                .operator === "entre" && (
                                <Box
                                  style={{
                                    marginTop: "22px",
                                    textAlign: "center",
                                  }}
                                >
                                  {t("TEXT.AND")}
                                </Box>
                              )}
                              {formik.values.sensorConditions[index]
                                .operator === "entre" && (
                                <Box
                                  width={
                                    mobile
                                      ? "100%"
                                      : language === "en"
                                        ? "60%"
                                        : "30%"
                                  }
                                  display="flex"
                                >
                                  {row === undefined ? (
                                    <MobileTimePicker
                                      label={t("TEXT.TIME_END")}
                                      ampm={language === "en" ? true : false}
                                      onChange={(newValue) =>
                                        handleDateFinalChange(
                                          index,
                                          newValue as Date,
                                        )
                                      }
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      slotProps={{
                                        textField: { variant: "standard" },
                                      }}
                                      sx={{
                                        textAlignLast: "center",
                                        width: "100%",
                                        ...timePickerBorderStyle,
                                      }}
                                    />
                                  ) : row?.conditions[index]?.posSensor !==
                                    -2 ? (
                                    <MobileTimePicker
                                      label={t("TEXT.TIME_END")}
                                      ampm={language === "en" ? true : false}
                                      onChange={(newValue) =>
                                        handleDateFinalChange(
                                          index,
                                          newValue as Date,
                                        )
                                      }
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      slotProps={{
                                        textField: { variant: "standard" },
                                      }}
                                      sx={{
                                        textAlignLast: "center",
                                        width: "100%",
                                        ...timePickerBorderStyle,
                                      }}
                                    />
                                  ) : (
                                    <MobileTimePicker
                                      label={t("TEXT.TIME_END")}
                                      ampm={language === "en" ? true : false}
                                      onChange={(newValue) =>
                                        handleDateFinalChange(
                                          index,
                                          newValue as Date,
                                        )
                                      }
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      value={
                                        new Date(
                                          row?.conditions[index]
                                            ?.valorReferencia_2 * 1000,
                                        )
                                      }
                                      slotProps={{
                                        textField: { variant: "standard" },
                                      }}
                                      sx={{
                                        textAlignLast: "center",
                                        width: "100%",
                                        ...timePickerBorderStyle,
                                      }}
                                    />
                                  )}
                                </Box>
                              )}
                            </DemoContainer>
                          </LocalizationProvider>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : selected[index] === firstLetterUpperCase(t("TEXT.STATE")) ? (
                <Box>
                  <Box width={mobile ? "100%" : "50%"} display="flex" mb={1}>
                    <Typography
                      alignSelf="center"
                      mr={1}
                      fontWeight={700}
                      fontSize={17}
                      width={100}
                      mb={0.8}
                    >
                      {`${t("TEXT.CONDITION")} ${index + 1}:`}
                    </Typography>
                    <FormControl variant="standard">
                      <MuiSelect
                        value={selected[index]}
                        onChange={() => selected[index]}
                        sx={selectBorderStyle}
                      >
                        {createOptionsActions(index)}
                      </MuiSelect>
                    </FormControl>
                  </Box>
                  <Box
                    display={mobile ? "grid" : "flex"}
                    sx={{ alignItems: "center" }}
                  >
                    <Box
                      display="flex"
                      width={
                        mobile ? "100%" : language === "en" ? "14%" : "15%"
                      }
                      mt={1.5}
                    >
                      <Typography
                        alignSelf="center"
                        mr={1}
                        fontWeight={500}
                        fontSize={17}
                        mt={mobile ? 1.5 : 0}
                      >
                        {t("TEXT.IF")}
                      </Typography>
                      <Typography
                        alignSelf="center"
                        fontSize={17}
                        mt={mobile ? 1.5 : 0}
                      >
                        {t("TEXT.THE")}
                      </Typography>
                      <Typography
                        alignSelf="center"
                        pl={0.6}
                        fontSize={17}
                        mt={mobile ? 1.5 : 0}
                        sx={{
                          width: "100%",
                        }}
                      >
                        {t("TEXT.STATE")}
                      </Typography>
                    </Box>
                    <Box display="flex" width={mobile ? "100%" : "25%"} mt={2}>
                      <Typography
                        alignSelf="center"
                        ml={mobile ? 0 : 1.6}
                        mr={1}
                        fontWeight={500}
                        fontSize={17}
                        mt={mobile ? 1.5 : -0.5}
                      >
                        {language === "es"
                          ? formik.values.sensorConditions[index]
                              .operatorIcon === "igual"
                            ? t("TEXT.IS")
                            : formik.values.sensorConditions[index]
                                  .operatorIcon === "diferente"
                              ? t("TEXT.IS")
                              : ""
                          : language === "pt"
                            ? formik.values.sensorConditions[index]
                                .operatorIcon === "igual"
                              ? t("TEXT.IS")
                              : formik.values.sensorConditions[index]
                                    .operatorIcon === "diferente"
                                ? t("TEXT.IS")
                                : ""
                            : formik.values.sensorConditions[index]
                                  .operatorIcon === "igual"
                              ? t("TEXT.IS")
                              : formik.values.sensorConditions[index]
                                    .operatorIcon === "diferente"
                                ? t("TEXT.IS")
                                : ""}
                      </Typography>
                      <Box display="grid" width="100%">
                        <Select
                          formik={formik}
                          label={t("TEXT.OPERATOR")}
                          name={`sensorConditions[${index}].operatorIcon`}
                          options={optionsOperatorIcons}
                          disabled={false}
                          variant="standard"
                          sx={{
                            ml: mobile ? 0 : 0.5,
                            mb: mobile ? 0 : 2.5,
                            textAlign: "center",
                            ...selectBorderStyle,
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      width={mobile ? "100%" : "46%"}
                      mt={1.5}
                    >
                      <Typography
                        alignSelf="center"
                        ml={mobile ? 0 : 1.5}
                        mr={1.4}
                        fontWeight={500}
                        fontSize={17}
                      >
                        {language === "es"
                          ? formik.values.sensorConditions[index]
                              .operatorIcon === "igual"
                            ? t("TEXT.TO")
                            : formik.values.sensorConditions[index]
                                  .operatorIcon === "diferente"
                              ? "de"
                              : ""
                          : language === "pt"
                            ? formik.values.sensorConditions[index]
                                .operatorIcon === "igual"
                              ? t("TEXT.TO")
                              : formik.values.sensorConditions[index]
                                    .operatorIcon === "diferente"
                                ? "de"
                                : ""
                            : formik.values.sensorConditions[index]
                                  .operatorIcon === "igual"
                              ? t("TEXT.TO")
                              : formik.values.sensorConditions[index]
                                    .operatorIcon === "diferente"
                                ? t("TEXT.TO")
                                : ""}
                      </Typography>
                      <Grid container>
                        {icons.map(({ icon, description, bit }, i: number) => (
                          <Grid item key={i}>
                            <MuiToggleButtonGroup
                              value={checkedIconList[index]}
                              onChange={(e, newChecked) =>
                                handleChecked(e, newChecked, index)
                              }
                            >
                              <MuiToggleButton
                                value={bit}
                                sx={{
                                  p: 1,
                                  pb: 0,
                                  m: 0.5,
                                  "&.Mui-selected": {
                                    backgroundColor: green[200],
                                  },
                                  "&.Mui-selected:hover": {
                                    backgroundColor: green[100],
                                  },
                                  backgroundColor:
                                    theme === "light" ? "#F8F8F8" : "#8C8C8C",
                                }}
                              >
                                <Tooltip
                                  title={description.active}
                                  enterTouchDelay={0}
                                >
                                  <Box>{icon}</Box>
                                </Tooltip>
                              </MuiToggleButton>
                            </MuiToggleButtonGroup>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box width={mobile ? "100%" : "50%"} display="flex" mb={1}>
                    <Typography
                      alignSelf="center"
                      mr={1}
                      fontWeight={700}
                      fontSize={17}
                      width={100}
                      mb={0.8}
                    >
                      {`${t("TEXT.CONDITION")} ${index + 1}:`}
                    </Typography>
                    <FormControl variant="standard">
                      <MuiSelect
                        value={selected[index]}
                        onChange={() => selected[index]}
                        sx={selectBorderStyle}
                      >
                        {createOptionsActions(index)}
                      </MuiSelect>
                    </FormControl>
                  </Box>
                  <Box display={mobile ? "grid" : "flex"}>
                    <Box
                      display="flex"
                      width={mobile ? "100%" : "40%"}
                      mt={1.5}
                    >
                      <Typography
                        alignSelf="center"
                        mr={1}
                        fontWeight={500}
                        fontSize={17}
                        mt={mobile ? 1.5 : 0}
                      >
                        {t("TEXT.IF")}
                      </Typography>
                      <Box display="grid" width={mobile ? "100%" : "100%"}>
                        <Select
                          formik={formik}
                          label="Sensor"
                          name={`sensorConditions[${index}].sensor`}
                          options={optionsSensor}
                          disabled={false}
                          variant="standard"
                          sx={{
                            ml: mobile ? 0 : 0.5,
                            textAlign: "center",
                            ...selectBorderStyle,
                          }}
                        />
                      </Box>
                    </Box>
                    <Box display="flex" width={mobile ? "100%" : "25%"}>
                      <Typography
                        alignSelf="center"
                        ml={mobile ? 0 : 1.5}
                        mr={1}
                        fontWeight={500}
                        fontSize={17}
                        mt={mobile ? 3 : 1.5}
                      >
                        {t("TEXT.IS")}
                      </Typography>
                      <Box display="grid" width="100%" mt={1.5}>
                        <Select
                          formik={formik}
                          label={t("TEXT.OPERATOR")}
                          name={`sensorConditions[${index}].operator`}
                          options={optionsOperator}
                          disabled={false}
                          variant="standard"
                          sx={{
                            ml: mobile ? 0 : 0.5,
                            textAlign: "center",
                            ...selectBorderStyle,
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      width={mobile ? "100%" : "20%"}
                      mt={1.5}
                    >
                      <Typography
                        alignSelf="center"
                        ml={mobile ? 0 : 1.5}
                        mr={1}
                        fontWeight={500}
                        fontSize={17}
                      >
                        {t("TEXT.THAN")}
                      </Typography>
                      <Box display="grid" width="100%">
                        <TextField
                          formik={formik}
                          disabled={false}
                          label={t("TEXT.VALUE")}
                          name={`sensorConditions[${index}].condValue`}
                          type="number"
                          fullWidth
                          variant="standard"
                          sx={{
                            ml: 0.5,
                            mb: 1.5,
                            textAlignLast: "center",
                            ...textFieldBorderStyle,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                {filteredUnit(index)}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      <ModalAllCondition
        handleOpen={openModalAllCondition}
        handleClose={handleCloseModalAllCondition}
      />
      <ModalAnyCondition
        handleOpen={openModalAnyCondition}
        handleClose={handleCloseModalAnyCondition}
      />
      <ModalDayOfWeek
        handleOpen={openModalDayOfWeek}
        handleClose={handleCloseModalDayOfWeek}
      />
    </Box>
  );
};

export default StepOne;
