// Checar o tipo de pesquisa
export const checkedQueryType = (
  deviceOption: number,
  searchedDevice: any,
  queryType: string,
) =>
  deviceOption === 0 && searchedDevice === ""
    ? "name"
    : deviceOption !== 0 && searchedDevice === ""
      ? ""
      : queryType;

// Filtrar status e pesquisa do dispositivo
export const queryTypeOptions = (
  deviceOption: number,
  searchedDevice: any,
  queryType: string,
) =>
  deviceOption === 0
    ? checkedQueryType(deviceOption, searchedDevice, queryType)
    : deviceOption === 1
      ? `ONLINE-${checkedQueryType(deviceOption, searchedDevice, queryType)}`
      : deviceOption === 2
        ? `OFFLINE-${checkedQueryType(deviceOption, searchedDevice, queryType)}`
        : `SHARED-${checkedQueryType(deviceOption, searchedDevice, queryType)}`;
