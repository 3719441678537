import React, { MutableRefObject, Ref } from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";

// Formatação de CPF em componentes Input
const CpfMask = React.forwardRef(
  (topLevelProps: MaskedInputProps, ref: Ref<HTMLInputElement>) => {
    return (
      <MaskedInput
        render={(textMaskRef, props) => (
          <input
            {...props}
            ref={(node) => {
              if (node) {
                textMaskRef(node);
                if (ref) {
                  (ref as MutableRefObject<HTMLInputElement>).current = node;
                }
              }
            }}
          />
        )}
        {...topLevelProps}
        mask={[
          /[z0-9_.]/,
          /[z0-9_.]/,
          /[z0-9_.]/,
          ".",
          /[z0-9_.]/,
          /[z0-9_.]/,
          /[z0-9_.]/,
          ".",
          /[z0-9_.]/,
          /[z0-9_.]/,
          /[z0-9_.]/,
          "-",
          /[z0-9_.]/,
          /[z0-9_.]/,
        ]}
        style={{ textTransform: "uppercase" }}
      />
    );
  },
);

export default CpfMask;
