import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContext } from "../../../../contexts/ToastContext";
import { AuthContext } from "../../../../contexts/AuthContext";
import { TextField, yupRequiredString } from "../../../../components/Form";
import { TOKEN_KEY } from "../../../../auth/constants";
import { api } from "../../../../services/api";
import Modal from "../../../Devices/Modal";
import ErrorMessage from "../../../../components/ErrorMessage";
import { editTag } from "../../../../services/data/TAGs";

interface ModalEditTagProps {
  data: any;
  setTagEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalEditTag = ({
  data,
  setTagEdited,
  handleOpen,
  handleClose,
}: ModalEditTagProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);

  const maxLength = 20;

  const formik = useFormik({
    initialValues: {
      name: (data?.data?.name as string) ?? "",
    },
    validationSchema: Yup.object({
      name: yupRequiredString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        // Novos dados do usuario
        const newUserData = {
          tag_name: values.name.toUpperCase(),
        };

        // Editar o telefone do usuário logado
        editTag(data.data.tagId, newUserData)
          .then(() => {
            toastSuccess(t("TOAST.SUCCESS.EDITED_TAG"));
            setTagEdited(true);
            handleClose();
          })
          .catch((e) => {
            if (e?.response?.data?.message) {
              toastError(t(e?.response?.data?.message));
            } else {
              toastError(t("TOAST.ERROR.EDITED_TAG"));
            }
          });
      }
    },
  });

  // Valor inicial do formulário
  React.useEffect(() => {
    formik.resetForm({
      values: {
        name: (data?.data?.name as string) ?? "",
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.name]);

  const isFormValid = formik.dirty && formik.isValid;

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.EDIT")} TAG
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            onClick={(e: any) => e.stopPropagation()}
            label={t("TEXT.NAME")}
            name="name"
            maxLength={19}
            fullWidth
            inputProps={{ style: { textTransform: "uppercase" }, maxLength }}
          />
          {formik.errors.name && formik.touched.name ? (
            <ErrorMessage>{formik.errors.name}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: "#C7252C",
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              backgroundColor: "#FFD5D7",
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalEditTag;
