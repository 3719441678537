import {
  Box,
  CardActions,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  SendOutlined as SendOutlinedIcon,
  GridOn as GridOnIcon,
  Checklist as ChecklistIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ModalActions from "../Modal/Actions";
import React from "react";
import ModalDevices from "../Modal/Devices";
import Spinner from "../../../components/Spinner";
import ModalChangeSetpoint from "../Modal/ChangeSetpoint";
import {
  CtrlConfig,
  MeasuresData,
} from "../../../components/Controller/parser/types";

interface FooterProps {
  checked: boolean;
  data: any;
  submittingAction: boolean;
  startIntervalAction: () => void;
  setSectorEdited: (e: boolean) => void;
  isInModal: boolean;
  setValue: (e: any) => void;
  setChangingSetpoit: (e: boolean) => void;
  bgColor: string;
  mainMeasureAvg: MeasuresData;
  secondaryMeasuresAvg: MeasuresData[];
  editSetpointIsValid: boolean;
  allDevicesOffline: boolean;
  ctrlConfig: CtrlConfig;
  devices: any;
}

const Footer = ({
  checked,
  data,
  submittingAction,
  startIntervalAction,
  setSectorEdited,
  isInModal,
  setValue,
  setChangingSetpoit,
  bgColor,
  mainMeasureAvg,
  secondaryMeasuresAvg,
  editSetpointIsValid,
  allDevicesOffline,
  ctrlConfig,
  devices,
}: FooterProps) => {
  const { t } = useTranslation();

  // Ações de abrir o modal de Ações
  const [openModalActions, setOpenModalActions] =
    React.useState<boolean>(false);
  const handleOpenModalActions = () => setOpenModalActions(true);
  const handleCloseModalActions = () => {
    setOpenModalActions(false);
  };

  // Ações de abrir o modal de dispositivos
  const [openModalDevices, setOpenModalDevices] =
    React.useState<boolean>(false);
  const handleOpenModalDevices = () => setOpenModalDevices(true);
  const handleCloseModalDevices = () => {
    setOpenModalDevices(false);
  };

  // Ações de abrir o modal de selecionar setpoint
  const [openModalSetpoints, setOpenModalSetpoints] =
    React.useState<boolean>(false);
  const handleOpenModalSetpoints = () => setOpenModalSetpoints(true);
  const handleCloseModalSetpoints = () => {
    setOpenModalSetpoints(false);
  };

  // Resgatar as Ações do Controlador
  const { acoes: acoesConfig } = ctrlConfig;
  const setpointsLength = ctrlConfig.setpoints.length;

  return (
    <>
      {isInModal === true ? (
        setpointsLength > 1 && <Divider sx={{ mt: -1 }} />
      ) : (
        <Divider sx={{ mt: -1 }} />
      )}
      <CardActions
        disableSpacing
        sx={{
          backgroundColor: bgColor,
          placeContent: "center",
          display: "flex",
          justifyContent: "space-around",
          pt: 0.5,
          pl: setpointsLength > 1 && isInModal === false ? "30px" : "8px",
        }}
      >
        {isInModal === false &&
          acoesConfig &&
          (submittingAction === true ? (
            <Box mt={0.8}>
              <Spinner size={35} disableMargin />
            </Box>
          ) : (
            <Box
              textAlign="center"
              onClick={() =>
                !checked && !allDevicesOffline && handleOpenModalActions()
              }
              sx={{
                cursor: !checked && !allDevicesOffline ? "pointer" : "default",
              }}
            >
              <IconButton
                color="inherit"
                disabled={checked || allDevicesOffline}
              >
                <SendOutlinedIcon
                  sx={{
                    color: checked || allDevicesOffline ? "inherit" : "white",
                  }}
                />
              </IconButton>
              <Typography
                textAlign="center"
                fontSize={14}
                mt={-0.6}
                fontWeight={500}
                sx={{
                  color: checked || allDevicesOffline ? "" : "white",
                  opacity: checked || allDevicesOffline ? "50%" : "100%",
                }}
              >
                {t("TEXT.COMMANDS")}
              </Typography>
            </Box>
          ))}
        {setpointsLength > 1 && (
          <Box
            onClick={() =>
              !checked && !editSetpointIsValid && handleOpenModalSetpoints()
            }
            sx={{
              cursor: !checked && !editSetpointIsValid ? "pointer" : "default",
              textAlign: "center",
            }}
          >
            <IconButton
              color="inherit"
              disabled={checked || editSetpointIsValid}
            >
              <ChecklistIcon
                sx={{
                  color: checked || editSetpointIsValid ? "inherit" : "white",
                }}
              />
            </IconButton>
            <Typography
              textAlign="center"
              fontSize={14}
              mt={-0.6}
              fontWeight={500}
              sx={{
                color: checked || editSetpointIsValid ? "" : "white",
                opacity: checked || editSetpointIsValid ? "50%" : "100%",
              }}
            >
              Setpoint
            </Typography>
          </Box>
        )}
        {isInModal === false && (
          <Box
            onClick={handleOpenModalDevices}
            textAlign="center"
            mr={setpointsLength > 1 ? 1.5 : 0}
            sx={{ cursor: "pointer" }}
          >
            <IconButton disabled={checked}>
              <GridOnIcon sx={{ color: "white" }} />
            </IconButton>
            <Typography
              textAlign="center"
              fontSize={14}
              mt={-0.6}
              fontWeight={500}
              sx={{ color: "white" }}
            >
              {t("TEXT.DEVICES")}
            </Typography>
          </Box>
        )}
      </CardActions>
      <ModalActions
        handleOpen={openModalActions}
        handleClose={handleCloseModalActions}
        data={data}
        startIntervalAction={startIntervalAction}
      />
      <ModalDevices
        handleOpen={openModalDevices}
        handleClose={handleCloseModalDevices}
        data={data}
        setSectorEdited={setSectorEdited}
        mainMeasureAvg={mainMeasureAvg}
        secondaryMeasuresAvg={secondaryMeasuresAvg}
        checked={checked}
        startIntervalAction={startIntervalAction}
        submittingAction={submittingAction}
        actionsIsDisabled={checked || allDevicesOffline}
        devices={devices}
      />
      <ModalChangeSetpoint
        handleClose={handleCloseModalSetpoints}
        handleOpen={openModalSetpoints}
        setSectorEdited={setSectorEdited}
        setpoints={ctrlConfig.setpoints}
        sectorId={data._id}
        sectorData={data}
        setValue={setValue}
        setChangingSetpoit={setChangingSetpoit}
      />
    </>
  );
};

export default Footer;
