import React from "react";
import { useTranslation } from "react-i18next";

import {
  LocalOffer as LocalOfferIcon,
  LocalOfferOutlined as LocalOfferOutlinedIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import { green } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import { TOKEN_KEY } from "../../../../../auth/constants";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { TagsContext } from "../../../../../contexts/TagsContext";
import { ToastContext } from "../../../../../contexts/ToastContext";
import { api } from "../../../../../services/api";
import {
  addTagToCtrl,
  getTagNamesById,
} from "../../../../../services/data/TAGs";
import ErrorMessage from "../../../../ErrorMessage";
import { Select, yupRequiredString } from "../../../../Form";
import Modal from "../Modal";

interface ModalSelectTagProps {
  handleOpen: boolean;
  handleClose: () => void;
  setTagEdited: (e: boolean) => void;
  ctrlId?: string;
}

const ModalSelectTag = ({
  handleOpen,
  handleClose,
  setTagEdited,
  ctrlId,
}: ModalSelectTagProps) => {
  const { t } = useTranslation();
  const params = useParams();

  const { token, authenticated, userId } = React.useContext(AuthContext);
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const [tagList, setTagList] = React.useState<any[]>([]);
  const { setGlobalTagEdited } = React.useContext(TagsContext);

  // Resgatar a lista de TAGs do usuário
  React.useEffect(() => {
    getTagNamesById(+userId).then((res) => {
      const { data } = res;
      setTagList(data);
    });
  }, [handleOpen, userId]);

  // Lista de Tags
  const optionsTags = tagList.map((value: any, i: number) => (
    <MenuItem
      value={value._id}
      key={i}
      sx={{ placeContent: "center", borderTop: "1px solid #F0EFEE" }}
    >
      <Chip
        label={value.tag_name}
        avatar={<LocalOfferOutlinedIcon />}
        color="success"
        sx={{
          fontWeight: 700,
          backgroundColor: green[100],
          color: green[800],
          textTransform: "uppercase",
          fontSize: 12,
          mt: 0.5,
          mb: 0.5,
        }}
      />
    </MenuItem>
  ));

  // Submit das edições de cada parâmetro do Controlador
  const formik = useFormik({
    initialValues: {
      id: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      id: yupRequiredString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const data = {
          tag_id: values.id,
          ctrlId: ctrlId ?? `${params.mac}/${params.ctrl}`,
        };

        // Selecionar TAG
        addTagToCtrl(data)
          .then(() => {
            setTagEdited(true);
            setGlobalTagEdited(true);
            handleClose();
            formik.resetForm();
            toastSuccess(t("TOAST.SUCCESS.SELECTED_TAG"));
          })
          .catch((e) => {
            if (e?.response?.data?.message) {
              toastError(t(e?.response?.data?.message));
            } else {
              toastError(t("TOAST.ERROR.SELECTED_TAG"));
            }
          });
      }
    },
  });

  // Limpar o formulário e fechar o Modal
  const closeModal = () => {
    formik.resetForm();
    handleClose();
  };

  const isFormValid = formik.dirty && formik.isValid;

  return (
    <Modal open={handleOpen} handleClose={closeModal} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <LocalOfferIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.SELECT_TAG")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <Select
            formik={formik}
            label={t("TEXT.NAME")}
            name="id"
            options={optionsTags}
            disabled={tagList.length === 0}
            variant="standard"
            sx={{ textAlign: "center" }}
          />
          {formik.errors.id && formik.touched.id ? (
            <ErrorMessage>{formik.errors.id}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: "#C7252C",
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          <Button
            onClick={closeModal}
            fullWidth
            sx={{
              backgroundColor: "#FFD5D7",
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalSelectTag;
