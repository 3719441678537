import React from "react";
import { useTranslation } from "react-i18next";

import { AddToPhotos as AddToPhotosIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enUS, esES, ptBR } from "@mui/x-date-pickers/locales";
import enLocale from "date-fns/locale/en-GB";
import deLocale from "date-fns/locale/es";
import ptLocale from "date-fns/locale/pt-BR";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TOKEN_KEY } from "../../../../../auth/constants";
import AvatarIcon from "../../../../../components/AvatarIcon";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { Select, TextField, yupString } from "../../../../../components/Form";
import {
  yupNumberMin,
  yupRequiredString,
  yupRequiredStringMin,
} from "../../../../../components/Form/yup";
import Spinner from "../../../../../components/Spinner";
import Switch from "../../../../../components/Switch";
import { AuthContext } from "../../../../../contexts/AuthContext";
import { ToastContext } from "../../../../../contexts/ToastContext";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { api } from "../../../../../services/api";
import { addCoupom } from "../../../../../services/data/coupon";
import { getMacListAdmin } from "../../../../../services/data/mac";
import { getSelectPlan } from "../../../../../services/data/subscriptions";
import { getLanguage } from "../../../../../translations/languages";
import Modal from "../../../../Devices/Modal";
import { convertToDate } from "../../../../Report/Pages/GeneralInfo/parse";
import { planName } from "../../../parse";

interface ModalAddProps {
  setCouponsEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalAdd = ({
  handleOpen,
  handleClose,
  setCouponsEdited,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);
  const [startValue, setStartValue] = React.useState<Date | null>(new Date());
  const [endValue, setEndValue] = React.useState<Date | null>(new Date());
  const [pages, setPages] = React.useState<number>(1);
  const [macList, setMaclist] = React.useState<any[]>([]);
  const [loadingMacs, setLoadingMacs] = React.useState(false);
  const [notShow, setNotShow] = React.useState(false);
  const [plans, setPlans] = React.useState<any>();
  const [renderPlan, setRenderPlan] = React.useState(false);
  const [renderMac, setRenderMac] = React.useState(false);

  // Ação de alterar o status
  const [status, setStatus] = React.useState(true);
  const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.checked);
  };

  // Ação de alterar a renderização da data de expiração
  const [expDate, setExpDate] = React.useState(true);
  const handleChangeExpDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExpDate(event.target.checked);
  };

  // Ação de alterar a renderização da quantidade ilimitada
  const [ilimitDate, setIlimitDate] = React.useState(false);
  const handleChangeIlimitDate = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIlimitDate(event.target.checked);
  };

  // Ação de alterar a renderização de seleciona um Plano
  const handleChangeRenderPlan = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRenderPlan(event.target.checked);
  };

  // Ação de alterar a renderização de seleciona um endereço MAC
  const handleChangeRenderMac = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRenderMac(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      code: "",
      value: "",
      quantity: "",
      starting_quantity: null,
      mac: "",
      plan: "",
    },
    validationSchema: Yup.object({
      code: yupRequiredStringMin(6),
      value: yupNumberMin(1),
      quantity: ilimitDate === true ? yupNumberMin(1) : yupString,
      mac: !renderMac ? yupString : yupRequiredString,
      plan: !renderPlan ? yupString : yupRequiredString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const startDate =
          startValue &&
          convertToDate(startValue?.getTime(), language).replaceAll("/", "-");
        const expiresDate =
          endValue &&
          convertToDate(endValue?.getTime(), language).replaceAll("/", "-");

        const data = {
          status: status === true ? "ACTIVE" : "INACTIVE",
          starts_at: startDate,
          value: +values.value * 100,
          expires_at: expDate === true ? expiresDate : "31-12-2100",
          quantity: ilimitDate === true ? values.quantity : -1,
          code: values.code.toUpperCase(),
          starting_quantity: ilimitDate === true ? null : -1,
        };

        // Checar se o admin selecionou o switch de selecionar plano ou MAC
        const newData =
          !!renderMac && !!renderPlan
            ? { ...data, plan: values.plan, mac: values.mac }
            : renderPlan
              ? { ...data, plan: values.plan }
              : renderMac
                ? { ...data, mac: values.mac }
                : data;

        // Rota para criar cupom no modo admin
        addCoupom(newData)
          .then(() => {
            toastSuccess(t("TOAST.SUCCESS.ADD_COUPON"));
            setCouponsEdited(true);
            formik.resetForm();
            handleClose();
          })
          .catch((e) => {
            if (e?.response?.data?.messages[0].message) {
              toastError(e?.response?.data?.messages[0].message);
            } else {
              toastError(t("TOAST.ERROR.ADD_COUPON"));
            }
          });
      }
    },
  });

  // Linguagem do componente Input das datas
  const checkLanguage =
    language === "pt" ? ptLocale : language === "en" ? enLocale : deLocale;

  // Linguagem do componente Input das datas
  const checkLanguageText =
    language === "pt"
      ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
      : language === "en"
        ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
        : esES.components.MuiLocalizationProvider.defaultProps.localeText;

  // Alterar a data inicial no Input
  const handleStartDateChange = (start: Date | null) => {
    setStartValue(start);
    if (start !== null) {
      const endValueMax = new Date(start);
      endValueMax.setMonth(start.getMonth() + 1);
      const currentDate = new Date();
      if (endValueMax > currentDate) {
        endValueMax.setTime(currentDate.getTime()); // Set endValue to the current date
      }
      if (endValue !== null && endValue > endValueMax) {
        setEndValue(endValueMax);
      }
      if (endValue !== null && endValue < start) {
        setEndValue(start);
      }
    }
  };

  // Alterar a data final no Input
  const handleEndDateChange = (end: Date | null) => {
    setEndValue(end);
  };

  // Limpar o formulário e fechar o Modal
  const closeModal = () => {
    formik.resetForm();
    handleClose();
  };

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

      // Lista de planos disponíveis
      getSelectPlan().then((res) => {
        const { data } = res;
        const filterPlans = data
          .filter((f: any) => f.status === "ACTIVE" && f.hidden === false)
          .sort((a: any, b: any) => {
            return a.value_cents - b.value_cents;
          })
          .reverse();
        setPlans(filterPlans);
      });
    }
  }, [authenticated, token]);

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        if (pages > 0) {
          // Rota que retorna a lista de endereços MACs gerais para o admin
          getMacListAdmin(pages, 10, "", "").then((res) => {
            if (res?.data) {
              if (res.data.items.length === 0 || res.data.items.length < 10) {
                setNotShow(true);
              }
              const payloadData = res.data.items.filter(
                (f: any) => f.status === "REGISTERED",
              );

              // Remover endereços MACs duplicados
              const uniqueMacs = payloadData.filter(
                (item: any, index: number) =>
                  payloadData.indexOf(item) === index,
              );

              setMaclist((prevOptions: any) => [...prevOptions, ...uniqueMacs]);
              setLoadingMacs(false);
            }
          });
        }
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, pages, status, token]);

  // Lista de planos
  const optionsPlans = plans?.map((value: any, i: number) => (
    <MenuItem value={value._id} key={i}>
      <List>
        <ListItem alignItems="center">
          <ListItemAvatar sx={{ alignSelf: "center", mt: 0 }}>
            <AvatarIcon name={planName(value, language)} bgcolor={"#C7252C"} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  sx={{
                    display: "inline",
                    fontWeight: 450,
                    whiteSpace: "break-spaces",
                  }}
                >
                  {planName(value, language)}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    </MenuItem>
  ));

  // Lista de endereço MACs
  const optionsMacs = macList.map((value: any) => (
    <MenuItem value={value._id} key={value._id}>
      <List>
        <ListItem alignItems="center">
          <ListItemAvatar sx={{ alignSelf: "center", mt: 0 }}>
            <AvatarIcon name={value._id} bgcolor={"#C7252C"} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography
                  component="span"
                  sx={{
                    display: "inline",
                    fontWeight: 450,
                    whiteSpace: "break-spaces",
                  }}
                >
                  {value._id}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    </MenuItem>
  ));

  // Adicionar novos dados no select dos MACs
  const handleLoadMore = () => {
    setPages((prevPage) => prevPage + 1);
  };

  // Adicionar novos dados no select dos MACs
  const addMoreItens = (
    <MenuItem>
      <Button
        onClick={(e: any) => {
          e.stopPropagation();
          handleLoadMore();
        }}
        disabled={loadingMacs}
        fullWidth
        variant="outlined"
      >
        {loadingMacs ? <Spinner size={24} /> : "Carregar mais MACs"}
      </Button>
    </MenuItem>
  );

  // Adicionar o botão 'addMoreItems' na lista de MACs
  const allMenuItems =
    notShow === true || macList.length === 0
      ? optionsMacs
      : [...optionsMacs, addMoreItens];

  // Validação do formulário
  const isFormValid =
    !!renderMac && !!renderPlan
      ? formik.values.plan !== "" &&
        formik.values.mac !== "" &&
        formik.dirty &&
        formik.isValid
      : renderPlan
        ? formik.values.plan !== "" && formik.dirty && formik.isValid
        : renderMac
          ? formik.values.mac !== "" && formik.dirty && formik.isValid
          : formik.dirty && formik.isValid;

  return (
    <Modal
      open={handleOpen}
      handleClose={closeModal}
      width={mobile ? 340 : 600}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <AddToPhotosIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("BUTTON.ADD")} {t("TEXT.COUPON")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            onClick={(e: any) => e.stopPropagation()}
            label={t("TEXT.COUPON_CODE")}
            name="code"
            maxLength={100}
            fullWidth
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
          {formik.errors.code && formik.touched.code ? (
            <ErrorMessage>{formik.errors.code}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="flex" mt={2} mb={2}>
          <Box display="grid" width="100%">
            <FormControl fullWidth>
              <InputLabel>{t("TEXT.DISCOUNT_AMOUNT")}</InputLabel>
              <OutlinedInput
                name="value"
                type="number"
                value={formik.values.value}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                startAdornment={
                  <InputAdornment position="start">R$</InputAdornment>
                }
                label={t("TEXT.DISCOUNT_AMOUNT")}
              />
            </FormControl>
            {formik.errors.value && formik.touched.value ? (
              <ErrorMessage>{formik.errors.value}</ErrorMessage>
            ) : null}
          </Box>
          {ilimitDate && (
            <Box display="grid" ml={2} width="100%">
              <TextField
                type="number"
                formik={formik}
                disabled={false}
                onClick={(e: any) => e.stopPropagation()}
                label={t("TEXT.AMOUNT_COUPON")}
                name="quantity"
                maxLength={100}
                fullWidth
              />
            </Box>
          )}
        </Box>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={checkLanguage}
          localeText={checkLanguageText}
        >
          <Box display="flex">
            <MobileDatePicker
              label={t("TEXT.START_DATE")}
              onChange={handleStartDateChange}
              value={startValue}
              maxDate={new Date()}
              disabled={false}
              format={language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              sx={{ width: "100%" }}
            />
            {expDate === true && (
              <Box ml={2} width="100%">
                <MobileDatePicker
                  label={t("TEXT.EXPIRATION_DATE")}
                  onChange={handleEndDateChange}
                  value={endValue}
                  minDate={startValue as Date}
                  disabled={false}
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                  format={language === "en" ? "MM/dd/yyyy" : "dd/MM/yyyy"}
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
            )}
          </Box>
        </LocalizationProvider>
        <Box
          display={mobile ? "grid" : "flex"}
          mt={!!renderPlan || !!renderMac ? 2 : 0}
          mb={!!renderPlan || !!renderMac ? 2 : 0}
        >
          {!!renderPlan && (
            <Box display="grid" width="100%">
              <FormControl fullWidth>
                <Select
                  formik={formik}
                  label="Selecione o plano"
                  name="plan"
                  options={optionsPlans}
                  disabled={false}
                  sx={
                    formik.values.plan !== ""
                      ? {
                          "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              p: 0,
                            },
                          "& .css-qiwgdb": {
                            p: 0,
                          },
                        }
                      : {}
                  }
                />
              </FormControl>
            </Box>
          )}
          {!!renderMac && (
            <Box
              display="grid"
              mt={mobile && !!renderPlan ? 2 : 0}
              ml={!mobile && !!renderPlan ? 2 : 0}
              width="100%"
            >
              <Select
                formik={formik}
                label={t("TEXT.SELECT_MAC")}
                name="mac"
                options={allMenuItems}
                disabled={false}
                sx={
                  formik.values.mac !== ""
                    ? {
                        "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            p: 0,
                          },
                        "& .css-qiwgdb": {
                          p: 0,
                        },
                      }
                    : {}
                }
              />
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" ml={2} mr={2} mt={4}>
          <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ mr: 1.5 }}
                  checked={status}
                  onChange={handleChangeStatus}
                />
              }
              labelPlacement="end"
              label="Status"
            />
          </Tooltip>
          <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ mr: 1.5 }}
                  checked={expDate}
                  onChange={handleChangeExpDate}
                />
              }
              labelPlacement="end"
              label={t("TEXT.EXPIRATION_DATE")}
            />
          </Tooltip>
          <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ mr: 1.5 }}
                  checked={ilimitDate}
                  onChange={handleChangeIlimitDate}
                />
              }
              labelPlacement="end"
              label={t("TEXT.LIMITED_QUANTITY")}
            />
          </Tooltip>
        </Box>
        <Box display="flex" ml={2} mr={2} mt={4}>
          <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
            <FormControlLabel
              sx={{ mr: 3 }}
              control={
                <Switch
                  sx={{ mr: 1.5 }}
                  checked={renderPlan}
                  onChange={handleChangeRenderPlan}
                />
              }
              labelPlacement="end"
              label={t("TEXT.PLANS")}
            />
          </Tooltip>
          <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
            <FormControlLabel
              control={
                <Switch
                  sx={{ mr: 1.5 }}
                  checked={renderMac}
                  onChange={handleChangeRenderMac}
                />
              }
              labelPlacement="end"
              label={t("TEXT.MAC_ADDRESS")}
            />
          </Tooltip>
        </Box>
        <Box mt={4} display="flex" justifyContent="space-between" pl={3} pr={3}>
          <Button
            onClick={closeModal}
            variant="outlined"
            size={mobile ? "small" : "medium"}
          >
            {t("BUTTON.CANCEL")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormValid}
            size={mobile ? "small" : "medium"}
          >
            {t("BUTTON.CONFIRM")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalAdd;
