import { api } from "../api";
import {
  COUPON_ROUTE,
  editCouponByIdRoute,
  getCouponByNameRoute,
  removeCouponByIdRoute,
} from "../routes/coupon";

// Resgatar os dados de um cupom específico pelo o nome
export const getCouponByName = (coupon: string, plan: string, mac: string) =>
  api.get<any>(getCouponByNameRoute(coupon, plan, mac));

// Rota para criar cupom no modo admin
export const addCoupom = async (data: any) => api.post<any>(COUPON_ROUTE, data);

// Rota para editar um cupom por 'id' no modo Admin
export const editCouponById = (id: string, data: any) =>
  api.patch<void>(editCouponByIdRoute(id), data);

// Rota para remover um cupom por 'id' no modo Admin
export const removeCouponById = (id: string) =>
  api.delete<any>(removeCouponByIdRoute(id));
