// Validação do CPF
export function validateCPF(cpf: string) {
  // Remove non-numeric characters
  cpf = cpf.replace(/[^\d]/g, "");

  // Validate length and disallowed repeated numbers
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  let remainder;

  // Calculate the first check digit
  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  sum = 0;

  // Calculate the second check digit
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(cpf.substring(10, 11))) {
    return false;
  }

  return true;
}

// Validação do CNPJ
export function validateCNPJ(cnpj: string) {
  // Remove non-numeric characters
  cnpj = cnpj.replace(/[^\d]/g, "");

  // Validate length
  if (cnpj.length !== 14) {
    return false;
  }

  // Disallow repeated numbers like 11111111111111
  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  const calculateDigit = (cnpj: any, weight: any) => {
    let sum = 0;
    for (let i = 0; i < weight.length; i++) {
      sum += parseInt(cnpj[i]) * weight[i];
    }
    const remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  // Weights for the first and second check digits
  const weights1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  const weights2 = [6, ...weights1];

  // Calculate first check digit
  const digit1 = calculateDigit(cnpj.slice(0, 12), weights1);

  // Calculate second check digit
  const digit2 = calculateDigit(cnpj.slice(0, 12) + digit1, weights2);

  // Validate calculated digits against provided ones
  return digit1 === parseInt(cnpj[12]) && digit2 === parseInt(cnpj[13]);
}
