import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from ".";
import { TOKEN_KEY } from "../../../auth/constants";
import AvatarIcon from "../../../components/AvatarIcon";
import ErrorMessage from "../../../components/ErrorMessage";
import { TextField, yupRequiredString } from "../../../components/Form";
import MacMask from "../../../components/Masks/MacMask";
import { AuthContext } from "../../../contexts/AuthContext";
import { CurrentMqttSubsContext } from "../../../contexts/CurrentMqttSubsContext";
import { ToastContext } from "../../../contexts/ToastContext";
import { api } from "../../../services/api";
import { addDevice } from "../../../services/data/arcsys";

interface ModalAddProps {
  setDeviceEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalAdd = ({
  handleOpen,
  handleClose,
  setDeviceEdited,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated, client } = React.useContext(AuthContext);
  const { currentMqtt, setCurrentMqtt } = React.useContext(
    CurrentMqttSubsContext,
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      mac: "",
      status: "ACTIVE",
    },
    validationSchema: Yup.object({
      name: yupRequiredString,
      mac: yupRequiredString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        const data = {
          name: values.name,
          mac: values.mac.toUpperCase(),
          status: values.status,
        };

        // Adicionar Dispositivo
        addDevice(data)
          .then(() => {
            if (currentMqtt) {
              // Caso exista o campo
              if (!currentMqtt.includes(values.mac + `/EVT/#`)) {
                // Caso não esteja na lista
                client?.subscribe(values.mac + `/EVT/#`);
                setCurrentMqtt(currentMqtt + "," + values.mac + `/EVT/#`);
              }
            } else {
              // Caso não tenha lista
              client?.subscribe(values.mac + `/EVT/#`);
              setCurrentMqtt(values.mac + `/EVT/#`);
            }

            setDeviceEdited(true);
            handleClose();
            toastSuccess(t("TOAST.SUCCESS.DEVICE_ADDED"));
            formik.resetForm({ values });
          })
          .catch((err) => {
            const error = err.response.data.message;
            if (error === "TOASTS.REGISTER.MAC_EXISTS") {
              toastError(t("TOAST.ERROR.MAC_IN_USE"));
            } else if (error === "TOASTS.REGISTER.MAC_UNREGISTERED") {
              toastError(t("TOAST.ERROR.MAC_NOT_REGISTERED"));
            } else {
              toastError(t("TOAST.ERROR.DEVICE_ADDED"));
            }
          });
      }
    },
  });

  // Limpar o formulário e fechar o Modal
  const closeModal = () => {
    formik.resetForm();
    handleClose();
  };

  const isFormValid =
    formik.dirty && formik.isValid && !formik.values.mac.includes("_");

  return (
    <Modal open={handleOpen} handleClose={closeModal} width={340}>
      <Paper
        sx={{
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Box display="flex" justifyContent="center">
          <Avatar sx={{ width: 85, height: 85, boxShadow: 3 }}>
            <AvatarIcon
              name={formik.values.name ? formik.values.name : "?"}
              width={130}
              height={130}
              fontSize={70}
              bgcolor="#C7252C"
            />
          </Avatar>
        </Box>
        <Box textAlign="center" pt={2}>
          <Typography
            fontSize={22}
            fontWeight={500}
            textOverflow="ellipsis"
            overflow="hidden"
            pl={formik.values.name.length > 4 ? 1 : 0}
          >
            {formik.values.name ? formik.values.name : "..."}
          </Typography>
          <Typography fontSize={15}>
            {formik.values.mac ? formik.values.mac.toUpperCase() : "..."}
          </Typography>
        </Box>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.ADD_DEVICE")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            label={t("TEXT.NAME")}
            name="name"
            maxLength={100}
            fullWidth
          />
          {formik.errors.name && formik.touched.name ? (
            <ErrorMessage>{formik.errors.name}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            label={t("TEXT.MAC_NAME")}
            name="mac"
            fullWidth
            InputProps={{
              inputComponent: MacMask,
            }}
          />
          {formik.errors.mac && formik.touched.mac ? (
            <ErrorMessage>{formik.errors.mac}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: "#C7252C",
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          <Button
            onClick={closeModal}
            fullWidth
            sx={{
              backgroundColor: "#FFD5D7",
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalAdd;
