import React from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Replay as ReplayIcon,
  FileDownload as FileDownloadIcon,
  AddToPhotos as AddToPhotosIcon,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import { AuthContext } from "../../contexts/AuthContext";
import { scenariosById } from "../../services/data/scenarios";
import Table from "./Table";
import useIsMobile from "../../hooks/useIsMobile";
import { getModelos } from "../../services/data/modelo";
import { updateCtrl } from "../../config";
import { fileURL, printUserManualAsPDF } from "./parse";
import ModalAdd from "./ModalAdd";

const ScenariosPage = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { token, authenticated } = React.useContext(AuthContext);
  const [scenarios, setScenarios] = React.useState<any[]>([]);
  const [scenarioEdited, setScenarioEdited] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);
  const [searchedDevice, setSearchedDevice] = React.useState<string>("");
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  React.useEffect(() => {
    const fetchStatus = () => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        // Se a key 'ageonRef' do localStorage for indefinida:
        // Resgata os modelos JSON conforme a linguagem selecionada
        if (localStorage.ageonRef === undefined) {
          getModelos().then((res) => {
            if (localStorage.lang === "pt") {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model["pt-br"]),
              );
            } else if (localStorage.lang === "en") {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model.en),
              );
            } else {
              localStorage.setItem(
                "ageonRef",
                JSON.stringify(res.data.model.es),
              );
            }
            updateCtrl(localStorage.ageonRef);
          });
        } else {
          updateCtrl(localStorage.ageonRef);
        }
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  // Resgatar os Cenários do usuário
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        scenariosById(searchedDevice, pageNumber, rowsPerPage, "").then(
          (res) => {
            const { data } = res;

            setScenarios(data.items);
            setPageNumber(data.page);
            setTotalPages(data.total);
            if (res.data.items.length === 0) {
              setLoading(false);
            }
          },
        );
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, token, searchedDevice, pageNumber, rowsPerPage]);

  // Atualizar os dados caso houver edição
  React.useEffect(() => {
    if (scenarioEdited) {
      scenariosById(searchedDevice, pageNumber, rowsPerPage, "").then((res) => {
        const { data } = res;

        setScenarios(data.items);
        setTotalPages(data.total);
        if (res.data.items.length === 0) {
          setLoading(false);
        }
      });
      setScenarioEdited(false);
    }
  }, [scenarioEdited, searchedDevice, pageNumber, rowsPerPage]);

  // Ação de atualizar tabela
  const reloadTable = () => {
    scenariosById(searchedDevice, pageNumber, rowsPerPage, "").then((res) => {
      const { data } = res;
      setScenarios(data.items);
      setPageNumber(data.page);
      setTotalPages(data.total);
    });
  };

  // Ação ao alterar a página da tabela de cenários
  const handleChangePage = (value: number) => {
    setPageNumber(value + 1);
  };

  // Ações de abrir o modal de Adicionar Cenário
  const [openModalAdd, setOpenModalAdd] = React.useState<boolean>(false);
  const handleOpenModalAdd = () => setOpenModalAdd(true);
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  return (
    <Box display="grid">
      <Box overflow="auto" whiteSpace="nowrap">
        <Box display="flex" justifyContent="center">
          <Card elevation={3} sx={{ width: 1500, p: mobile ? 0 : 1, mb: 2 }}>
            <CardContent sx={{ p: mobile ? 0 : 1 }}>
              <Box display="flex" justifyContent="space-between" ml={2}>
                <Typography
                  fontSize={mobile ? 22 : 25}
                  gutterBottom
                  sx={{ mt: mobile ? 1 : 0, mb: 0 }}
                >
                  {t("TEXT.SCENARIOS")}
                </Typography>
                <Box display="flex">
                  <Box mt={mobile ? 0.4 : 0.7} mr={mobile ? 0 : 2}>
                    <Tooltip title={t("TEXT.USER_MANUAL")}>
                      {mobile ? (
                        <IconButton
                          color="warning"
                          onClick={() =>
                            printUserManualAsPDF(
                              `${t("TEXT.USER_MANUAL")}.pdf`,
                              fileURL,
                            )
                          }
                          sx={{ mt: mobile ? 0.5 : 0, mr: mobile ? 0.5 : 2 }}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      ) : (
                        <Button
                          size="small"
                          color="warning"
                          startIcon={<FileDownloadIcon />}
                          onClick={() =>
                            printUserManualAsPDF(
                              `${t("TEXT.USER_MANUAL")}.pdf`,
                              fileURL,
                            )
                          }
                          sx={{ fontSize: 13, fontWeight: 600 }}
                        >
                          {t("TEXT.USER_MANUAL")}
                        </Button>
                      )}
                    </Tooltip>
                  </Box>
                  <Box mt={mobile ? 0.4 : 0.7} mr={mobile ? 0 : 2}>
                    {mobile ? (
                      <IconButton color="warning" onClick={handleOpenModalAdd}>
                        <AddToPhotosIcon />
                      </IconButton>
                    ) : (
                      <Button
                        size="small"
                        color="warning"
                        startIcon={<AddToPhotosIcon />}
                        onClick={handleOpenModalAdd}
                        sx={{ fontSize: 13, fontWeight: 600 }}
                      >
                        {t("BUTTON.ADD")}
                      </Button>
                    )}
                  </Box>
                  <Tooltip title={t("TEXT.UPDATE")}>
                    <IconButton
                      color="warning"
                      onClick={reloadTable}
                      sx={{ mt: mobile ? 0.5 : 0 }}
                    >
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Card elevation={0}>
                <CardContent>
                  <Table
                    data={scenarios.reverse()}
                    loading={loading}
                    setLoading={setLoading}
                    setScenarioEdited={setScenarioEdited}
                    searchedDevice={searchedDevice}
                    setSearchedDevice={setSearchedDevice}
                    handleChangePage={handleChangePage}
                    pageNumber={pageNumber}
                    totalPages={totalPages}
                    setRowsPerPage={setRowsPerPage}
                  />
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Box>
      </Box>
      {openModalAdd && (
        <ModalAdd
          handleOpen={openModalAdd}
          handleClose={handleCloseModalAdd}
          setScenarioEdited={setScenarioEdited}
        />
      )}
    </Box>
  );
};

export default ScenariosPage;
