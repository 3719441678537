import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface TagsProviderProps {
  children: ReactNode;
}

interface TagsData {
  tagsList: any[];
  setTagsList: Dispatch<SetStateAction<any[]>>;
  globalTagEdited: boolean;
  setGlobalTagEdited: Dispatch<SetStateAction<boolean>>;
}

export const TagsContext = createContext({} as TagsData);

export const TagsProvider = ({ children }: TagsProviderProps) => {
  const [tagsList, setTagsList] = useState<any[]>([]);
  const [globalTagEdited, setGlobalTagEdited] = useState(false);

  const TagsProviderValue = useMemo(
    () => ({
      tagsList,
      setTagsList,
      globalTagEdited,
      setGlobalTagEdited,
    }),
    [globalTagEdited, tagsList],
  );

  return (
    <TagsContext.Provider value={TagsProviderValue}>
      {children}
    </TagsContext.Provider>
  );
};
