import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { TextField } from "../../../Form";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../hooks/useIsMobile";
import React from "react";
import ModalStatus from "../ModalQuestions/ModalStatus";
import ModalFiring from "../ModalQuestions/ModalFiring";
import ModalShots from "../ModalQuestions/ModalShots";
import ErrorMessage from "../../../ErrorMessage";
import ModalError from "../ModalQuestions/ModalError";
import Switch from "../../../Switch";

interface StepThreeProps {
  formik: any;
  active: string;
  singleShot: string;
  handleChangeActive: (e: any) => void;
  handleChangeSingleShot: (e: any) => void;
  handleChangeDisableError: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  setIntervalTypeHour: (interval: boolean) => void;
  timeUnit: string;
  setTimeUnit: (e: string) => void;
}

const StepThree = ({
  formik,
  active,
  singleShot,
  handleChangeActive,
  handleChangeSingleShot,
  handleChangeDisableError,
  checked,
  setIntervalTypeHour,
  timeUnit,
  setTimeUnit,
}: StepThreeProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  const handleTimeUnitChange = (event: any) => {
    setTimeUnit(event.target.value);
    const currentValue = formik.values.hysteresis;
    if (event.target.value === "hours") {
      formik.setFieldValue(
        "hysteresis",
        Math.trunc(parseInt(currentValue) / 60).toString(),
      );
      setIntervalTypeHour(true);
    } else {
      setIntervalTypeHour(false);
      formik.setFieldValue("hysteresis", currentValue * 60);
    }
  };

  // Ações de abrir o modal de dúvidas sobre o 'Status do Cenário'
  const [openModalQuestionStatus, setOpenModalQuestionStatus] =
    React.useState<boolean>(false);
  const handleOpenModalQuestionStatus = () => setOpenModalQuestionStatus(true);
  const handleCloseModalQuestionStatus = () =>
    setOpenModalQuestionStatus(false);

  // Ações de abrir o modal de dúvidas sobre a 'Frequência de Despero'
  const [openModalQuestionFiring, setOpenModalQuestionFiring] =
    React.useState<boolean>(false);
  const handleOpenModalQuestionFiring = () => setOpenModalQuestionFiring(true);
  const handleCloseModalQuestionFiring = () =>
    setOpenModalQuestionFiring(false);

  // Ações de abrir o modal de dúvidas sobre a 'Intervalo entre disparos'
  const [openModalQuestionShots, setOpenModalQuestionShots] =
    React.useState<boolean>(false);
  const handleOpenModalQuestionShots = () => setOpenModalQuestionShots(true);
  const handleCloseModalQuestionShots = () => setOpenModalQuestionShots(false);

  // Ações de abrir o modal de dúvidas sobre 'Desabilitar após o erro'
  const [openModalSwitch, setOpenModalSwitch] = React.useState<boolean>(false);
  const handleOpenModalSwitch = () => setOpenModalSwitch(true);
  const handleCloseModalSwitch = () => setOpenModalSwitch(false);

  return (
    <Box
      sx={{
        maxHeight: mobile ? 400 : window.screen.width < 1550 ? 300 : "100%",
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >
      <Box display="flex">
        <FormLabel sx={{ mt: 1 }}>{t("TEXT.SCENARIOS_STATUS")}</FormLabel>
        <Box>
          <IconButton
            color="primary"
            onClick={handleOpenModalQuestionStatus}
            sx={{ pl: 1 }}
          >
            <HelpOutlineIcon color="info" />
          </IconButton>
        </Box>
      </Box>
      <RadioGroup row value={active} onChange={handleChangeActive}>
        <FormControlLabel
          value="true"
          control={<Radio />}
          label={t("TEXT.ACTIVE")}
        />
        <FormControlLabel
          value="false"
          control={<Radio />}
          label={t("TEXT.INACTIVE")}
        />
      </RadioGroup>
      <Box display="flex" mt={1}>
        <FormLabel sx={{ mt: 1 }}>{t("TEXT.FIRING_FREQUENCY")}</FormLabel>
        <Box>
          <IconButton
            color="primary"
            onClick={handleOpenModalQuestionFiring}
            sx={{ pl: 1 }}
          >
            <HelpOutlineIcon color="info" />
          </IconButton>
        </Box>
      </Box>
      <RadioGroup row value={singleShot} onChange={handleChangeSingleShot}>
        <FormControlLabel
          value="0"
          control={<Radio />}
          label={t("TEXT.SINGLE_SHOT")}
        />
        <FormControlLabel
          value="2"
          control={<Radio />}
          label={t("TEXT.DAILY_SHOT")}
        />
        <FormControlLabel
          value="1"
          control={<Radio />}
          label={t("TEXT.CONTINUOUS_SHOOTING")}
        />
      </RadioGroup>
      {singleShot === "1" && (
        <Box
          border="1px solid lightGray"
          borderRadius="10px"
          p={1.5}
          pt={1}
          display="grid"
          width={mobile ? "90%" : "100%"}
        >
          <Box display="flex" mt={1}>
            <FormLabel sx={{ mt: 1 }}>
              {t("TEXT.INTERVAL_BETWEEN_SHOTS")}
            </FormLabel>
            <Box>
              <IconButton
                color="primary"
                onClick={handleOpenModalQuestionShots}
                sx={{ pl: 1 }}
              >
                <HelpOutlineIcon color="info" />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mt={1} gap={2}>
            <TextField
              formik={formik}
              disabled={false}
              label={t("TEXT.INTERVAL")}
              name="hysteresis"
              type="number"
              fullWidth
              sx={{ flex: 1 }}
            />
            <FormControl sx={{ width: "50%" }}>
              <InputLabel id="time-unit-label">{t("TEXT.TYPE")}</InputLabel>
              <Select
                labelId="time-unit-label"
                value={timeUnit}
                onChange={handleTimeUnitChange}
                label={t("TEXT.TYPE")}
              >
                <MenuItem value={"minutes"}>{t("TEXT.MINUTES")}</MenuItem>
                <MenuItem value={"hours"}>{t("TEXT.HOURS")}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {formik.errors.hysteresis && formik.touched.hysteresis ? (
            <Box>
              <ErrorMessage>{formik.errors.hysteresis}</ErrorMessage>
              {formik.errors.hysteresis === "ERROR.VALUE_NOT_ALLOWED" && (
                <Typography fontSize={14} ml={0.5} mt={-0.1} color="error">
                  {timeUnit === "hours"
                    ? `(${t("TEXT.MINIMUM").toLowerCase()}: 1 h, ${t(
                        "TEXT.MAXIMUM",
                      ).toLowerCase()}: 24 h)`
                    : `(${t("TEXT.MINIMUM").toLowerCase()}: 5 min, ${t(
                        "TEXT.MAXIMUM",
                      ).toLowerCase()}: 1440 min)`}
                </Typography>
              )}
            </Box>
          ) : null}
        </Box>
      )}
      {singleShot !== "0" && (
        <Box mt={1}>
          <Box display="flex" mt={1}>
            <FormLabel sx={{ mt: 1 }}>
              {t("TEXT.DISABLE_AFTER_ERROR")}
            </FormLabel>
            <Box>
              <IconButton
                color="primary"
                onClick={handleOpenModalSwitch}
                sx={{ pl: 1 }}
              >
                <HelpOutlineIcon color="info" />
              </IconButton>
            </Box>
          </Box>
          <FormControlLabel
            sx={{ ml: 0.2, width: 208, mr: 0 }}
            control={
              <Switch
                name="disable_on_error"
                checked={checked}
                onChange={handleChangeDisableError}
                sx={{ mr: 1 }}
              />
            }
            label={checked ? t("TEXT.ACTIVE") : t("TEXT.INACTIVE")}
          />
        </Box>
      )}
      <ModalStatus
        handleOpen={openModalQuestionStatus}
        handleClose={handleCloseModalQuestionStatus}
      />
      <ModalFiring
        handleOpen={openModalQuestionFiring}
        handleClose={handleCloseModalQuestionFiring}
      />
      <ModalShots
        handleOpen={openModalQuestionShots}
        handleClose={handleCloseModalQuestionShots}
      />
      <ModalError
        handleOpen={openModalSwitch}
        handleClose={handleCloseModalSwitch}
      />
    </Box>
  );
};

export default StepThree;
