import { LanguageCode } from "../../translations/types";

// Converter timestamp em data DD/MM/AAAA hh:mm
export const convertToDateTime = (
  timestamp: number,
  language: LanguageCode,
) => {
  const fullDate = new Date(timestamp).toLocaleDateString(
    language === "en" ? "en-US" : "pt-BR",
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    },
  );
  return fullDate;
};

// Converter vencimento do pagamento pendente em data de acordo com o timezone
export const convertToDatePaymentPending = (
  date: string,
  language: LanguageCode,
) => {
  const getDate = date.split("-").reverse();
  const day = getDate[0].split("T")[0];
  const month = getDate[1];
  const year = getDate[2];

  const fullDate =
    language === "en" ? `${month}/${day}/${year}` : `${day}/${month}/${year}`;
  return fullDate;
};

// Data de expiração do Plano na linguagem 'en'
export const enExpiresDate = (expiresDate: string) => {
  const expiresData = expiresDate?.split("/");

  const payload =
    expiresData && `${expiresData[1]}/${expiresData[0]}/${expiresData[2]}`;

  return payload;
};

// Formatação dos valores dos Planos
export const formatValueCents = (text: string) =>
  text?.length === 5
    ? `R$: ${text[0]}${text[1]}${text[2]},${text[3]}${text[4]}`
    : text?.length === 4
      ? `R$: ${text[0]}${text[1]},${text[2]}${text[3]}`
      : text?.length === 3
        ? `R$: ${text[0]},${text[1]}${text[2]}`
        : `R$: ${text}`;

// Formatação dos valores dos Planos Disponiveis
export const formatValueCentsAvailablePlans = (text: string) =>
  text?.length === 5
    ? `${text[0]}${text[1]}${text[2]},${text[3]}${text[4]}`
    : text?.length === 4
      ? `${text[0]}${text[1]},${text[2]}${text[3]}`
      : text?.length === 3
        ? `${text[0]},${text[1]}${text[2]}`
        : `${text}`;

// Resgatar o nome do Plano de acordo com a linguagem selecionada
export const selectNameByTranslations = (
  filterSubscriptionsActive: any,
  language: string,
) =>
  language === "es"
    ? filterSubscriptionsActive?.plan?.data[2]?.name
    : language === "en"
      ? filterSubscriptionsActive?.plan?.data[1]?.name
      : filterSubscriptionsActive?.plan?.data[0]?.name;

// Resgatar o nome do Plano de acordo com a linguagem selecionada
export const selectNameByTranslationsDetails = (
  filterSubscriptionsActive: any,
  language: string,
) =>
  language === "es"
    ? filterSubscriptionsActive?.planData[2].name
    : language === "en"
      ? filterSubscriptionsActive?.planData[1].name
      : filterSubscriptionsActive?.planData[0].name;

// Resgatar o nome do Plano de acordo com a linguagem selecionada
export const planName = (p: any, language: LanguageCode) =>
  language === "es"
    ? p && p?.data[2].name
    : language === "en"
      ? p?.data[1].name
      : p?.data[0].name;

// Resgatar os benefícios de acordo com a linguagem selecionada
export const benefits = (p: any, language: LanguageCode) =>
  language === "es"
    ? p && p?.data[2].benefits
    : language === "en"
      ? p?.data[1].benefits
      : p?.data[0].benefits;

// Resgatar os nomes dos Planos com pagamento pendentes
export const planNamePending = (plan_name: any, language: LanguageCode) =>
  language === "es"
    ? plan_name && plan_name[2]?.name
    : language === "en"
      ? plan_name[1]?.name
      : plan_name[0]?.name;

// Converter uma data DD/MM/YYYY em timestamp
export const convertToDateValue = (dateString: string, language: string) => {
  //  Convert a "dd/MM/yyyy" string into a Date object
  const d = dateString?.split("/");
  const dat =
    d &&
    new Date(
      language === "en"
        ? d[2] + "/" + d[0] + "/" + d[1]
        : d[2] + "/" + d[1] + "/" + d[0],
    ).getTime();
  return dat;
};

// Checar o tipo de pesquisa
export const checkedQueryType = (
  deviceOption: number,
  searchedDevice: any,
  queryType: string,
) =>
  deviceOption === 0 && searchedDevice === ""
    ? "device"
    : deviceOption !== 0 && searchedDevice === ""
      ? ""
      : queryType;

// Filtrar status e pesquisa dos planos
export const queryTypeOptions = (
  deviceOption: number,
  searchedDevice: any,
  queryType: string,
) =>
  deviceOption === 0
    ? checkedQueryType(deviceOption, searchedDevice, queryType)
    : deviceOption === 1
      ? `ACTIVE-${checkedQueryType(deviceOption, searchedDevice, queryType)}`
      : deviceOption === 2
        ? `FREE-${checkedQueryType(deviceOption, searchedDevice, queryType)}`
        : deviceOption === 3
          ? `TOEXPIRE-${checkedQueryType(deviceOption, searchedDevice, queryType)}`
          : `AVAILABLE-${checkedQueryType(deviceOption, searchedDevice, queryType)}`;
