String.prototype.leftZeroPad = function (size) {
  const numZeros = size;
  const asNumber = parseInt(this as string);
  const n = Math.abs(asNumber);
  const zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
  let zeroString = Math.pow(10, zeros).toString().substr(1);
  if (asNumber < 0) {
    zeroString = "-" + zeroString;
  }
  return zeroString + n;
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.contains = function (string) {
  return this.indexOf(string) >= 0;
};

String.prototype.beginsWith = function (string) {
  return this.indexOf(string) === 0;
};

String.prototype.isEmpty = function () {
  return this.length === 0;
};

String.prototype.isNumber = function () {
  return !isNaN(parseInt(this as string));
};

String.prototype.isBoolean = function () {
  return this === "true" || this === "false";
};

String.prototype.shuffle = function () {
  return this.split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");
};

String.prototype.removeAccents = function () {
  let r = this.toLowerCase();
  r = r.replace(new RegExp("\\s", "g"), "");
  r = r.replace(new RegExp("[àáâãäå]", "g"), "a");
  r = r.replace(new RegExp("æ", "g"), "ae");
  r = r.replace(new RegExp("ç", "g"), "c");
  r = r.replace(new RegExp("[èéêë]", "g"), "e");
  r = r.replace(new RegExp("[ìíîï]", "g"), "i");
  r = r.replace(new RegExp("ñ", "g"), "n");
  r = r.replace(new RegExp("[òóôõö]", "g"), "o");
  r = r.replace(new RegExp("œ", "g"), "oe");
  r = r.replace(new RegExp("[ùúûü]", "g"), "u");
  r = r.replace(new RegExp("[ýÿ]", "g"), "y");
  return r;
};

String.prototype.reverse = function () {
  return this.split("").reverse().join("");
};

String.prototype.toHex = function () {
  let hex = "";
  for (let i = 0; i < this.length; i++) {
    hex += "" + this.charCodeAt(i).toString(16);
  }
  return hex;
};

String.prototype.removeSpaces = function () {
  return this.replace(/\s+/g, "");
};

String.prototype.removePunctuation = function () {
  return this.replace(/[^\w\s]/g, "");
};

export {};
