import { PaletteMode, createTheme } from "@mui/material";
import { yellow } from "@mui/material/colors";

// Tema geral do Cloud
export const theme = (mode: PaletteMode | undefined) =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: "#C7252C",
      },
      secondary: {
        main: yellow[800],
      },
      background: {
        default: mode === "light" ? "#FFFF" : "#121212",
      },
    },
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 2,
          sx: {
            borderRadius: 3,
          },
        },
      },
      MuiAppBar: {
        defaultProps: {
          color: mode === "light" ? "inherit" : "default",
        },
      },
    },
  });

export default theme;
