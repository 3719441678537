import React from "react";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import useIsMobile from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { getSelectPlan } from "../../services/data/subscriptions";
import { AuthContext } from "../../contexts/AuthContext";
import { TOKEN_KEY } from "../../auth/constants";
import { api } from "../../services/api";
import Spinner from "../../components/Spinner";
import { getLanguage } from "../../translations/languages";
import {
  benefits,
  enExpiresDate,
  formatValueCentsAvailablePlans,
  planName,
} from "./parse";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import ModalBenefits from "./Modal/ModalBenefits";
import { getDevice } from "../../services/data/arcsys";
import { useParams } from "react-router-dom";
import AvatarIcon from "../../components/AvatarIcon";
import ModalSelectPlan from "./Modal/ModalSelectPlan";
import { convertToDate } from "../Report/Pages/GeneralInfo/parse";
import "./index.css";

const PlansAvailable = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const params = useParams();
  const language = getLanguage().code;
  const { token, authenticated } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const [loading, setLoading] = React.useState(true);
  const [plans, setPlans] = React.useState<any>();
  const [device, setDevice] = React.useState<any>();
  const [openModal, setOpenModal] = React.useState(false);
  const [planValue, setPlanValue] = React.useState<any>();
  const [openModalChange, setOpenModalChange] = React.useState(false);
  const [planChangeValue, setChangePlanValue] = React.useState<any>();
  const mac = params.mac ?? "";

  // Modal dos benefícios
  const handleOpenModal = (p: any) => {
    setOpenModal(true);
    setPlanValue({ benefits: p });
  };
  const handleCloseModal = () => setOpenModal(false);

  // Modal alterar o Plano
  const handleOpenModalChange = (p: any) => {
    setOpenModalChange(true);
    setChangePlanValue({ changePlan: p });
  };
  const handleCloseModalChange = () => setOpenModalChange(false);

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

      // Lista de planos disponíveis
      getSelectPlan().then((res) => {
        const { data } = res;
        const filterPlans = data
          .filter((f: any) => f.status === "ACTIVE" && f.hidden === false)
          .sort((a: any, b: any) => {
            return a.value_cents - b.value_cents;
          })
          .reverse();
        setPlans(filterPlans);
      });

      // Resgatar dados gerais do Dispositivo
      getDevice(mac).then((res) => {
        setDevice(res.data);
      });
    }
  }, [authenticated, mac, token]);

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (plans && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [plans, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  // Id do plano atual
  const planId =
    device &&
    device?.subscriptions
      .filter((f: any) => f.status === "ACTIVE" && f.user_id === device.ownerId)
      ?.reverse()[0]?.plan._id;

  // Dados do Plano atual
  const currentPlan =
    device &&
    device?.subscriptions
      .filter((f: any) => f.status === "ACTIVE" && f.user_id === device.ownerId)
      ?.reverse()[0]?.plan;

  // Data de expiração do Plano atual
  const expiresDate = React.useMemo(
    () =>
      device?.subscriptions
        .filter(
          (f: any) => f.status === "ACTIVE" && f.user_id === device?.ownerId,
        )
        ?.reverse()[0]
        ?.expires_at?.replace("-", "/")
        ?.replace("-", "/")
        .toString(),
    [device?.ownerId, device?.subscriptions],
  );

  // Alterar o formato da data de expiração de acordo com a linguagem
  const convertedExpiresDate =
    language !== "en" ? expiresDate : enExpiresDate(expiresDate);

  return (
    <Grid
      container
      sx={{
        placeContent: "center",
      }}
    >
      <Box m={3.5} mt={0}>
        <Box position="sticky" top="125px">
          {device && (
            <Card sx={{ mt: 0 }}>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    pt: 3.5,
                    pb: 3.5,
                    pl: 5,
                    pr: 5,
                    borderRadius: 4,
                    mb: 2.4,
                    backgroundColor: "#FFD5D7",
                  }}
                >
                  <Avatar
                    sx={{
                      width: 120,
                      height: 120,
                      boxShadow: 3,
                      ml: 4,
                      mr: 4,
                    }}
                  >
                    <AvatarIcon
                      name={device.name}
                      width={120}
                      height={120}
                      fontSize={70}
                      bgcolor={"#C7252C"}
                    />
                  </Avatar>
                </Box>
                <Box mt={2} textAlign="center">
                  <Typography
                    fontSize={25}
                    fontWeight={500}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    width={260}
                  >
                    {device.name}
                  </Typography>
                  <Box display="flex" justifyContent="center" mt={0.5} mb={0.5}>
                    <Typography fontWeight={600} fontSize={14}>
                      MAC:
                    </Typography>
                    <Typography ml={0.6} fontSize={14}>
                      {device.mac}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
              <Divider />
              <CardContent>
                <Box>
                  <Box display="flex" justifyContent="center" mt={0.5} mb={0.5}>
                    <Typography fontWeight={500}>{t("TEXT.PLAN")}:</Typography>
                    <Typography ml={0.6}>
                      {planName(currentPlan, language)}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" mt={0.5} mb={0.5}>
                    <Typography fontWeight={500}>
                      {t("TEXT.ACCESSION_DATE")}:
                    </Typography>
                    <Typography ml={0.6}>
                      {convertToDate(
                        device?.subscriptions
                          .filter(
                            (f: any) =>
                              f.status === "ACTIVE" &&
                              f.user_id === device?.ownerId,
                          )
                          .reverse()[0]?.created_at,
                        language,
                      )}
                    </Typography>
                  </Box>
                  {currentPlan?.identifier?.includes("free") && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      mt={0.5}
                      mb={0.5}
                    >
                      <Typography fontWeight={500}>
                        {t("TEXT.EXPIRATION_DATE")}:
                      </Typography>
                      <Typography ml={0.6}>{convertedExpiresDate}</Typography>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          )}
        </Box>
      </Box>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Box display="grid">
          <Box overflow="auto" whiteSpace="nowrap">
            <Box display="flex" justifyContent="center">
              <Box
                className="wrapper"
                sx={{
                  fontFamily: "'Poppins', sans-serif",
                  position: "relative",
                  pt: "30px",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ placeContent: "center" }}
                  ml={2}
                  mr={2}
                  mb={3}
                >
                  <Typography
                    gutterBottom
                    variant={mobile ? "h5" : "h4"}
                    fontWeight={600}
                    sx={{
                      mb: 3,
                      fontFamily: "'Poppins', sans-serif",
                    }}
                  >
                    {t("TEXT.PLANS_AVAILABLE")}
                  </Typography>
                </Box>
                {loading ? (
                  <Box m="126px 0px">
                    <Spinner />
                  </Box>
                ) : (
                  plans &&
                  plans && (
                    <Grid container spacing={1} sx={{ placeContent: "center" }}>
                      {plans.map((m: any, i: number) => (
                        <Grid key={i}>
                          <Paper
                            className={
                              m?.value_cents < 10001
                                ? "table free"
                                : m?.value_cents > 9999 &&
                                    m?.value_cents < 20000
                                  ? "table standard"
                                  : "table premium"
                            }
                            sx={{
                              width: mobile ? 300 : 350,
                              minHeight: 530,
                              m: 2,
                              borderRadius: 3,
                              "&:hover":
                                theme && theme === "light"
                                  ? {
                                      boxShadow:
                                        "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                                    }
                                  : theme && theme === "dark"
                                    ? {
                                        boxShadow:
                                          "0px 1px 2px 0px rgba(175, 175, 175, 0.8), 1px 2px 4px 0px rgba(175, 175, 175, 0.8), 2px 4px 8px 0px rgba(175, 175, 175, 0.8), 2px 4px 16px 0px rgba(175, 175, 175, 0.8)",
                                      }
                                    : null,
                            }}
                            elevation={4}
                          >
                            <Box
                              className={
                                m?.value_cents < 10001
                                  ? "ribbonfree"
                                  : m?.value_cents > 9999 &&
                                      m?.value_cents < 20000
                                    ? "ribbonstandard"
                                    : "ribbon"
                              }
                            >
                              <Box component="span">
                                {m.interval === 1
                                  ? `${m.interval} ${t("TEXT.MONTH")}`
                                  : `${m.interval} ${t("TEXT.MONTHS")}`}
                              </Box>
                            </Box>
                            <Box className="price-section">
                              <Box className="price-area">
                                <Box className="inner-area">
                                  {m?.value_cents > 0 && (
                                    <Box component="span" className="text">
                                      R$
                                    </Box>
                                  )}
                                  <Box component="span" className="price">
                                    {m?.value_cents === 0
                                      ? t("TEXT.FREE")
                                      : formatValueCentsAvailablePlans(
                                          m?.value_cents.toString(),
                                        )}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="package-name">
                              <Typography
                                sx={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  fontSize: "25px",
                                  fontWeight: 500,
                                  background:
                                    theme === "light" ? "#fff" : "#252525",
                                  padding: "0 15px",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                {planName(m, language)}
                              </Typography>
                            </Box>
                            {planId === m._id && (
                              <Typography
                                sx={{
                                  mb: 1,
                                  textAlign: "center",
                                  color:
                                    theme === "light"
                                      ? "rgb(108, 115, 127)"
                                      : "#FFFF",
                                }}
                              >
                                ({t("TEXT.CURRENT_PLAN")})
                              </Typography>
                            )}
                            <List
                              sx={{
                                mt: planId === m._id ? 0 : 5.5,
                                pl: mobile ? 1.5 : 1,
                                pr: mobile ? 1.5 : 0,
                              }}
                            >
                              {benefits(m, language)
                                ?.slice(0, 3)
                                .map((b: any, i: number) => (
                                  <ListItem key={i}>
                                    <ListItemIcon sx={{ minWidth: "35px" }}>
                                      <CheckIcon color="success" />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={b}
                                      primaryTypographyProps={{
                                        variant: "subtitle2",
                                        style: {
                                          width: mobile ? 210 : 270,
                                          whiteSpace: "normal",
                                          overflow: "hidden",
                                          textOverflow:
                                            benefits(m, language).length > 3
                                              ? "ellipsis"
                                              : "none",
                                        },
                                      }}
                                    />
                                  </ListItem>
                                ))}
                            </List>
                            <Box alignSelf="end">
                              {benefits(m, language).length > 3 && (
                                <Box display="flex" justifyContent="center">
                                  <Button
                                    sx={{ p: 0, fontSize: 12 }}
                                    onClick={() =>
                                      handleOpenModal(benefits(m, language))
                                    }
                                  >
                                    {t("TEXT.SEE_ALL_BENEFITS")}
                                  </Button>
                                </Box>
                              )}
                            </Box>
                            {planId !== m._id && (
                              <Box className="btn">
                                {(currentPlan?.value_cents === 0 ||
                                  m?.value_cents !== 0) && (
                                  <Button
                                    fullWidth
                                    onClick={() => handleOpenModalChange(m)}
                                    variant="contained"
                                    sx={{ borderRadius: 3 }}
                                  >
                                    {t("TEXT.CHOOSE")}
                                  </Button>
                                )}
                              </Box>
                            )}
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  )
                )}
              </Box>
            </Box>
          </Box>
          {planValue && (
            <ModalBenefits
              benefits={planValue.benefits}
              handleClose={handleCloseModal}
              handleOpen={openModal}
            />
          )}
          {planChangeValue && (
            <ModalSelectPlan
              currentPlan={currentPlan}
              changePlan={planChangeValue.changePlan}
              handleClose={handleCloseModalChange}
              handleOpen={openModalChange}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default PlansAvailable;
