import { Warning as WarningIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../hooks/useIsMobile";
import Modal from "../../Devices/Modal";

interface ModalHelpTagsProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalHelpTags = ({ handleOpen, handleClose }: ModalHelpTagsProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 350 : 500}
    >
      <Box
        sx={{
          maxHeight: mobile ? 500 : window.screen.width < 1550 ? 300 : "100%",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 3.5,
            pb: 3.5,
            borderRadius: 4,
            mb: 2,
            backgroundColor: "#FFD5D7",
          }}
          elevation={0}
        >
          <Avatar
            sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
          >
            <WarningIcon sx={{ fontSize: 65 }} />
          </Avatar>
        </Paper>
        <Typography fontSize={21} fontWeight={700} mb={1}>
          {t("TEXT.TAG_FILTER")}
        </Typography>
        <Paper
          sx={{
            backgroundColor: "#FFF4E4",
            p: 4,
            mt: 1,
            borderRadius: 3,
          }}
          elevation={0}
        >
          <Typography mb={3} fontSize={16} fontWeight={600}>
            {t("TEXT.TAG_MODAL_TITLE_DASHBOARD")}
          </Typography>
          <Typography whiteSpace="break-spaces">
            {t("TEXT.TAG_MODAL_STEP_TITLE")}:
          </Typography>
          <Stepper orientation="vertical" sx={{ ml: mobile ? 0 : 4 }}>
            <Step active>
              <StepLabel sx={{ p: 0, pt: 1 }}>
                <Typography>{t("TEXT.TAG_MODAL_STEP_SUBTITLE")}</Typography>
              </StepLabel>
            </Step>
            <Step active>
              <StepLabel sx={{ p: 0 }}>
                <Typography>{t("TEXT.TAG_MODAL_STEP_SUBTITLE_2")}</Typography>
              </StepLabel>
            </Step>
          </Stepper>
          <Typography mt={3}>
            {t("TEXT.TAG_MODAL_SUBTITLE_DASHBOARD")}
          </Typography>
        </Paper>
      </Box>
      <Box mt={2} textAlign="center">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#FFD5D7",
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalHelpTags;
