import React from "react";
import { useTranslation } from "react-i18next";

import { Delete as DeleteIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";

import { ToastContext } from "../../../contexts/ToastContext";
import Modal from "../../Devices/Modal";
import { filteredNameByMac } from "../parse";

interface ModalRemoveDeviceAppliedProps {
  handleOpen: boolean;
  handleClose: () => void;
  setAppliedCleaned: (e: boolean) => void;
  modalRemoveValue: any;
  devices: any;
}

const ModalRemoveDeviceApplied = ({
  handleOpen,
  handleClose,
  setAppliedCleaned,
  modalRemoveValue,
  devices,
}: ModalRemoveDeviceAppliedProps) => {
  const { t } = useTranslation();
  const { toastSuccess } = React.useContext(ToastContext);

  // Ação de remover controlador associado
  const handleClearApply = () => {
    toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
    setAppliedCleaned(true);
    handleClose();
  };

  // Filtrar controlador associado que está apontado para ser removido
  const ctrlAssociated =
    typeof modalRemoveValue === "string"
      ? modalRemoveValue
      : `${modalRemoveValue.name} - ${
          modalRemoveValue._id.split("/")[1].split("L")[1]
        } - ${filteredNameByMac(modalRemoveValue._id.split("/")[0], devices)}`;

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={350}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <DeleteIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={17.5} fontWeight={700} mb={1}>
        {t("TEXT.REMOVE_ASSOCIATED_CTRL")}?
      </Typography>
      <Typography variant="body2">
        {t("TEXT.REMOVE_ASSOCIATED_CTRL_MESSAGE")}
        <strong>{` ${ctrlAssociated}`}</strong>?
      </Typography>
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={() => handleClearApply()}
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: "#C7252C",
          fontSize: 12,
          pt: 1,
          pb: 1,
        }}
      >
        {t("TEXT.REMOVE")}
      </Button>
      <Button
        onClick={handleClose}
        fullWidth
        sx={{
          backgroundColor: "#FFD5D7",
          mt: 1,
          borderRadius: 2,
          fontSize: 12,
          pt: 1,
          pb: 1,
          color: "black",
        }}
      >
        {t("BUTTON.CANCEL")}
      </Button>
    </Modal>
  );
};

export default ModalRemoveDeviceApplied;
