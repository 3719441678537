import React from "react";

import {
  Alert,
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import {
  HelpOutline as HelpOutlineIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Modal from "../../../Devices/Modal";
import useIsMobile from "../../../../hooks/useIsMobile";
import { sincronizeSector } from "../../../../services/data/sector";
import { ToastContext } from "../../../../contexts/ToastContext";
import { getCtrlConfig } from "../../../../components/Controller/parser";
import { getRawCtrlConfig } from "../../../../config";
import Spinner from "../../../../components/Spinner";

interface ModalSaveSyncProps {
  handleOpen: boolean;
  handleClose: () => void;
  isSubmitting: boolean;
  modalSyncValue?: string;
  startIntervalSubmit?: (e: string) => void;
  setSectorEdited?: (e: boolean) => void;
  dataEdited?: any;
}

const ModalSaveSync = ({
  handleOpen,
  handleClose,
  isSubmitting,
  modalSyncValue,
  startIntervalSubmit,
  setSectorEdited,
  dataEdited,
}: ModalSaveSyncProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { toastSuccess, toastError } = React.useContext(ToastContext);

  // Ação de sincronizar setor
  const handleSync = () => {
    if (modalSyncValue && startIntervalSubmit) {
      startIntervalSubmit(modalSyncValue);
    }
    if (dataEdited && modalSyncValue) {
      sincronizeSector(modalSyncValue, dataEdited).catch((err) => {
        const error = err.response.status;
        // Sinalizar que a sincronização está em andamento
        if (error === 400) {
          toastError(t("TEXT.SYNC_IN_PROGRESS"));
        }
      });
    }
    handleClose();
  };

  const handleCloseModal = () => {
    toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
    if (setSectorEdited) {
      setSectorEdited(true);
    }
    handleClose();
  };

  // Resgatar o payload do setpoints do Dispositivo selecionado
  const ctrlConfig = getCtrlConfig(dataEdited?.sector_model);
  const rawCtrlConfig = getRawCtrlConfig(dataEdited?.sector_model);
  const setpoints = ctrlConfig.setpoints;
  const actualSetpoint =
    setpoints[+dataEdited?.default_resource.split("/")[1].split("s")[1]];
  const setpointName = actualSetpoint?.description;
  const max = dataEdited?.resource_limit[dataEdited?.default_resource]?.max;
  const min = dataEdited?.resource_limit[dataEdited?.default_resource]?.min;
  const unit = actualSetpoint?.unitOfMeasurement;
  const value = dataEdited?.resource_value[dataEdited?.default_resource];
  const minLabel =
    rawCtrlConfig?.detalhamento[
      String(actualSetpoint?.min).split("detalhamento")[1]
    ]?.[0] ?? "";
  const maxLabel =
    rawCtrlConfig?.detalhamento[
      String(actualSetpoint?.max).split("detalhamento")[1]
    ]?.[0] ?? "";

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 350 : 550}
    >
      <Box
        sx={{
          maxHeight: mobile ? 650 : window.screen.width < 1600 ? 500 : 700,
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 3.5,
            pb: 3.5,
            borderRadius: 4,
            mb: 2,
            backgroundColor: "#FFD5D7",
          }}
          elevation={0}
        >
          <Avatar
            sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
          >
            <HelpOutlineIcon sx={{ fontSize: 65 }} />
          </Avatar>
        </Paper>
        <Typography fontSize={21} fontWeight={700} mb={1}>
          {t("BUTTON.SYNCHRONIZE")}
        </Typography>
        <Box
          sx={{
            maxHeight: mobile ? 350 : window.screen.width < 1600 ? 300 : 1200,
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <Alert severity="info" sx={{ mt: 2, mb: 2, alignItems: "center" }}>
            <Typography>
              <strong style={{ paddingRight: "5px" }}>
                {t("TEXT.SAVE_AND_SYNC_MESSAGE_0")}:
              </strong>
              {t("TEXT.SAVE_AND_SYNC_MESSAGE")}
            </Typography>
            <List sx={{ pb: 0 }}>
              {unit !== " Hz" && (
                <>
                  {setpointName && value && unit ? (
                    <ListItem sx={{ p: 0, pl: mobile ? 0 : 1 }}>
                      <ListItemIcon sx={{ mr: -3 }}>
                        <KeyboardArrowRightIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${setpointName}: ${
                          isNaN(value) ? "" : value
                        } ${unit}`}
                      />
                    </ListItem>
                  ) : (
                    <Spinner />
                  )}
                </>
              )}
              {maxLabel && max && unit && (
                <ListItem sx={{ p: 0, pl: mobile ? 0 : 1 }}>
                  <ListItemIcon sx={{ mr: -3 }}>
                    <KeyboardArrowRightIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={`${maxLabel}: ${max} ${unit}`} />
                </ListItem>
              )}
              {minLabel && min && unit && (
                <ListItem sx={{ p: 0, pl: mobile ? 0 : 1 }}>
                  <ListItemIcon sx={{ mr: -3 }}>
                    <KeyboardArrowRightIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={`${minLabel}: ${min} ${unit}`} />
                </ListItem>
              )}
            </List>
            <Typography mt={1}>{t("TEXT.SAVE_AND_SYNC_MESSAGE_1")}</Typography>
            <Typography mt={1}>
              <strong style={{ paddingRight: "5px" }}>
                {t("TEXT.IMPORTANT")}:
              </strong>
              {t("TEXT.SAVE_AND_SYNC_MESSAGE_1_IMPORTANT")}
            </Typography>
          </Alert>
          <Alert severity="error" sx={{ mt: 2, mb: 2, alignItems: "center" }}>
            <Typography>
              <strong style={{ paddingRight: "5px" }}>
                {t("TEXT.SAVE_AND_SYNC_MESSAGE_2")}:
              </strong>
              {t("TEXT.SAVE_AND_SYNC_MESSAGE_3")}
            </Typography>
          </Alert>
          <Alert severity="warning" sx={{ mt: 2, mb: 2, alignItems: "center" }}>
            <Typography>
              <strong style={{ paddingRight: "5px" }}>
                {t("TEXT.FREQUENCY_INVERTERS")}:
              </strong>
              {t("TEXT.FREQUENCY_INVERTERS_MESSAGE")}
            </Typography>
            {isSubmitting && (
              <Typography mt={1.2}>
                {`${t("TEXT.FREQUENCY_INVERTERS_MESSAGE_2")} ${t("TEXT.SYNC")}`}
                .
              </Typography>
            )}
          </Alert>
        </Box>
      </Box>
      <Box
        mt={2}
        justifyContent={isSubmitting ? "space-between" : "center"}
        display="flex"
      >
        <Button
          onClick={() => (isSubmitting ? handleCloseModal() : handleClose())}
          sx={{
            backgroundColor: "#FFD5D7",
            mt: 1,
            ml: isSubmitting ? 2 : 0,
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {isSubmitting ? t("BUTTON.CLOSE") : t("BUTTON.RETURN")}
        </Button>
        {isSubmitting && (
          <Button
            variant="contained"
            onClick={handleSync}
            sx={{
              mt: 1,
              mr: 2,
              fontSize: 12,
              p: 1,
              pl: 3,
              pr: 3,
            }}
          >
            {t("BUTTON.SYNCHRONIZE")}
          </Button>
        )}
      </Box>
    </Modal>
  );
};

export default ModalSaveSync;
