import React from "react";
import { useTranslation } from "react-i18next";

import { Edit as EditIcon } from "@mui/icons-material";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TOKEN_KEY } from "../../../../auth/constants";
import ErrorMessage from "../../../../components/ErrorMessage";
import { TextField, yupNumberMinMax } from "../../../../components/Form";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ToastContext } from "../../../../contexts/ToastContext";
import { api } from "../../../../services/api";
import { editDevice } from "../../../../services/data/arcsys";
import Modal from "../../Modal";

interface ModalAddProps {
  id: string;
  allDevice: any;
  setDeviceEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalEditAlertCom = ({
  id,
  allDevice,
  setDeviceEdited,
  handleOpen,
  handleClose,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      alertComHours: allDevice.allDevice.alertComHours as string,
    },
    validationSchema: Yup.object({
      alertComHours: yupNumberMinMax(0, 24),
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
        const allDevices = allDevice.allDevices;
        // Novos dados do dispositivo
        const newDevice = {
          ...allDevices,
          alertComHours: values.alertComHours,
        };

        // Editar Alerta de Falha de Comunicação do Dispositivo
        editDevice(id, newDevice)
          .then(() => {
            toastSuccess(t("TOAST.SUCCESS.DEVICE_EDITED"));
            setDeviceEdited(true);
            handleClose();
          })
          .catch(() => {
            toastError(t("TOAST.ERROR.DEVICE_EDITED"));
          });
      }
    },
  });

  const isFormValid = formik.dirty && formik.isValid;

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <EditIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.EDIT_DEVICE")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            onClick={(e: any) => e.stopPropagation()}
            label={t("TEXT.COMMUNICATION_FAILURE")}
            helperText={`${t("TEXT.HOUR")}(s)`}
            name="alertComHours"
            type="number"
            maxLength={19}
            fullWidth
          />
          {formik.errors.alertComHours && formik.touched.alertComHours ? (
            <ErrorMessage>{formik.errors.alertComHours}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: "#C7252C",
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SAVE")}
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              backgroundColor: "#FFD5D7",
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalEditAlertCom;
