import React from "react";
import { useTranslation } from "react-i18next";

import { HighlightOff as HighlightOffIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import Modal from ".";
import { ToastContext } from "../../../contexts/ToastContext";
import { LOGIN } from "../../../Routes/constants";
import { cancelRegistration } from "../../../services/data/auth";

interface ModalRemoveProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalCancel = ({ handleOpen, handleClose }: ModalRemoveProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  // Cancelar a ativação da conta
  const handleConfirm = () => {
    cancelRegistration({ token: token })
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.REGISTRATION_CANCELED"));
        navigate(LOGIN);
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.REGISTRATION_CANCELED"));
      });
  };

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={325}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <HighlightOffIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.CANCEL_REGISTRATION")}?
      </Typography>
      <Typography variant="body2">
        {t("TEXT.CANCEL_REGISTRATION_MESSAGE")}
      </Typography>
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={handleConfirm}
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: "#C7252C",
          fontSize: 12,
          pt: 1,
          pb: 1,
        }}
      >
        {t("TEXT.CONFIRM_CANCELATION")}
      </Button>
      <Button
        onClick={handleClose}
        fullWidth
        sx={{
          backgroundColor: "#FFD5D7",
          mt: 1,
          borderRadius: 2,
          fontSize: 12,
          pt: 1,
          pb: 1,
          color: "black",
        }}
      >
        {t("BUTTON.CANCEL")}
      </Button>
    </Modal>
  );
};

export default ModalCancel;
