import React from "react";

import {
  DataGrid,
  enUS,
  esES,
  GridColDef,
  gridPageCountSelector,
  GridPagination,
  GridSlotsComponent,
  ptBR,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import { getLanguage } from "../../translations/languages";
import useIsMobile from "../../hooks/useIsMobile";
import { TablePaginationProps } from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";

const Pagination = ({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) => {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
};

const CustomPagination = (props: any) => {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
};

interface DataGridProps {
  rows: any;
  columns: GridColDef[];
  message: string;
  components?: Partial<GridSlotsComponent> | undefined;
  [x: string]: any;
}

const DataGridTable = ({
  rows,
  columns,
  message,
  components,
  ...rest
}: DataGridProps) => {
  const { theme } = React.useContext(ThemeModeContext);
  const language = getLanguage().code;
  const mobile = useIsMobile();

  // Lisguagem da tabela
  const languageTable =
    language === "en"
      ? enUS.components.MuiDataGrid.defaultProps.localeText
      : language === "es"
        ? esES.components.MuiDataGrid.defaultProps.localeText
        : ptBR.components.MuiDataGrid.defaultProps.localeText;

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableColumnSelector
      localeText={languageTable}
      {...rest}
      components={
        components
          ? {
              ...components,
            }
          : {
              NoRowsOverlay: () => CustomNoRowsOverlay(message),
              Pagination: CustomPagination,
            }
      }
      sx={{
        borderRadius: "12px",
        cursor: "pointer",
        ".highlight": {
          backgroundColor: "#F5F5F5",
          "&:hover": {
            backgroundColor: "#F5F5F5",
            cursor: "default",
          },
          "&.Mui-selected": {
            backgroundColor: "#F5F5F5",
          },
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
          {
            outline: "none !important",
          },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
          {
            outline: "none",
          },
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontSize: 12,
          color: theme === "light" ? "#7E7E7E" : "#FFFF",
          textTransform: "uppercase",
          whiteSpace: "normal",
          lineHeight: "normal",
          textAlign: "center",
        },
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
          display: mobile ? "none" : "",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme === "light" ? "#F9FAFB" : "#121212",
          borderRadius: "12px 12px 0px 0px",
        },
        "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
          backgroundColor: theme === "light" ? "#F9FAFB" : "#121212",
          borderRadius: "12px 12px 0px 0px",
        },
        "& .MuiDataGrid-cellContent": {
          whiteSpace: "normal !important",
          wordWrap: "break-word !important",
          textAlign: "center",
        },
      }}
    />
  );
};

export default DataGridTable;
