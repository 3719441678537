import React from "react";
import { useTranslation } from "react-i18next";

import { LockOutlined as LockOutlinedIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";
import { ToastContext } from "../../../contexts/ToastContext";
import Modal from "../../Devices/Modal";
import { sectorLock } from "../../../services/data/sector";

interface ModalLockProps {
  data: any;
  setSectorEdited: (e: boolean) => void;
  setChecked: (e: boolean) => void;
  checked: boolean;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalLock = ({
  data,
  setSectorEdited,
  setChecked,
  checked,
  handleOpen,
  handleClose,
}: ModalLockProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);

  // Alterar o estado do botão 'Bloquear alterações'
  const handleChangeSwitch = () => {
    sectorLock(data.id, { locked: !checked })
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
        setChecked(!checked);
        setSectorEdited(true);
        handleClose();
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
      });
  };

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={325}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <LockOutlinedIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.LOCK_CHANGES")}?
      </Typography>
      <Typography variant="body2">
        {t("TEXT.LOCK_CHANGES_MESSAGE")}
        <strong>{` ${data.sector_name}`}</strong>?
      </Typography>
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={handleChangeSwitch}
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: "#C7252C",
          fontSize: 12,
          pt: 1,
          pb: 1,
        }}
      >
        {t("TEXT.LOCK")}
      </Button>
      <Button
        onClick={handleClose}
        fullWidth
        sx={{
          backgroundColor: "#FFD5D7",
          mt: 1,
          borderRadius: 2,
          fontSize: 12,
          pt: 1,
          pb: 1,
          color: "black",
        }}
      >
        {t("BUTTON.CANCEL")}
      </Button>
    </Modal>
  );
};

export default ModalLock;
