import React from "react";

import {
  Box,
  FormControlLabel,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Clear as ClearIcon,
  Search as SearchIcon,
  History as HistoryIcon,
  WarningAmberOutlined as WarningAmberOutlinedIcon,
  ReportGmailerrorred as ReportGmailerrorredIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";
import useIsMobile, { useIsMediumPage } from "../../hooks/useIsMobile";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { ThemeModeContext } from "../../contexts/ThemeModeContext";
import AvatarIcon from "../../components/AvatarIcon";
import { grey, yellow } from "@mui/material/colors";
import Switch from "../../components/Switch";
import {
  enableDisableScenario,
  getConditionalRecords,
} from "../../services/data/scenarios";
import { ToastContext } from "../../contexts/ToastContext";
import ModalHistory from "../../components/Scenarios/Modal/ModalHistory";
import {
  ctrlData,
  parseData,
  splitValue,
} from "../../components/Scenarios/parse";
import ModalRemove from "../../components/Scenarios/Modal/ModalRemove";
import ModalConditions from "../../components/Scenarios/Modal/ModalConditions";
import { getCtrlConfig } from "../../components/Controller/parser";
import {
  CtrlConfig,
  Estado,
  RowData,
} from "../../components/Controller/parser/types";
import DataGridServerSide from "../../components/DataGridServerSide";
import { AuthContext } from "../../contexts/AuthContext";
import ModalEditScenario from "./ModalEdit";
import { applyDivider } from "../../components/Controller/parser/utils";
import { truncateText } from "./parse";

interface TableProps {
  data: any[];
  loading: boolean;
  setLoading: (e: boolean) => void;
  setScenarioEdited: (e: boolean) => void;
  searchedDevice: string;
  setSearchedDevice: (e: string) => void;
  handleChangePage: (e: number) => void;
  pageNumber: number;
  totalPages: number;
  setRowsPerPage: (e: number) => void;
}

const Table = ({
  data,
  loading,
  setLoading,
  setScenarioEdited,
  searchedDevice,
  setSearchedDevice,
  handleChangePage,
  pageNumber,
  totalPages,
  setRowsPerPage,
}: TableProps) => {
  const { t } = useTranslation();
  const { theme } = React.useContext(ThemeModeContext);
  const { userId } = React.useContext(AuthContext);
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const [openModalConditions, setOpenModalConditions] = React.useState(false);
  const [modalConditionsValues, setModalConditionsValues] =
    React.useState<any>();
  const [openModalHistory, setOpenModalHistory] = React.useState(false);
  const [modalHistoryValues, setModalHistoryValues] = React.useState<any>();
  const [dataRecords, setDataRecords] = React.useState<any[]>([]);
  const [loadingModalHistory, setLoadingModalHistory] = React.useState(true);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const [modalEditValues, setModalEditValues] = React.useState<any>();
  const [searchedClicked, setSearchedClicked] = React.useState<string>("");

  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1250;
  const mediumPage = useIsMediumPage();

  // Filtrar o sensor selecionado
  const sensores = (model: string) => {
    return ctrlData(model).sensores;
  };

  const sensorSelectedHistory = (sensor: number) =>
    modalHistoryValues &&
    parseData(sensores(modalHistoryValues.row.model)).filter(
      (f: any, i: number) => +i === sensor,
    )[0];

  const sensorSelectedConditions = (sensor: number) =>
    modalConditionsValues &&
    parseData(sensores(modalConditionsValues.conditions.model)).filter(
      (f: any, i: number) => +i === sensor,
    )[0];

  const ctrlConfig: CtrlConfig = modalConditionsValues
    ? getCtrlConfig(modalConditionsValues?.conditions?.model)
    : modalHistoryValues && getCtrlConfig(modalHistoryValues?.row?.model);

  const estado: Estado[] = ctrlConfig?.estado;

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      data.map((m, i) => {
        const addr: number = m.ctrlId.split("/")[1].split("L")[1];
        const addrCtrl: number = m.actions[0].ctrlId
          ?.split("/")[1]
          .split("L")[1];

        // Filtrar o campo 'Setpoints'
        const setpoints: RowData[] = getCtrlConfig(
          m?.actions[0]?.model,
        )?.setpoints;

        // Filtrar o campo 'Setpoints'
        const filterSetpoints = setpoints?.filter(
          (_f, i: number) =>
            +i === +splitValue(m.actions[0]?.resource)[1]?.split("s")[1],
        )[0];

        return {
          id: i + 1,
          rowId: m._id,
          name: m.name,
          description: m.description,
          histerese: m.histerese,
          enabled: m.enabled,
          trigger_type: m.trigger_type,
          conditions: m.conditions,
          action_obj: m.actions[0],
          operator: m.operator,
          scenarioActions: "",
          ctrlId: m.ctrlId,
          historic: "",
          last_status: m?.last_status,
          disable_on_error: m?.disable_on_error,
          device: m.controller.arcsys_name,
          addr: `${m.controller.name} (${addr})`,
          model: m.model,
          deviceName: m.actions[0].arcsys_name ?? "",
          ctrlName: m.actions[0].ctrl_name
            ? `${m.actions[0].ctrl_name} (${addrCtrl})`
            : "--",
          setpointDivider: applyDivider(
            m.actions[0].value,
            filterSetpoints?.divider ?? 0,
          ),
          incompatible: m.incompatible,
          controller: m.controller,
          days_of_week: m.days_of_week,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, userId],
  );

  //  Filtrar pela nome do cenario, nome do dispositivo ou nome do controlador
  const searchRows = React.useMemo(
    () =>
      rows.filter(
        (f: any) =>
          f.name.toUpperCase().includes(searchedDevice.toUpperCase()) ||
          f.name.toLowerCase().includes(searchedDevice.toLowerCase()),
      ),
    [rows, searchedDevice],
  );

  // Alterar o switch de Status do Cenário
  const handleChange = (row: any) => {
    const newData = {
      enabled: !row.enabled,
    };

    // Rota para habeilitar/desabilitar cenário
    enableDisableScenario(row.rowId, newData)
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.SCENARIO_STATUS_SWITCH"));
        setScenarioEdited(true);
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.SCENARIO_STATUS_SWITCH"));
      });
  };

  // Barra de pesquisa do Dispositivo
  const handleSearch = (e: any) => {
    setSearchedDevice(e);
  };

  // Limpar barra de pesquisa do Dispositivo
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    setSearchedDevice("");
    setSearchedClicked("");
  };

  // Ações do modal de visualizar os controladores
  const handleOpenModalConditions = (conditions: any) => {
    setModalConditionsValues({ conditions });
    setOpenModalConditions(true);
  };
  const handleCloseModalControllers = () => {
    setModalConditionsValues(false);
  };

  // Ações do modal de excluir cenário
  const handleOpenModalDelete = (e: any, row: any) => {
    e.stopPropagation();

    setModalDeleteValues({ row });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ações de abrir o modal de Editar Cenário
  const ctrlConfigEdit: CtrlConfig =
    modalEditValues && getCtrlConfig(modalEditValues?.row?.model);
  const estadoOfEdit: Estado[] = ctrlConfigEdit?.estado;
  const parametrosOfEdit: RowData[] = ctrlConfigEdit?.parametros;
  const sensoresOfEdit: any =
    modalEditValues && ctrlData(modalEditValues?.row?.model)?.sensores;

  const handleOpenModalEdit = (e: any, row: any) => {
    e.stopPropagation();

    setModalEditValues({ row });

    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  // Ações de abrir o modal do histórico do Cenário
  const handleOpenModalHistory = (e: any, row: any) => {
    e.stopPropagation();

    setModalHistoryValues({ row });

    // Requisição get do histórico do Cenário
    getConditionalRecords(row.ctrlId, row.rowId).then((res) => {
      const { data } = res;
      setDataRecords(data.reverse());
    });
    setOpenModalHistory(true);
    setTimeout(() => {
      setLoadingModalHistory(false);
    }, 500);
  };
  const handleCloseModalHistory = () => {
    setOpenModalHistory(false);
    setLoadingModalHistory(true);
  };

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "name",
            headerName: t("TEXT.NAME"),
            align: "left",
            headerAlign: "left",
            flex: 3,
            renderHeader: () => (
              <p
                style={{
                  fontSize: "12px",
                  color: theme === "light" ? "#7E7E7E" : "inherit",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginLeft: "15px",
                }}
              >
                {t("TEXT.NAME")}
              </p>
            ),
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                    <AvatarIcon
                      name={row.name}
                      bgcolor={
                        row?.last_status && row?.last_status !== "SUCESSO"
                          ? yellow[700]
                          : row?.enabled === false
                            ? grey[400]
                            : row.incompatible
                              ? grey[400]
                              : "#C7252C"
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Box display="flex" component="span">
                          <Tooltip title={row.name}>
                            <Typography
                              component="span"
                              variant="body2"
                              textOverflow="ellipsis"
                              overflow="hidden"
                              whiteSpace="break-spaces"
                              fontWeight={500}
                              sx={{
                                display: "inline",
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                              }}
                            >
                              {row.name}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" component="span">
                          <Tooltip title={row.description}>
                            <Typography
                              component="span"
                              variant="body2"
                              textOverflow="ellipsis"
                              overflow="hidden"
                              whiteSpace="break-spaces"
                              sx={{
                                display: "inline",
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                              }}
                            >
                              {row.description}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </React.Fragment>
                    }
                  />
                  {row.incompatible && (
                    <Box p={1} pl={2}>
                      <Tooltip title={t("TEXT.INCOMPATIBLE_MODEL")}>
                        <ReportGmailerrorredIcon
                          color="error"
                          fontSize="large"
                        />
                      </Tooltip>
                    </Box>
                  )}
                </ListItem>
              </List>
            ),
          },
          {
            field: "addr",
            headerName: t("TEXT.MONITORING_CTRL"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 500,
                            whiteSpace: "break-spaces",
                            fontSize: 14,
                            color:
                              theme === "light"
                                ? "inherit"
                                : theme === "dark" && row.incompatible
                                  ? "black"
                                  : "white",
                          }}
                        >
                          {row.addr}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            color:
                              theme === "light"
                                ? "inherit"
                                : theme === "dark" && row.incompatible
                                  ? "black"
                                  : "white",
                          }}
                          variant="body2"
                        >
                          {truncateText(row.device)}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ),
          },
          {
            field: "actionDefinition",
            headerName: t("TEXT.ACTION_DEFIINITION"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start" sx={{ pl: 0, pr: 0 }}>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 500,
                            whiteSpace: "break-spaces",
                            fontSize: 14,
                            color:
                              theme === "light"
                                ? "inherit"
                                : theme === "dark" && row.incompatible
                                  ? "black"
                                  : "white",
                          }}
                        >
                          {row.ctrlName}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            color:
                              theme === "light"
                                ? "inherit"
                                : theme === "dark" && row.incompatible
                                  ? "black"
                                  : "white",
                          }}
                          variant="body2"
                        >
                          {truncateText(row.deviceName)}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ),
          },
          {
            field: "scenarioActions",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.ACTIONS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box component="span">
                <Tooltip title={t("TEXT.DELETE_SCENARIO")}>
                  <IconButton
                    sx={{ p: 0 }}
                    color="error"
                    onClick={(e) => handleOpenModalDelete(e, row)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("TEXT.EDIT_SCENARIO")}>
                  <IconButton
                    sx={{ p: 0, ml: 1 }}
                    color="warning"
                    onClick={(e) => handleOpenModalEdit(e, row)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ),
          },
          {
            field: "enabled",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.ENABLE_SCENARIO"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box display="flex" justifyContent="center">
                <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
                  <FormControlLabel
                    sx={{ m: 0, p: 3 }}
                    control={
                      <Switch
                        checked={row.incompatible ? false : row.enabled}
                      />
                    }
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => handleChange(row)}
                    label=""
                    disabled={row.incompatible}
                  />
                </Tooltip>
              </Box>
            ),
          },
          {
            field: "historic",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.HISTORIC"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box
                component="span"
                display="flex"
                onClick={(e) => handleOpenModalHistory(e, row)}
                p={3}
              >
                <Tooltip title={t("TEXT.ACCESS")}>
                  <IconButton color="info">
                    <HistoryIcon sx={{ fontSize: 28 }} />
                  </IconButton>
                </Tooltip>
                {row?.last_status && row?.last_status !== "SUCESSO" && (
                  <Box mt={1}>
                    <Tooltip title={t("TEXT.SCENARIO_FAILURE")}>
                      <WarningAmberOutlinedIcon
                        color="warning"
                        sx={{ fontSize: 28 }}
                      />
                    </Tooltip>
                  </Box>
                )}
              </Box>
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "device",
            headerName: t("TEXT.DEVICE"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemAvatar sx={{ alignSelf: "center" }}>
                      <AvatarIcon
                        name={row.name}
                        bgcolor={
                          row?.last_status && row?.last_status !== "SUCESSO"
                            ? yellow[700]
                            : row?.enabled === false
                              ? grey[400]
                              : row.incompatible
                                ? grey[400]
                                : "#C7252C"
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box display="flex">
                          <Box display="grid">
                            <Typography
                              component="span"
                              fontWeight={500}
                              whiteSpace="break-spaces"
                              sx={{
                                display: "inline",
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                              }}
                            >
                              {row.name}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{
                                width: "10px",
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                              }}
                              mt={0.5}
                            >
                              {row.description}
                            </Typography>
                          </Box>
                          {row.incompatible && (
                            <Box pl={2} alignSelf="center">
                              <Tooltip title={t("TEXT.INCOMPATIBLE_MODEL")}>
                                <ReportGmailerrorredIcon
                                  color="error"
                                  fontSize="large"
                                />
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      }
                      secondary={
                        <React.Fragment>
                          <Box
                            display="grid"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.MONITORING_CTRL")}:
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                              }}
                            >
                              {row.addr}
                            </Typography>
                          </Box>
                          <Box
                            display="grid"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.MONITORING_DEVICE")}:
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                              }}
                            >
                              {truncateText(row.device)}
                            </Typography>
                          </Box>
                          <Box
                            display="grid"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.ACTION_DEFIINITION")}:
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                              }}
                            >
                              {row.ctrlName}
                            </Typography>
                          </Box>
                          <Box
                            display="grid"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.ACTION_DEFIINITION_DEVICE")}:
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                              }}
                            >
                              {truncateText(row.deviceName)}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            mt={0.5}
                            mb={0.5}
                            component="span"
                          >
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.3,
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.ACTIONS")}:
                            </Typography>
                            <IconButton
                              sx={{ p: 0 }}
                              color="error"
                              onClick={(e) => handleOpenModalDelete(e, row)}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                            <IconButton
                              sx={{ p: 0, ml: 1 }}
                              color="warning"
                              onClick={(e) => handleOpenModalEdit(e, row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                          <Box display="flex" mb={0.5} component="span">
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 2,
                                mt: 0.5,
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.ENABLE_SCENARIO")}:
                            </Typography>
                            <Box component="span">
                              <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
                                <FormControlLabel
                                  sx={{ m: 0, pr: 3 }}
                                  control={
                                    <Switch
                                      checked={
                                        row.incompatible ? false : row.enabled
                                      }
                                    />
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={() => handleChange(row)}
                                  label=""
                                  disabled={row.incompatible}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box display="flex" component="span">
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mt: 0.5,
                                color:
                                  theme === "light"
                                    ? "inherit"
                                    : theme === "dark" && row.incompatible
                                      ? "black"
                                      : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.HISTORIC")}:
                            </Typography>
                            <IconButton
                              sx={{ p: 1, pt: 0 }}
                              color="info"
                              onClick={(e) => handleOpenModalHistory(e, row)}
                            >
                              <HistoryIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                            {row?.last_status &&
                              row?.last_status !== "SUCESSO" && (
                                <Box
                                  onClick={(e) =>
                                    handleOpenModalHistory(e, row)
                                  }
                                >
                                  <Tooltip title={t("TEXT.SCENARIO_FAILURE")}>
                                    <WarningAmberOutlinedIcon
                                      color="warning"
                                      sx={{ fontSize: 28 }}
                                    />
                                  </Tooltip>
                                </Box>
                              )}
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (rows.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [rows.length, loading, setLoading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 30000);
  }, [setLoading]);

  return (
    <Box>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          mr: mobile ? 2 : 4,
          borderRadius: 5,
          width: "100%",
          mt: 1,
          mb: 3,
          backgroundColor: theme === "dark" ? "#181818" : "#F9F9F9",
        }}
      >
        <Tooltip title={t("BUTTON.SEARCH")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={(e: any) => {
              handleSearch(searchedClicked);
              e.preventDefault();
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <InputBase
          id="myInput"
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              handleSearch(e.target.value);
              e.preventDefault();
            }
          }}
          onChange={(e: any) => {
            setSearchedClicked(e.target.value);
          }}
          sx={{ ml: 2, flex: 1 }}
          placeholder={t("TEXT.SEARCH_SCENARIO")}
        />
        <Tooltip title={t("TEXT.CLEAN")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleClearSearch}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      {loading ? (
        <Box
          m={
            mobile
              ? "230px 0px"
              : window.screen.width < 1550
                ? "240px 0px"
                : "418px 0px"
          }
        >
          <Spinner />
        </Box>
      ) : rows.length !== 0 ? (
        <>
          <Paper
            style={{
              height: mobile
                ? 450
                : mediumPage
                  ? 835
                  : window.screen.width < 1550
                    ? 480
                    : 835,
              cursor: "pointer",
            }}
            elevation={0}
          >
            <DataGridServerSide
              rows={searchedDevice === "" ? rows : searchRows}
              columns={columns}
              message={t("TEXT.NO_DEVICES")}
              getRowHeight={() => "auto"}
              totalPages={totalPages}
              pageNumber={pageNumber}
              onRowClick={(params: GridRowParams) =>
                !params.row.incompatible &&
                handleOpenModalConditions(params.row)
              }
              getRowClassName={(params: GridRowParams) => {
                return params.row.incompatible ? "highlight" : "";
              }}
              onPageChange={(newPage: number) => {
                handleChangePage(newPage);
              }}
              onPageSizeChange={(rowsPerPage: any) =>
                setRowsPerPage(rowsPerPage)
              }
            />
          </Paper>
        </>
      ) : (
        <Paper
          sx={{
            backgroundColor: "#E5F6FD",
            p: 4,
            mt: 1,
            borderRadius: 3,
          }}
          elevation={0}
        >
          <Typography mb={3} fontWeight={600}>
            {t("TEXT.EMPTY_SCENARIOS")}.
          </Typography>
          <Typography whiteSpace="break-spaces">
            {t("TEXT.SCENARIOS_EMPTY_TITLE")} <strong>{t("BUTTON.ADD")}</strong>{" "}
            {t("TEXT.SCENARIOS_EMPTY_TITLE_2")}
          </Typography>
          <Stepper orientation="vertical" sx={{ ml: mobile ? 0 : 4, mt: 3 }}>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={600}>
                  {t("TEXT.SCENARIOS_EMPTY_SUBTITLE_TITLE_1")}:
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.SCENARIOS_EMPTY_SUBTITLE_MESSAGE_1")}
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={600}>
                  {t("TEXT.SCENARIOS_EMPTY_SUBTITLE_TITLE_2")}:
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.SCENARIOS_EMPTY_SUBTITLE_MESSAGE_2")}
              </StepContent>
            </Step>
            <Step active>
              <StepLabel>
                <Typography fontSize={16} fontWeight={600}>
                  {t("TEXT.SCENARIOS_EMPTY_SUBTITLE_TITLE_3")}:
                </Typography>
              </StepLabel>
              <StepContent sx={{ whiteSpace: "break-spaces" }}>
                {t("TEXT.SCENARIOS_EMPTY_SUBTITLE_MESSAGE_3")}
              </StepContent>
            </Step>
          </Stepper>
        </Paper>
      )}
      {modalConditionsValues && (
        <ModalConditions
          sensorSelected={sensorSelectedConditions}
          values={modalConditionsValues}
          action_obj={modalConditionsValues?.conditions?.action_obj}
          handleOpen={openModalConditions}
          handleClose={handleCloseModalControllers}
          parametros={ctrlConfig.parametros}
          estado={estado}
        />
      )}
      {modalDeleteValues && (
        <ModalRemove
          setScenarioEdited={setScenarioEdited}
          row={modalDeleteValues.row}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
      {modalHistoryValues && (
        <ModalHistory
          row={modalHistoryValues.row}
          dataRecords={dataRecords}
          handleOpen={openModalHistory}
          handleClose={handleCloseModalHistory}
          sensorSelected={sensorSelectedHistory}
          loadingModalHistory={loadingModalHistory}
          estado={estado}
        />
      )}
      {modalEditValues && (
        <ModalEditScenario
          handleOpen={openModalEdit}
          handleClose={handleCloseModalEdit}
          estadoOfEdit={estadoOfEdit}
          modalEditValues={modalEditValues}
          parametrosOfEdit={parametrosOfEdit}
          sensoresOfEdit={sensoresOfEdit}
          setScenarioEdited={setScenarioEdited}
        />
      )}
    </Box>
  );
};

export default Table;
