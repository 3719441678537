import React from "react";
import { useTranslation } from "react-i18next";

import { Delete as DeleteIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";
import { ToastContext } from "../../../contexts/ToastContext";
import Modal from "../../Devices/Modal";
import { deleteHistoricById } from "../../../services/data/translations";

interface ModalRemoveProps {
  id: string;
  day: string;
  setTranslationsEdited: (e: boolean) => void;
  setSaveButton: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalRemove = ({
  handleOpen,
  id,
  day,
  setTranslationsEdited,
  setSaveButton,
  handleClose,
}: ModalRemoveProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);

  const handleDelete = () => {
    deleteHistoricById(id)
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.ALTERNATE_ACTION"));
        setTranslationsEdited(true);
        setSaveButton(false);
        handleClose();
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.CONTROLLER_CHANGED"));
      });
  };

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={325}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <DeleteIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.REMOVE")} {t("TEXT.TRANSLATIONS")}?
      </Typography>
      <Typography variant="body2">
        {t("TEXT.REMOVE_HISTORIC_MESSAGE")}
        <strong>{` ${day}`}</strong>?
      </Typography>
      <Button
        variant="contained"
        color="error"
        type="button"
        onClick={handleDelete}
        fullWidth
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: "#C7252C",
          fontSize: 12,
          pt: 1,
          pb: 1,
        }}
      >
        {t("TEXT.REMOVE")}
      </Button>
      <Button
        onClick={handleClose}
        fullWidth
        sx={{
          backgroundColor: "#FFD5D7",
          mt: 1,
          borderRadius: 2,
          fontSize: 12,
          pt: 1,
          pb: 1,
          color: "black",
        }}
      >
        {t("BUTTON.CANCEL")}
      </Button>
    </Modal>
  );
};

export default ModalRemove;
