import React from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import { TOKEN_KEY } from "../../auth/constants";
import { getConfig } from "../../services/data/arcsys";
import useIsMobile from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import Spinner from "../../components/Spinner";
import Form from "./Form";

const Settings = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { token, authenticated } = React.useContext(AuthContext);
  const [configEdited, setConfigEdited] = React.useState<boolean>(false);
  const [config, setConfig] = React.useState<any>();
  const [loading, setLoading] = React.useState(true);

  // getConfig

  // Resgatar os Cenários do usuário
  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        getConfig().then((res) => {
          const { data } = res;
          setConfig(data);
          if (res.data.length === 0) {
            setLoading(false);
          }
        });
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, token]);

  // Atualizar os dados caso houver edição
  React.useEffect(() => {
    if (configEdited) {
      getConfig().then((res) => {
        const { data } = res;
        setConfig(data);
        if (res.data.length === 0) {
          setLoading(false);
        }
      });
      setConfigEdited(false);
    }
  }, [configEdited]);

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (config && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [config, loading, setLoading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 30000);
  }, [setLoading]);

  return (
    <Box display="grid">
      <Box overflow="auto" whiteSpace="nowrap">
        <Box display="flex" justifyContent="center">
          <Card elevation={3} sx={{ width: 1500, p: mobile ? 0 : 1, mb: 2 }}>
            <CardContent sx={{ p: mobile ? 0 : 1 }}>
              <Box display="flex" justifyContent="space-between" ml={2}>
                <Typography
                  fontSize={mobile ? 22 : 25}
                  gutterBottom
                  sx={{ mt: mobile ? 1 : 0, mb: 0 }}
                >
                  {t("TEXT.SETTINGS")}
                </Typography>
              </Box>
              <Card elevation={0}>
                <CardContent>
                  {loading === true ? (
                    <Box
                      m={
                        mobile
                          ? "230px 0px"
                          : window.screen.width < 1550
                            ? "240px 0px"
                            : "418px 0px"
                      }
                    >
                      <Spinner />
                    </Box>
                  ) : (
                    config && (
                      <Form config={config} setConfigEdited={setConfigEdited} />
                    )
                  )}
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
