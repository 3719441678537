import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";

import { Menu as MenuIcon } from "@mui/icons-material";

import {
  Alert,
  Box,
  Divider,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import { getLanguage } from "../../../../translations/languages";

import Row from "./Row";
import { generalInfo } from "../../../../services/data/report";
import { convertToDate, convertToTime } from "./parse";
import { getRawCtrlConfig } from "../../../../config";
import Spinner from "../../../../components/Spinner";
import { arcsysLogo, arcsysLogo2 } from "../../parse";

type GeneralInfoProps = {
  macId: string;
  beginTs: number;
  endTs: number;
  mac: string;
  rotulo: string;
  modelo: string;
  generalInfoTitle: string;
};

const GeneralInfo = ({
  macId,
  beginTs,
  endTs,
  mac,
  rotulo,
  modelo,
  generalInfoTitle,
}: GeneralInfoProps) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState<any>();
  const [graphLabels, setGraphLabels] = React.useState<number[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const language = getLanguage().code;
  const checkLanguage = language === "en" ? "en-US" : "pt-BR";
  const checkLanguageOfPayload =
    language === "en" ? "en" : language === "es" ? "es" : "pt-br";
  const date = new Date()?.toLocaleString(checkLanguage)?.slice(0, 10);
  const convertedDate = language === "en" ? date?.replace(",", "") : date;

  // Abre o menu de opções de exportações de arquivo
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Fecha o menu de opções de exportações de arquivo
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // XX-XX-XX-XX-XX-XX/CTRLXX
  const ctrlId = `${mac?.slice(0, 17)}%2F${mac?.slice(18, 25)}`;

  React.useEffect(() => {
    // Resgatar as datas e médias do Relatório
    generalInfo(
      macId,
      ctrlId,
      endTs,
      beginTs,
      checkLanguageOfPayload,
      modelo,
    ).then((res) => {
      const { data } = res;
      for (let i = 0; i < data.length; i++) {
        data[i]?.data?.sort((a: { day: number }, b: { day: number }) =>
          a.day > b.day ? 1 : -1,
        );
      }
      setData(data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beginTs, endTs, checkLanguageOfPayload]);

  React.useEffect(() => {
    // Pegar as labels do payload
    const labels =
      data &&
      data?.map((v: any) => {
        return v.title;
      });

    if (labels) {
      setGraphLabels(Object.values(labels));
    }
  }, [data, t, language]);

  // Array com a data e os valores (max, min, avg) utilizado no arquivo de exportar
  const values = React.useMemo(
    () =>
      data &&
      data?.map((v: any) => {
        return v?.data?.map((o: any) => {
          return [
            convertToDate(o.day, language),
            o.max.value.toString() + "," + convertToTime(o.max.timestamp),
            o.min.value.toString() + "," + convertToTime(o.min.timestamp),
            o.avg,
          ];
        });
      }),
    [data, language],
  );

  // Títulos utilizados no arquivo de exportar
  const titles = [
    t("TEXT.DATE"),
    t("TEXT.MAXIMUM"),
    t("TEXT.MAX_TIME"),
    t("TEXT.MINIMUM"),
    t("TEXT.MIN_TIME"),
    t("TEXT.AVERAGE"),
  ];

  // Função que exporta arquivo em .txt e .csv
  const downloadFile = (type: string) => {
    let csv = `
    MAC: ${mac.slice(0, 17)}
    ${t("TEXT.MODEL")}: ${modelo}
    ${t("TEXT.LABEL")}: ${rotulo}
    ${t("TEXT.ADDRESS").replace("ç", "c")}: ${+mac?.split("CTRL")[1]}\n
    ${t("TEXT.GENERAL_INFO")}\n`;

    values.forEach((row: any, i: number) => {
      csv += "\n";
      csv += graphLabels[i];
      csv += "\n";
      csv += `${titles?.toString()}\n`;
      for (let i = 0; i < row.length; i++) {
        csv += row[i]?.toString() + "\n";
      }
    });

    const hiddenElement = document.createElement("a");
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
    hiddenElement.target = "_blank";

    hiddenElement.download = `${convertedDate.replace(
      " ",
      "",
    )}_${rotulo}_Info${type}`;
    hiddenElement.click();
  };

  const generalInfoRef: any = useRef(false);

  // Array com os tipos de exportações
  const menuList = ["Download .TXT", "Download .CSV", t("TEXT.PRINT")];

  // Nome do modelo
  const name = getRawCtrlConfig(modelo)?.nome;

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (data && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [data, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 30000);
  }, []);

  return (
    <>
      <style>
        {/* Style da exportação de imprimir Relatório */}
        {`.print-header, .print-footer, .show-on-print {
            display: none;
          }
          @media print {
            .print-content {
              padding-top: 0mm;
              padding-bottom: 0mm;
              margin: 10mm;
            }
            .print-header, .print-footer {
              display: block;
              width: 100%;
            }
            .print-header {
              padding-top: 2mm;
            }
            .print-footer {
              position: relative;
              bottom: 0;
              padding-bottom: 5mm;
            }
            .hide-on-print {
              display: none;
            }
            .show-on-print {
              display: block;
            }
          }
        `}
      </style>
      <Box height="auto" flexDirection="column" mb={2}>
        <Box textAlign="right">
          <Divider />
          {data && data?.length > 0 ? (
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={openMenu ? "long-menu" : undefined}
              aria-expanded={openMenu ? "true" : undefined}
              aria-haspopup="true"
              color="primary"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box mt={5} />
          )}
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: "20ch",
              },
            }}
          >
            {menuList?.map((option) => (
              <ReactToPrint
                content={() => generalInfoRef.current}
                key={option}
                bodyClass={"print-content"}
                documentTitle={rotulo}
              >
                <PrintContextConsumer>
                  {({ handlePrint }: any) => (
                    <MenuItem
                      onClick={() =>
                        option === "Download .TXT"
                          ? downloadFile(".txt")
                          : option === "Download .CSV"
                            ? downloadFile(".csv")
                            : handlePrint()
                      }
                      sx={{ justifyContent: "center" }}
                    >
                      {option}
                    </MenuItem>
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
            ))}
          </Menu>
        </Box>
        <div ref={generalInfoRef}>
          <div className="print-header">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Box>
                  <FormLabel sx={{ fontSize: 24 }}>{rotulo}</FormLabel>
                </Box>
                <Typography pt={0} pb={3} variant="subtitle1">
                  {generalInfoTitle}
                </Typography>
              </Box>
              <Box>
                <Box display="flex">
                  <Typography fontWeight="bold">MAC:</Typography>
                  <Typography pl={0.5}>{mac?.slice(0, 17)}</Typography>
                </Box>
                <Box display="flex">
                  <Typography fontWeight="bold">{t("TEXT.MODEL")}:</Typography>
                  <Typography pl={0.5}>{name}</Typography>
                </Box>
                <Box display="flex">
                  <Typography fontWeight="bold">
                    {t("TEXT.ADDRESS")}:
                  </Typography>
                  <Typography pl={0.5}>{mac?.split("CTRL")[1]}</Typography>
                </Box>
              </Box>
            </div>
          </div>
          <div className="print-content">
            <Box pt={2} pb={0} className="hide-on-print">
              <FormLabel sx={{ fontSize: 24 }}>{rotulo}</FormLabel>
            </Box>
            <Typography
              className="hide-on-print"
              pt={0}
              pb={3}
              variant="subtitle1"
            >
              {generalInfoTitle}
            </Typography>
            {loading ? (
              <>
                <Box m="126px 0px">
                  <Spinner />
                </Box>
              </>
            ) : data && data.length > 0 ? (
              <>
                <div className="hide-on-print">
                  <Row data={data} print={false} />
                </div>
                <div className="show-on-print">
                  <Row data={data} print={true} />
                </div>
              </>
            ) : (
              <Box display="inline-table">
                <Alert
                  severity="info"
                  sx={{
                    mt: 3,
                    mb: 1,
                    textAlign: "center",
                    placeContent: "center",
                    width: 310,
                  }}
                >
                  {t("TOAST.INFO.NO_DATA_RECORD")}
                </Alert>
              </Box>
            )}
          </div>
          <div className="print-footer">
            <Box display="flex" justifyContent="space-between">
              <img
                src={arcsysLogo}
                alt="ArcSys Cloud"
                style={{ width: "90px", height: "44px" }}
              />
              <img
                src={arcsysLogo2}
                alt="ArcSys Cloud"
                style={{ width: "90px", height: "44px" }}
              />
            </Box>
          </div>
        </div>
      </Box>
    </>
  );
};

export default GeneralInfo;
