import { Card, CardContent, Divider } from "@mui/material";

import Form from "./Form";
import Version from "./Version";
import useIsMobile from "../../hooks/useIsMobile";

const Login = () => {
  const mobile = useIsMobile();

  return (
    <Card
      sx={{
        minWidth: mobile ? 310 : 350,
        p: 2,
      }}
      elevation={5}
    >
      <CardContent sx={{ position: "relative" }}>
        <Form />
        <Divider />
        <Version />
      </CardContent>
    </Card>
  );
};

export default Login;
