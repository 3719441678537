import { api } from "../api";
import { MAC_ROUTE, getMacListAdminRoute, getMacRoute } from "../routes/mac";

// Verifica se o endereço MAC está sendo utilizado
export const getMac = (mac: string) => api.get<any>(getMacRoute(mac));

// Rota que retorna a lista de endereços MACs gerais para o admin
export const getMacListAdmin = (
  page: number,
  offset: number,
  general: string,
  status: string,
) => api.get<any>(getMacListAdminRoute(page, offset, general, status));

// Rota para adicionar endereço MAC no modo admin
export const addMacAdmin = async (data: any) => {
  const resp = await api.post(MAC_ROUTE, data);
  return resp;
};

// Rota para remover endereço MAC no modo admin
export const deleteMacAdmin = (mac: string) =>
  api.delete<any>(getMacRoute(mac));
