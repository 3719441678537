import React from "react";

import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputBase,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  PendingOutlined as PendingOutlinedIcon,
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  CircleOutlined as CircleOutlinedIcon,
  Receipt as ReceiptIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useIsMobile, { useIsMediumPage } from "../../../../hooks/useIsMobile";
import { getLanguage } from "../../../../translations/languages";
import { AuthContext } from "../../../../contexts/AuthContext";
import { ThemeModeContext } from "../../../../contexts/ThemeModeContext";
import { api } from "../../../../services/api";
import { TOKEN_KEY } from "../../../../auth/constants";
import { getInvoicesAdmin } from "../../../../services/data/subscriptions";
import { GridColDef } from "@mui/x-data-grid";
import { blue, green } from "@mui/material/colors";
import { convertToDatePaymentPending, formatValueCents } from "../../parse";
import AvatarIcon from "../../../../components/AvatarIcon";
import ModalRemove from "./ModalRemove";
import Spinner from "../../../../components/Spinner";
import DataGridServerSide from "../../../../components/DataGridServerSide";

const PendingPayments = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const smallWindow = window.innerWidth < 1250;
  const mediumPage = useIsMediumPage();
  const { token, authenticated } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const [payments, setPayments] = React.useState<any[]>([]);
  const [paymentsEdited, setPaymentsEdited] = React.useState<boolean>(false);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [searchedPayment, setSearchedPayment] = React.useState<string>("");
  const [status, setStatus] = React.useState<string>("");
  const [paymentOption, setPaymentOption] = React.useState<number>(0);
  const [searchedClicked, setSearchedClicked] = React.useState<string>("");
  const [loading, setLoading] = React.useState(true);
  const [queryType, setQueryType] = React.useState("mac");
  const [totalPages, setTotalPages] = React.useState<number>(0);

  // Selecionar o tipo de pesquisa
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryType(event.target.value);
  };

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        if (pageNumber > 0) {
          // Rota para resgatar lista de pagamentos pendentes no modo Admin
          getInvoicesAdmin(
            pageNumber,
            rowsPerPage,
            searchedPayment,
            status,
            queryType,
          ).then((res) => {
            const { data } = res;
            setPayments(data?.data);
            setTotalPages(data.total);
          });
        }
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, pageNumber, rowsPerPage, searchedPayment, token, status]);

  // Ação ao alterar a página da tabela
  const handleChangePage = (value: number) => {
    setPageNumber(value + 1);
  };

  // Atualizar os dados após a edição de qualquer dado
  React.useEffect(() => {
    if (paymentsEdited) {
      if (pageNumber > 0) {
        // Rota para resgatar lista de pagamentos pendentes no modo Admin
        getInvoicesAdmin(
          pageNumber,
          rowsPerPage,
          searchedPayment,
          status,
          queryType,
        ).then((res) => {
          const { data } = res;
          setPayments(data?.data);
          setTotalPages(data.total);
        });
      }
      setPaymentsEdited(false);
    }
  }, [
    pageNumber,
    paymentsEdited,
    rowsPerPage,
    searchedPayment,
    status,
    queryType,
  ]);

  // Armazenar o estado do usestate dos filtros dos Tabs
  React.useEffect(() => {
    if (paymentOption === 1) {
      setStatus("pending");
    } else if (paymentOption === 2) {
      setStatus("paid");
    } else if (paymentOption === 3) {
      setStatus("payment_error");
    } else {
      setStatus("");
    }
  }, [paymentOption]);

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      payments?.map((row: any, i: number) => {
        // Checar se a fatura está vencida
        const checkOverdueInvoice =
          new Date(row?.due_date).getTime() < new Date().getTime();

        return {
          id: i + 1,
          _id: row._id,
          due_date: convertToDatePaymentPending(row?.due_date, language),
          nomeDoPlano: row.nomeDoPlano,
          total_value_cents:
            formatValueCents(row.total_value_cents.toString()) === "R$: 0"
              ? t("TEXT.FREE")
              : formatValueCents(row.total_value_cents.toString()),
          secure_url: row.secure_url,
          status:
            row.status === "paid"
              ? t("TEXT.PAID")
              : checkOverdueInvoice
                ? t("TEXT.EXPIRED")
                : row.status === "pending"
                  ? t("TEXT.PENDING")
                  : t("TEXT.FAILURE"),
          username: row.username,
          mac: row.mac,
          email: row.email,
          mobileMode: "",
          actions: "",
        };
      }),
    [language, payments, t],
  );

  // Filtrar status pendente
  const pendingRows = React.useMemo(
    () => rows.filter((f: any) => f.status === t("TEXT.PENDING")),
    [rows, t],
  );

  // Filtrar status pago
  const paidRows = React.useMemo(
    () => rows.filter((f: any) => f.status === t("TEXT.PAID")),
    [rows, t],
  );

  // Filtrar status com falha
  const failureRows = React.useMemo(
    () => rows.filter((f: any) => f.status === t("TEXT.FAILURE")),
    [rows, t],
  );

  // Alterar tipo de filtro da tabela
  const handleChangeFilter = (_: any, value: number) => {
    setPaymentOption(value);
  };

  // Ação de limpar barra de pesquisa dos Cupons
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    setSearchedPayment("");
    setSearchedClicked("");
  };

  // Ações do modal de deletar pagamento
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const handleOpenModalDelete = (row: any) => {
    setModalDeleteValues({ row });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "username",
            headerName: t("TEXT.USER"),
            headerAlign: "left",
            flex: 3,
            renderHeader: () => (
              <p
                style={{
                  fontSize: "12px",
                  color: theme === "light" ? "#7E7E7E" : "inherit",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginLeft: "15px",
                }}
              >
                {t("TEXT.USER")}
              </p>
            ),
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                    <AvatarIcon name={row.username} bgcolor={"#C7252C"} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {row.username}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Box display="flex" mt={0.2} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography
                            component="span"
                            sx={{ display: "inline" }}
                            variant="body2"
                          >
                            {row.mac}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ),
          },
          {
            field: "due_date",
            headerName: t("TEXT.DUE_DATE"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },

          {
            field: "nomeDoPlano",
            headerName: t("TEXT.PLAN"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "total_value_cents",
            headerName: t("TEXT.AMOUNT"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "secure_url",
            headerName: t("TEXT.PAYMENT_LINK"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Link href={row.secure_url} target="_blank">
                <Tooltip title={t("TEXT.ACCESS")}>
                  <IconButton
                    color={
                      row.status === t("TEXT.PENDING") ||
                      row.status === t("TEXT.FAILURE")
                        ? "info"
                        : "default"
                    }
                  >
                    <ReceiptIcon />
                  </IconButton>
                </Tooltip>
              </Link>
            ),
          },
          {
            field: "actions",
            headerName: t("TEXT.ACTIONS"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Tooltip title={t("TEXT.REMOVE")}>
                <IconButton
                  sx={{ p: 0.5 }}
                  color="error"
                  onClick={(e) => handleOpenModalDelete(row)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Tooltip>
            ),
          },
          {
            field: "status",
            sortable: false,
            disableColumnMenu: true,
            headerName: "Status",
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mt={2} mb={2}>
                <Chip
                  label={row.status}
                  sx={{
                    mr: 1,
                    fontWeight: 700,
                    backgroundColor:
                      row.status === t("TEXT.PENDING")
                        ? blue[100]
                        : row.status === t("TEXT.PAID")
                          ? green[100]
                          : "#FFD5D7",
                    color:
                      row.status === t("TEXT.PENDING")
                        ? blue[800]
                        : row.status === t("TEXT.PAID")
                          ? green[800]
                          : "#C7252C",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                />
              </Box>
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "due_date",
            hide: true,
          },
          {
            field: "total_value_cents",
            hide: true,
          },
          {
            field: "secure_url",
            hide: true,
          },
          {
            field: "status",
            hide: true,
          },
          {
            field: "nomeDoPlano",
            hide: true,
          },
          {
            field: "mobileMode",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.OUTSTANDING_PAYMENTS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemAvatar sx={{ alignSelf: "center" }}>
                      <AvatarIcon name={row.username} bgcolor={"#C7252C"} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Box display="flex">
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.DUE_DATE")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.due_date}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.USER")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.username}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              MAC:
                            </Typography>
                            <Typography variant="body2">{row.mac}</Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PLAN")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.nomeDoPlano}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.AMOUNT")}:
                            </Typography>
                            <Typography variant="body2">
                              {formatValueCents(
                                row.total_value_cents.toString(),
                              ) === "R$: 0"
                                ? t("TEXT.FREE")
                                : formatValueCents(
                                    row.total_value_cents.toString(),
                                  )}
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Typography
                              fontWeight={500}
                              sx={{
                                fontSize: 13,
                                placeSelf: "center",
                              }}
                            >
                              {t("TEXT.PAYMENT_LINK")}:
                            </Typography>
                            <Typography variant="body2">
                              <Link href={row.secure_url} target="_blank">
                                <Tooltip title={t("TEXT.ACCESS")}>
                                  <IconButton
                                    sx={{ p: 0.5 }}
                                    color={
                                      row.status === t("TEXT.PENDING") ||
                                      row.status === t("TEXT.FAILURE")
                                        ? "info"
                                        : "default"
                                    }
                                  >
                                    <ReceiptIcon />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Typography
                              sx={{
                                mr: 0.5,
                                mt: 0.3,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.ACTIONS")}:
                            </Typography>
                            <Tooltip title={t("BUTTON.REMOVE")}>
                              <IconButton
                                sx={{ p: 0 }}
                                color="error"
                                onClick={(e) => handleOpenModalDelete(row)}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 1,
                                fontSize: 13,
                              }}
                            >
                              Status:
                            </Typography>
                            <Chip
                              label={row.status}
                              sx={{
                                mr: 1,
                                fontWeight: 700,
                                backgroundColor:
                                  row.status === t("TEXT.PENDING")
                                    ? blue[100]
                                    : row.status === t("TEXT.PAID")
                                      ? green[100]
                                      : "#FFD5D7",
                                color:
                                  row.status === t("TEXT.PENDING")
                                    ? blue[800]
                                    : row.status === t("TEXT.PAID")
                                      ? green[800]
                                      : "#C7252C",
                                textTransform: "uppercase",
                                fontSize: 12,
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (payments?.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [payments, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  return (
    <Box mt={3}>
      <Tabs
        value={paymentOption}
        onChange={handleChangeFilter}
        orientation="horizontal"
        variant="scrollable"
        allowScrollButtonsMobile
        sx={{
          fontSize: 20,
          pb: 2,
          pt: 2,
          placeContent: "center",
        }}
      >
        <Tab
          icon={<CircleOutlinedIcon />}
          label={t("TEXT.ALL")}
          disableRipple
          sx={{ pt: 0 }}
        />
        <Tab
          icon={<PendingOutlinedIcon />}
          label={t("TEXT.PENDING")}
          disableRipple
          sx={{ pt: 0 }}
        />
        <Tab
          icon={<CheckCircleOutlineOutlinedIcon />}
          label={t("TEXT.PAID")}
          disableRipple
          sx={{ pt: 0 }}
        />
        <Tab
          icon={<ErrorOutlineOutlinedIcon />}
          label={t("TEXT.FAILURE")}
          sx={{ pt: 0 }}
          disableRipple
        />
      </Tabs>
      <FormControl sx={{ mt: 1 }}>
        <FormLabel>{t("BUTTON.SEARCH")}:</FormLabel>
        <RadioGroup row value={queryType} onChange={handleChangeSearch}>
          <FormControlLabel value="mac" control={<Radio />} label="MAC" />
          <FormControlLabel
            value="user"
            control={<Radio />}
            label={t("TEXT.USERNAME")}
          />
          <FormControlLabel
            value="plan"
            control={<Radio />}
            label={t("TEXT.PLAN")}
          />
        </RadioGroup>
      </FormControl>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          mr: mobile ? 2 : 4,
          borderRadius: 5,
          width: "100%",
          mt: 1,
          mb: 3,
          backgroundColor: theme === "dark" ? "#181818" : "#F9F9F9",
        }}
      >
        <Tooltip title={t("BUTTON.SEARCH")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={(e: any) => {
              setSearchedPayment(searchedClicked);
              e.preventDefault();
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <InputBase
          id="myInput"
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              setSearchedPayment(e.target.value);
              e.preventDefault();
            }
          }}
          onChange={(e: any) => {
            setSearchedClicked(e.target.value);
          }}
          sx={{ ml: 2, flex: 1 }}
          placeholder={`${t("TEXT.SEARCH_PAYMENTS")} ${t("TEXT.FOR_MAC")}`}
        />
        <Tooltip title={t("TEXT.CLEAN")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleClearSearch}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      {loading ? (
        <Box
          m={
            mobile
              ? "100px 0px"
              : window.screen.width < 1550
                ? "107px 0px"
                : "282.8px 0px"
          }
        >
          <Spinner />
        </Box>
      ) : (
        <Paper
          style={{
            height: mobile
              ? 400
              : mediumPage
                ? 621.5
                : window.screen.width < 1550
                  ? 270
                  : 621.5,
          }}
          elevation={0}
        >
          {rows && (
            <DataGridServerSide
              rows={
                paymentOption === 1
                  ? pendingRows
                  : paymentOption === 2
                    ? paidRows
                    : paymentOption === 3
                      ? failureRows
                      : rows
              }
              columns={columns}
              message={t("TOAST.INFO.NO_DATA_RECORD")}
              getRowHeight={() => "auto"}
              pageNumber={pageNumber}
              totalPages={totalPages}
              onPageChange={(newPage: number) => {
                handleChangePage(newPage);
              }}
              onPageSizeChange={(rowsPerPage: any) =>
                setRowsPerPage(rowsPerPage)
              }
              disableSelectionOnClick
            />
          )}
        </Paper>
      )}
      {modalDeleteValues && (
        <ModalRemove
          handleClose={handleCloseModalDelete}
          handleOpen={openModalDelete}
          setPaymentsEdited={setPaymentsEdited}
          row={modalDeleteValues.row}
        />
      )}
    </Box>
  );
};

export default PendingPayments;
