import React from "react";
import { useTranslation } from "react-i18next";

import { ArrowRight as ArrowRightIcon } from "@mui/icons-material";
import {
  Box,
  Divider,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";

import { ItemsProps } from ".";
import { useNavigate } from "react-router-dom";
import { REPORTS } from "../../../Routes/constants";
import { getCtrlConfig } from "../../Controller/parser";

interface MenuItemsProps {
  id: string;
  ctrl: string;
  addr: number;
  listItems: ItemsProps[];
  modelFound: boolean;
  comError: boolean;
  modelo: string;
  handleClose: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  setpointsector?: string | undefined;
  handleOpenModalDelete?: () => void;
  checked?: boolean;
}

// Itens do menu dos cards do Dashboard
const Items = ({
  id,
  ctrl,
  addr,
  listItems,
  modelFound,
  comError,
  modelo,
  handleClose,
  setpointsector,
  handleOpenModalDelete,
  checked,
}: MenuItemsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ctrlConfig = getCtrlConfig(modelo).nome;

  const handleClick = (text: string) => {
    switch (text) {
      case "TEXT.REPORTS":
        navigate(`${REPORTS}/${id}/${ctrl}/${modelo}`);
        break;
      case "TEXT.REMOVE":
        if (handleOpenModalDelete) {
          handleOpenModalDelete();
        }
        break;
      default:
        throw new Error(`Item not found "${text}`);
    }
  };

  return (
    <>
      {addr < 33 && (
        <MenuItem
          sx={{
            fontSize: 14,
            cursor: "default",
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <ListItemIcon>
            <ArrowRightIcon />
          </ListItemIcon>
          <Box display="flex">
            <Typography>{t("TEXT.ADDRESS")}:</Typography>
            <Typography ml={0.7} fontWeight={450}>
              {addr}
            </Typography>
          </Box>
        </MenuItem>
      )}
      <MenuItem
        sx={{
          fontSize: 14,
          cursor: "default",
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <ListItemIcon>
          <ArrowRightIcon />
        </ListItemIcon>
        <Box display="flex">
          <Typography>{t("TEXT.CODE")}:</Typography>
          <Typography ml={0.7} fontWeight={450}>
            {modelo}
          </Typography>
        </Box>
      </MenuItem>
      <MenuItem
        sx={{
          fontSize: 14,
          cursor: "default",
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <ListItemIcon>
          <ArrowRightIcon />
        </ListItemIcon>
        {ctrlConfig && (
          <Box display="flex">
            <Typography>{t("TEXT.MODEL")}:</Typography>
            <Typography ml={0.7} fontWeight={450}>
              {ctrlConfig}
            </Typography>
          </Box>
        )}
      </MenuItem>
      {listItems.map(({ text, icon }) => {
        const reports = text === "TEXT.REPORTS";
        const sector = text === "TEXT.REMOVE";
        const disabledItem = checked === true && text === "TEXT.REMOVE";
        const showOption =
          setpointsector !== undefined
            ? modelFound && (reports || sector)
            : modelFound && reports;

        return (
          showOption && (
            <Box
              key={text}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Divider />
              <MenuItem
                key={text}
                onClick={(event) => {
                  handleClose(event);
                  handleClick(text);
                }}
                disabled={disabledItem}
                sx={{ pt: 1.2, pb: 1.2 }}
              >
                <ListItemIcon
                  style={{
                    minWidth: "33px",
                  }}
                >
                  <ArrowRightIcon />
                </ListItemIcon>
                <ListItemIcon
                  style={{
                    minWidth: "30px",
                  }}
                >
                  {React.createElement(icon, {
                    fontSize: "small",
                    color: "primary",
                  })}
                </ListItemIcon>
                {t(text)}
              </MenuItem>
            </Box>
          )
        );
      })}
    </>
  );
};

export default Items;
