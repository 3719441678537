import moment from "moment";
import { Params } from "react-router-dom";
import { ParamEdit } from "../../contexts/ControllerEditContext";
import mqtt from "mqtt";

// Resgatar os dados dos parametros
export async function requestCtrlData(
  ctrlId: string,
  clientId: string,
  client: mqtt.MqttClient | undefined,
) {
  const timeIdStamp = moment(moment.now()).format("YYYYMMDDHHmmssSSS");
  while (clientId.length < 17) {
    clientId = "0" + clientId;
  }
  client?.subscribe(
    `${ctrlId.split("/")[0]}/${clientId}/CFG/REPLY/${timeIdStamp}`,
  );
  client?.publish(
    `${ctrlId.split("/")[0]}/CFG/GET/${ctrlId.split("/")[1]}`,
    JSON.stringify({
      "request.id": timeIdStamp,
      "requester.client.id": clientId,
    }),
  );

  return `${ctrlId.split("/")[0]}/${clientId}/CFG/REPLY/${timeIdStamp}`;
}

// Editar ação do Controlador
export async function PutCommandData(
  params: Readonly<Params<string>>,
  paramToEdit: ParamEdit<undefined> | undefined,
  values: {
    value: string | number | undefined;
  },
  client: mqtt.MqttClient | undefined,
  tipo: string,
) {
  let clientId = "666";
  const timeIdStamp = moment(moment.now()).format("YYYYMMDDHHmmssSSS");
  while (clientId.length < 17) {
    clientId = "0" + clientId;
  }
  client?.subscribe(`${params.mac}/${clientId}/CFG/REPLY/${timeIdStamp}`);

  if (tipo === "toggle") {
    client?.publish(
      `${params.mac}/CFG/PUT/${params.ctrl}/${paramToEdit?.param}/${paramToEdit?.subparam}`,
      `{"${
        paramToEdit?.subparam
      }": ${1}, "request.id":"${timeIdStamp}", "requester.client.id": "${clientId}"}`,
    );
  } else {
    client?.publish(
      `${params.mac}/CFG/PUT/${params.ctrl}/${paramToEdit?.param}/${paramToEdit?.subparam}`,
      `{"${paramToEdit?.subparam}": ${
        (values?.value! as number) * paramToEdit?.divider!
      }, "request.id":"${timeIdStamp}", "requester.client.id": "${clientId}"}`,
    );
  }

  const topic_array = localStorage.awaiting_reply;
  if (!topic_array) {
    localStorage.setItem(
      "awaiting_reply",

      `${params.mac}/${clientId}/CFG/REPLY/${timeIdStamp}=ACTION`,
    );
  } else {
    localStorage.setItem(
      "awaiting_reply",

      topic_array +
        "," +
        `${params.mac}/${clientId}/CFG/REPLY/${timeIdStamp}=ACTION`,
    );
  }

  return `${params.mac}/${clientId}/CFG/REPLY/${timeIdStamp}`;
}

// Editar parâmetro da página do Controlador
export async function SetRequestCtrlData(
  params: Readonly<Params<string>>,
  paramToEdit: ParamEdit<undefined> | undefined,
  values: {
    value: string | number | undefined;
  },
  client: mqtt.MqttClient | undefined,
) {
  let clientId = "666";
  const timeIdStamp = moment(moment.now()).format("YYYYMMDDHHmmssSSS");
  while (clientId.length < 17) {
    clientId = "0" + clientId;
  }

  client?.subscribe(`${params.mac}/${clientId}/CFG/REPLY/${timeIdStamp}`);

  if (paramToEdit?.param === "rotulo") {
    // Este é o unico caso onde é enviado uma string e possui essa rota, por isso o caso expecifico
    client?.publish(
      `${params.mac}/CFG/PUT/${params.ctrl}/${paramToEdit?.param}`,
      `{"${
        paramToEdit?.param
      }": "${values?.value!}", "request.id":"${timeIdStamp}", "requester.client.id": "${clientId}"}`,
    );
  } else {
    client?.publish(
      `${params.mac}/CFG/PUT/${params.ctrl}/${paramToEdit?.param}/${paramToEdit?.subparam}`,
      `{"${paramToEdit?.subparam}": ${
        paramToEdit?.divider!
          ? (values?.value! as number) * paramToEdit?.divider!
          : (values?.value! as number)
      }, "request.id":"${timeIdStamp}", "requester.client.id": "${clientId}"}`,
    );
  }

  const topic_array = localStorage.awaiting_reply;
  if (!topic_array) {
    localStorage.setItem(
      "awaiting_reply",

      `${params.mac}/${clientId}/CFG/REPLY/${timeIdStamp}=PARAM`,
    );
  } else {
    localStorage.setItem(
      "awaiting_reply",

      topic_array +
        "," +
        `${params.mac}/${clientId}/CFG/REPLY/${timeIdStamp}=PARAM`,
    );
  }

  return `${params.mac}/${clientId}/CFG/REPLY/${timeIdStamp}`;
}
