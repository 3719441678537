import React from "react";
import { useTranslation } from "react-i18next";

import { Share as ShareIcon } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from ".";
import { TOKEN_KEY } from "../../../auth/constants";
import ErrorMessage from "../../../components/ErrorMessage";
import {
  Select,
  TextField,
  yupRequiredEmail,
  yupRequiredString,
} from "../../../components/Form";
import { AuthContext } from "../../../contexts/AuthContext";
import { ToastContext } from "../../../contexts/ToastContext";
import { api } from "../../../services/api";
import { shareDevice } from "../../../services/data/arcsys";

interface ModalAddProps {
  id: string;
  setDeviceEdited: (e: boolean) => void;
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalShare = ({
  id,
  handleOpen,
  handleClose,
  setDeviceEdited,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { token, authenticated } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      permission: "",
    },
    validationSchema: Yup.object({
      email: yupRequiredEmail,
      permission: yupRequiredString,
    }),
    onSubmit: (values) => {
      if (authenticated) {
        if (token !== undefined) {
          localStorage.setItem(TOKEN_KEY, token);
        }
        api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);

        // Compartilhar o Dispositivo via e-mail
        shareDevice(id, { role: values.permission, email: values.email })
          .then(() => {
            toastSuccess(t("TOAST.SUCCESS.SHARE_DEVICE"));
            setDeviceEdited(true);
            handleClose();
          })
          .catch((err) => {
            const error = err.response.data.name;
            if (error === "EXISTENT") {
              toastError(t("TOAST.ERROR.SHARE_DEVICE_EXISTENT"));
            } else if (error === "FORBIDDEN") {
              toastError(t("TOAST.ERROR.SHARE_DEVICE_FORBIDDEN"));
            } else {
              toastError(t("TOAST.ERROR.SHARE_DEVICE"));
            }
          });
      }
    },
  });

  const permissions = ["EDIT", "VIEW"];

  const options = permissions.map((value: any, i: number) => (
    <MenuItem value={value} key={i}>
      {value === "EDIT" ? t("TEXT.EDIT") : t("TEXT.VIEW")}
    </MenuItem>
  ));

  const isFormValid = formik.dirty && formik.isValid;

  return (
    <Modal open={handleOpen} handleClose={handleClose} width={340}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <ShareIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("TEXT.SHARE_DEVICE")}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box display="grid" mt={2}>
          <TextField
            formik={formik}
            disabled={false}
            onClick={(e: any) => e.stopPropagation()}
            label="E-mail"
            name="email"
            fullWidth
          />
          {formik.errors.email && formik.touched.email ? (
            <ErrorMessage>{formik.errors.email}</ErrorMessage>
          ) : null}
        </Box>
        <Box display="grid" mt={2}>
          <Select
            formik={formik}
            onClick={(e: any) => e.stopPropagation()}
            label={t("TEXT.PERMISSION")}
            name="permission"
            options={options}
            disabled={false}
          />
        </Box>
        <Box display="grid" mt={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid}
            sx={{
              mt: 2,
              borderRadius: 2,
              backgroundColor: "#C7252C",
              fontSize: 12,
              pt: 1,
              pb: 1,
            }}
            fullWidth
          >
            {t("BUTTON.SEND")}
          </Button>
          <Button
            onClick={handleClose}
            fullWidth
            sx={{
              backgroundColor: "#FFD5D7",
              mt: 1,
              borderRadius: 2,
              fontSize: 12,
              pt: 1,
              pb: 1,
              color: "black",
            }}
          >
            {t("BUTTON.CANCEL")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default ModalShare;
