import React from "react";

import {
  FilterList as FilterListIcon,
  Tune as TuneIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  Assignment as AssignmentIcon,
  BrowserNotSupported as BrowserNotSupportedIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormLabel,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MobileStepper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../hooks/useIsMobile";
import {
  filteredConditions,
  firstLetterUpperCase,
  getSelectedDaysString,
  parseData,
  splitTime,
} from "../../parse";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { getLanguage } from "../../../../translations/languages";
import { ThemeModeContext } from "../../../../contexts/ThemeModeContext";
import { Acoes, Estado, RowData } from "../../../Controller/parser/types";
import { getCtrlOption } from "../../../../config";
import { getDeviceController } from "../../../../services/data/arcsys";

interface StepFourProps {
  formik: any;
  selected: string[];
  conditionsLength: number;
  checkedIcon: number[];
  sensorDivider: number[];
  estado: Estado[];
  sensores: any;
  conditionAction: string;
  acoes: Acoes[];
  parametros: RowData[];
  setpoints: RowData[];
  conditionWork: string;
  timeUnit: string;
  setIntervalTypeHour: (e: boolean) => void;
  singleShot: string;
  controllerId?: string;
  deviceName?: string;
  deviceNameAction?: string;
  ctrlInitialName: string;
}

const StepFour = ({
  formik,
  selected,
  conditionsLength,
  checkedIcon,
  sensorDivider,
  estado,
  sensores,
  conditionAction,
  acoes,
  parametros,
  setpoints,
  conditionWork,
  timeUnit,
  setIntervalTypeHour,
  singleShot,
  controllerId,
  deviceName,
  deviceNameAction,
  ctrlInitialName,
}: StepFourProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const [ctrlName, setCtrlName] = React.useState<string>("");

  const { theme } = React.useContext(ThemeModeContext);
  const [intervalValue, setIntervalValue] = React.useState<string>("");

  // Ações de avançar ou voltar stepp das condições
  const [activeStep, setActiveStep] = React.useState(0);
  const themeStepp = useTheme();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

  // Checar se o valor do 'Invervalo mínimo entre disparos' está formato 'horas' ou 'minutos'
  React.useEffect(() => {
    if (timeUnit === "hours") {
      setIntervalValue((parseInt(formik.values.hysteresis) * 60).toString());
      setIntervalTypeHour(true);
    } else {
      setIntervalValue(formik.values.hysteresis);
      setIntervalTypeHour(false);
    }
  }, [formik.values.hysteresis, setIntervalTypeHour, timeUnit]);

  // Checar as condicionais preenchidas
  const conditions = formik.values.sensorConditions
    .slice(0, conditionsLength)
    .map((condition: any, index: number) => {
      const {
        sensor,
        operator,
        operatorIcon,
        condValue,
        timeCondition,
        timeConditionFinal,
      } = condition;
      const posSensor =
        selected[index] === firstLetterUpperCase(t("TEXT.STATE"))
          ? -1
          : selected[index] === firstLetterUpperCase(t("TEXT.TIME"))
            ? -2
            : sensor;
      const operador =
        selected[index] === firstLetterUpperCase(t("TEXT.STATE"))
          ? operatorIcon
          : operator;
      const valorReferencia =
        selected[index] === firstLetterUpperCase(t("TEXT.TIME"))
          ? +timeCondition
          : selected[index] === firstLetterUpperCase(t("TEXT.STATE"))
            ? checkedIcon[index]
            : sensorDivider[index] > 0
              ? +condValue * sensorDivider[index]
              : +condValue;
      const valorReferencia_2 = +timeConditionFinal;
      const finalValue =
        timeConditionFinal !== ""
          ? { posSensor, operador, valorReferencia, valorReferencia_2 }
          : { posSensor, operador, valorReferencia };
      return finalValue;
    });

  // Filtrar o sensor selecionado
  const sensorSelected = (sensor: number) =>
    parseData(sensores)?.filter((_f: any, i: number) => +i === sensor)[0];

  // Icones dos Estados
  const conditionFilteredData = filteredConditions(estado, conditions, theme);

  // Resgata o valor final com o dividor de cada Sensor no .map
  const finalValueSensorDivider = (
    posSensor: number,
    valorReferencia: number,
  ) =>
    (sensorSelected(+posSensor) as any)?.max[1] > 0
      ? valorReferencia / (sensorSelected(+posSensor) as any)?.max[1]
      : valorReferencia;

  // Filtrar o campo 'Ação'
  const filterAction = acoes?.filter((f) => +f.id === +formik.values.action)[0];

  // Filtrar o campo 'Parametros'
  const filterParam = parametros?.filter(
    (_f, i: number) => +i === +formik.values.param,
  )[0];

  // Filtrar o campo 'Setpoints'
  const filterSetpoints = setpoints?.filter(
    (_f, i: number) => +i === +formik.values.setpoint,
  )[0];

  // Titulo da Ação
  const checkActionTitle =
    conditionAction === "0"
      ? t("TEXT.TAKE_ACTION_COMMAND")
      : conditionAction === "1"
        ? t("TEXT.PARAMETER_CHANGE")
        : conditionAction === "2"
          ? `${t("TEXT.ALTER")} setpoint`
          : t("TEXT.TELEGRAM_MESSAGE");

  // Nome da Ação
  const checkActionName =
    filterAction?.tipo === "toggle" && conditionAction === "0"
      ? filterAction?.label
      : filterAction?.tipo !== "toggle" && conditionAction === "0"
        ? `${filterAction?.label}:`
        : conditionAction === "1"
          ? `${filterParam?.description}:`
          : conditionAction === "2"
            ? `${filterSetpoints?.description}:`
            : "";

  // Unidade da Ação
  const checkActionUnit =
    conditionAction === "0"
      ? ""
      : conditionAction === "1"
        ? filterParam?.unitOfMeasurement
        : conditionAction === "2"
          ? filterSetpoints?.unitOfMeasurement
          : conditionAction === "3"
            ? formik.values.valueNotification
            : "";

  // Valor da Ação
  const checkActionValue =
    conditionAction === "0"
      ? +formik.values.valueAction
      : conditionAction === "1"
        ? +formik.values.valueParam
        : +formik.values.valueSetpoint;

  // Checar se o valor da ação não está definido
  const valueAction =
    formik.values.valueAction !== "" &&
    formik.values.valueParam !== "" &&
    formik.values.valueSetpoint !== "";

  // Checa se o parâmetro não é do tipo 'INT'
  const optionsParamNotInt =
    getCtrlOption(`d${filterParam?.type}`) ?? getCtrlOption(filterParam?.type);

  // Caso o parâmetro não é do tipo 'INT', resgata o valor
  const getValueParamNotInt =
    conditionAction !== "3" &&
    optionsParamNotInt &&
    Object.entries(optionsParamNotInt).filter(
      (f: any) => +f[0] === +formik.values.valueAction,
    )[0][1];

  React.useEffect(() => {
    if (controllerId) {
      const mac = controllerId?.split("/")[0];
      const ctrl = controllerId?.split("/")[1];

      // Regatar dados do controlador
      getDeviceController(mac, ctrl).then((res) => {
        setCtrlName(
          `${res.data.ctrl.name} (${
            res.data.ctrl._id?.split("/")[1].split("L")[1]
          })`,
        );
      });
    }
  }, [controllerId]);

  return (
    <Box
      sx={{
        maxHeight: mobile ? 440 : window.screen.width < 1550 ? 300 : 600,
        overflow: "scroll",
        overflowX: "hidden",
      }}
    >
      {!mobile && (
        <Box display="flex" justifyContent="center">
          <FormLabel
            sx={{
              textAlign: "center",
              fontSize: 20,
              mt: 1,
            }}
          >
            {t("TEXT.SCENARIOS_SUMMARY")}
          </FormLabel>
        </Box>
      )}
      <Stepper orientation="vertical" sx={{ ml: mobile ? 0 : 4, mt: 1 }}>
        <Step active sx={{ pb: mobile ? 1 : 0 }}>
          <StepLabel>
            <Typography fontSize={16} fontWeight={600}>
              {t("TEXT.SCENARIOS_IDENTIFICATION")}
            </Typography>
          </StepLabel>
          <StepContent>
            <ListItem sx={{ p: 0, pl: 0.5 }}>
              <ListItemAvatar sx={{ minWidth: 51 }}>
                <Avatar>
                  <AssignmentIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography fontSize={16} fontWeight={500}>
                    {formik.values.name}
                  </Typography>
                }
                secondary={
                  <Typography
                    fontSize={15}
                    color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                  >
                    {formik.values.description}
                  </Typography>
                }
              />
            </ListItem>
            {deviceName && (
              <ListItem sx={{ p: 0, pl: 0.5 }}>
                <ListItemAvatar sx={{ minWidth: 51 }}>
                  <Avatar>
                    <AssignmentIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography fontSize={16} fontWeight={500}>
                      {t("TEXT.DEVICE")}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      fontSize={15}
                      color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                    >
                      {deviceName}
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {ctrlInitialName && (
              <ListItem sx={{ p: 0, pl: 0.5 }}>
                <ListItemAvatar sx={{ minWidth: 51 }}>
                  <Avatar>
                    <AssignmentIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography fontSize={16} fontWeight={500}>
                      {t("TEXT.CONTROLLER")}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      fontSize={15}
                      color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                    >
                      {ctrlInitialName}
                    </Typography>
                  }
                />
              </ListItem>
            )}
          </StepContent>
        </Step>
        <Step active>
          <StepLabel sx={{ p: 0, pb: mobile ? 1 : 0 }}>
            <Typography fontSize={16} fontWeight={600}>
              {t("TEXT.DEFINING_CONDITIONS")}
            </Typography>
          </StepLabel>
          <StepContent>
            {formik.values?.daysOfWeek && (
              <ListItem sx={{ p: 0, pl: 0.5, pt: 1 }}>
                <ListItemAvatar sx={{ minWidth: 51 }}>
                  <Avatar>
                    <FilterListIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography fontSize={16} fontWeight={500}>
                      {t("TEXT.SELECT_DAYS")}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      fontSize={15}
                      color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                    >
                      {getSelectedDaysString(t, formik.values.daysOfWeek)}
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {conditions.length > 1 && (
              <ListItem sx={{ p: 0, pl: 0.5 }}>
                <ListItemAvatar sx={{ minWidth: 51 }}>
                  <Avatar>
                    <FilterListIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography fontSize={16} fontWeight={500}>
                      {t("TEXT.RELATIONSHIP_CONDITIONS")}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      fontSize={15}
                      color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                    >
                      {conditionWork === "AND"
                        ? t("TEXT.EXECUTE_ALL_CONDITION")
                        : t("TEXT.EXECUTE_ANY_CONDITION")}
                    </Typography>
                  }
                />
              </ListItem>
            )}
            <Box>
              {conditions.length > 1 && (
                <MobileStepper
                  steps={conditions.length}
                  position="static"
                  activeStep={activeStep}
                  sx={{ pb: 0, pl: 0 }}
                  nextButton={
                    <Button
                      size="small"
                      onClick={handleNext}
                      disabled={activeStep === conditions.length - 1}
                    >
                      {themeStepp.direction === "rtl" ? (
                        <KeyboardArrowLeft fontSize="large" />
                      ) : (
                        <KeyboardArrowRight fontSize="large" />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {themeStepp.direction === "rtl" ? (
                        <KeyboardArrowRight fontSize="large" />
                      ) : (
                        <KeyboardArrowLeft fontSize="large" />
                      )}
                    </Button>
                  }
                />
              )}
              <AutoPlaySwipeableViews
                axis={themeStepp.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {conditions?.map((row: any, i: number) => (
                  <ListItem sx={{ p: 0, pl: 0.5, pt: 1, height: 70 }} key={i}>
                    <ListItemAvatar sx={{ minWidth: 51 }}>
                      <Avatar>
                        <FilterListIcon />
                      </Avatar>
                    </ListItemAvatar>
                    {row.posSensor === -2 ? (
                      <ListItemText
                        primary={
                          <Typography fontSize={16} fontWeight={500}>
                            {`${t("TEXT.CONDITION")} ${i + 1}`}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            fontSize={15}
                            color={
                              theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"
                            }
                          >
                            {`${t("TEXT.IF")} ${t("TEXT.THE")} ${t(
                              "TEXT.TIME",
                            ).toLowerCase()}
                            ${t("TEXT.IS")} ${
                              row.operador === "maior"
                                ? t("TEXT.TIME_AFTER")
                                : row.operador === "menor"
                                  ? t("TEXT.TIME_BEFORE")
                                  : t("TEXT.BETWEEN")
                            } ${
                              language === "es"
                                ? t("TEXT.TIME_DAS")
                                : language === "pt"
                                  ? row.operador === "menor"
                                    ? t("TEXT.TIME_DAS")
                                    : row.operador === "maior"
                                      ? "as"
                                      : ""
                                  : t("TEXT.THAN")
                            }
                            ${splitTime(row.valorReferencia * 1000, language)}
                            ${row.operador === "entre" ? t("TEXT.AND") : ""}
                            ${
                              row.operador === "entre"
                                ? splitTime(
                                    row.valorReferencia_2 * 1000,
                                    language,
                                  )
                                : ""
                            }`}
                          </Typography>
                        }
                      />
                    ) : row.operador === "igual" ||
                      row.operador === "diferente" ||
                      row.operador === "qualquer" ? (
                      <ListItemText
                        secondary={
                          <Box component="span" display="flex">
                            <Box component="span" alignSelf="center">
                              <Typography
                                fontSize={16}
                                fontWeight={500}
                                component="span"
                                sx={{
                                  color: theme === "light" ? "black" : "white",
                                  display: "grid",
                                }}
                              >
                                {`${t("TEXT.CONDITION")} ${i + 1}`}
                              </Typography>
                              {row.operador !== "qualquer" && (
                                <Typography
                                  fontSize={15}
                                  component="span"
                                  alignSelf="center"
                                  mr={0.5}
                                  color={
                                    theme === "light"
                                      ? "rgba(0, 0, 0, 0.6)"
                                      : "#FFF"
                                  }
                                >
                                  {`${t("TEXT.IF")} ${t("TEXT.THE")}
                            ${t("TEXT.STATE")} 
                            ${t("TEXT.IS")} ${
                              row.operador === "igual"
                                ? t("TEXT.EQUAL").toLowerCase()
                                : t("TEXT.DIFFERENT").toLowerCase()
                            } ${
                              language === "es"
                                ? row.operador === "igual"
                                  ? t("TEXT.TO")
                                  : "de"
                                : language === "pt"
                                  ? row.operador === "igual"
                                    ? t("TEXT.TO")
                                    : "de"
                                  : row.operador === "igual"
                                    ? t("TEXT.TO")
                                    : t("TEXT.TO")
                            }`}
                                </Typography>
                              )}
                              {row.operador === "qualquer" && (
                                <Typography
                                  fontSize={13.5}
                                  component="span"
                                  alignSelf="center"
                                  mr={0.5}
                                >
                                  {`${t("TEXT.IF")} ${t("TEXT.THE")}
                            ${t("TEXT.STATE")} 
                            ${t("TEXT.ANYONE").toLowerCase()}`}
                                </Typography>
                              )}
                            </Box>
                            <Box alignSelf="center" component="span">
                              {conditionFilteredData[i]?.length !== 0 ? (
                                <Grid
                                  container
                                  component="span"
                                  width={mobile ? 60 : 120}
                                  mb={-3.5}
                                  spacing={0.18}
                                  ml={0.2}
                                >
                                  {conditionFilteredData[i]?.map(
                                    (
                                      { icon, description }: any,
                                      index: number,
                                    ) => (
                                      <Grid item key={index} component="span">
                                        <Tooltip
                                          title={description.active}
                                          enterTouchDelay={0}
                                        >
                                          <Box component="span">{icon}</Box>
                                        </Tooltip>
                                      </Grid>
                                    ),
                                  )}
                                </Grid>
                              ) : (
                                <Box component="span">
                                  <Box mt={3.5}>
                                    <Tooltip
                                      title={t("TEXT.NO_STATE_SELECTED")}
                                      enterTouchDelay={0}
                                    >
                                      <BrowserNotSupportedIcon
                                        sx={{
                                          color:
                                            theme === "light"
                                              ? "#B2B2B2"
                                              : "gray",
                                          width: "28px",
                                          height: "28px",
                                        }}
                                      />
                                    </Tooltip>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        }
                      />
                    ) : (
                      <ListItemText
                        primary={
                          <Typography fontSize={16} fontWeight={500}>
                            {`${t("TEXT.CONDITION")} ${i + 1}`}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            fontSize={15}
                            color={
                              theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"
                            }
                          >
                            {`${t("TEXT.IF")} ${
                              sensorSelected(+row.posSensor)?.description
                            } ${t("TEXT.IS")} ${
                              row.operador === "maior"
                                ? t("TEXT.BIGGER").toLowerCase()
                                : t("TEXT.SMALLER").toLowerCase()
                            } ${t("TEXT.THAN")} 
                            ${finalValueSensorDivider(
                              row.posSensor,
                              row.valorReferencia,
                            )}
                          ${sensorSelected(+row.posSensor)?.min}`}
                          </Typography>
                        }
                      />
                    )}
                  </ListItem>
                ))}
              </AutoPlaySwipeableViews>
            </Box>
          </StepContent>
        </Step>
        <Step active>
          <StepLabel sx={{ p: 0, pb: mobile ? 1 : 0 }}>
            <Typography fontSize={16} fontWeight={600}>
              {t("TEXT.DEFINITION_ACTIONS")}
            </Typography>
          </StepLabel>
          <StepContent>
            <ListItem sx={{ p: 0, pl: 0.5, pt: 1 }}>
              <ListItemAvatar sx={{ minWidth: 51 }}>
                <Avatar>
                  <PlayCircleOutlineIcon />
                </Avatar>
              </ListItemAvatar>
              {conditionAction === "0" && filterAction?.tipo === "toggle" ? (
                <ListItemText
                  primary={
                    <Typography fontSize={16} fontWeight={500}>
                      {checkActionTitle}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      fontSize={15}
                      color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                    >
                      {checkActionName}
                    </Typography>
                  }
                />
              ) : optionsParamNotInt ? (
                <ListItemText
                  primary={
                    <Typography fontSize={16} fontWeight={500}>
                      {checkActionTitle}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      fontSize={15}
                      color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                    >
                      {conditionAction === "3"
                        ? checkActionUnit
                        : `${checkActionName} ${getValueParamNotInt}`}
                    </Typography>
                  }
                />
              ) : !optionsParamNotInt ? (
                <ListItemText
                  primary={
                    <Typography fontSize={16} fontWeight={500}>
                      {checkActionTitle}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      fontSize={15}
                      color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                    >
                      {conditionAction === "3"
                        ? checkActionUnit
                        : `${checkActionName} ${checkActionValue} ${checkActionUnit}`}
                    </Typography>
                  }
                />
              ) : (
                valueAction && (
                  <Typography fontSize={15} mt={0.2} fontWeight={500}>
                    {checkActionName}
                  </Typography>
                )
              )}
            </ListItem>
            {deviceNameAction && conditionAction !== "3" && (
              <ListItem sx={{ p: 0, pl: 0.5 }}>
                <ListItemAvatar sx={{ minWidth: 51 }}>
                  <Avatar>
                    <PlayCircleOutlineIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography fontSize={16} fontWeight={500}>
                      {t("TEXT.DEVICE")}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      fontSize={15}
                      color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                    >
                      {deviceNameAction}
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {controllerId && conditionAction !== "3" && (
              <ListItem sx={{ p: 0, pl: 0.5, pt: 1 }}>
                <ListItemAvatar sx={{ minWidth: 51 }}>
                  <Avatar>
                    <PlayCircleOutlineIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography fontSize={16} fontWeight={500}>
                      {t("TEXT.CONTROLLER")}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      fontSize={15}
                      color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                    >
                      {ctrlName}
                    </Typography>
                  }
                />
              </ListItem>
            )}
          </StepContent>
        </Step>
        <Step active>
          <StepLabel sx={{ p: 0, pb: mobile ? 1 : 0 }}>
            <Typography fontSize={16} fontWeight={600}>
              {t("TEXT.ADDITIONAL_SETTINGS")}
            </Typography>
          </StepLabel>
          <StepContent>
            <ListItem sx={{ p: 0, pl: 0.5, pt: 1 }}>
              <ListItemAvatar sx={{ minWidth: 51 }}>
                <Avatar>
                  <TuneIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography fontSize={16} fontWeight={500}>
                    {t("TEXT.FIRING_FREQUENCY")}
                  </Typography>
                }
                secondary={
                  <Typography
                    fontSize={15}
                    color={theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"}
                  >
                    {singleShot === "0"
                      ? t("TEXT.SINGLE_SHOT")
                      : singleShot === "1"
                        ? t("TEXT.CONTINUOUS_SHOOTING")
                        : t("TEXT.DAILY_SHOT")}
                  </Typography>
                }
              />
            </ListItem>
            {singleShot === "1" && (
              <Box component="span">
                <ListItem sx={{ p: 0, pl: 0.5 }}>
                  <ListItemAvatar sx={{ minWidth: 51 }}>
                    <Avatar>
                      <TuneIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography fontSize={16} fontWeight={500}>
                        {t("TEXT.MINIMUM_INTERVAL_SHOTS")}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        fontSize={15}
                        color={
                          theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"
                        }
                      >
                        {`${intervalValue} ${t("TEXT.MINUTES")}`}
                      </Typography>
                    }
                  />
                </ListItem>
              </Box>
            )}
            {formik.values?.disable_on_error !== undefined &&
              singleShot !== "0" && (
                <ListItem sx={{ p: 0, pl: 0.5 }}>
                  <ListItemAvatar sx={{ minWidth: 51 }}>
                    <Avatar>
                      <TuneIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography fontSize={16} fontWeight={500}>
                        {t("TEXT.DISABLE_AFTER_ERROR")}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        fontSize={15}
                        color={
                          theme === "light" ? "rgba(0, 0, 0, 0.6)" : "#FFF"
                        }
                      >
                        {formik.values?.disable_on_error === true
                          ? t("TEXT.ACTIVE")
                          : t("TEXT.INACTIVE")}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
};

export default StepFour;
