import React, { Context } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Logout as LogoutIcon,
  PersonOutline as PersonOutlineIcon,
  ChatBubbleOutlineOutlined as ChatBubbleOutlineOutlinedIcon,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  ListItemIcon,
  MenuItem,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { AuthContext } from "../../../contexts/AuthContext";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import { LOGIN, PROFILE } from "../../../Routes/constants";
import ThemeSwitch from "../../ThemeSwitch";
import { version } from "../../../version";
import { WelcomeContext } from "../../../contexts/WelcomeContext";
import { MqttContext } from "../../../contexts/MqttContext";
import { ArcsysContext } from "../../../contexts/ArcsysProviderContext";
import { SectorsContext } from "../../../contexts/SectorContext";
import { AccordionMacListContext } from "../../../contexts/AccordeonOrder";
import { CurrentMqttSubsContext } from "../../../contexts/CurrentMqttSubsContext";
import { DashboardFilterContext } from "../../../contexts/DashboardFilterContext";
import { TagsContext } from "../../../contexts/TagsContext";

interface ItemsProps {
  text: string;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">> & {
    muiName: string;
  };
  path?: string;
  callback?: () => void;
}

interface FormatWordProps {
  word: string;
}

const FormatWord = ({ word }: FormatWordProps) => {
  return word !== "Alterar Senha" ? (
    <Box width={96}>
      <Typography>{word.split(" ")[0]}</Typography>
      <Typography>{word.split(" ")[1]}</Typography>
    </Box>
  ) : (
    <Typography>{word}</Typography>
  );
};

interface MenuItemsProps {
  handleClose: () => void;
  colorModeContext: Context<{ toggleColorMode: () => void }>;
  setChangeColor: (e: string) => void;
  handleOpenModalFeedback: () => void;
}

const MenuItems = ({
  handleClose,
  colorModeContext,
  setChangeColor,
  handleOpenModalFeedback,
}: MenuItemsProps) => {
  const { setWelcome, setIsOpenned } = React.useContext(WelcomeContext);
  const {
    setMacsToSubscribe,
    setDevicesOffline,
    setIsMacsToSubscribeRequested,
  } = React.useContext(ArcsysContext);
  const { setSectors } = React.useContext(SectorsContext);
  const { setMqtt } = React.useContext(MqttContext);
  const { setAccordionMacList } = React.useContext(AccordionMacListContext);
  const { setCurrentMqtt } = React.useContext(CurrentMqttSubsContext);
  const { setTag, setIsOfflineDevicesStored } = React.useContext(
    DashboardFilterContext,
  );
  const { setTagsList } = React.useContext(TagsContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const colorMode = React.useContext(colorModeContext);
  const { theme } = React.useContext(ThemeModeContext);
  const { signOut, userId } = React.useContext(AuthContext);
  const [checked, setChecked] = React.useState<boolean>(false);

  const handleLogout = () => {
    signOut().finally(() => {
      if (theme === "dark") {
        colorMode.toggleColorMode();
      }
      setWelcome(false);
      setIsOpenned(true);
      setChangeColor("/");
      navigate(LOGIN);
      // Clear context
      setMqtt({});
      setDevicesOffline([]);
      setMacsToSubscribe([]);
      setIsMacsToSubscribeRequested(false);
      setAccordionMacList([]);
      setCurrentMqtt("");
      setTag([]);
      setTagsList([]);
      setIsOfflineDevicesStored(false);
      setSectors([]);
    });
  };

  React.useEffect(() => {
    if (theme === "light") {
      setChecked(false);
    } else if (theme === "dark") {
      setChecked(true);
    }
  }, [theme]);

  const Items: ItemsProps[] = [
    {
      text: "TEXT.PROFILE",
      icon: PersonOutlineIcon,
      path: `${PROFILE}/${userId}`,
    },
    {
      text: "TEXT.LOGOUT",
      icon: LogoutIcon,
      callback: handleLogout,
    },
    {
      text: "Feedback",
      icon: ChatBubbleOutlineOutlinedIcon,
      callback: handleOpenModalFeedback,
    },
  ];

  return (
    <>
      <Box textAlign="center" pb={2.5} pt={2}>
        <ThemeSwitch checked={checked} onClick={colorMode.toggleColorMode} />
      </Box>
      <Divider />
      <Box mb={1.5} mt={1}>
        {Items.map((item) => {
          const { text, icon, path, callback } = item;
          return (
            <MenuItem
              key={item.text}
              onClick={() => {
                handleClose();
                callback?.();
                if (path) {
                  navigate(path);
                }
              }}
            >
              <ListItemIcon>
                {React.createElement(icon, { fontSize: "small" })}
              </ListItemIcon>
              <FormatWord word={t(text)} />
            </MenuItem>
          );
        })}
      </Box>
      <Divider />
      <Box textAlign="center" mt={1}>
        <Typography variant="caption" fontSize={14}>
          {version}
        </Typography>
      </Box>
    </>
  );
};

export default MenuItems;
