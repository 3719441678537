import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

import { Alarmes, Color } from "../components/Controller/parser/types";
import { Ctrl, Measures } from "../services/types/ctrls";

interface ControllerProviderProps {
  children: ReactNode;
}

export interface CardIcon {
  active: boolean;
  description: {
    active: string;
    inactive: string;
  };
  icon: React.JSX.Element;
}

export interface CardData {
  alarm?: Alarmes;
  color: Color;
  icons: CardIcon[];
  measures: Measures;
}

interface ControllerData {
  cardData: CardData | undefined;
  ctrlData: Ctrl | undefined;
  setCardData: Dispatch<SetStateAction<CardData | undefined>>;
  setCtrlData: Dispatch<SetStateAction<Ctrl | undefined>>;
  acoes: boolean | undefined;
  setAcoes: Dispatch<SetStateAction<boolean | undefined>>;
}

export const ControllerContext = createContext({} as ControllerData);

export const ControllerProvider = ({ children }: ControllerProviderProps) => {
  const [cardData, setCardData] = useState<CardData>();
  const [ctrlData, setCtrlData] = useState<Ctrl>();
  const [acoes, setAcoes] = useState<boolean>();

  const ControllerProviderValue = useMemo(
    () => ({
      cardData,
      ctrlData,
      acoes,
      setAcoes,
      setCardData,
      setCtrlData,
    }),
    [acoes, cardData, ctrlData],
  );

  return (
    <ControllerContext.Provider value={ControllerProviderValue}>
      {children}
    </ControllerContext.Provider>
  );
};
