import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Chip, TableCell, TableRow, Typography } from "@mui/material";

import { getCtrlOption } from "../../../config";
import useIsMobile, { useIsMediumPage } from "../../../hooks/useIsMobile";
import {
  GetSubparam,
  GetSubparamType,
  Param,
} from "../../../services/types/ctrls";
import { convertParamToTranslateKey } from "../parser/translations";
import { DataType, RowData } from "../parser/types";
import { applyDivider } from "../parser/utils";
import EditButton from "./EditButton";
import {
  ControllerEditContext,
  ParamEdit,
} from "../../../contexts/ControllerEditContext";
import Slider from "../../Slider";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import { ToastContext } from "../../../contexts/ToastContext";
import { useParams } from "react-router-dom";

interface RowTitleProps {
  children: React.ReactNode;
}

export const RowTitle = ({ children }: RowTitleProps) => (
  <TableRow>
    <TableCell colSpan={4}>
      <Typography variant="h6" pl={1}>
        {children}
      </Typography>
    </TableCell>
  </TableRow>
);

const convertParamEdit = <T extends Param | undefined = undefined>(
  config: RowData,
  value: string | number,
  divider: number,
  param: T,
  subparam: GetSubparam<T>,
) => {
  const editValues = {
    ...config,
    value,
    divider,
    param,
    subparam,
  } as unknown as ParamEdit;
  delete editValues.defaultValue;
  return editValues;
};

const parseBinary = (value: number, dataType: DataType) => {
  const { amountOfValues, prefix, separator } = dataType;
  const BITS_IN_NUMBER = 16;
  const bitLength = Math.floor(BITS_IN_NUMBER / amountOfValues); // 8
  const remainder = BITS_IN_NUMBER % bitLength;
  const valuesLength = Math.floor(BITS_IN_NUMBER / bitLength); // 2

  let binary = (value >>> 0).toString(2);
  if (binary.length < BITS_IN_NUMBER) {
    binary = binary.padStart(BITS_IN_NUMBER - remainder, "0");
  } else {
    binary = binary.slice(BITS_IN_NUMBER);
  }

  const values = [];
  for (let bits = valuesLength; bits > 0; bits--) {
    const endIndex = bits * bitLength;
    const startIndex = endIndex - bitLength;
    const slice = binary.slice(startIndex, endIndex);
    const parsed = parseInt(slice, 2);
    values.push(parsed);
  }
  values.reverse();

  const getPrefix = (i: number) =>
    prefix ? (typeof prefix === "string" ? prefix : prefix[i]) : "";
  const result = values
    .map((v, i) => `${getPrefix(i)}${separator ?? ""}${v}`)
    .join(", ");

  return result;
};

const isNumber = (type: string) => ["NUM", "INT"].includes(type);
const isNumberOff = (type: string) => ["NUMOFF", "INTOFF"].includes(type);

// Linhas dos parâmetros do Controlador
export const Row = <T extends Param>({
  param,
  config,
  values,
  permissionToEdit,
  devicePermission,
  checkFrequencySetpoint,
  rawCtrlConfig,
}: {
  param: T;
  config: RowData[];
  values: GetSubparamType<T>;
  permissionToEdit: boolean;
  devicePermission: boolean;
  checkFrequencySetpoint?: boolean;
  rawCtrlConfig: any;
}) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const mediumPage = useIsMediumPage();
  const currentValues =
    values && (Object.entries(values) as [GetSubparam<T>, number][]);
  const { setParamToEdit } = React.useContext(ControllerEditContext);
  const { theme } = React.useContext(ThemeModeContext);
  const { toastError } = React.useContext(ToastContext);
  const urlParam = useParams();
  const paramCtrl = urlParam.ctrl;

  return config?.length ? (
    <>
      <RowTitle>{t(convertParamToTranslateKey(param))}</RowTitle>
      {config?.map(
        (
          {
            code,
            description,
            divider,
            dataType,
            min,
            max,
            separator,
            defaultValue,
            unitOfMeasurement,
            readOnly,
            type,
            visible,
          },
          i,
        ) => {
          const convertValue = (value: number | string) =>
            typeof value === "number"
              ? applyDivider(value, divider ?? 0)
              : value;

          const minimum = convertValue(min);
          const maximum = convertValue(max);

          const range = isNumber(type)
            ? `${minimum}${separator}${maximum}${unitOfMeasurement}`
            : isNumberOff(type)
              ? `oFF${separator}${maximum}${unitOfMeasurement}`
              : "";
          const [paramKey, paramValue] =
            currentValues && currentValues[i] && currentValues[i];

          const valueToDisplay = () => {
            const options = getCtrlOption(type);
            if (options) {
              return options[paramValue];
            }
            switch (type) {
              case "INTBIN": {
                // Substituir valores iguais a E0 por '-'
                const checkValueE0 = parseBinary(paramValue, dataType)
                  .replaceAll("E0", " - ")
                  .toString();

                return checkValueE0;
              }
              case "NUM": {
                const value = applyDivider(
                  paramValue ?? defaultValue,
                  divider ?? 0,
                );

                return !Number.isInteger(value) ? value : `${value}.0`;
              }
              default:
                return applyDivider(paramValue ?? defaultValue, divider ?? 0);
            }
          };
          const value = applyDivider(paramValue ?? defaultValue, divider ?? 0);

          // Checar se o setpoint do inversor é igual a 0
          const checkSetpoint =
            param === "setpoints" &&
            value === 0 &&
            rawCtrlConfig?.inversor &&
            rawCtrlConfig?.inversor === true;

          const minValue = type === "INTOFF" ? +minimum : +minimum - 1;

          const paramEdit = convertParamEdit(
            config[i],
            checkSetpoint ? "oFF" : value === minValue ? "oFF" : value,
            divider ?? 0,
            param,
            paramKey,
          );
          const viewEditButton = !readOnly;

          // Verificar se o dispositivo tem permissão para editar
          const handleClick = () =>
            checkFrequencySetpoint === true
              ? toastError(t("TOAST.ERROR.INVERTER_ON"))
              : permissionToEdit
                ? setParamToEdit(paramEdit)
                : toastError(t("TOAST.ERROR.SHARE_DEVICE_PERMISSION"));

          const checkIsDisabledValue = () =>
            checkSetpoint
              ? "oFF"
              : valueToDisplay() === minValue
                ? "oFF"
                : valueToDisplay() === 3276.7
                  ? "--"
                  : valueToDisplay();

          const checkIsDisabledUnit = () =>
            checkSetpoint
              ? ""
              : valueToDisplay() === minValue
                ? ""
                : valueToDisplay() === 3276.7
                  ? ""
                  : unitOfMeasurement;

          return (
            visible && (
              <TableRow
                key={code}
                hover
                onClick={() =>
                  viewEditButton && devicePermission && handleClick()
                }
                sx={{
                  cursor:
                    viewEditButton && devicePermission ? "pointer" : "default",
                }}
              >
                <TableCell
                  sx={{
                    textAlign: "center",
                    verticalAlign: "text-top",
                    maxWidth:
                      paramCtrl === "CTRL36" && mobile
                        ? 42
                        : paramCtrl === "CTRL36"
                          ? 34
                          : 180,
                  }}
                >
                  {React.createElement(Chip, {
                    label:
                      param === "alarme" ? description.split(" ")[0] : code,
                    sx: {
                      borderRadius: 1,
                      fontSize: mobile ? 11 : 14,
                      width: 60,
                      height: 40,
                      mr: mobile ? 0.5 : 0,
                      ml: mobile ? 1 : 0,
                      mb: isNumber(type) || isNumberOff(type) ? 0 : 1,
                    },
                  })}
                </TableCell>
                <TableCell sx={{ verticalAlign: "text-top", maxWidth: 330 }}>
                  {mobile || mediumPage ? (
                    <>
                      <Typography
                        sx={{
                          mt: paramCtrl === "CTRL36" ? 1.5 : 0.5,
                          fontSize: 14,
                          ml: paramCtrl === "CTRL36" ? 2 : 0,
                          mb: paramCtrl === "CTRL36" ? 2 : 0,
                        }}
                      >
                        {param === "alarme"
                          ? description.substring(
                              description.indexOf(" ") + 1,
                              description.length,
                            )
                          : description}
                      </Typography>
                      {paramCtrl !== "CTRL36" && (
                        <Typography variant="body2" color="text.secondary">
                          {range}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          mt: 2,
                          color: theme === "light" ? "#6D6D6D" : "#FFFF",
                          mb: paramCtrl === "CTRL36" ? 2 : 0,
                          fontSize: 16,
                        }}
                      >
                        {param === "alarme"
                          ? description.substring(
                              description.indexOf(" ") + 1,
                              description.length,
                            )
                          : description}
                      </Typography>
                    </>
                  )}
                </TableCell>
                {paramCtrl === "CTRL36" && <TableCell></TableCell>}
                {+minimum === +maximum &&
                checkIsDisabledValue() !== +minimum ? (
                  <TableCell></TableCell>
                ) : (
                  <TableCell sx={{ pb: 0, pt: 1.5 }} align="right">
                    {(isNumber(type) || isNumberOff(type)) &&
                    !mobile &&
                    !mediumPage &&
                    viewEditButton ? (
                      <Slider
                        minimum={+minimum}
                        maximum={+maximum}
                        unit={unitOfMeasurement}
                        value={value}
                      />
                    ) : (
                      <Box pt={2} pb={4} />
                    )}
                  </TableCell>
                )}
                <TableCell align={mobile ? "center" : "right"}>
                  <Typography
                    variant="body1"
                    sx={{
                      mt: 0.5,
                      fontSize: mobile ? 14 : 16,
                      color: theme === "light" ? "#6D6D6D" : "#FFFF",
                    }}
                  >
                    {checkIsDisabledValue()}
                    {checkIsDisabledUnit()}
                  </Typography>
                </TableCell>
                <TableCell align={mobile ? "center" : "right"}>
                  {viewEditButton && devicePermission && <EditButton />}
                </TableCell>
              </TableRow>
            )
          );
        },
      )}
    </>
  ) : null;
};
