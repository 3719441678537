import React from "react";
import { useTranslation } from "react-i18next";

import { DoneOutline as DoneOutlineIcon } from "@mui/icons-material";
import { Avatar, Button, Paper, Typography } from "@mui/material";

import { ToastContext } from "../../../contexts/ToastContext";
import { sendSectorCommand } from "../../../services/data/sector";
import Modal from "../../Devices/Modal";

interface ModalApplyProps {
  data: any;
  value: number;
  submitValue: number | null;
  setSubmitValue: (e: number | null) => void;
  handleOpen: boolean;
  handleClose: () => void;
  setValue: (e: number) => void;
  startIntervalApply: () => void;
}

const ModalApply = ({
  data,
  handleOpen,
  value,
  submitValue,
  setSubmitValue,
  handleClose,
  setValue,
  startIntervalApply,
}: ModalApplyProps) => {
  const { t } = useTranslation();
  const { toastError } = React.useContext(ToastContext);

  // Ação do botão de Aplicar
  const handleClickApply = () => {
    const dataApply = {
      resource: data.default_resource,
      value: +value.toFixed(1),
    };
    sendSectorCommand(data._id, dataApply)
      .then(() => {
        setSubmitValue(value);
        startIntervalApply();
        handleClose();
      })
      .catch(() => {
        toastError(t("ERROR.OPERATION_IN_PROGRESS"));
      });
  };

  // Ação de fechar modal
  const handleCloseModalApply = () => {
    if (submitValue !== null) {
      setValue(+submitValue.toFixed(1));
    } else {
      setValue(
        Object.values(data.resource_value)[
          data.default_resource.split("/")[1].split("s")[1]
        ] as number,
      );
    }
    handleClose();
  };

  return (
    <Modal open={handleOpen} handleClose={handleCloseModalApply} width={325}>
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <DoneOutlineIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={21} fontWeight={700} mb={1}>
        {t("BUTTON.APPLY")} Setpoint?
      </Typography>
      <Typography sx={{ mt: 1 }} fontSize={15} variant="body2">
        {t("TEXT.APPLY_SETPOINT_MESSAGE")}
      </Typography>
      <Button
        variant="contained"
        color="error"
        fullWidth
        onClick={handleClickApply}
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: "#C7252C",
          fontSize: 12,
          pt: 1,
          pb: 1,
        }}
      >
        {t("BUTTON.CONFIRM")}
      </Button>
      <Button
        onClick={handleCloseModalApply}
        fullWidth
        sx={{
          backgroundColor: "#FFD5D7",
          mt: 1,
          borderRadius: 2,
          fontSize: 12,
          pt: 1,
          pb: 1,
          color: "black",
        }}
      >
        {t("BUTTON.CANCEL")}
      </Button>
    </Modal>
  );
};

export default ModalApply;
