import React from "react";
import { useTranslation } from "react-i18next";

import "./index.css";
import c3, { AxisName, ChartType } from "c3";

import "c3/c3.css";
import { ThemeModeContext } from "../../../../contexts/ThemeModeContext";
import { getLanguage } from "../../../../translations/languages";
import { blue, red, lightGreen, orange } from "@mui/material/colors";

interface GraphProps {
  graphData: any;
  graphTimes: number[];
  mobile: boolean;
}

type GraphTypes = {
  [key: string]: ChartType;
};

type GraphAxes = {
  [key: string]: AxisName;
};

type GraphColors = {
  [key: string]: string;
};

// Gráfico da página do Controlador
const Graph = ({ graphData, graphTimes, mobile }: GraphProps) => {
  const { t } = useTranslation();
  const { theme } = React.useContext(ThemeModeContext);
  // pt || en || es
  const language = getLanguage().code;
  const format = language === "en" ? "%Y-%m-%d %H:%M" : "%d-%m-%Y %H:%M";
  const formatDate =
    language === "en" ? "en-US" : language === "pt" ? "pt-BR" : "es-ES";

  // Alterar as strings '"--"' recebidas na lista para 'null'
  const replaceStringToNull = React.useMemo(
    () =>
      graphData.map((v: any) => {
        const newValue = v.map((o: any) => {
          if (o === "--") {
            return null;
          }
          return o;
        });
        return newValue;
      }),
    [graphData],
  );

  const types: GraphTypes = {};
  const axes: GraphAxes = {};
  const tooltip_order: any[] = [];
  const colors: GraphColors = {};
  graphData.forEach((element: any[]) => {
    tooltip_order.push(element[0]);
    if (element[0].includes("(") && element[0].includes(")")) {
      // Medidas
      types[element[0]] = "line";
      axes[element[0]] = "y";
    } else {
      // Estados
      types[element[0]] = "area-step";
      axes[element[0]] = "y2";
      if (
        element[0].includes("Degelo") ||
        element[0].includes("Defrost") ||
        element[0].includes("Deshielo")
      ) {
        colors[element[0]] = orange[600];
      }

      if (
        element[0].includes("Comp") // Compressor and Compresor
      ) {
        colors[element[0]] = blue[600];
      }

      if (
        element[0].includes("Aquecimento") ||
        element[0].includes("Heating") ||
        element[0].includes("Calefac")
      ) {
        colors[element[0]] = red[500];
      }

      if (
        element[0].includes("Eco") // Eco and Economico
      ) {
        colors[element[0]] = lightGreen[500];
      }
    }
  });

  // Criar o payload dos dados do gráfico
  const columns = React.useMemo(
    () => [["x", ...graphTimes], ...replaceStringToNull],
    [graphTimes, replaceStringToNull],
  );

  React.useEffect(() => {
    c3.generate({
      bindto: "#chart",
      point: { show: false },
      padding: {
        right: 30,
        bottom: 10,
      },
      size: { height: 500 },
      data: {
        x: "x",
        columns: columns,
        types: types,
        axes: axes,
        colors: colors,
      },
      tooltip: {
        grouped: true,
        order: tooltip_order,
        format: {
          title: function (d) {
            return t("TEXT.DATE") + ": " + d?.toLocaleString(formatDate);
          },
          value: function (value, ratio, id) {
            return !id.includes("(") && !id.includes(")")
              ? value
                ? t("TEXT.ACTIVE")
                : t("TEXT.INACTIVE")
              : value?.toString();
          },
        },
      },
      zoom: {
        enabled: true,
        type: "scroll",
        rescale: true,
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            width: 55,
            count: mobile ? 4 : 7,
            format,
          },
          height: 60,
          label: {
            text: t("TEXT.HOUR"),
            position: "outer-center",
          },
        },
        y2: {
          max: 0.8,
          show: true,
          tick: {
            count: 1,
          },
          padding: { top: 50 },
        },
      },
      subchart: { show: true, axis: { x: { show: true } } },
      grid: {
        x: {
          show: false,
        },
        y: {
          show: true,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, format, mobile, t]);

  return (
    <div
      id="chart"
      style={{ fill: theme === "light" ? "black" : "white", textAlign: "left" }}
    />
  );
};

export default Graph;
