import { FormControl, InputLabel, Select as SelectItens } from "@mui/material";

import { SelectProps } from "./types";

function get(object: any, path: string): any {
  const result = object;
  if (!path.includes("sensorConditions")) {
    if (path in result) {
      return result[path];
    }
    return "";
  }
  const nested = path.split(".")[1];
  const index = path.split("[")[1][0];
  return result?.sensorConditions[+index][nested];
}

// Input de select que recebe a lib Formik
export const Select = ({
  formik,
  name,
  label,
  options,
  isDisabled,
  ...rest
}: SelectProps) => {
  return (
    <FormControl fullWidth disabled={isDisabled}>
      <InputLabel id="value">{label}</InputLabel>
      <SelectItens
        labelId={label}
        name={name}
        value={get(formik.values, name)}
        label={label}
        onChange={formik?.handleChange}
        required
        MenuProps={{ PaperProps: { sx: { maxHeight: 450 } } }}
        {...rest}
      >
        {options}
      </SelectItens>
    </FormControl>
  );
};
