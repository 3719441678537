import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

interface DashboardFilterProps {
  children: ReactNode;
}

interface DashboardFilterData {
  searchedDevice: string;
  setSearchedDevice: Dispatch<SetStateAction<string>>;
  selectedDevice: string;
  setSelectedDevice: Dispatch<SetStateAction<string>>;
  tag: any[];
  setTag: Dispatch<SetStateAction<any[] | []>>;
  dashboardDeviceEdited: boolean;
  setDashboardDeviceEdited: Dispatch<SetStateAction<boolean>>;
  isOfflineDevicesStored: boolean;
  setIsOfflineDevicesStored: Dispatch<SetStateAction<boolean>>;
  orderOfRenderGrater: boolean;
  setOrderOfRenderGrater: Dispatch<SetStateAction<boolean>>;
}

export const DashboardFilterContext = createContext({} as DashboardFilterData);

export const DashboardFilterProvider = ({ children }: DashboardFilterProps) => {
  const [searchedDevice, setSearchedDevice] = useState<string>("");
  const [selectedDevice, setSelectedDevice] = useState<string>("");
  const [orderOfRenderGrater, setOrderOfRenderGrater] = useState(true);
  const [dashboardDeviceEdited, setDashboardDeviceEdited] =
    useState<boolean>(false);
  const [isOfflineDevicesStored, setIsOfflineDevicesStored] =
    useState<boolean>(false);
  const [tag, setTag] = useState<any[]>([]);

  const DashboardFilterValue = useMemo(
    () => ({
      searchedDevice,
      setSearchedDevice,
      selectedDevice,
      setSelectedDevice,
      tag,
      setTag,
      dashboardDeviceEdited,
      setDashboardDeviceEdited,
      isOfflineDevicesStored,
      setIsOfflineDevicesStored,
      orderOfRenderGrater,
      setOrderOfRenderGrater,
    }),
    [
      searchedDevice,
      selectedDevice,
      tag,
      dashboardDeviceEdited,
      isOfflineDevicesStored,
      orderOfRenderGrater,
    ],
  );

  return (
    <DashboardFilterContext.Provider value={DashboardFilterValue}>
      {children}
    </DashboardFilterContext.Provider>
  );
};
