import React from "react";

import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  HighlightOff as HighlightOffIcon,
  Devices as DevicesIcon,
  DeleteOutline as DeleteOutlineIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";

import { green, grey } from "@mui/material/colors";
import useIsMobile, { useIsMediumPage } from "../../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ModalRemove from "../../Devices/Modal/ModalRemove";
import ModalUnshare from "../../Devices/Modal/ModalUnshare";
import Switch from "../../../components/Switch";
import { ToastContext } from "../../../contexts/ToastContext";
import { editUserById } from "../../../services/data/user";
import AvatarIcon from "../../../components/AvatarIcon";
import { ThemeModeContext } from "../../../contexts/ThemeModeContext";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import { AuthContext } from "../../../contexts/AuthContext";
import { getUserDevices } from "../../../services/data/arcsys";
import DataGridServerSide from "../../../components/DataGridServerSide";

interface DevicesProps {
  user: any;
  setDeviceEdited: (e: boolean) => void;
  deviceEdited: boolean;
}

const Devices = ({ user, setDeviceEdited, deviceEdited }: DevicesProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mobile = useIsMobile();
  const smallWindow = window.innerWidth < 1270;
  const mediumPage = useIsMediumPage();
  const { toastError, toastSuccess } = React.useContext(ToastContext);
  const { theme } = React.useContext(ThemeModeContext);
  const { userArcsys, userId } = React.useContext(AuthContext);
  const [searchedDevice, setSearchedDevice] = React.useState<string>("");
  const [isSearched, setIsSearched] = React.useState<boolean>(false);
  const [searchedClicked, setSearchedClicked] = React.useState<string>("");
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [devices, setDevices] = React.useState<any>([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [searchedCleaned, setSearchedCleaned] = React.useState<boolean>(false);
  const [queryType, setQueryType] = React.useState("name");

  // Checar se o usuário é Admin
  const userIsAdmin = userArcsys?.role === "ADMIN";

  // useStates do modal de deletar
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);

  // useStates do modal de descompartilhar
  const [modalUnshareValues, setModalUnshareValues] = React.useState<any>();
  const [openModalUnshare, setOpenModalUnshare] = React.useState(false);

  // Email de alertas
  const [checkedValues, setCheckedValues] = React.useState<any[]>([]);

  // Selecionar o tipo de pesquisa
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryType(event.target.value);
  };

  // Ações do modal de deletar
  const handleOpenModalDelete = (e: any, id: string, device: string) => {
    e.stopPropagation();
    setModalDeleteValues({ id, device });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Ações do modal de descompartilhar
  const handleOpenModalUnshare = (
    e: any,
    id: string,
    device: string,
    ownerId: number,
  ) => {
    e.stopPropagation();
    setModalUnshareValues({ id, device, ownerId });
    setOpenModalUnshare(true);
  };
  const handleCloseModalUnshare = () => {
    setOpenModalUnshare(false);
  };

  React.useEffect(() => {
    const fetchStatus = () => {
      getUserDevices(
        false,
        pageNumber,
        rowsPerPage,
        searchedDevice,
        queryType,
        user._id,
      ).then((res: any) => {
        if (res?.data) {
          // Resgatar os dados do usuário para preencher os switchs
          if (res.data.preferences?.length > 0) {
            setCheckedValues(res.data.preferences);
          }

          // Resgatar os dispositivos do usuário
          setDevices(
            res.data.arcsys?.sort((s: any) =>
              s.arcsys.online === true ? -1 : 1,
            ),
          );
          setTotalPages(res.data.total);
        }
      });
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 10000000);
    return () => clearInterval(statusInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    rowsPerPage,
    searchedDevice,
    searchedCleaned,
    user._id,
    isSearched,
  ]);

  React.useEffect(() => {
    if (deviceEdited) {
      getUserDevices(
        false,
        pageNumber,
        rowsPerPage,
        searchedDevice,
        queryType,
        user._id,
      ).then((res) => {
        if (res?.data) {
          // Resgatar os dados do usuário para preencher os switchs
          if (res.data.preferences?.length > 0) {
            setCheckedValues(res.data.preferences);
          }

          // Resgatar os dispositivos do usuário
          setDevices(
            res.data.arcsys?.sort((s: any) =>
              s.arcsys.online === true ? -1 : 1,
            ),
          );
          setTotalPages(res.data.total);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceEdited, pageNumber, rowsPerPage, searchedDevice, user._id]);

  // Salvar os dados do usuário para preencher os switchs
  const handleSelect = (checked: string) => {
    const newNames = checkedValues?.includes(checked)
      ? checkedValues?.filter((name) => name !== checked)
      : [...(checkedValues ?? []), checked];
    setCheckedValues(newNames);
    // Editar os dados do usuário
    editUserById(+userId, { preferences: [...newNames] })
      .then(() => {
        toastSuccess(t("TOAST.SUCCESS.ALERT_EMAIL"));
        setDeviceEdited(true);
      })
      .catch(() => {
        toastError(t("TOAST.ERROR.ALERT_EMAIL"));
      });
    return newNames;
  };

  // Reordenar a lista de Arcsys e renderizar na tabela
  const arcsys = React.useMemo(
    () =>
      devices &&
      devices
        .filter((f: any) => f.id !== null)
        .sort((s: any) => (s.id?.online === true ? -1 : 1)),
    [devices],
  );

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      arcsys
        .filter(
          (f: any) =>
            f.arcsys?.ownerId === +userId ||
            (f.arcsys?.ownerId !== +userId && f.role !== "OWNER") ||
            userIsAdmin,
        )
        .map((row: any, i: number) => {
          return {
            id: i + 1,
            rowId: row.arcsys?._id,
            rowIdDelete: row.arcsys.id,
            ownerId: row.arcsys?.ownerId,
            name: row.arcsys?.name,
            email: row.arcsys?.owner?.email,
            mac: row.arcsys?.mac,
            actions: "",
            role: row.role,
            pending: row.pending,
            online: row.arcsys?.online,
            isMobile: "",
          };
        }),
    [arcsys, userId, userIsAdmin],
  );

  // Ação ao alterar a página da tabela de dispositivos
  const handleChangePage = (value: number) => {
    setPageNumber(value + 1);
  };

  // Barra de pesquisa
  const handleSearch = (e: any) => {
    setSearchedDevice(e);
    setIsSearched(true);
  };

  // Limpar barra de pesquisa
  const handleClearSearch = () => {
    setSearchedDevice("");
    setSearchedClicked("");
    setSearchedCleaned(true);
  };

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "ownerId",
            hide: true,
          },
          {
            field: "email",
            hide: true,
          },
          {
            field: "mac",
            hide: true,
          },
          {
            field: "pending",
            hide: true,
          },
          {
            field: "online",
            hide: true,
          },
          {
            field: "isMobile",
            hide: true,
          },
          {
            field: "name",
            headerName: t("TEXT.DEVICES"),
            align: "left",
            headerAlign: "left",
            flex: 3,
            renderHeader: () => (
              <p
                style={{
                  fontSize: "12px",
                  color: theme === "light" ? "#7E7E7E" : "inherit",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginLeft: "15px",
                }}
              >
                {t("TEXT.DEVICES")}
              </p>
            ),
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start" sx={{ p: 0, pl: 1 }}>
                  <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                    <AvatarIcon name={row.name} bgcolor={"#C7252C"} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {row.name}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          sx={{ display: "inline" }}
                          variant="body2"
                        >
                          {row.email}
                        </Typography>
                        <Box display="flex" mt={0.2} component="span">
                          <Typography
                            component="span"
                            fontWeight={500}
                            sx={{
                              mr: 0.5,
                              mt: 0.1,
                              color: theme === "light" ? "black" : "white",
                              fontSize: 13,
                            }}
                          >
                            MAC:
                          </Typography>
                          <Typography
                            component="span"
                            sx={{ display: "inline" }}
                            variant="body2"
                          >
                            {row.mac}
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            ),
          },
          {
            field: "actions",
            headerName: t("TEXT.ACTIONS"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) =>
              row.role === "OWNER" || userIsAdmin ? (
                <Tooltip title={t("TEXT.REMOVE_DEVICE")}>
                  <IconButton
                    color="error"
                    sx={{ p: 0 }}
                    onClick={(e) =>
                      handleOpenModalDelete(e, row.rowIdDelete, row.name)
                    }
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t("TEXT.UNSHARE_DEVICE_TITLE")}>
                  <IconButton
                    sx={{ p: 0 }}
                    color="error"
                    onClick={(e) =>
                      handleOpenModalUnshare(
                        e,
                        row.rowIdDelete,
                        row.name,
                        row.ownerId,
                      )
                    }
                  >
                    <HighlightOffIcon sx={{ fontSize: 26 }} />
                  </IconButton>
                </Tooltip>
              ),
          },
          {
            field: "role",
            headerName: t("TEXT.PERMISSION"),
            hide: +userId !== user._id ? false : true,
            renderCell: ({ row }: any) => (
              <Typography>
                {row?.role === "EDIT"
                  ? t("TEXT.EDIT")
                  : row?.role === "VIEW"
                    ? t("TEXT.VIEW")
                    : t("TEXT.MANAGE")}
              </Typography>
            ),
          },
          {
            field: "rowId",
            headerName: t("TEXT.EMAIL_ALERTS"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
                <FormControlLabel
                  sx={{ m: 0 }}
                  control={
                    <Switch checked={checkedValues.includes(row.rowId)} />
                  }
                  onClick={(e) => e.stopPropagation()}
                  onChange={() =>
                    row.pending === false &&
                    +userId === user._id &&
                    handleSelect(row.rowId)
                  }
                  label=""
                />
              </Tooltip>
            ),
          },
          {
            field: "status",
            sortable: false,
            disableColumnMenu: true,
            headerName: "Status",
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Chip
                label={
                  row.pending === true
                    ? t("TEXT.PENDING")
                    : row.pending === false && row.online
                      ? "Online"
                      : row.pending === false && !row.online && "Offline"
                }
                color={row.online ? "success" : "error"}
                sx={{
                  mr: 1,
                  fontWeight: 700,
                  backgroundColor:
                    row.pending === true
                      ? grey[200]
                      : row.online
                        ? green[100]
                        : "#FFD5D7",
                  color:
                    row.pending === true
                      ? grey[700]
                      : row.online
                        ? green[800]
                        : "#C7252C",
                  textTransform: "uppercase",
                  fontSize: mobile ? 10 : 12,
                }}
              />
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "rowId",
            hide: true,
          },
          {
            field: "ownerId",
            hide: true,
          },
          {
            field: "name",
            hide: true,
          },
          {
            field: "email",
            hide: true,
          },
          {
            field: "mac",
            hide: true,
          },
          {
            field: "actions",
            hide: true,
          },
          {
            field: "role",
            hide: true,
          },
          {
            field: "pending",
            hide: true,
          },
          {
            field: "online",
            hide: true,
          },
          {
            field: "isMobile",
            headerName: t("TEXT.DEVICES"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemAvatar sx={{ alignSelf: "center" }}>
                      <AvatarIcon name={row.name} bgcolor={"#C7252C"} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          sx={{
                            display: "inline",
                            fontWeight: 450,
                            whiteSpace: "break-spaces",
                          }}
                        >
                          {row.name}
                        </Typography>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ width: "10px" }}
                            mt={0.5}
                          >
                            {row.email}
                          </Typography>
                          <Box display="flex" mt={0.5} component="span">
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              MAC:
                            </Typography>
                            <Typography component="span" variant="body2">
                              {row.mac}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.5} component="span">
                            <Typography
                              component="span"
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.2,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.ACTIONS")}:
                            </Typography>
                            {row.role === "OWNER" || userIsAdmin ? (
                              <Tooltip title={t("TEXT.REMOVE_DEVICE")}>
                                <IconButton
                                  color="error"
                                  sx={{ p: 0 }}
                                  onClick={(e) =>
                                    handleOpenModalDelete(
                                      e,
                                      row.rowIdDelete,
                                      row.name,
                                    )
                                  }
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title={t("TEXT.UNSHARE_DEVICE_TITLE")}>
                                <IconButton
                                  sx={{ p: 0 }}
                                  color="error"
                                  onClick={(e) =>
                                    handleOpenModalUnshare(
                                      e,
                                      row.rowIdDelete,
                                      row.name,
                                      row.ownerId,
                                    )
                                  }
                                >
                                  <HighlightOffIcon sx={{ fontSize: 26 }} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                          {+userId !== user._id && (
                            <Box
                              display="flex"
                              mt={0.5}
                              mb={0.5}
                              component="span"
                            >
                              <Typography
                                component="span"
                                fontWeight={500}
                                sx={{
                                  mr: 0.5,
                                  color: theme === "light" ? "black" : "white",
                                  fontSize: 13,
                                }}
                              >
                                {t("TEXT.PERMISSION")}:
                              </Typography>
                              <Typography component="span" variant="body2">
                                {row?.role === "EDIT"
                                  ? t("TEXT.EDIT")
                                  : row?.role === "VIEW"
                                    ? t("TEXT.VIEW")
                                    : t("TEXT.MANAGE")}
                              </Typography>
                            </Box>
                          )}
                          {+userId === user._id && (
                            <Box
                              component="span"
                              display="flex"
                              justifyContent="left"
                              mt={0.5}
                            >
                              <Typography
                                fontWeight={600}
                                component="span"
                                sx={{
                                  mt: 0.4,
                                  mr: 1,
                                  color: theme === "light" ? "black" : "white",
                                  fontSize: 13,
                                }}
                              >
                                {t("TEXT.EMAIL_ALERTS")}:
                              </Typography>
                              <Tooltip title={t("TEXT.DISABLE_ENABLE")}>
                                <FormControlLabel
                                  sx={{ m: 0 }}
                                  control={
                                    <Switch
                                      checked={checkedValues.includes(
                                        row.rowId,
                                      )}
                                    />
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={() =>
                                    row.pending === false &&
                                    +userId === user._id &&
                                    handleSelect(row.rowId)
                                  }
                                  label=""
                                />
                              </Tooltip>
                            </Box>
                          )}
                          <Box
                            component="span"
                            display="flex"
                            justifyContent="left"
                            mt={0.5}
                          >
                            <Typography
                              fontWeight={600}
                              component="span"
                              sx={{
                                mt: 0.4,
                                mr: 1,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              Status:
                            </Typography>
                            <Chip
                              component="span"
                              label={
                                row.pending === true
                                  ? t("TEXT.PENDING")
                                  : row.pending === false && row.online
                                    ? "Online"
                                    : row.pending === false &&
                                      !row.online &&
                                      "Offline"
                              }
                              color={row.online ? "success" : "error"}
                              sx={{
                                mr: 1,
                                fontWeight: 700,
                                backgroundColor:
                                  row.pending === true
                                    ? grey[200]
                                    : row.online
                                      ? green[100]
                                      : "#FFD5D7",
                                color:
                                  row.pending === true
                                    ? grey[700]
                                    : row.online
                                      ? green[800]
                                      : "#C7252C",
                                textTransform: "uppercase",
                                fontSize: mobile ? 10 : 12,
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  return (
    <>
      <Paper
        sx={{ p: mobile ? 0 : 3.5, borderRadius: 3, mt: 3.5, mb: 3.5 }}
        elevation={3}
      >
        <Box
          display="flex"
          alignItems="center"
          pt={mobile ? 2 : 0}
          pl={mobile ? 2 : 0}
          pb={1.5}
        >
          <Box sx={{ mr: 1.6 }}>
            <DevicesIcon fontSize="large" />
          </Box>
          <Typography fontSize={mobile ? 25 : 28} gutterBottom>
            {t("TEXT.DEVICES")}
          </Typography>
        </Box>
        <Box pl={mobile ? 1.5 : 0} pr={mobile ? 1.5 : 0}>
          <FormControl>
            <FormLabel>{t("BUTTON.SEARCH")}:</FormLabel>
            <RadioGroup row value={queryType} onChange={handleChangeSearch}>
              <FormControlLabel
                value="name"
                control={<Radio />}
                label={t("TEXT.NAME")}
              />
              <FormControlLabel value="mac" control={<Radio />} label="MAC" />
            </RadioGroup>
          </FormControl>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              mr: mobile ? 2 : 4,
              borderRadius: 5,
              width: "100%",
              mt: 1,
              mb: 3,
              backgroundColor: theme === "dark" ? "#181818" : "#F7F7F7",
            }}
          >
            <Tooltip title={t("BUTTON.SEARCH")}>
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={(e: any) => {
                  handleSearch(searchedClicked);
                  e.preventDefault();
                  if (isSearched) {
                    setIsSearched(false);
                  }
                }}
              >
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <InputBase
              id="myInput"
              value={searchedClicked}
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  handleSearch(e.target.value);
                  e.preventDefault();
                  if (isSearched) {
                    setIsSearched(false);
                  }
                }
              }}
              onChange={(e: any) => {
                setSearchedClicked(e.target.value);
              }}
              sx={{ ml: 2, flex: 1 }}
              placeholder={t("TEXT.SEARCH_DEVICE")}
            />
            <Tooltip title={t("TEXT.CLEAN")}>
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={handleClearSearch}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </Paper>
        </Box>
        <Paper
          style={{
            height: 585,
            cursor: "pointer",
          }}
        >
          <DataGridServerSide
            rows={rows}
            isRowSelectable={(params: GridRowParams) =>
              +params.row.userId === +userId
            }
            columns={columns}
            message={t("TEXT.NO_DEVICES_AVAILABLE")}
            getRowHeight={() => "auto"}
            pageNumber={pageNumber}
            totalPages={totalPages}
            onRowClick={(params: any) =>
              params.row.pending === false &&
              navigate(`/devices/${params.row.mac}/${params.row.ownerId}`)
            }
            disableSelectionOnClick
            onPageChange={(newPage: number) => {
              handleChangePage(newPage);
            }}
            onPageSizeChange={(rowsPerPage: any) => setRowsPerPage(rowsPerPage)}
          />
        </Paper>
      </Paper>
      {modalDeleteValues && (
        <ModalRemove
          setDeviceEdited={setDeviceEdited}
          id={modalDeleteValues.id}
          device={modalDeleteValues.device}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
      {modalUnshareValues && (
        <ModalUnshare
          setDeviceEdited={setDeviceEdited}
          id={modalUnshareValues.id}
          userId={+user.id}
          device={modalUnshareValues.device}
          handleOpen={openModalUnshare}
          handleClose={handleCloseModalUnshare}
        />
      )}
    </>
  );
};

export default Devices;
