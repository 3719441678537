import { Box, Typography } from "@mui/material";

// Termos de uso
const Terms = () => {
  return (
    <Box
      sx={{
        overflowY: "auto",
        height: window.screen.width < 1550 ? 400 : 500,
        textAlign: "justify",
      }}
    >
      <Typography textAlign="center" variant="h5" sx={{ mt: 1 }}>
        Termos de uso do ARCSYS CLOUD
      </Typography>
      <Typography textAlign="center" variant="h5" sx={{ mt: 1 }}>
        Contrato de Licença de Usuário Final (EULA)
      </Typography>
      <Typography textAlign="center" variant="subtitle1" sx={{ mt: 1, mb: 1 }}>
        Última atualização de 12 de abril de 2018
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Os termos e condições a seguir estabelecem um contrato legal
        (“Contrato”) entre você (um indivíduo ou uma entidade), o usuário final
        e a InControl Controles Industriais Ltda (AGEON), localizada na Av. Ivo
        Lucchi 627, Palhoça, SC, 88133-510, Brasil.
      </Typography>
      <Typography variant="h6">Seção 1 - Da Aceitação dos termos</Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Para utilizar o sistema, o usuário deverá primeiro concordar com estes
        Termos e suas possíveis atualizações . Não é permitido o uso do sistema
        por parte do usuário se ele não aceitar os presentes Termos. O sistema é
        composto por uma combinação de produtos/dispositivos, software e
        serviços. O usuário pode aceitar os Termos: expressamente, clicando na
        opção aceitar ou concordar, caso esta opção esteja disponível na
        interface do sistema com o usuário; ou tacitamente, a partir do início
        da utilização do sistema. Neste caso, o usuário compreende e aceita que
        a AGEON considerará que o início da utilização do sistema constitui
        integral aceitação dos Termos aqui elencados.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        - PARA UTILIZAR O SISTEMA, VOCÊ DEVE OBRIGATORIAMENTE ACEITAR
        ATUALIZAÇÕES AUTOMÁTICAS DO SISTEMA. SE VOCÊ NÃO CONCORDA COM ISTO, VOCÊ
        NÃO PODERÁ UTILIZAR O SISTEMA.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        - A SEÇÃO 8, DESCREVE LIMITAÇÕES IMPORTANTES QUANTO A UTILIZAÇÃO DO
        SISTEMA, ESPECIALMENTE NO QUE DIZ RESPEITO ÀS APLICAÇÕES QUE ENVOLVAM
        RISCO DE MORTE OU OUTROS USOS CRÍTICOS. POR FAVOR LEIA ESTAS INFORMAÇÕES
        CUIDADOSAMENTE, POIS SERÁ PRESUMIDO QUE VOCÊ AS LEU E AS ACEITOU ANTES
        DE INICIAR A UTILIZAÇÃO DO SISTEMA.
      </Typography>
      <Typography variant="h6">Seção 2 - Da Licença</Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Sujeito aos Termos deste contrato (EULA), a Ageon concede a você uma
        licença limitada e não exclusiva (sem o direito de sublicenciar) para
        executar uma (1) cópia do software do sistema, somente na forma do
        código executável e somente em conjunto com produtos da Ageon que você
        seja o proprietário ou tenha permissão para acessá-lo.
      </Typography>
      <Typography variant="h6">Seção 3 - Da Restrições</Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        É absolutamente proibido que você ou qualquer outra pessoa pratique os
        seguintes atos:
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        a) Licenciar, vender, alugar, ceder, distribuir, transmitir, fazer
        hospedagem, fazer terceirização, divulgar ou explorar comercialmente o
        software do sistema, bem como disponibilizar o software do sistema por
        qualquer meio;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        b) Copiar ou usar o software do sistema para qualquer outra finalidade
        que não seja a permitida na Seção 2;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        c) Usar qualquer parte do software do sistema em dispositivo ou
        computador que não em conjunto com o produto da AGEON que você seja o
        proprietário ou tenha permissão para acessá-lo;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        d) Remover ou alterar qualquer marca registrada, logotipo, direitos
        autorais ou outros avisos de propriedade, legendas, símbolos ou rótulos
        do software do sistema;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        e) Modificar, fazer trabalhos derivados, ou fazer engenharia reversa de
        qualquer parte do software do sistema ou de qualquer parte do sistema.
      </Typography>
      <Typography variant="h6">
        Seção 4 - Das Atualizações automáticas de software
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON pode de tempos em tempos desenvolver correções de bugs,
        atualizações e outras modificações para melhorar a performance do
        sistema e os seus serviços relacionados. Estas poderão ser
        automaticamente instaladas, sem aviso prévio ou a necessidade de
        aprovação adicional. Você obrigatoriamente consentirá com estas
        atualizações automáticas.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Você declara, desde já, ter conhecimento de que você também poderá ser
        solicitado a instalar atualizações de software do sistema, e por esta
        razão concorda em instalar imediatamente qualquer atualização, correção
        ou modificação fornecida pela AGEON. O uso do sistema implica na sua
        integral concordância com estes Termos.
      </Typography>
      <Typography variant="h6">Seção 5 - Dos Direitos Autorais</Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        O software do sistema, seu direito autoral, seus segredos industriais,
        comerciais e outras propriedades intelectuais, a ele inerentes,
        pertencem exclusivamente a AGEON.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON se reserva todos os direitos associados ao sistema e ao software
        do sistema, mesmo os que não estão expressamente garantidos por este
        contrato (EULA), especialmente os previstos nas Leis n.9.609/98 e
        9.610/98.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        O software do sistema (e portanto as suas cópias) é licenciado para sua
        utilização, ficando resguardado o direito de propriedade da AGEON de
        acordo com este contrato (EULA) e com a legislação específica. Não
        existem licenças implícitas neste contrato (EULA).
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }} color="primary">
        Toda sugestão ou feedback fornecidos por você sobre o sistema será de
        propriedade da AGEON. A AGEON, de posse destas informações poderá usar,
        copiar, modificar, publicar ou redistribuir o conteúdo para qualquer fim
        e de qualquer maneira sem qualquer compensação para você.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }} color="primary">
        Você também concorda que a AGEON não renuncia a quaisquer direitos de
        usar idéias similares, ou relacionadas, anteriormente conhecidas pela
        AGEON, sejam elas desenvolvidas por seus colaboradores ou obtidas por
        outras fontes.
      </Typography>
      <Typography variant="h6">Seção 6 - Dos Termos e Rescisão</Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Este contrato (EULA), e a licença concedida nos termos deste, entra em
        vigor na data em que você começar a utilizar o sistema, e continuará
        válido enquanto você o utilizar, a menos que este contrato (EULA) seja
        encerrado de acordo com o previsto nesta seção.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON poderá rescindir este contrato (EULA) a qualquer momento se você
        não cumprir quaisquer dos termos aqui estipulados.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Você poderá rescindir este contrato (EULA) imediatamente após
        notificação por escrito à AGEON.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Após a rescisão deste contrato (EULA), a licença concedida nestes Termos
        terminará e você deverá cessar todo e qualquer uso do sistema, contudo,
        é importante ressaltar que os Termos da Seção 3 permanecerão eternamente
        em vigor, mesmo após o término deste contrato (EULA).
      </Typography>
      <Typography variant="h6">
        Seção 7 - Da Isenção de Garantia/ Responsabilidade
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        NÃO EXISTINDO NADA EM CONTRÁRIO E NA MÁXIMA EXTENSÃO PERMITIDA PELA LEI
        APLICÁVEL A AGEON FORNECE O SISTEMA &quot;NO ESTADO EM QUE SE
        ENCONTRA&quot;, POR ESTA RAZÃO NÃO OFERECE NENHUMA GARANTIA DE QUE O
        SISTEMA EXECUTARÁ COM APTIDÃO QUALQUER OBJETIVO PARTICULAR, NÃO GARANTE
        O ATENDIMENTO DE UMA NECESSIDADE ESPECÍFICA, NÃO GARANTE QUE SEJA OBTIDO
        UM RESULTADO DESEJADO, ASSIM COMO TAMBÉM NÃO GARANTE A PRECISÃO E A NÃO
        VIOLAÇÃO DE DIREITOS DE TERCEIROS.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON NÃO GARANTE QUALQUER RESULTADO ESPECÍFICO DESEJADO PELO USO DO
        SISTEMA.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON NÃO GARANTE QUE O FUNCIONAMENTO DO SISTEMA SERÁ ININTERRUPTO,
        LIVRE DE VÍRUS OU OUTRO ERRO DE CÓDIGO, OPORTUNO, SEGURO OU SEM ERROS.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ DEVE USAR TODAS AS INFORMAÇÕES (COMO DEFINIDO ABAIXO), DO SISTEMA E
        DESTE CONTRATO À SEU PRÓPRIO CRITÉRIO E RISCO.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ SERÁ RESPONSÁVEL POR (A AGEON SE ISENTA DE TODA E QUALQUER
        RESPONSABILIDADE) TODA E QUALQUER PERDA OU DANOS, INCLUINDO O CONJUNTO
        NO QUAL O SISTEMA POSSA ESTAR INSTALADO, TAIS COMO IMÓVEIS, OUTROS
        PRODUTOS, OUTROS PERIFÉRICOS CONECTADOS AO SISTEMA, COMPUTADORES,
        DISPOSITIVOS MÓVEIS, OUTROS ARTIGOS OU ANIMAIS NO LOCAL.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ É RESPONSÁVEL POR MANTER A CONFIDENCIALIDADE DA SUA SENHA E CONTA.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ É TOTALMENTE RESPONSÁVEL POR TODAS AS ATIVIDADES E AÇÕES QUE
        OCORREREM COM O USO DA SUA SENHA, CONTA E/OU CONTA COMPARTILHADA.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ DECLARA TER CONHECIMENTO DE QUE VOCÊ TERÁ A OPÇÃO DE COMPARTILHAR
        OS DISPOSITIVOS DA SUA CONTA COM TERCEIROS, E QUE SE VOCÊ COMPARTILHAR
        ALGUM DISPOSITIVO DA SUA CONTA COM TERCEIROS ELES PODERÃO TER ACESSO
        TOTAL OU PARCIAL AOS DADOS E CONFIGURAÇÕES DO SEU SISTEMA, INCLUINDO,
        MAS NÃO LIMITANDO-SE, A VISUALIZAÇÃO DE DADOS, EMISSÃO DE RELATÓRIOS,
        CONFIGURAÇÃO DE ALARMES, ALTERAÇÕES DE PARÂMETROS, PROGRAMAÇÕES, PODENDO
        INCLUSIVE DESLIGAR OU LIGAR O DISPOSITIVO E/OU OS CONTROLADORES
        CONECTADOS AO DISPOSITIVO.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ RECONHECE E CONCORDA QUE A AGEON NÃO TEM NENHUMA RESPONSABILIDADE
        COM RELAÇÃO ÀS AÇÕES DESTES TERCEIROS SOBRE OS DISPOSITIVOS QUE VOCÊ
        EVENTUALMENTE TENHA COMPARTILHADO.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ RECONHECE E CONCORDA QUE SE VOCÊ, COMPARTILHAR UM DISPOSITIVO COM
        TERCEIROS, ESTE PODERÁ RECEBER MENSAGENS DE ALERTA OU ALARME DO SEU
        DISPOSITIVO, JUNTO COM INFORMAÇÕES QUE PERMITAM IDENTIFICAR A FONTE DO
        ALERTA OU ALARME. ESTE TERCEIRO PODERÁ, OU NÃO, CONTACTAR VOCÊ NA
        OCORRÊNCIA DE UM ALERTA OU ALARME.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ RECONHECE E CONCORDA QUE A AGEON NÃO TERÁ NENHUMA RESPONSABILIDADE
        COM RELAÇÃO A AÇÃO OU A FALTA DE AÇÃO POR PARTE DE TERCEIROS.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ RECONHECE E CONCORDA QUE A AGEON NÃO TEM NENHUMA RESPONSABILIDADE
        NA CONFIGURAÇÃO DOS DADOS E ALERTAS/ALARMES DO SEU DISPOSITIVOS E/OU
        PERIFÉRICOS CONECTADOS A ELE.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ RECONHECE, COMPREENDE E CONCORDA QUE A AGEON NÃO POSSUI QUALQUER
        RESPONSABILIDADE:
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        a) PELA PRECISÃO, COMPLETUDE, CONFIABILIDADE E DISPONIBILIDADE DE
        QUALQUER CONTEÚDO OU INFORMAÇÃO FORNECIDA, DISPONIBILIZADA OU ACESSÍVEL
        ATRAVÉS DO SISTEMA;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        b) PELO USO OU INCAPACIDADE DE USO DO SISTEMA;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        c) PELA DELEÇÃO OU PERDA DE DADOS;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        d) POR EVENTUAIS QUEBRAS DE SEGURANÇA;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        e) PELAS INFORMAÇÕES DISPONIBILIZADAS ATRAVÉS DE MENSAGENS EM FÓRUNS;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        f) POR FALHAS EVENTUALMENTE COMETIDAS PELA AGEON;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        g) PELOS DANOS, PREJUÍZO OU PERDA DE VIDA OU DANOS À PROPRIEDADE.
      </Typography>
      <Typography variant="h6">
        Seção 8 - Da Limitação de responsabilidade
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Nada neste contrato (EULA), e em particular nesta cláusula de
        &quot;Limitação de Responsabilidade&quot;, deve excluir a
        responsabilidade que não pode ser excluída de acordo com a lei
        aplicável.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        NA EXTENSÃO MÁXIMA PERMITIDA PELA LEI APLICÁVEL, E ADICIONALMENTE ÀS
        ISENÇÕES DE GARANTIA/ RESPONSABILIDADE DA Seção 7:
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A) NO LIMITE PERMITIDO PELA LEI, EM HIPÓTESE ALGUMA A AGEON, SEUS
        REVENDEDORES AUTORIZADOS OU SUAS SUBSIDIÁRIAS SERÃO RESPONSÁVEIS POR
        QUAISQUER DANOS ESPECIAIS, INCIDENTAIS, DIRETOS, INDIRETOS, PUNITIVOS OU
        CONSEQUENTES (INCLUINDO, SEM LIMITAÇÃO, DANOS DE PERDA DE LUCROS OU
        INFORMAÇÕES CONFIDENCIAIS E OUTRAS, INTERRUPÇÃO DE NEGÓCIOS, DANOS
        PESSOAIS, PERDA DE PRIVACIDADE, FALHA NO CUMPRIMENTO DE QUALQUER
        OBRIGAÇÃO, E QUALQUER OUTRA PERDA PECUNIÁRIA OU DE QUALQUER OUTRA
        NATUREZA) DECORRENTES OU DE QUALQUER FORMA RELACIONADOS AO USO OU À
        INCAPACIDADE DO SISTEMA, AO FORNECIMENTO OU NÃO FORNECIMENTO DE SERVIÇOS
        DE SUPORTE, OU RELACIONADO A QUALQUER DISPOSIÇÃO DA LICENÇA, MESMO NO
        CASO DE FALHA, (INCLUINDO NEGLIGÊNCIA ), RESPONSABILIDADE ESTRITA,
        QUEBRA DE CONTRATO OU DE GARANTIA DA AGEON, MESMO QUE A AGEON TENHA SIDO
        AVISADA DA POSSIBILIDADE DE TAIS DANOS, OU MESMO SE SOUBESSE OU DEVESSE
        SABER DA POSSIBILIDADE DE TAIS DANOS, E;
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        B) EM NENHUMA HIPÓTESE A RESPONSABILIDADE CUMULATIVA TOTAL DA AGEON,
        PROVENIENTE OU RELACIONADA AO SISTEMA, SEJA EM CONTRATO, ATO ILÍCITO OU
        DE QUALQUER OUTRA FORMA, PODERÁ EXCEDER OS VALORES EFETIVAMENTE PAGOS
        POR VOCÊ A AGEON, NOS ÚLTIMOS 12 MESES (SE HOUVER), PELO PRODUTO QUE
        APRESENTOU PROBLEMA. ESTA LIMITAÇÃO É CUMULATIVA E NÃO SERÁ AUMENTADA
        PELA EXISTÊNCIA DE MAIS DE UM INCIDENTE OU RECLAMAÇÃO. A AGEON RENUNCIA
        A TODA E QUALQUER RESPONSABILIDADE, DE QUALQUER TIPO, DE LICENCIADORES
        E/OU FORNECEDORES DA AGEON.
      </Typography>
      <Typography variant="h6">Seção 9 - Das Limitações do Sistema</Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ DECLARA TER CONHECIMENTO DE QUE ESTE SISTEMA NÃO É UM SISTEMA PARA
        SITUAÇÕES DE EMERGÊNCIA.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        VOCÊ ENTENDE QUE ESTE SISTEMA NÃO É UM SISTEMA DE MONITORAMENTO DE
        EMERGÊNCIA TERCEIRIZADO.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON NÃO MONITORA NOTIFICAÇÕES DE EMERGÊNCIA E NÃO TENTARÁ AVISAR POR
        TELEFONE OU MEIO SIMILAR, E TAMBÉM NÃO IRÁ DESPACHAR UMA AUTORIDADE EM
        EMERGÊNCIA PARA O LOCAL ONDE O EQUIPAMENTO ESTÁ INSTALADO, CASO OCORRA
        ALGUMA EMERGÊNCIA OU ALERTA/ ALARME.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Este sistema não pode ser considerado uma solução para monitorar, salvar
        ou manter a vida de alguém que se encontre em risco.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Este sistema não é um substituto aos serviços ou mecanismos de
        emergência ou de qualquer equipamento de suporte à vida.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Este sistema recebe e disponibiliza informações de dispositivos
        instalados em um determinado local. Todas as informações dos
        dispositivos são apresentadas &quot;como estão&quot; e &quot;conforme
        disponível&quot;.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON não pode garantir que a informação, disponibilizada pelo
        sistema, esteja correta ou atualizada. Nos casos em que é crítico o
        acesso às Informações dos dispositivos através do sistema, ele não
        poderá ser considerado um substituto para o acesso direto da informação
        no local.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Este sistema não poderá ser utilizado em aplicações críticas, onde
        qualquer alteração remota indevida, seja ela acidental ou proposital,
        nas configurações ou parâmetros do dispositivo ou dos controladores
        conectados a ele, possam ocasionar danos, prejuízos à saúde, perda de
        vida ou dano a propriedade.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON NÃO GARANTE OU PROMETE A DISPONIBILIDADE DE INFORMAÇÕES OU DE UM
        SISTEMA ININTERRUPTO OU ISENTO DE FALHAS.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON NÃO GARANTE QUE O SISTEMA VAI GERAR ALERTAS E ALARMES ADEQUADOS
        EM QUALQUER SITUAÇÃO.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON NÃO GARANTE QUE O SISTEMA OU O SEU DISPOSITIVO MÓVEL OU
        COMPUTADOR IRÁ TRANSMITIR OU RECEBER TODOS OS SINAIS ENVIADOS POR VOCÊ
        OU PARA VOCÊ.
      </Typography>
      <Typography variant="h6">Seção 10 - Das Alterações dos Termos</Typography>
      <Typography sx={{ mt: 2 }}>
        a) A AGEON poderá alterar periodicamente estes Termos ou incluir Termos
        adicionais. Quando essas alterações forem realizadas, a AGEON
        disponibilizará uma nova cópia dos termos universais em
        http://www.arcsyscloud.com/pt-BR/eula_text.html e quaisquer novos termos
        adicionais serão disponibilizados ao usuário no sistema afetado ou por
        meio deles;
      </Typography>
      <Typography sx={{ mb: 2 }}>
        b) O usuário compreende e concorda que, se utilizar o sistema após a
        data de alteração destes Termos e/ou a inclusão de Termos adicionais, a
        AGEON considerará essa utilização como uma aceitação da alteração dos
        Termos ou da inclusão de Termos adicionais atualizados.
      </Typography>
      <Typography variant="h6">
        Seção 11 - Da Descontinuação ou Modificação dos Serviços
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Você reconhece que a AGEON tem o direito de descontinuar a fabricação e
        o desenvolvimento de qualquer dos produtos e o suporte para o produto, a
        seu exclusivo critério, a qualquer momento, incluindo a distribuição de
        versões antigas do sistema, desde que a AGEON não descontinue o suporte
        para esse produto durante a continuidade do ano calendário vigente, sem
        prejuízo das disposições de rescisão aqui descritas.
      </Typography>
      <Typography variant="h6">
        Seção 12 - Do Cancelamento e das Taxas
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON reserva-se o direito, a qualquer momento e de tempos em tempos,
        de cobrar pelo uso do sistema, modificar ou interromper, temporariamente
        ou permanentemente, o sistema (ou qualquer parte dele), com ou sem aviso
        prévio.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Você concorda, desde já, que a AGEON não será responsável perante você
        ou qualquer terceiro por qualquer modificação, suspensão ou
        descontinuação do sistema.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Este contrato (EULA) é válido a partir do momento que você utilizar o
        sistema pela primeira vez, e continuará valendo enquanto você continuar
        usando o sistema e seus serviços, e fazendo os pagamentos necessários
        para a utilização do sistema.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        A AGEON poderá rescindir este EULA a qualquer momento se você não
        cumprir qualquer um dos Termos aqui descritos, incluindo, se aplicável,
        a falta dos pagamentos necessários para a utilização do sistema.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Em caso de falta de pagamento, se aplicável, a AGEON poderá suspender ou
        encerrar definitivamente o seu acesso ao sistema.
      </Typography>
      <Typography sx={{ mt: 2, mb: 2 }}>
        Dependendo dos serviços contratados, de forma expressa ou tácita, a
        AGEON poderá negar o seu acesso, suspender ou encerrar sua conta de
        usuário devido à sua inatividade, à sua atividade excessiva, ou em
        decorrência da violação deste EULA, ou mesmo, por qualquer outra razão,
        a qualquer tempo.
      </Typography>
      <Typography sx={{ mt: 2 }}>
        Dependendo dos serviços contratados, de forma expressa ou tácita, você
        poderá rescindir este EULA mediante notificação por escrito à AGEON.
        Após a rescisão deste EULA, a licença concedida será rescindida, sem
        prejuízo do disposto na Seção 3, e você deverá interromper imediatamente
        a utilização do sistema.
      </Typography>
    </Box>
  );
};

export default Terms;
