import * as Yup from "yup";

// Validações dos inputs que recebem a lib Formik
export const yupRequired = (yup: Yup.AnySchema) =>
  yup.required("ERROR.REQUIRED_FIELD");

export const yupString = Yup.string();

export const yupRequiredString = yupRequired(yupString);

export const yupStringMin = (min: number) => yupString.min(min);

export const yupRequiredUsername = yupRequired(
  Yup.string().matches(/^[a-zA-Z0-9_.-]+$/, "ERROR.INVALID_CHARACTERS"),
);

export const yupWhen = (
  key: string | string[],
  status: any,
  result: Yup.AnySchema,
) =>
  (yupString as any).when(key, {
    is: status,
    then: result,
  });

export const yupStringMax = (max: number) => yupString.max(max);

export const yupRequiredStringMax = (max: number) =>
  yupRequired(yupStringMax(max));

export const yupRequiredStringMin = (min: number) =>
  yupRequired(yupString.min(min, "ERROR.PASSWORD"));

export const yupNotOneOf = (values: string[], message = "") =>
  yupRequiredString.notOneOf(values, message);

export const yupOneOf = (values: unknown[], message = "") =>
  yupRequiredString.oneOf(values, message);

export const yupBoolean = yupRequired(Yup.boolean());

export const yupRequiredEmail = yupRequired(
  yupString.email("ERROR.INVALID_EMAIL"),
);

export const yupNumberMin = (min: number) =>
  yupRequired(Yup.number().min(min, "ERROR.VALUE_NOT_ALLOWED"));

export const yupNumberMinMax = (min: number, max: number) =>
  yupRequired(
    Yup.number()
      .min(min, "ERROR.VALUE_NOT_ALLOWED")
      .max(max, "ERROR.VALUE_NOT_ALLOWED")
      .typeError("ERROR.VALUE_NOT_ALLOWED"),
  );

export const yupInteger = (min: number, max: number) =>
  yupNumberMinMax(min, max).integer("ERROR.VALUE_NOT_ALLOWED");

export const yupDate = () =>
  Yup.date()
    .min(
      // Valor mínimo: data atual/mes atual/1900
      new Date(-2208988800000),
      "ERROR.VALUE_NOT_ALLOWED",
    )
    .max(new Date(), "ERROR.VALUE_NOT_ALLOWED")
    .typeError("ERROR.VALUE_NOT_ALLOWED")
    .required("ERROR.REQUIRED_FIELD");

export const yupMinimumArrayLength = (min: number) =>
  Yup.array().min(min, "ERROR.REQUIRED_FIELD");
