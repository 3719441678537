import { CancelScheduleSend as CancelScheduleSendIcon } from "@mui/icons-material";
import { Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { t } from "i18next";
import Modal from "../pages/Devices/Modal";

interface ModalProps {
  open: boolean;
  handleClose: (e: any) => void;
  title: string;
  description?: string;
  confirm: (e: any) => void;
}

export const ConfirmationModal = ({
  open,
  handleClose,
  title,
  description,
  confirm,
}: ModalProps) => {
  const closeModal = () => {
    handleClose(false);
  };

  return (
    <Modal open={open} handleClose={closeModal} width={330}>
      <Box display="grid">
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 3.5,
            pb: 3.5,
            borderRadius: 4,
            mb: 2,
            backgroundColor: "#FFD5D7",
          }}
          elevation={0}
        >
          <Avatar
            sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
          >
            <CancelScheduleSendIcon sx={{ fontSize: 55 }} />
          </Avatar>
        </Paper>
        <Typography fontSize={21} fontWeight={700} mb={1}>
          {t("TEXT.INVALIDATE_CODE")}
        </Typography>
        <Typography fontSize={14}>{title}</Typography>
        {description && (
          <Typography fontSize={16} fontWeight={700} align="center">
            {description}
          </Typography>
        )}
        <Button
          onClick={(e) => {
            confirm(e);
            handleClose(e);
          }}
          variant="contained"
          type="submit"
          sx={{
            mt: 2,
            borderRadius: 2,
            backgroundColor: "#C7252C",
            fontSize: 12,
            pt: 1,
            pb: 1,
          }}
          fullWidth
        >
          {t("BUTTON.CONFIRM")}
        </Button>
        <Button
          onClick={handleClose}
          fullWidth
          sx={{
            backgroundColor: "#FFD5D7",
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            pt: 1,
            pb: 1,
            color: "black",
          }}
        >
          {t("BUTTON.CANCEL")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
