import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";

import { RowData } from "../components/Controller/parser/types";
import { GetSubparamKeys, Param } from "../services/types/ctrls";

interface ControllerEditProviderProps {
  children: ReactNode;
}

export type ParamEdit<T extends Param | undefined = undefined> =
  Partial<RowData> & {
    value: number | string;
    param: T;
    subparam: GetSubparamKeys<T>;
  };

interface ControllerEditData {
  paramToEdit: ParamEdit | undefined;
  setParamToEdit: Dispatch<SetStateAction<ParamEdit | undefined>>;
}

export const ControllerEditContext = createContext({} as ControllerEditData);

export const ControllerEditProvider = ({
  children,
}: ControllerEditProviderProps) => {
  const [paramToEdit, setParamToEdit] = useState<ParamEdit>();

  const ControllerEditProviderValue = useMemo(
    () => ({
      paramToEdit,
      setParamToEdit,
    }),
    [paramToEdit],
  );

  return (
    <ControllerEditContext.Provider value={ControllerEditProviderValue}>
      {children}
    </ControllerEditContext.Provider>
  );
};
