import React from "react";

import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DeleteOutline as DeleteOutlineIcon,
  ManageAccountsOutlined as ManageAccountsOutlinedIcon,
  HelpOutline as HelpOutlineIcon,
} from "@mui/icons-material";

import useIsMobile from "../../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../contexts/AuthContext";
import ModalRemove from "../../Users/ModalRemove";
import ModalHelpDeleteAccount from "./Modal/ModalHelp";

interface AccountProps {
  user: any;
  setDeviceEdited: (e: boolean) => void;
}

const Account = ({ user, setDeviceEdited }: AccountProps) => {
  const mobile = useIsMobile();
  const { t } = useTranslation();
  const { userId } = React.useContext(AuthContext);

  // Ações de abrir o modal de dúvidas
  const [openModalHelp, setOpenModalHelp] = React.useState<boolean>(false);
  const handleOpenModalHelp = () => setOpenModalHelp(true);
  const handleCloseModalHelp = () => {
    setOpenModalHelp(false);
  };

  // Ações do modal de deletar o usuário
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleOpenModalDelete = () => {
    setModalDeleteValues({ id: user._id, user: user.name });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  return (
    <>
      <Paper sx={{ p: mobile ? 0 : 3.5, borderRadius: 3, mb: 3 }} elevation={3}>
        <Box
          display="flex"
          alignItems="center"
          pt={mobile ? 2 : 0}
          pl={mobile ? 2 : 0}
          pb={1.5}
        >
          <Box mr={1} mb={0.6}>
            <ManageAccountsOutlinedIcon fontSize="large" />
          </Box>
          <Typography fontSize={mobile ? 25 : 28} gutterBottom>
            {t("TEXT.ACCOUNT_MESSAGE")}
          </Typography>
        </Box>
        <Table size="small">
          <TableBody>
            {+userId === user._id && (
              <TableRow hover>
                <TableCell align="left">
                  <Box display="flex">
                    <DeleteOutlineIcon sx={{ alignSelf: "center" }} />
                    <Typography sx={{ ml: 2 }} alignContent="center">
                      {t("TEXT.ACCOUNT_TOPIC")}
                    </Typography>
                    <IconButton color="primary" onClick={handleOpenModalHelp}>
                      <HelpOutlineIcon color="info" />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="right">
                  {mobile ? (
                    <IconButton color="primary" onClick={handleOpenModalDelete}>
                      <DeleteOutlineIcon color="warning" />
                    </IconButton>
                  ) : (
                    <Button
                      size="small"
                      color="warning"
                      onClick={handleOpenModalDelete}
                      startIcon={<DeleteOutlineIcon />}
                      sx={{ fontSize: 13, fontWeight: 600, mt: 1, mb: 1 }}
                    >
                      {t("BUTTON.DELETE")}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      {modalDeleteValues && (
        <ModalRemove
          setUserEdited={setDeviceEdited}
          id={modalDeleteValues.id}
          name={modalDeleteValues.user}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
          isProfileUser={true}
        />
      )}
      <ModalHelpDeleteAccount
        handleOpen={openModalHelp}
        handleClose={handleCloseModalHelp}
      />
    </>
  );
};

export default Account;
