const SUBSCRIPTION_ROUTE = "/subscription";

export const INVOICE_ROUTE = "/invoice";

export const SELECT_PLAN_ROUTE = "/plan?sort=order:asc";

export const SUBMIT_PLAN_FREE_ROUTE = `${SUBSCRIPTION_ROUTE}/courtesy`;

// Lista de pagamentos pendentes
export const PENDING_INVOICES_ROUTE = (mac: string) =>
  `${INVOICE_ROUTE}/pendingInvoices/${mac}`;

export const V2_ROUTE = "/v2";

export const PLAN_ROUTE = "/plan";

// Rota para resgatar o histórico do pagamento
export const getStatementRoute = (mac: string) => `/statement/${mac}`;

// Rota para adicionar endereço MAC no Plano disponível selecionado
export const addSubscriptionInMACRoute = (mac: string, id: string) =>
  `${SUBSCRIPTION_ROUTE}/setopenplan?mac=${mac}&subID=${id}`;

// Rota para excluir pagamento pendente do Plano
export const deletePaymentPendingRoute = (id: string) =>
  `${INVOICE_ROUTE}/${id}`;

// Rota para criar uma assinatura de Recorrencia do pagamento
export const RECURRING_ROUTE = "/plansubscription";

// Rota para excluir pagamento recorrente do Plano
export const deletePaymentRecurringRoute = (id: string) =>
  `${RECURRING_ROUTE}/${id}`;

// Rota para cancelar uma assinatura de Recorrencia do pagamento
export const cancelRecurringRoute = (id: string) => `${RECURRING_ROUTE}/${id}`;

// Rota para resgatar lista de planos no modo Admin
export const GET_PLANS_ADMIN_ROUTE = `${PLAN_ROUTE}${V2_ROUTE}`;

// Rota para resgatar lista de cupons no modo Admin
export const GET_COUPONS_ADMIN_ROUTE = `/coupon${V2_ROUTE}`;

// Rota para resgatar os planos, pagamentos únicos e recorrências
export const getPlansRoute = (
  page: number,
  offset: number,
  general: string,
  queryType: string,
  status: string,
  asAdmin: number,
) =>
  `${SUBSCRIPTION_ROUTE}/v3?page=${page}&offset=${offset}&general=${general}&queryType=${queryType}&asAdmin=${asAdmin}&status=${status}`;

// Rota para editar plano por 'id' no modo Admin
export const editPlanByIdRoute = (id: string) => `${PLAN_ROUTE}/${id}`;

// Rota para remover plano por 'id' no modo Admin
export const deletePlanByIdRoute = (id: string) => `${PLAN_ROUTE}/${id}`;

// Rota para gerar o relatorio da lista de subs via csv no modo Admin nos planos
export const generateReportAdminAreaRoute = (
  includefree: boolean,
  status: string,
) =>
  `${SUBSCRIPTION_ROUTE}/activecsv?includefree=${includefree}&status=${status}`;

// Rota para resgatar lista de pagamentos pendentes no modo Admin
export const getInvoicesAdminRoute = (
  page: number,
  offset: number,
  general: string,
  status: string,
  queryType: string,
) =>
  `${INVOICE_ROUTE}/listinvoices?page=${page}&offset=${offset}&general=${general}&status=${status}&queryType=${queryType}`;

// Rota para deletar pagamento pendente no modo Admin
export const deleteInvoicesAdminRoute = (id: string) =>
  `${INVOICE_ROUTE}/${id}`;

// Rota que retorna o número de planos à vencer
export const PLANS_TO_EXPIRE_ROUTE = `${SUBSCRIPTION_ROUTE}/getplanstoexpire`;
