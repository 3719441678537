import React from "react";

import SettingsTable from "../../components/Controller/SettingsTable";
import Spinner from "../../components/Spinner";
import { Ctrl, CtrlModels, Setpoints } from "../../services/types/ctrls";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ParamProps {
  model: CtrlModels;
  permissionToEdit: boolean;
  devicePermission: boolean;
  permissionToEditTag: boolean;
  ctrls: Ctrl | undefined;
  deviceUpdateInterval: number;
  tagName: string;
  tag_id: string;
  setTagEdited: (e: boolean) => void;
  setpoint: Setpoints;
}

const Params = ({
  model,
  permissionToEdit,
  devicePermission,
  permissionToEditTag,
  ctrls,
  deviceUpdateInterval,
  tagName,
  tag_id,
  setTagEdited,
  setpoint,
}: ParamProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchStatus = () => {
      if (ctrls && model && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [ctrls, loading, model]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return loading ? (
    <Spinner />
  ) : ctrls && model ? (
    <SettingsTable
      settings={ctrls}
      model={model}
      permissionToEdit={permissionToEdit}
      permissionToEditTag={permissionToEditTag}
      devicePermission={devicePermission}
      deviceUpdateInterval={deviceUpdateInterval}
      tagName={tagName}
      tag_id={tag_id}
      setTagEdited={setTagEdited}
      setpoint={setpoint}
    />
  ) : (
    <Alert severity="warning" sx={{ mt: 1, textAlign: "center" }}>
      {t("TOAST.WARNING.ERROR_COM")}
    </Alert>
  );
};

export default Params;
