import {
  Box,
  Button,
  Chip,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  AddToPhotos as AddToPhotosIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { green } from "@mui/material/colors";
import React from "react";
import { getPlansAdmin } from "../../../../services/data/subscriptions";
import { api } from "../../../../services/api";
import { AuthContext } from "../../../../contexts/AuthContext";
import useIsMobile, { useIsMediumPage } from "../../../../hooks/useIsMobile";
import { getLanguage } from "../../../../translations/languages";
import { useTranslation } from "react-i18next";
import { TOKEN_KEY } from "../../../../auth/constants";
import { formatValueCents } from "../../parse";
import { GridColDef } from "@mui/x-data-grid";
import DataGridTable from "../../../../components/DataGrid/DataGridTable";
import ModalBenefits from "../../Modal/ModalBenefits";
import ModalPermissions from "./Modal/Permissions";
import { ThemeModeContext } from "../../../../contexts/ThemeModeContext";
import AvatarIcon from "../../../../components/AvatarIcon";
import ModalRemove from "./Modal/Remove";
import ModalAddPlan from "./Modal/Add";
import ModalEditPlan from "./Modal/Edit";
import Spinner from "../../../../components/Spinner";

const Plans = () => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const language = getLanguage().code;
  const smallWindow = window.innerWidth < 1200;
  const mediumPage = useIsMediumPage();
  const { token, authenticated } = React.useContext(AuthContext);
  const { theme } = React.useContext(ThemeModeContext);
  const [plans, setPlans] = React.useState<any[]>([]);
  const [planEdited, setPlanEdited] = React.useState<boolean>(false);
  const [searchedPlan, setSearchedPlan] = React.useState<string>("");
  const [loading, setLoading] = React.useState(true);
  const [searchedClicked, setSearchedClicked] = React.useState<string>("");

  React.useEffect(() => {
    if (authenticated) {
      if (token !== undefined) {
        localStorage.setItem(TOKEN_KEY, token);
      }
      api.defaults.headers.authorization = localStorage.getItem(TOKEN_KEY);
      const fetchStatus = () => {
        // Rota para resgatar lista de planos no modo Admin
        getPlansAdmin().then((res) => {
          const { data } = res;
          setPlans(data.data);
        });
      };
      fetchStatus();
      const statusInterval = setInterval(fetchStatus, 10000000);
      return () => clearInterval(statusInterval);
    }
  }, [authenticated, token]);

  // Se o Plano for editado resgata novamente a lista de Planos e atualiza o useState
  React.useEffect(() => {
    if (planEdited) {
      // Rota para resgatar lista de planos no modo Admin
      getPlansAdmin().then((res) => {
        const { data } = res;
        setPlans(data.data);
      });
      setPlanEdited(false);
    }
  }, [planEdited]);

  // Ação do modal dos benefícios
  const [openModalBenefits, setOpenModalBenefits] = React.useState(false);
  const [modalBenefitsValues, setModalBenefitsValues] = React.useState<any>();
  const handleOpenModalBenefits = (benefits: any) => {
    setModalBenefitsValues({ benefits });
    setOpenModalBenefits(true);
  };
  const handleCloseModalBenefits = () => setOpenModalBenefits(false);

  // Ação do modal das permissões
  const [openModalPermissions, setOpenModalPermissions] = React.useState(false);
  const [modalPermissionsValues, setModalPermissionsValues] =
    React.useState<any>();
  const handleOpenModalPermissions = (permissions: any) => {
    setModalPermissionsValues({ permissions });
    setOpenModalPermissions(true);
  };
  const handleCloseModalPermissions = () => setOpenModalPermissions(false);

  // Ação de limpar barra de pesquisa dos Planos
  const handleClearSearch = () => {
    (document.getElementById("myInput") as any).value = "";
    setSearchedPlan("");
    setSearchedClicked("");
  };

  // Ações do modal de adicionar
  const [openModalAdd, setOpenModalAdd] = React.useState(false);
  const handleOpenModalAdd = () => {
    setOpenModalAdd(true);
  };
  const handleCloseModalAdd = () => {
    setOpenModalAdd(false);
  };

  // Ações do modal de editar
  const [modalEditValues, setModalEditValues] = React.useState<any>();
  const [openModalEdit, setOpenModalEdit] = React.useState(false);
  const handleOpenModalEdit = (e: any, data: any) => {
    e.stopPropagation();
    setModalEditValues({ data });
    setOpenModalEdit(true);
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  // Ações do modal de remover
  const [modalDeleteValues, setModalDeleteValues] = React.useState<any>();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleOpenModalDelete = (e: any, id: string, plan: string) => {
    e.stopPropagation();
    setModalDeleteValues({ id, plan });
    setOpenModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  // Linhas da tabela
  const rows = React.useMemo(
    () =>
      plans
        .filter((f) => f?.data.length !== 0)
        .map((row: any, i: number) => {
          return {
            id: i + 1,
            _id: row?._id,
            name:
              language === "es"
                ? row?.data[2]?.name
                : language === "en"
                  ? row?.data[1]?.name
                  : row?.data[0]?.name,
            value:
              formatValueCents(row.value_cents.toString()) === "R$: 0"
                ? t("TEXT.FREE")
                : formatValueCents(row.value_cents.toString()),
            recurrence: row.interval,
            benefits:
              row?.data.length === 0
                ? []
                : language === "es"
                  ? row?.data[2]?.benefits
                  : language === "en"
                    ? row?.data[1]?.benefits
                    : row?.data[0]?.benefits,
            permissions: row?.permissions,
            hidden:
              row.hidden === true ? t("TEXT.NOT_VISIBLE") : t("TEXT.VISIBLE"),
            actions: "",
            mobileMode: "",
            identifier: row?.identifier,
            order: row?.order,
            languages: row?.data,
          };
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plans, language],
  );

  // Filtrar planos ativos
  const filteredRows = React.useMemo(
    () =>
      rows?.filter(
        (f: any) =>
          f.name?.toUpperCase()?.includes(searchedPlan?.toUpperCase()) ||
          f.name?.toLowerCase()?.includes(searchedPlan?.toLowerCase()),
      ),
    [rows, searchedPlan],
  );

  // Colunas da tabela
  const columns: GridColDef[] =
    !mobile && !mediumPage && !smallWindow
      ? [
          {
            field: "id",
            hide: true,
          },
          {
            field: "name",
            headerName: `${t("TEXT.NAME")}`,
            headerAlign: "left",
            flex: 2,
            renderHeader: () => (
              <p
                style={{
                  fontSize: "12px",
                  color: theme === "light" ? "#7E7E7E" : "inherit",
                  textTransform: "uppercase",
                  fontWeight: 700,
                  marginLeft: "15px",
                }}
              >
                {t("TEXT.NAME")}
              </p>
            ),
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="flex-start" sx={{ placeItems: "center" }}>
                  <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                    <AvatarIcon name={row.name} bgcolor={"#C7252C"} />
                  </ListItemAvatar>
                  <Box>
                    <Typography
                      component="span"
                      sx={{
                        display: "inline",
                        fontWeight: 450,
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {row.name !== "" ? row.name : "--"}
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            ),
          },
          {
            field: "value",
            headerName: t("TEXT.VALUE"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "recurrence",
            headerName: t("TEXT.RECURRENCE"),
            align: "center",
            headerAlign: "center",
            flex: 1,
          },
          {
            field: "benefits",
            headerName: t("TEXT.VIEW"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <List>
                <ListItem alignItems="center" sx={{ pb: 0 }}>
                  <Button
                    sx={{ p: 0, fontSize: 12 }}
                    onClick={() => handleOpenModalBenefits(row.benefits)}
                  >
                    {t("TEXT.BENEFITS")}
                  </Button>
                </ListItem>
                <ListItem alignItems="center" sx={{ pt: 0.8 }}>
                  <Button
                    sx={{ p: 0, fontSize: 12 }}
                    onClick={() => handleOpenModalPermissions(row.permissions)}
                  >
                    {t("TEXT.PERMISSIONS")}
                  </Button>
                </ListItem>
              </List>
            ),
          },
          {
            field: "actions",
            headerName: t("TEXT.ACTIONS"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <>
                <Tooltip title={t("TEXT.EDIT")}>
                  <IconButton
                    sx={{ p: 0.5 }}
                    color="warning"
                    onClick={(e) => handleOpenModalEdit(e, row)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("TEXT.REMOVE")}>
                  <IconButton
                    sx={{ p: 0.5 }}
                    color="error"
                    onClick={(e) => handleOpenModalDelete(e, row._id, row.name)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          },
          {
            field: "hidden",
            headerName: t("TEXT.VISIBILITY"),
            sortable: false,
            disableColumnMenu: true,
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mt={2} mb={2}>
                <Chip
                  label={row.hidden}
                  color={row.hidden === t("TEXT.VISIBLE") ? "success" : "error"}
                  sx={{
                    mr: 1,
                    fontWeight: 700,
                    backgroundColor:
                      row.hidden === t("TEXT.VISIBLE") ? green[100] : "#FFD5D7",
                    color:
                      row.hidden === t("TEXT.VISIBLE") ? green[800] : "#C7252C",
                    textTransform: "uppercase",
                    fontSize: 12,
                  }}
                />
              </Box>
            ),
          },
        ]
      : [
          {
            field: "id",
            hide: true,
          },
          {
            field: "name",
            hide: true,
          },
          {
            field: "value",
            hide: true,
          },
          {
            field: "recurrence",
            hide: true,
          },
          {
            field: "benefits",
            hide: true,
          },
          {
            field: "permissions",
            hide: true,
          },
          {
            field: "status",
            hide: true,
          },
          {
            field: "mobileMode",
            sortable: false,
            disableColumnMenu: true,
            headerName: t("TEXT.PLANS"),
            align: "center",
            headerAlign: "center",
            flex: 1,
            renderCell: ({ row }: any) => (
              <Box mr="auto">
                <List>
                  <ListItem alignItems="flex-start" sx={{ pl: 0.5, pr: 0 }}>
                    <ListItemAvatar sx={{ alignSelf: "center", mb: 1 }}>
                      <AvatarIcon name={row.name} bgcolor={"#C7252C"} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Box display="flex">
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.NAME")}:
                            </Typography>
                            <Typography variant="body2">{row.name}</Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.VALUE")}:
                            </Typography>
                            <Typography variant="body2">{row.value}</Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.RECURRENCE")}:
                            </Typography>
                            <Typography variant="body2">
                              {row.recurrence}
                            </Typography>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.3,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.BENEFITS")}:
                            </Typography>
                            <Button
                              sx={{ p: 0, fontSize: 12 }}
                              onClick={() =>
                                handleOpenModalBenefits(row.benefits)
                              }
                            >
                              {t("TEXT.VIEW")}
                            </Button>
                          </Box>
                          <Box display="flex" mt={0.8}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 0.3,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.PERMISSIONS")}:
                            </Typography>
                            <Button
                              sx={{ p: 0, fontSize: 12 }}
                              onClick={() =>
                                handleOpenModalPermissions(row.permissions)
                              }
                            >
                              {t("TEXT.VIEW")}
                            </Button>
                          </Box>
                          <Box display="flex" mt={1}>
                            <Typography
                              sx={{
                                mr: 0.5,
                                mt: 0.3,
                                color: theme === "light" ? "black" : "white",
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.ACTIONS")}:
                            </Typography>
                            <Tooltip title={t("BUTTON.EDIT")}>
                              <IconButton
                                sx={{ p: 0 }}
                                color="warning"
                                onClick={(e) => handleOpenModalEdit(e, row)}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("BUTTON.REMOVE")}>
                              <IconButton
                                sx={{ p: 0 }}
                                color="error"
                                onClick={(e) =>
                                  handleOpenModalDelete(e, row._id, row.name)
                                }
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Box display="flex" mt={1}>
                            <Typography
                              fontWeight={500}
                              sx={{
                                mr: 0.5,
                                mt: 1,
                                fontSize: 13,
                              }}
                            >
                              {t("TEXT.VISIBILITY")}:
                            </Typography>
                            <Chip
                              label={row.hidden}
                              color={
                                row.hidden === t("TEXT.VISIBLE")
                                  ? "success"
                                  : "error"
                              }
                              sx={{
                                mr: 1,
                                fontWeight: 700,
                                backgroundColor:
                                  row.hidden === t("TEXT.VISIBLE")
                                    ? green[100]
                                    : "#FFD5D7",
                                color:
                                  row.hidden === t("TEXT.VISIBLE")
                                    ? green[800]
                                    : "#C7252C",
                                textTransform: "uppercase",
                                fontSize: 12,
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            ),
          },
        ];

  // Loading do Spinner inicial da página
  React.useEffect(() => {
    const fetchStatus = () => {
      if (plans?.length !== 0 && loading) {
        setLoading(false);
      }
    };
    fetchStatus();
    const statusInterval = setInterval(fetchStatus, 1000);
    return () => clearInterval(statusInterval);
  }, [plans, loading]);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  return (
    <Box>
      <Box textAlign="right" mb={2}>
        <Button
          size="small"
          color="warning"
          startIcon={<AddToPhotosIcon />}
          onClick={handleOpenModalAdd}
          sx={{
            fontSize: 13,
            fontWeight: 600,
          }}
        >
          {t("BUTTON.ADD")}
        </Button>
      </Box>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          mr: mobile ? 2 : 4,
          borderRadius: 5,
          width: "100%",
          mt: 1,
          mb: 3,
          backgroundColor: theme === "dark" ? "#181818" : "#F9F9F9",
        }}
      >
        <Tooltip title={t("BUTTON.SEARCH")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={(e: any) => {
              setSearchedPlan(searchedClicked);
              e.preventDefault();
            }}
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <InputBase
          id="myInput"
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              setSearchedPlan(e.target.value);
              e.preventDefault();
            }
          }}
          onChange={(e: any) => {
            setSearchedClicked(e.target.value);
          }}
          sx={{ ml: 2, flex: 1 }}
          placeholder={t("TEXT.SEARCH_PLANS")}
        />
        <Tooltip title={t("TEXT.CLEAN")}>
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleClearSearch}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      {loading ? (
        <Box
          m={
            mobile
              ? "120px 0px"
              : window.screen.width < 1550
                ? "151px 0px"
                : "327.5px 0px"
          }
        >
          <Spinner />
        </Box>
      ) : (
        <Paper
          style={{
            height: mobile
              ? 365
              : mediumPage
                ? 711
                : window.screen.width < 1550
                  ? 359
                  : 711,
          }}
          elevation={0}
        >
          {rows && (
            <DataGridTable
              rows={searchedPlan === "" ? rows : filteredRows}
              columns={columns}
              message={t("TEXT.NO_SIGNATURE")}
              getRowHeight={() => "auto"}
              rowsPerPageOptions={[50]}
              disableSelectionOnClick
            />
          )}
        </Paper>
      )}
      {modalBenefitsValues && (
        <ModalBenefits
          benefits={modalBenefitsValues.benefits}
          handleClose={handleCloseModalBenefits}
          handleOpen={openModalBenefits}
        />
      )}
      {modalPermissionsValues && (
        <ModalPermissions
          permissions={modalPermissionsValues.permissions}
          handleClose={handleCloseModalPermissions}
          handleOpen={openModalPermissions}
        />
      )}
      <ModalAddPlan
        setPlanEdited={setPlanEdited}
        handleOpen={openModalAdd}
        handleClose={handleCloseModalAdd}
      />
      {modalEditValues && (
        <ModalEditPlan
          setPlanEdited={setPlanEdited}
          data={modalEditValues.data}
          handleOpen={openModalEdit}
          handleClose={handleCloseModalEdit}
        />
      )}
      {modalDeleteValues && (
        <ModalRemove
          setPlanEdited={setPlanEdited}
          id={modalDeleteValues.id}
          plan={modalDeleteValues.plan}
          handleOpen={openModalDelete}
          handleClose={handleCloseModalDelete}
        />
      )}
    </Box>
  );
};

export default Plans;
