import { api } from "../api";
import axios, { CancelTokenSource } from "axios";
import {
  GET_COUPONS_ADMIN_ROUTE,
  GET_PLANS_ADMIN_ROUTE,
  INVOICE_ROUTE,
  PENDING_INVOICES_ROUTE,
  PLANS_TO_EXPIRE_ROUTE,
  PLAN_ROUTE,
  RECURRING_ROUTE,
  SELECT_PLAN_ROUTE,
  SUBMIT_PLAN_FREE_ROUTE,
  addSubscriptionInMACRoute,
  cancelRecurringRoute,
  deleteInvoicesAdminRoute,
  deletePaymentPendingRoute,
  deletePaymentRecurringRoute,
  deletePlanByIdRoute,
  editPlanByIdRoute,
  generateReportAdminAreaRoute,
  getInvoicesAdminRoute,
  getPlansRoute,
  getStatementRoute,
} from "../routes/subscriptions";

// Resgatar o histórico do pagamento
export const getStatement = (mac: string) =>
  api.get<any>(getStatementRoute(mac));

// Lista de planos disponíveis
export const getSelectPlan = () => api.get<any>(SELECT_PLAN_ROUTE);

// Adicionar endereço MAC no Plano disponível selecionado
export const addSubscriptionInMAC = async (mac: string, id: string) =>
  api.post<any>(addSubscriptionInMACRoute(mac, id));

// Se o valor do Plano for '0' altera diretamente o Plano para o selecionado
export const submitPlanFree = async (data: any) =>
  api.post<any>(SUBMIT_PLAN_FREE_ROUTE, data);

let cancelTokenSetInvoice: CancelTokenSource;
let isRequestInProgress: boolean = false;

// Gera um Invoice de pagamento com os dados preenchidos do formulário
export const setInvoice = async (data: any) => {
  // Cancele a solicitação atual se houver outra em andamento
  if (isRequestInProgress) {
    return;
  }
  isRequestInProgress = true;
  // Cria um novo token de cancelamento
  cancelTokenSetInvoice = axios.CancelToken.source();
  try {
    const response = await api.post(INVOICE_ROUTE, data, {
      cancelToken: cancelTokenSetInvoice.token,
    });
    isRequestInProgress = false;
    return response;
  } catch (error) {
    isRequestInProgress = false;
    throw error;
  }
};

// Lista de pagamentos pendentes
export const getPendingInvoices = (mac: string) =>
  api.get<any>(PENDING_INVOICES_ROUTE(mac));

// Excluir pagamento pendente do Plano
export const deletePaymentPending = (id: string) =>
  api.delete<any>(deletePaymentPendingRoute(id));

// Excluir pagamento recorrente do Plano
export const deletePaymentRecurring = (id: string) =>
  api.delete<any>(deletePaymentRecurringRoute(id));

// Rota para criar uma assinatura de Recorrencia do pagamento
export const recurring = async (data: any) =>
  api.post<any>(RECURRING_ROUTE, data);

// Rota para cancelar uma assinatura de Recorrencia do pagamento
export const cancelRecurring = (id: string) =>
  api.delete<any>(cancelRecurringRoute(id));

// Rota para resgatar o IP da máquina do usuário
export const getIp = () => axios.get<any>("https://ifconfig.me/ip");

// Rota para resgatar lista de planos no modo Admin
export const getPlansAdmin = () => api.get<any>(GET_PLANS_ADMIN_ROUTE);

// Rota para resgatar lista de cupons no modo Admin
export const getCouponsAdmin = () => api.get<any>(GET_COUPONS_ADMIN_ROUTE);

// Rota para resgatar os planos, pagamentos únicos e recorrências
export const getPlans = (
  page: number,
  offset: number,
  general: string,
  queryType: string,
  status: string,
  asAdmin: number,
) =>
  api.get<any>(
    getPlansRoute(page, offset, general, queryType, status, asAdmin),
  );

// Rota para criar um novo plano no modo Admin
export const createAdminPlan = async (data: any) =>
  api.post<any>(PLAN_ROUTE, data);

// Rota para editar plano por 'id' no modo Admin
export const editPlanById = (id: string, data: any) =>
  api.patch<void>(editPlanByIdRoute(id), data);

// Rota para remover plano por 'id' no modo Admin
export const deletePlanById = (id: string) =>
  api.delete<any>(deletePlanByIdRoute(id));

// Rota para gerar o relatorio da lista de subs via csv no modo Admin nos planos
export const generateReportAdminArea = (includefree: boolean, status: string) =>
  api.get<any>(generateReportAdminAreaRoute(includefree, status));

// Rota para resgatar lista de pagamentos pendentes no modo Admin
export const getInvoicesAdmin = (
  page: number,
  offset: number,
  general: string,
  status: string,
  queryType: string,
) =>
  api.get<any>(getInvoicesAdminRoute(page, offset, general, status, queryType));

// Rota para deletar pagamento pendente no modo Admin
export const deleteInvoicesAdmin = (id: string) =>
  api.delete<any>(deleteInvoicesAdminRoute(id));

// Rota que retorna o número de planos à vencer
export const getPlansToExpire = () => api.get<any>(PLANS_TO_EXPIRE_ROUTE);
