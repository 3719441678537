import React from "react";
import { useTranslation } from "react-i18next";

import { HelpOutline as HelpOutlineIcon } from "@mui/icons-material";
import { Alert, Avatar, Box, Button, Paper, Typography } from "@mui/material";
import { PROFILE } from "../../../../Routes/constants";
import { AuthContext } from "../../../../contexts/AuthContext";
import useIsMobile from "../../../../hooks/useIsMobile";
import Modal from "../../Modal";

interface ModalAddProps {
  handleOpen: boolean;
  handleClose: () => void;
}

const ModalComFailureQuestion = ({
  handleOpen,
  handleClose,
}: ModalAddProps) => {
  const { t } = useTranslation();
  const mobile = useIsMobile();
  const { userId } = React.useContext(AuthContext);

  return (
    <Modal
      open={handleOpen}
      handleClose={handleClose}
      width={mobile ? 340 : 430}
    >
      <Paper
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 3.5,
          pb: 3.5,
          borderRadius: 4,
          mb: 2,
          backgroundColor: "#FFD5D7",
        }}
        elevation={0}
      >
        <Avatar
          sx={{ width: 85, height: 85, bgcolor: "#C7252C", boxShadow: 3 }}
        >
          <HelpOutlineIcon sx={{ fontSize: 65 }} />
        </Avatar>
      </Paper>
      <Typography fontSize={19} fontWeight={700} mb={2}>
        {t("TEXT.COMMUNICATION_FAILURE")}
      </Typography>
      <Alert severity="warning" sx={{ alignItems: "center" }}>
        <strong>{t("TEXT.ATTENTION")}:</strong>{" "}
        {t("TEXT.COMMUNICATION_FAILURE_INFO")}
        <strong> {t("TEXT.EMAIL_ALERTS")}</strong> {t("TEXT.OF_THE_DEVICE_IN")}
        <strong style={{ marginLeft: "4px" }}>
          <a href={`${PROFILE}/${userId}`}>{t("TEXT.USER_PROFILE")}</a>.
        </strong>
      </Alert>
      <Box mt={2} textAlign="center">
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#FFD5D7",
            mt: 1,
            borderRadius: 2,
            fontSize: 12,
            p: 1,
            pl: 3,
            pr: 3,
            color: "black",
          }}
        >
          {t("BUTTON.RETURN")}
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalComFailureQuestion;
